/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { CHECK_IN } from '../../../../api/mutations/appointment'
import { GET_PATIENT_CARD_DATA } from '../../../../api/queries/patient'
import { DateFormat } from '../../../../constants/dateFormat'
import { Spacing } from '../../../../constants/spacing'
import i18n from '../../../../locales/i18n'
import {
  Appointment,
  AppointmentReason,
} from '../../../../types/dto/Appointment'
import { VeterinarianInfo } from '../../../../types/dto/Veterinarian'
import { getPersonName } from '../../../../utils'
import { showBusinessOnMaps } from '../../../../utils/business'
import {
  addYearToFormatIfNotCurrent,
  getTimeDiffInSeconds,
} from '../../../../utils/date'
import {
  addAppointmentToCalendar,
  getPatientCardQueryVariables,
} from '../../../../utils/patient'
import { spacing } from '../../../../utils/spacing'
import Button, {
  ButtonEmphasis,
  ButtonSize,
  ButtonTheme,
} from '../../button/Button'
import Card from '../../card/Card/Card'
import Divider from '../../divider/Divider'
import CancelAppointmentModal from '../../modal/CancelAppointmentModal'
import Tag, { TagTheme } from '../../tag/Tag'
import Text, { TextVariant } from '../../typography/Text/Text'

export const SHOW_CHECK_IN_TIME = 60 * 60 // seconds

const CheckInState = {
  NONE: 'none',
  SHOW_CHECK_IN: 'checkIn',
  SHOW_ARRIVE: 'arrive',
}

type UpcomingAppointmentWidgetItemProps = {
  appointment: Appointment
  index: number
  onArrived: () => void
  onCheckedIn: (appointment: Appointment) => void
  parentIndex?: number
  supportedReminderStateIds: string[]
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: spacing(Spacing.S3, Spacing.S4),
  }),
  arrivedCheckedInContainer: css({
    padding: spacing(Spacing.S6, Spacing.S4, Spacing.S3, Spacing.S4),
  }),
  status: css({
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 0,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
  }),
  divider: css({
    marginTop: spacing(Spacing.S2),
    marginBottom: spacing(Spacing.S3),
  }),
  buttonsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: spacing(Spacing.S3),
    alignItems: 'center',
  }),
  cancelButton: css({
    marginRight: 'auto',
  }),
}

export const getAppointmentTypeWithDoctor = (
  reason: AppointmentReason | undefined,
  doctor: VeterinarianInfo | undefined,
) =>
  reason?.name
    ? `${reason?.name}${
      doctor
        ? ` ${i18n.t('Common:WITH').toLowerCase()} ${getPersonName(doctor)}`
        : ''
    }`
    : ''

const UpcomingAppointmentWidgetItem = ({
  appointment,
  index,
  parentIndex = 1,
  supportedReminderStateIds,
  onArrived,
  onCheckedIn,
  ...rest
}: UpcomingAppointmentWidgetItemProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const [checkInState, setCheckInState] = useState(CheckInState.NONE)
  const [showCancelModal, setShowCancelModal] = useState(false)

  const [checkIn, { loading }] = useMutation(CHECK_IN, {
    refetchQueries: [
      {
        query: GET_PATIENT_CARD_DATA,
        variables: getPatientCardQueryVariables(
          appointment?.patient?.id,
          supportedReminderStateIds,
        ),
      },
    ],
    onCompleted: ({ checkIn: data }) => {
      if (data.state.arrived) {
        onArrived()
      } else {
        onCheckedIn({ ...appointment, ...(data || {}) })
      }
    },
  })

  const isBoarding = appointment?.subType?.name?.toLowerCase() === 'boarding'
  const isCheckedIn = Boolean(appointment?.state?.checkedIn)
  const isArrived = Boolean(appointment?.state?.arrived)

  const arriveTime = addYearToFormatIfNotCurrent(
    DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME_SHORT,
    moment.tz(
      appointment.scheduledStartDatetime,
      appointment?.business?.timezone || 'UTC',
    ),
  )
  const leaveTime = addYearToFormatIfNotCurrent(
    DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME_SHORT,
    moment.tz(
      appointment.scheduledEndDatetime,
      appointment?.business?.timezone || 'UTC',
    ),
  )
  const type = getAppointmentTypeWithDoctor(
    appointment?.businessReason,
    appointment?.veterinarianInfo,
  )

  useEffect(() => {
    const secondsBeforeAppointmentStart = getTimeDiffInSeconds(
      appointment?.scheduledStartDatetime,
    )

    if (
      appointment
      && secondsBeforeAppointmentStart
      && secondsBeforeAppointmentStart >= -SHOW_CHECK_IN_TIME
      && secondsBeforeAppointmentStart <= SHOW_CHECK_IN_TIME
    ) {
      if (!appointment?.state?.checkedIn) {
        setCheckInState(CheckInState.SHOW_CHECK_IN)
      } else if (!appointment?.state?.arrived) {
        setCheckInState(CheckInState.SHOW_ARRIVE)
      } else {
        setCheckInState(CheckInState.NONE)
      }
    }
  }, [appointment])

  const handleCheckIn = (arrived: boolean) => {
    checkIn({
      variables: {
        businessId: appointment?.business?.id,
        appointmentId: appointment?.id,
        arrived,
      },
    })
  }

  const handleShowBusinessOnMaps = () => {
    if (appointment?.business) {
      showBusinessOnMaps(appointment.business)
    }
  }

  return (
    <Card
      css={[
        styles.container,
        (isCheckedIn || isArrived) && styles.arrivedCheckedInContainer,
      ]}
      {...rest}
    >
      {(isCheckedIn || isArrived) && (
        <Tag css={styles.status} theme={TagTheme.DEFAULT}>
          {isArrived ? t('Home:ARRIVED') : t('Home:CHECKED_IN')}
        </Tag>
      )}

      <Text variant={TextVariant.PARAGRAPH}>{appointment?.business?.name}</Text>

      <Text variant={TextVariant.SECTION_2}>
        {isBoarding ? `${t('Home:ARRIVE')}: ${arriveTime}` : arriveTime}
      </Text>

      {isBoarding && (
        <Text variant={TextVariant.SECTION_2}>
          {`${t('Home:LEAVE')}: ${leaveTime}`}
        </Text>
      )}

      <Text variant={TextVariant.PARAGRAPH}>{type}</Text>

      <Divider css={styles.divider} />

      <div css={styles.buttonsContainer}>
        <Button
          css={styles.cancelButton}
          disabled={loading}
          emphasis={ButtonEmphasis.TERTIARY}
          id={`pc${parentIndex}ua${index}-can`}
          size={ButtonSize.MEDIUM}
          theme={ButtonTheme.UTILITY}
          onClick={() => setShowCancelModal(true)}
        >
          {t('Common:CANCEL')}
        </Button>

        {appointment?.business && (
          <Button
            disabled={loading}
            emphasis={ButtonEmphasis.SECONDARY}
            id={`pc${parentIndex}ua${index}-map`}
            size={ButtonSize.MEDIUM}
            onClick={handleShowBusinessOnMaps}
          >
            {t('Home:MAP')}
          </Button>
        )}

        {checkInState === CheckInState.SHOW_CHECK_IN && (
          <Button
            disabled={loading}
            id={`pc${parentIndex}ua${index}-bcin`}
            loading={loading}
            size={ButtonSize.MEDIUM}
            onClick={() => handleCheckIn(false)}
          >
            {t('Home:CHECK_IN')}
          </Button>
        )}

        {checkInState === CheckInState.SHOW_ARRIVE && (
          <Button
            disabled={loading}
            id={`pc${parentIndex}ua${index}-barr`}
            loading={loading}
            size={ButtonSize.MEDIUM}
            onClick={() => handleCheckIn(true)}
          >
            {t('Home:HAVE_ARRIVED')}
          </Button>
        )}

        {checkInState === CheckInState.NONE && (
          <Button
            id={`pc${parentIndex}ua${index}-bcal`}
            size={ButtonSize.MEDIUM}
            onClick={() => addAppointmentToCalendar(appointment)}
          >
            {t('Home:ADD_TO_CALENDAR')}
          </Button>
        )}
      </div>

      <CancelAppointmentModal
        appointment={appointment}
        open={showCancelModal}
        supportedReminderStateIds={supportedReminderStateIds}
        onClose={() => setShowCancelModal(false)}
      />
    </Card>
  )
}

export default UpcomingAppointmentWidgetItem
