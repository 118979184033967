/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'

import { Chat } from '../../../types/dto/Chat'
import CreateChatForm from '../form/CreateChatForm'
import Modal, { ModalProps } from '../modal/Modal'
import { CertificatesTypeProp } from '../patient/certificates/CertificatesSheet'

type CreateChatModalProps = ModalProps & {
  certificateType?: CertificatesTypeProp
  onChatCreated: (chat: Chat) => void
  prescriptionTitle?: string
  selectedPatientId?: string
}

const CreateChatModal = ({
  selectedPatientId,
  certificateType,
  prescriptionTitle,
  onChatCreated,
  ...rest
}: CreateChatModalProps) => {
  const { t } = useTranslation('Chat')

  return (
    <Modal title={t('Chat:NEW_CHAT')} {...rest}>
      <CreateChatForm
        certificateType={certificateType}
        prescriptionTitle={prescriptionTitle}
        selectedPatientId={selectedPatientId}
        onChatCreated={onChatCreated}
      />
    </Modal>
  )
}

export default CreateChatModal
