/** @jsxImportSource @emotion/react */
import {
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { flushSync } from 'react-dom'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { GET_ACCOUNT_CLIENT_DETAILS_DATA } from '../../../api/queries/client'
import {
  SaveFormHandle,
  SaveFormLoadingState,
  SaveFormProps,
} from '../../../types/forms'
import ClientAddressForm from './ClientAddressForm'
import ClientContactInformationForm from './ClientContactInformationForm'

const ID = 'clientForm'

type CheckInReviewClientFormProps = SaveFormProps

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
}

const CheckInReviewClientForm = forwardRef<
SaveFormHandle,
CheckInReviewClientFormProps
>(function CheckInReviewClientForm(
  { onReady, onSaveCompleted, onSaveLoadingStateChange, ...rest },
  ref,
) {
  const { data: { me } = {}, loading } = useQuery(
    GET_ACCOUNT_CLIENT_DETAILS_DATA,
  )

  const refs = useRef<RefObject<SaveFormHandle>[]>([
    useRef<SaveFormHandle>(null),
    useRef<SaveFormHandle>(null),
  ])

  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({})
  const [completionState, setCompletionState] = useState<
  Record<string, boolean>
  >({})

  useEffect(() => {
    if (!loading) {
      onReady?.(ID)
    }
  }, [loading])

  const handleSave = () => {
    refs?.current?.forEach(handleRef => handleRef?.current?.save())
  }

  const handleSaveLoadingStateChange = (state: SaveFormLoadingState) => {
    const newState = {
      ...loadingState,
      [state.id]: state.loading,
    }
    setLoadingState(newState)

    if (onSaveLoadingStateChange) {
      onSaveLoadingStateChange({
        id: ID,
        loading: R.any(Boolean, R.values(newState)),
      })
    }
  }

  const handleSaveCompleted = async (id: string) => {
    flushSync(() => {
      const newState = {
        ...completionState,
        [id]: true,
      }
      setCompletionState(newState)

      if (
        onSaveCompleted
        && R.values(newState).length === refs?.current?.length
        && R.all(Boolean, R.values(newState))
      ) {
        onSaveCompleted(ID)
      }
    })
  }

  useImperativeHandle(ref, () => ({
    save: handleSave,
  }))

  return (
    <div css={styles.container} {...rest}>
      <ClientContactInformationForm
        alwaysEditMode
        hideBottomDivider
        hideEditButtons
        hideEmail
        client={me}
        loading={loading}
        ref={refs?.current?.[0]}
        onSaveCompleted={handleSaveCompleted}
        onSaveLoadingStateChange={handleSaveLoadingStateChange}
      />

      <ClientAddressForm
        alwaysEditMode
        hideBottomDivider
        hideEditButtons
        client={me}
        loading={loading}
        ref={refs?.current?.[1]}
        onSaveCompleted={handleSaveCompleted}
        onSaveLoadingStateChange={handleSaveLoadingStateChange}
      />
    </div>
  )
})

export default CheckInReviewClientForm
