/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_TASK_COMPLETED } from '../../api/queries/tasks'
import { Spacing } from '../../constants/spacing'
import { Constant } from '../../types/dto/Constants'
import { Task } from '../../types/dto/Task'
import { spacing } from '../../utils/spacing'
import Divider from '../common/divider/Divider'
import ChildTasksList, { BATCH_SIZE } from './ChildTasksList'

type CompletedChildTasksListProps = {
  parentTask: Task
  taskStates: Constant[]
}

const styles = {
  divider: css({
    margin: spacing(Spacing.S4, 0),
  }),
}

const CompletedChildTasksList = ({
  parentTask,
  taskStates,
  ...rest
}: CompletedChildTasksListProps) => {
  const { t } = useTranslation('Tasks')

  const [currentPage, setCurrentPage] = useState(1)

  const { data, loading: completedLoading } = useQuery(GET_TASK_COMPLETED, {
    variables: {
      id: parentTask?.id,
      offset: (currentPage - 1) * BATCH_SIZE,
      limit: BATCH_SIZE,
    },
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [parentTask])

  return data?.task?.history?.data?.length > 0 || completedLoading ? (
    <>
      <Divider css={styles.divider} />

      <ChildTasksList
        showTotalCount
        currentPage={currentPage}
        loading={completedLoading}
        parentTask={parentTask}
        taskStates={taskStates}
        tasks={data?.task?.history?.data || []}
        title={t('Tasks:COMPLETED')}
        totalCount={data?.task?.history?.totalCount}
        onPageChanged={setCurrentPage}
        {...rest}
      />
    </>
  ) : undefined
}

export default CompletedChildTasksList
