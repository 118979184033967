/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Patient } from '../../../types/dto/Patient'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import InputText from '../input/InputText'
import Text, { TextVariant } from '../typography/Text/Text'

type PatientInsuranceFormProps = {
  loading?: boolean
  onChange: (changed: boolean) => void
  patient?: Patient
}

export type PatientInsuranceFormHandle = {
  getData: () => {
    insurancePolicyNumber: string | undefined
    insuranceProvider: string | undefined
  }
  validate: () => boolean
}

const styles = {
  title: css({
    marginTop: spacing(Spacing.S4),
  }),
  fieldRow: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  firstField: css({
    marginTop: spacing(Spacing.S4),
  }),
  field: css({
    width: '100%',
  }),
  halfField: css({
    width: '48%',
  }),
  halfFieldRight: css({
    width: '50%',
  }),
}

const PatientInsuranceForm = forwardRef<
PatientInsuranceFormHandle,
PatientInsuranceFormProps
>(function PatientBasicInfoForm({ patient, loading, onChange, ...rest }, ref) {
  const { t } = useTranslation('Pet')

  const [insurance, setInsurance] = useState<string | undefined>()
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState<
  string | undefined
  >()

  useEffect(() => {
    if (patient) {
      setInsurance(patient.insuranceProvider)
      setInsurancePolicyNumber(patient.insurancePolicyNumber)
    }
  }, [patient, loading])

  useEffect(() => {
    onChange(
      !R.equals(patient?.insuranceProvider || '', insurance || '')
        || !R.equals(
          patient?.insurancePolicyNumber || '',
          insurancePolicyNumber || '',
        ),
    )
  }, [insurance, insurancePolicyNumber])

  useImperativeHandle(ref, () => ({
    getData: () => ({
      insuranceProvider: insurance,
      insurancePolicyNumber,
    }),
    validate: R.T,
  }))

  return (
    <div {...rest}>
      <Text
        css={styles.title}
        skeletonWidth={160}
        variant={TextVariant.SECTION_1}
      >
        {t('Pet:INSURANCE')}
      </Text>

      <div css={[styles.fieldRow, styles.firstField]}>
        <InputText
          css={[
            mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
            mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
          ]}
          id="epc-insurance"
          label={t('Pet:INSURANCE')}
          value={insurance}
          onChangeText={setInsurance}
        />

        <InputText
          css={[
            mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfFieldRight,
            mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
          ]}
          id="epc-policy"
          label={t('Pet:POLICY_NUMBER')}
          value={insurancePolicyNumber}
          onChangeText={setInsurancePolicyNumber}
        />
      </div>
    </div>
  )
})

export default PatientInsuranceForm
