import { gql } from '@apollo/client/core'

export const GET_PATIENT_CARD_DATA = gql`
  query GetPatientCardData(
    $id: ID!
    $offset: Int!
    $limit: Int!
    $states: [ID!]!
    $timezone: String!
    $dueFrom: Instant
    $dueTo: Instant
  ) {
    pet(id: $id) {
      id
      name
      photo
      photoThumbnail
      businesses {
        id
        timezone
      }
      upcomingAppointments {
        id
        meetingLink
        dialIn
        business {
          id
          name
          logo
          address
          suite
          state
          zip
          city
          location {
            latitude
            longitude
          }
          timezone
        }
        businessReason {
          businessAppointmentTypeId
          name
        }
        patient {
          id
          name
        }
        scheduledStartDatetime
        scheduledEndDatetime
        state {
          appointmentId
          arrived
          checkedIn
        }
        veterinarianInfo {
          id
          firstName
          lastName
        }
      }
      suggestedAppointments {
        title
        veterinarian {
          id
          firstName
          lastName
          photo
          photoThumbnail
        }
        description
        businessAppointmentReason {
          businessAppointmentTypeId
          name
          staticAppointmentType {
            id
            name
            category
          }
        }
        businessId
        scheduledStartDatetime
        scheduledEndDatetime
        notes
      }
      reminders(
        offset: $offset
        limit: $limit
        states: $states
        dueFrom: $dueFrom
        dueTo: $dueTo
      ) {
        data {
          id
          state {
            id
            name
          }
          name
          dueDatetime
        }
      }
      prescriptions(
        offset: $offset
        limit: $limit
        modifiedFrom: $dueFrom
        modifiedTo: $dueTo
      ) {
        data {
          id
          title
          dispense(timezone: $timezone)
          refillAmount
          refillsLeft
          business {
            id
            name
            primaryPhone
          }
        }
      }
    }
  }
`

export const GET_PATIENT_BASIC_DETAILS = gql`
  query GetPatientBasicDetails($id: ID!) {
    pet(id: $id) {
      id
      name
      photo
      photoThumbnail
      species {
        id
        name
        humanReadable
      }
      breeds {
        id
        name
      }
      gender {
        id
        name
        friendlyName
      }
      spayedNeuteredStatus {
        id
        name
      }
      dateOfBirth
      approximateAge
    }
  }
`

export const GET_PATIENT_DETAILS = gql`
  query GetPatientDetails($id: ID!) {
    pet(id: $id) {
      id
      name
      photo
      photoThumbnail
      species {
        id
        name
        humanReadable
      }
      breeds {
        id
        name
      }
      gender {
        id
        name
        friendlyName
      }
      spayedNeuteredStatus {
        id
        name
      }
      dateOfBirth
      approximateAge
      microchipNumber
      rabiesTag
      rabiesTagExpiration
      insuranceProvider
      insurancePolicyNumber
      license
      environment {
        id
        name
      }
      serviceDesignation {
        id
        name
      }
      fitnessLevel {
        id
        name
      }
      purchasedFrom {
        id
        name
      }
    }
  }
`

export const GET_PATIENT_SECONDARY_DETAILS = gql`
  query GetPatientSecondaryDetails($id: ID!) {
    pet(id: $id) {
      id
      name
      diet
      allergies
      schedule
      notes
    }
  }
`

export const GET_TIMELINE = gql`
  query GetTimeline($id: ID!, $types: [ID!]!, $from: Int!, $to: Int!) {
    pet(id: $id) {
      id
      name
      timelines(types: $types, from: $from, to: $to) {
        data {
          id
          title
          date
          type {
            id
            name
          }
          ... on TimelineVaccination {
            soap {
              id
            }
          }
          ... on TimelinePrescription {
            refills
          }
          ... on TimelineDiagnosisLogEntry {
            state {
              id
              name
              friendlyName
            }
            location {
              id
              name
              friendlyName
            }
            creationDate
            modificationDate
          }
          ... on TimelineProblemDiagnosisLogEntry {
            state {
              id
              name
              friendlyName
            }
            enums {
              id
              enumName {
                id
                name
                friendlyName
              }
              enumValue {
                id
                name
                friendlyName
              }
              parentEnumValueId
            }
            creationDate
            modificationDate
            modifier {
              id
              firstName
              lastName
              photo
              photoThumbnail
            }
          }
          business {
            id
            timezone
          }
        }
        totalCount
      }
    }
  }
`

export const GET_CERTIFICATES = gql`
  query GetCertificates($patientId: ID!) {
    certificates(patientId: $patientId) {
      proofOfVaccinationsCertificates {
        id
        name
        fileUrl
        creationDate
      }
      rabiesCertificates {
        id
        name
        fileUrl
        creationDate
      }
    }
  }
`
