/** @jsxImportSource @emotion/react */
import { KibChevronDownIcon, KibChevronRightIcon } from '@chewy/kib-icons-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { clickable } from '../../../utils/component'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Text, { TextVariant } from '../typography/Text/Text'

type NavigationItemProps = {
  current: boolean
  id?: string
  onItemSelected?: (path: string) => void
  path: string
  showChevron?: boolean
  showMenuChevron?: boolean
  title: string
}

const styles = {
  container: css({
    padding: spacing(Spacing.S4),
    '&:hover': css({
      backgroundColor: ColorVariables.UI_BG_OVERLAY_PRIMARY,
      cursor: 'pointer',
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
  }),
  rowContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  title: css({
    color: ColorVariables.TEXT_INVERSE,
    pointerEvents: 'none',
    opacity: 0.8,
  }),
  titleCurrent: css({
    fontWeight: 'bold',
    opacity: 1,
  }),
  chevron: css({
    widht: 34,
    height: 34,
    color: ColorVariables.TEXT_INVERSE,
    opacity: 0.8,
  }),
  chevronCurrent: css({
    opacity: 1,
  }),
  menuChevron: css({
    widht: 24,
    height: 24,
    color: ColorVariables.TEXT_INVERSE,
    opacity: 0.8,
    marginLeft: spacing(Spacing.S1),
  }),
  chevronHidden: css({
    visibility: 'hidden',
  }),
}

const NavigationItem = ({
  id,
  title,
  path,
  current,
  showChevron = true,
  showMenuChevron = false,
  onItemSelected,
}: NavigationItemProps) => (
  <div
    css={[styles.container, showMenuChevron && styles.rowContainer]}
    id={id}
    {...clickable(() => onItemSelected?.(path))}
  >
    <Text
      css={[styles.title, current && styles.titleCurrent]}
      variant={TextVariant.SECTION_1}
    >
      {title}
    </Text>

    {mediaQueryMatches(MediaQuery.MAX_SM) && (
      <KibChevronRightIcon
        css={[
          styles.chevron,
          !showChevron && styles.chevronHidden,
          current && styles.chevronCurrent,
        ]}
      />
    )}

    {showMenuChevron && (
      <KibChevronDownIcon
        css={[styles.menuChevron, current && styles.chevronCurrent]}
      />
    )}
  </div>
)

export default NavigationItem
