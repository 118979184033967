/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'

import { GET_PATIENT_DETAILS_CONSTANTS } from '../../../api/queries/constants'
import { MediaQuery } from '../../../constants/mediaQuery'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import PatientDetailsFragment from './PatientDetailsFragment'
import PatientTimelineFragment from './PatientTimelineFragment'

const PatientDetailsPage = () => {
  const { patientId } = useParams()

  const { data: { constants: { spayedNeuteredStatuses = [] } = {} } = {} } =
    useQuery(GET_PATIENT_DETAILS_CONSTANTS)

  return (
    <KibGrid align="center">
      <KibGridItem
        offset="2@min-lg"
        span="4@max-sm 3@min-md 3@min-lg"
      >
        <PatientDetailsFragment
          patientId={patientId}
          spayedNeuteredStatuses={spayedNeuteredStatuses}
        />
      </KibGridItem>

      {mediaQueryMatches(MediaQuery.MIN_MD) && (
        <KibGridItem push="2@min-lg" span="5@min-md 5@min-lg">
          <PatientTimelineFragment patientId={patientId} />
        </KibGridItem>
      )}
    </KibGrid>
  )
}

export default PatientDetailsPage
