/* eslint-disable no-console */
import { useRef } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'

import { Environment } from '../constants/environmentVariables'
import { getEnvironment, isLocalEnvironment } from '../utils/environment'

const X_BOOP_VARIATION_HEADER = 'X-Boop-Variation'
const X_BOOP_LOCALE = 'X-Boop-Locale'
export const X_BOOP_DEV_ENV = 'X-Boop-Dev-Env'

export interface ApolloProviderWithAuth0Props {
  children: React.ReactNode
}

type ApolloClientRef = React.MutableRefObject<
ApolloClient<NormalizedCacheObject> | undefined
>

const ApolloProviderWithAuth0 = ({
  children,
}: ApolloProviderWithAuth0Props) => {
  const { getAccessTokenSilently } = useAuth0()
  const client: ApolloClientRef = useRef()

  const httpLink = new HttpLink({
    uri: '/apigw-boop/graphql',
  })

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token
    try {
      token = await getAccessTokenSilently()
    } catch (error) {
      console.log(error)
    }

    const updatedHeaders = {
      ...headers,
      [X_BOOP_VARIATION_HEADER]: 'boop',
      [X_BOOP_LOCALE]: 'en-US',
      [X_BOOP_DEV_ENV]: isLocalEnvironment()
        ? Environment.STAGE
        : getEnvironment(),
    }

    if (!token) {
      return { ...rest, headers: updatedHeaders }
    }

    return {
      ...rest,
      headers: {
        ...updatedHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  })

  if (!client.current) {
    const cache = new InMemoryCache({
      typePolicies: {
        Constants: {
          merge(existing = [], incoming = []) {
            return { ...existing, ...incoming }
          },
        },
        MembershipPlan: {
          keyFields: ['planId'],
        },
        SavedAmount: {
          keyFields: ['businessId'],
        },
        FeatureToggle: {
          keyFields: ['name'],
        },
        Membership: {
          keyFields: ['businessId', 'patient', ['id']],
        },
        EmergencyContact: {
          keyFields: ['firstName', 'lastName', 'mobilePhone'],
        },
        ClientBusinessData: {
          keyFields: ['businessId'],
        },
        AppointmentStatus: {
          keyFields: ['appointmentId'],
        },
        Nps: {
          keyFields: ['appointmentId'],
        },
        TimeSlot: {
          keyFields: ['from', 'to'],
        },
        VaccinationDetails: {
          keyFields: ['title', 'date'],
        },
        BusinessAppointmentReason: {
          keyFields: ['businessAppointmentTypeId'],
        },
      },
    })

    client.current = new ApolloClient({
      link: authLink.concat(httpLink),
      cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    })
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>
}

export default ApolloProviderWithAuth0
