/** @jsxImportSource @emotion/react */
import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
} from 'react'
import { css } from '@emotion/react'

import { clickable } from '../../../../utils/component'

const styles = {
  file: css({
    display: 'none',
  }),
  childContainer: css({
    width: '100%',
  }),
}

export interface FileSelectorProps {
  accept?: string
  children: React.ReactNode
  multiple?: boolean
  onDragOverChange?: (value: boolean) => void
  onlyImages?: boolean
  processFiles: (
    event:
    | React.ChangeEvent<HTMLInputElement>
    | React.DragEvent<HTMLInputElement>,
  ) => void
}
const FileSelector = forwardRef(function FileSelector(
  {
    accept,
    children,
    onDragOverChange,
    onlyImages,
    processFiles,
    multiple = false,
  }: FileSelectorProps,
  ref,
) {
  const inputRef = useRef<HTMLInputElement>(null)

  const onClick = () => {
    inputRef?.current?.click()
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    processFiles(event)
    event.target.value = ''
  }

  const onDragIn = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (onDragOverChange) {
      onDragOverChange(true)
    }
  }

  const onDragOut = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (onDragOverChange) {
      onDragOverChange(false)
    }
  }

  const onDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    onDragOut(event)
    processFiles(event)
  }

  const childrenArray = React.Children.toArray(children) as ReactElement[]

  useImperativeHandle(ref, () => inputRef?.current)

  const content = childrenArray.map((child, index) => (
    <div
      css={styles.childContainer}
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      onDragEnter={onDragIn}
      onDragLeave={onDragOut}
      onDragOver={onDragIn}
      onDrop={onDrop}
      {...clickable(onClick)}
    >
      {child}
    </div>
  ))

  const acceptFilter = accept ?? (onlyImages ? 'image/*' : undefined)

  return (
    <>
      <input
        accept={acceptFilter}
        css={styles.file}
        multiple={multiple}
        ref={inputRef}
        type="file"
        onChange={onChange}
      />
      {content}
    </>
  )
})

export default FileSelector
