/** @jsxImportSource @emotion/react */
import {
  KibSuperSelect,
  KibSuperSelectProps,
} from '@chewy/kib-fields-new-react'

export type MultiselectOption = {
  label: string
  value: string
}

type MultiselectInputProps = Omit<
KibSuperSelectProps,
'options' | 'multiselect' | 'value'
> & {
  onChange?: (value: MultiselectOption[]) => void
  options?: MultiselectOption[]
  value?: MultiselectOption[]
}

const MultiselectInput = ({
  value,
  options,
  onChange,
  ...rest
}: MultiselectInputProps) => (
  <KibSuperSelect
    crossOrigin
    multiSelect
    options={options || []}
    value={value || []}
    onChange={onChange}
    {...rest}
  />
)

export default MultiselectInput
