/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react'
import { KibIngressCard } from '@chewy/kib-cards-react/legacy'
import { css } from '@emotion/react'
import {
  Client as TwilioChatClient,
  Conversation,
  ConversationUpdateReason,
} from '@twilio/conversations'
import moment from 'moment-timezone'
import * as R from 'ramda'

import {
  getConversationBySid,
  getUnreadMessagesCount,
} from '../../../api/twilio'
import { ColorVariables } from '../../../constants/colors'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import {
  LAST_MESSAGE,
  LAST_READ_MESSAGE_INDEX,
  UPDATED,
} from '../../../constants/twilio'
import { Chat } from '../../../types/dto/Chat'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Card from '../card/Card/Card'
import Avatar from '../icon/Avatar'
import Text, { TextVariant } from '../typography/Text/Text'

type ConversationUpdateData = {
  conversation: Conversation
  updateReasons: ConversationUpdateReason[]
}

type ConversationItemProps = {
  chat: Chat
  client?: TwilioChatClient
  id?: string
  onChatSelected: (chat: Chat) => void
  selected?: boolean
}

const styles = {
  container: css({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'visible',
    padding: spacing(Spacing.S2),
    '&:hover': {
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
      cursor: 'pointer',
    },
  }),
  selectedContainer: css({
    border: `2px solid ${ColorVariables.UI_BG_BRAND_PRIMARY}`,
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(0, Spacing.S2),
  }),
  lastMessageTimeContainer: css({
    display: 'flex',
    alignItems: 'center',
    columnGap: spacing(Spacing.S2),
  }),
  card: css({
    pointerEvents: 'none',
    marginTop: spacing(Spacing.S2),
    zIndex: 0,
    '.kib-ingress-card__content': {
      width: 0,
      flexGrow: 1,
      flex: 1,
    },
    '.kib-ingress-card__subtitle': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '.kib-ingress-card__image': {
      alignSelf: 'center',
    },
  }),
  selectedCard: css({
    '.kib-ingress-card__title': {
      color: ColorVariables.UI_BG_BRAND_PRIMARY,
    },
  }),
  unreadContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    position: 'absolute',
    top: -12,
    left: -12,
    backgroundColor: ColorVariables.UI_BG_BRAND_PRIMARY,
    ...mediaQuery(MediaQuery.MAX_SM, {
      position: 'unset',
      top: 'unset',
      left: 'unset',
      width: 20,
      height: 20,
    }),
  }),
  unreadText: css({
    color: ColorVariables.TEXT_INVERSE,
    fontWeight: 'bold',
  }),
}

const ConversationItem = ({
  id,
  chat,
  client,
  selected,
  onChatSelected,
  ...rest
}: ConversationItemProps) => {
  const [conversation, setConversation] = useState<Conversation | undefined>()
  const [unreadCount, setUnreadCount] = useState<number>(0)

  useEffect(() => {
    getConversationBySid(chat.channelSID, client)?.then(conv => {
      setConversation(conv)
    })
  }, [chat])

  const updateUnreadCount = () => {
    getUnreadMessagesCount(
      chat.channelSID,
      chat.messagesCount || 0,
      conversation,
    ).then(unread => {
      setUnreadCount(unread)
    })
  }

  const handleConversationUpdated = useCallback(
    (data: ConversationUpdateData) => {
      if (
        R.any(
          it => it === LAST_READ_MESSAGE_INDEX || it === LAST_MESSAGE,
          data.updateReasons,
        )
      ) {
        if (selected) {
          setUnreadCount(0)
        } else {
          updateUnreadCount()
        }
      }
    },
    [selected],
  )

  useEffect(() => {
    if (conversation) {
      updateUnreadCount()
    }
  }, [conversation])

  useEffect(() => {
    if (conversation) {
      conversation.on(UPDATED, handleConversationUpdated)
    }

    return () => {
      conversation?.off(UPDATED, handleConversationUpdated)
    }
  }, [conversation, selected])

  const businessName = chat?.business?.name
  const lastMessageDate = moment(chat?.messages?.data?.[0]?.createdAt).format(
    DateFormat.FULL_DATE_YEAR_SHORT,
  )
  const title = chat?.title || ''
  const subtitle = chat?.messages?.data?.[0]?.body || ''
  const photo = chat?.patient
    ? chat?.patient?.photoThumbnail || chat?.patient?.photo
    : chat?.business?.logo
  const avatarImageObjectFit = chat?.patient ? 'cover' : 'contain'
  const avatarBackgroundColor = chat?.patient
    ? ColorVariables.UI_BG_02
    : ColorVariables.UI_BG_PRIMARY

  const showSelected = selected && mediaQueryMatches(MediaQuery.MIN_MD)

  return (
    <Card
      css={[styles.container, showSelected && styles.selectedContainer]}
      id={id}
      {...rest}
      onClick={() => onChatSelected(chat)}
    >
      <div css={styles.header}>
        <Text variant={TextVariant.CAPTION}>{businessName}</Text>

        <div css={styles.lastMessageTimeContainer}>
          <Text variant={TextVariant.CAPTION}>{lastMessageDate}</Text>

          {unreadCount > 0 && mediaQueryMatches(MediaQuery.MAX_SM) && (
            <div css={styles.unreadContainer}>
              <Text css={styles.unreadText} variant={TextVariant.CAPTION}>
                {unreadCount}
              </Text>
            </div>
          )}
        </div>
      </div>

      <KibIngressCard
        css={[styles.card, showSelected && styles.selectedCard]}
        image={
          <Avatar
            avatarBackgroundColor={avatarBackgroundColor}
            avatarSize={48}
            imageStyle={css({ objectFit: avatarImageObjectFit })}
            photo={photo}
          />
        }
        subtitle={subtitle || ' '}
        tabIndex={-1}
        title={title}
        onClick={() => {}}
      />

      {unreadCount > 0 && mediaQueryMatches(MediaQuery.MIN_MD) && (
        <div css={styles.unreadContainer}>
          <Text css={styles.unreadText} variant={TextVariant.PARAGRAPH_2}>
            {unreadCount}
          </Text>
        </div>
      )}
    </Card>
  )
}

export default ConversationItem
