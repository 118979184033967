/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import { GET_PATIENT_BASIC_INFO_CONSTANTS } from '../../../api/queries/constants'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import i18n from '../../../locales/i18n'
import {
  Constant,
  ConstantWithFriendlyName,
  SortedSpecies,
} from '../../../types/dto/Constants'
import { Patient } from '../../../types/dto/Patient'
import { SaveFormProps } from '../../../types/forms'
import { findConstantByName } from '../../../utils'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Checkbox from '../choice/Checkbox'
import InputDatepicker from '../input/InputDatepicker'
import InputText from '../input/InputText'
import MultiselectInput, { MultiselectOption } from '../input/MultiselectInput'
import SelectInput from '../input/SelectInput'
import Text, { TextVariant } from '../typography/Text/Text'

const ID = 'patientBasicInfo'

type PatientBasicInfoFormProps = SaveFormProps & {
  loading?: boolean
  onChange?: (changed: boolean) => void
  patient?: Patient
}

export type PatientBasicInfoFormHandle = {
  getData: () => {
    breedIds: string[]
    dateOfBirth: string | null
    genderId: string | null
    name: string | undefined
    spayedNeuteredStatusId: string | null
    speciesId: string | undefined
  }
  validate: () => boolean
}

type ApproximateDate = {
  months?: string | number
  weeks?: string | number
  years?: string | number
}

type Validation = {
  name: boolean
  species: boolean
}

const styles = {
  title: css({
    marginTop: spacing(Spacing.S4),
  }),
  fieldRow: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  halfRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '48%',
  }),
  approximateRow: css({
    display: 'flex',
    alignItems: 'center',
    columnGap: spacing(Spacing.S3),
    width: '48%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '100%',
    }),
    ...mediaQuery(MediaQuery.MIN_MD, {
      columnGap: spacing(Spacing.S4),
    }),
  }),
  firstField: css({
    marginTop: spacing(Spacing.S4),
  }),
  field: css({
    width: '100%',
  }),
  halfField: css({
    width: '48%',
  }),
  halfFieldRight: css({
    width: '50%',
  }),
  checkboxContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S4),
  }),
}

const MALE_GENDER_NAME = 'Male'

const INTACT_NAME = 'Intact'
const SPAYED_NAME = 'Spayed'
const NEUTERED_NAME = 'Neutered'

const YES_OPTION = {
  label: i18n.t('Common:YES'),
  value: 'yes',
}
const NO_OPTION = {
  label: i18n.t('Common:NO'),
  value: 'no',
}

const getUpdatedDate = (approximateDate: ApproximateDate) =>
  moment(new Date())
    .subtract(approximateDate.years, 'years')
    .subtract(approximateDate.months, 'months')
    .subtract(approximateDate.weeks, 'weeks')
    .format(DateFormat.BACKEND_FULL_DATE)

const diffYears = (dob: string) =>
  String(moment(new Date()).diff(moment(dob), 'years'))

const diffMonths = (dob: string, years: string) =>
  String(
    moment(new Date()).subtract(years, 'years').diff(moment(dob), 'months'),
  )

const diffWeeks = (dob: string, years: string, months: string) =>
  String(
    moment(new Date())
      .subtract(years, 'years')
      .subtract(months, 'months')
      .diff(moment(dob), 'weeks'),
  )

const validateData = (
  name: string | undefined,
  species: SortedSpecies | undefined,
): Validation => ({
  name: Boolean(name),
  species: Boolean(species),
})

const PatientBasicInfoForm = forwardRef<
PatientBasicInfoFormHandle,
PatientBasicInfoFormProps
>(function PatientBasicInfoForm(
  { patient, loading, onChange, onReady, ...rest },
  ref,
) {
  const { t } = useTranslation(['Common', 'Pet'])

  const [name, setName] = useState<string | undefined>()
  const [species, setSpecies] = useState<SortedSpecies | undefined>()
  const [breeds, setBreeds] = useState<Constant[] | undefined>()
  const [gender, setGender] = useState<ConstantWithFriendlyName | undefined>()
  const [spayedNeuteredStatus, setSpayedNeuteredStatus] = useState<
  Constant | undefined
  >()
  const [dob, setDob] = useState<string | Date | undefined | null>()
  const [approximateAgeEnabled, setApproximateAgeEnabled] = useState<
  boolean | undefined
  >()
  const [approximateAge, setApproximateAge] = useState<
  ApproximateDate | undefined
  >({ years: 0, months: 0, weeks: 0 })

  const [triedToValidate, setTriedToValidate] = useState(false)
  const [validation, setValidation] = useState<Validation>()

  const {
    data: {
      constants: {
        spayedNeuteredStatuses = [],
        genders = [],
        sortedSpecies = [],
      } = {},
    } = {},
    loading: constantsLoading,
  } = useQuery(GET_PATIENT_BASIC_INFO_CONSTANTS)

  useEffect(() => {
    if (!constantsLoading) {
      onReady?.(ID)
    }
  }, [constantsLoading])

  useEffect(() => {
    if (patient) {
      setName(patient.name)

      if (patient?.species) {
        const selectedSpecies = sortedSpecies.find(
          (item: SortedSpecies) => item.id === patient?.species?.id,
        )
        setSpecies(selectedSpecies)

        if (patient?.breeds && patient?.breeds?.length > 0) {
          setBreeds(patient.breeds)
        }
      }

      setDob(patient.dateOfBirth)
      if (patient?.dateOfBirth) {
        const years = diffYears(patient.dateOfBirth)
        const months = diffMonths(patient.dateOfBirth, years)
        const weeks = diffWeeks(patient.dateOfBirth, years, months)
        setApproximateAge({
          years,
          months,
          weeks,
        })
      }
      setApproximateAgeEnabled(patient.approximateAge)

      setGender(patient.gender)
      setSpayedNeuteredStatus(patient.spayedNeuteredStatus)
    }
  }, [patient, sortedSpecies, loading, constantsLoading])

  useEffect(() => {
    if (onChange) {
      onChange(
        !R.equals(patient?.name || '', name || '')
          || patient?.species?.id !== species?.id
          || !R.equals(patient?.breeds || [], breeds || [])
          || !R.equals(patient?.dateOfBirth || '', dob || '')
          || patient?.approximateAge !== approximateAgeEnabled
          || patient?.gender?.id !== gender?.id
          || patient?.spayedNeuteredStatus?.id !== spayedNeuteredStatus?.id,
      )
    }
  }, [
    name,
    species,
    breeds,
    gender,
    spayedNeuteredStatus,
    dob,
    approximateAgeEnabled,
    approximateAge,
  ])

  const speciesOptions = sortedSpecies.map((item: SortedSpecies) => ({
    label: item.humanReadable,
    value: item.id,
  }))
  const selectedSpeciesOption = species
    ? { label: species.humanReadable, value: species.id }
    : {}

  const breedOptions =
    species?.breeds?.map(item => ({
      label: item.name,
      value: item.id,
    })) || []
  const selectedBreedsOption = breeds
    ? breeds.map(item => ({ label: item.name, value: item.id }))
    : []

  const gendersOptions = genders.map((item: ConstantWithFriendlyName) => ({
    label: item.friendlyName,
    value: item.id,
  }))
  const selectedGenderOption = gender
    ? { label: gender.friendlyName, value: gender.id }
    : {}

  const maleGender = findConstantByName(MALE_GENDER_NAME, genders)
  const isMale = gender?.id === maleGender?.id

  const intactOptions = [YES_OPTION, NO_OPTION]
  const intact = findConstantByName(INTACT_NAME, spayedNeuteredStatuses)
  const spayed = findConstantByName(SPAYED_NAME, spayedNeuteredStatuses)
  const neutered = findConstantByName(NEUTERED_NAME, spayedNeuteredStatuses)
  const isIntact = spayedNeuteredStatus?.id === intact?.id || false

  const handleSpeciesSelected = (id: string) => {
    const selectedSpecies = sortedSpecies.find(
      (item: SortedSpecies) => item.id === id,
    )
    if (selectedSpecies?.id !== species?.id) {
      setSpecies(selectedSpecies)
      setBreeds(undefined)
    }
  }

  const handleBreedSelected = (options: MultiselectOption[]) => {
    const newBreeds = R.innerJoin(
      (breed, option) => breed.id === option.value,
      species?.breeds || [],
      options,
    )
    setBreeds(newBreeds)
  }

  const handleGenderSelected = (id: string) => {
    const selectedGender = genders.find(
      (item: ConstantWithFriendlyName) => item.id === id,
    )
    setGender(selectedGender)
  }

  const handleIntactSelected = (option: string) => {
    if (!option) {
      setSpayedNeuteredStatus(undefined)
      return
    }

    const status =
      option === YES_OPTION.value ? intact : isMale ? neutered : spayed
    setSpayedNeuteredStatus(status)
  }

  const handleDobUpdated = (approximateDate: ApproximateDate) => {
    const date = getUpdatedDate(approximateDate)
    setDob(date)
  }

  const handleApproximateYearsChanged = (years: string | undefined) => {
    const newApproximateAge = approximateAge
      ? { ...approximateAge, years }
      : { years }
    setApproximateAge(newApproximateAge)

    handleDobUpdated(newApproximateAge)
  }

  const handleApproximateMonthsChanged = (months: string | undefined) => {
    const newApproximateAge = approximateAge
      ? { ...approximateAge, months }
      : { months }
    setApproximateAge(newApproximateAge)

    handleDobUpdated(newApproximateAge)
  }

  const handleApproximateWeeksChanged = (weeks: string | undefined) => {
    const newApproximateAge = approximateAge
      ? { ...approximateAge, weeks }
      : { weeks }
    setApproximateAge(newApproximateAge)

    handleDobUpdated(newApproximateAge)
  }

  useImperativeHandle(ref, () => ({
    getData: () => ({
      name,
      speciesId: species?.id,
      breedIds: breeds ? R.pluck('id', breeds) : [],
      genderId: gender?.id || null,
      dateOfBirth: dob ? moment(dob).utc(true).toISOString() : null,
      spayedNeuteredStatusId: spayedNeuteredStatus?.id || null,
    }),
    validate: () => {
      setTriedToValidate(true)
      const validationData = validateData(name, species)
      setValidation(validationData)

      return R.all(Boolean, Object.values(validationData))
    },
  }))

  return (
    <div {...rest}>
      <Text skeletonWidth={160} variant={TextVariant.SECTION_1}>
        {t('Pet:BASIC_INFORMATION')}
      </Text>

      <InputText
        css={[
          styles.firstField,
          mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
          mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
        ]}
        id="epc-name"
        invalid={validation && !validation.name}
        label={t('Common:NAME')}
        messagesContent={t('Pet:PLEASE_ENTER_NAME')}
        value={name}
        onChangeText={value => {
          setName(value)
          if (triedToValidate && validation) {
            setValidation({ ...validation, name: true })
          }
        }}
      />

      <div css={styles.fieldRow}>
        <SelectInput
          emptyOption
          css={[
            mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
            mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
          ]}
          id="epc-species"
          invalid={validation && !validation.species}
          label={t('Pet:SPECIES')}
          messagesContent={t('Pet:SPECIES_IS_REQUIRED')}
          options={speciesOptions}
          value={selectedSpeciesOption?.value}
          onChange={event => {
            handleSpeciesSelected(event.target.value)
            if (triedToValidate && validation) {
              setValidation({ ...validation, species: true })
            }
          }}
        />

        {species && species.breeds.length > 0 && (
          <MultiselectInput
            css={[
              mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfFieldRight,
              mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
            ]}
            id="epc-breeds"
            label={t('Pet:BREED')}
            options={breedOptions}
            value={selectedBreedsOption}
            onChange={value => {
              handleBreedSelected(value)
            }}
          />
        )}
      </div>

      <div
        css={[
          styles.fieldRow,
          mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfRow,
        ]}
      >
        <SelectInput
          emptyOption
          css={styles.halfField}
          id="epc-sex"
          label={t('Pet:SEX')}
          options={gendersOptions}
          value={selectedGenderOption?.value}
          onChange={event => {
            handleGenderSelected(event.target.value)
          }}
        />

        <SelectInput
          emptyOption
          css={styles.halfField}
          id="epc-intact"
          label={t('Pet:INTACT')}
          options={intactOptions}
          value={
            spayedNeuteredStatus
              ? isIntact
                ? YES_OPTION?.value
                : NO_OPTION?.value
              : undefined
          }
          onChange={event => {
            handleIntactSelected(event.target.value)
          }}
        />
      </div>

      <Text
        css={styles.title}
        skeletonWidth={160}
        variant={TextVariant.SECTION_1}
      >
        {t('Pet:DATE_OF_BIRTH')}
      </Text>

      <div css={styles.checkboxContainer}>
        <Checkbox
          id="epc-apprx-chk"
          label={t('Pet:I_AM_UNSURE_ESTIMATE')}
          selectedValue={approximateAgeEnabled}
          onChange={value => setApproximateAgeEnabled(Boolean(value))}
        />
      </div>

      {!approximateAgeEnabled && (
        <div css={styles.approximateRow}>
          <InputDatepicker
            inputId="epc-dob"
            inputStyle={[
              mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
              mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
            ]}
            label={t('Pet:DATE_OF_BIRTH')}
            value={dob ? moment(dob).toDate() : null}
            onChange={setDob}
          />
        </div>
      )}

      {approximateAgeEnabled && (
        <div css={styles.approximateRow}>
          <InputText
            id="epc-apprx-y"
            label={t('Common:YEARS')}
            type="number"
            value={approximateAge?.years}
            onChangeText={handleApproximateYearsChanged}
          />

          <InputText
            id="epc-apprx-m"
            label={t('Common:MONTHS')}
            type="number"
            value={approximateAge?.months}
            onChangeText={handleApproximateMonthsChanged}
          />

          <InputText
            id="epc-apprx-w"
            label={t('Common:WEEKS')}
            type="number"
            value={approximateAge?.weeks}
            onChangeText={handleApproximateWeeksChanged}
          />
        </div>
      )}
    </div>
  )
})

export default PatientBasicInfoForm
