/** @jsxImportSource @emotion/react */
import moment from 'moment-timezone'

import { DateFormat } from '../../../constants/dateFormat'
import { TimeSlot } from '../../../types/dto/Appointment'
import ChoiceChips, { ChoiceValue } from './ChoiceChips'

type BookTimeSlotsProps = {
  multiTime?: boolean
  onTimeSlotSelected: (timeSlot: TimeSlot) => void
  selectedTimeSlot?: TimeSlot
  timeSlots: TimeSlot[]
  timezone: string
}

const isBeforeNoon = (date: moment.Moment | undefined) => {
  const mDate = moment(date)
  const noon = mDate.clone().hour(12).minute(0).second(0)
  return mDate.isBefore(noon)
}

const formatTimeSlot = (
  from: moment.Moment | undefined,
  to: moment.Moment | undefined,
) => {
  const fromBeforeNoon = isBeforeNoon(from)
  const toBeforeNoon = isBeforeNoon(to)

  const fromFormat =
    (fromBeforeNoon && !toBeforeNoon) || (!fromBeforeNoon && toBeforeNoon)
      ? DateFormat.FULL_TIME_SHORT_MERIDIAN
      : DateFormat.FULL_TIME_SHORT

  const fromStr = moment(from).format(fromFormat)
  const toStr = moment(to).format(DateFormat.FULL_TIME_SHORT_MERIDIAN)
  return `${fromStr} - ${toStr}`
}

const formatTime = (time: moment.Moment | undefined) =>
  moment(time).format(DateFormat.FULL_TIME_SHORT_MERIDIAN)

const BookTimeSlots = ({
  timeSlots,
  multiTime,
  selectedTimeSlot,
  timezone,
  onTimeSlotSelected,
  ...rest
}: BookTimeSlotsProps) => {
  const toTimezone = (date: string | undefined) =>
    moment.tz(date, timezone || 'UTC')

  const currentTimeSlots =
    timeSlots.map((timeslot: TimeSlot, index) => ({
      label: multiTime
        ? formatTimeSlot(toTimezone(timeslot.from), toTimezone(timeslot.to))
        : formatTime(toTimezone(timeslot.from)),
      value: index,
    })) || []

  const selectedTimeSlotIndex = timeSlots.findIndex(
    (item: TimeSlot) =>
      item.from === selectedTimeSlot?.from && item.to === selectedTimeSlot?.to,
  )

  const handleTimeSlotSelected = (index: ChoiceValue) => {
    const timeSlot = timeSlots[Number(index)]
    onTimeSlotSelected(timeSlot)
  }

  return (
    <ChoiceChips
      choices={currentTimeSlots}
      id="ba-cc-ts"
      selectedValue={selectedTimeSlotIndex}
      onSelected={handleTimeSlotSelected}
      {...rest}
    />
  )
}

export default BookTimeSlots
