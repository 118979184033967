/** @jsxImportSource @emotion/react */
import { KibChipChoice, KibChipChoices, KibChipChoicesProps } from '@chewy/kib-chips-react'

export type ChoiceValue = string | number

export type ChoiceData = {
  label: string
  value: ChoiceValue
}

type ChoiceChipsProps = KibChipChoicesProps & {
  choices: ChoiceData[]
  className?: string
  onSelected: (value: ChoiceValue) => void
  selectedValue?: ChoiceValue
}

const ChoiceChips = ({
  choices,
  selectedValue,
  onSelected,
  ...rest
}: ChoiceChipsProps) => (
  <KibChipChoices {...rest}>
    {choices.map(choice => (
      <KibChipChoice
        key={choice.value}
        selectedValue={selectedValue}
        value={choice.value}
        onChange={event => onSelected(event.target.value)}
      >
        {choice.label}
      </KibChipChoice>
    ))}
  </KibChipChoices>
)

export default ChoiceChips
