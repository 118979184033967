/** @jsxImportSource @emotion/react */
import { KibPaginationNew, KibPaginationNewProps } from '@chewy/kib-menus-react'

type PagerProps = KibPaginationNewProps

const Pager = ({ ...rest }: PagerProps) => (
  <KibPaginationNew {...rest} />
)

export default Pager
