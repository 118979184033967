/** @jsxImportSource @emotion/react */
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_ACCOUNT_CLIENT_DETAILS_DATA } from '../../../api/queries/client'
import { Spacing } from '../../../constants/spacing'
import { spacing } from '../../../utils/spacing'
import Card from '../card/Card/Card'
import ClientAddressForm from '../form/ClientAddressForm'
import ClientContactInformationForm from '../form/ClientContactInformationForm'
import EmergencyContactForm from '../form/EmergencyContactForm'

const styles = {
  container: css({
    padding: spacing(Spacing.S5, Spacing.S4),
  }),
  infoForm: css({
    marginTop: spacing(Spacing.S3),
  }),
}

const ClientDetailsCard = ({ ...rest }) => {
  const { data: { me } = {}, loading } = useQuery(
    GET_ACCOUNT_CLIENT_DETAILS_DATA,
  )

  const contentLoading = !me && loading

  return (
    <Card css={styles.container} {...rest}>
      <ClientContactInformationForm client={me} loading={contentLoading} />

      <ClientAddressForm
        client={me}
        css={styles.infoForm}
        loading={contentLoading}
      />

      <EmergencyContactForm
        client={me}
        css={styles.infoForm}
        loading={contentLoading}
      />
    </Card>
  )
}

export default ClientDetailsCard
