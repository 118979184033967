/** @jsxImportSource @emotion/react */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import KibCropper, { KibCropperCropData } from '@chewy/kib-cropper-react'
import { KibImageIcon } from '@chewy/kib-icons-react/legacy'
import { css } from '@emotion/react'

import { BlobWithName, FileTemplate } from '../../../../types/entities'
import FileInputArea from './FileInputArea'
import SingleFileSelector from './SingleFileSelector'
import useFileInputHelper from './useFileInputHelper'

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
    height: 430,
  }),
}

export interface FileInputHandle {
  clear: () => void
  crop: () => void
  open: () => void
}

export interface FileInputProps {
  accept?: string
  aspectRatio?: string
  enableCropper?: boolean
  mobileText?: string
  onFileReady?: (file: FileTemplate) => void
  onFileSelected?: (file: FileTemplate) => void
  onProcessing?: (value: boolean) => void
  onlyImages?: boolean
  source?: string
  text?: string
}

const FileInput = forwardRef<FileInputHandle, FileInputProps>(
  function FileInput(
    {
      accept,
      onFileReady,
      onFileSelected,
      onProcessing,
      text,
      mobileText,
      aspectRatio,
      onlyImages = false,
      enableCropper = false,
      source,
    },
    ref,
  ) {
    const { t } = useTranslation('Common')
    const fileInputText =
      text || onlyImages
        ? t('Common:DRAG_YOUR_PHOTO_HERE_OR_CHOOSE_FILE')
        : t('Common:DRAG_YOUR_FILE_HERE_OR_CHOOSE_FILE')
    const fileInputMobileText = mobileText || t('Common:CLICK_CHOOSE_FILE')

    const [isDragOver, setIsDragOver] = useState(false)
    const [fileName, setFileName] = useState<string>()
    const [cropperData, setCropperData] = useState<KibCropperCropData>()

    const fileSelectorRef = useRef<HTMLInputElement>()

    const {
      updateSource,
      imageSource,
      setImageSource,
      onSelected: onSelectedHelper,
      error,
      setError,
    } = useFileInputHelper(onFileSelected)

    useEffect(() => {
      updateSource(source)
    }, [source])

    const onSelected = (file: FileTemplate) => {
      setFileName(file.raw?.name)
      onSelectedHelper(file)
    }

    const getCroppedCanvas = () => {
      if (cropperData && imageSource) {
        const tmpCanvas = document.createElement('canvas')
        tmpCanvas.width = cropperData.cropInfo.sWidth
        tmpCanvas.height = cropperData.cropInfo.sHeight

        const ctx = tmpCanvas.getContext('2d')
        if (ctx) {
          ctx.clearRect(0, 0, tmpCanvas.width, tmpCanvas.height)
          ctx.save()
          ctx.translate(tmpCanvas.width / 2, tmpCanvas.height / 2)
          ctx.rotate((cropperData.cropInfo.rotation * Math.PI) / 180)

          const img = new Image()
          img.src = imageSource
          ctx.drawImage(
            img,
            cropperData.cropInfo.sx,
            cropperData.cropInfo.sy,
            cropperData.cropInfo.sWidth,
            cropperData.cropInfo.sHeight,
            -cropperData.cropInfo.sWidth / 2,
            -cropperData.cropInfo.sHeight / 2,
            cropperData.cropInfo.sWidth,
            cropperData.cropInfo.sHeight,
          )

          ctx.restore()

          return tmpCanvas
        }
      }

      return undefined
    }

    const crop = () => {
      const canvas = getCroppedCanvas()
      if (canvas) {
        if (onProcessing) {
          onProcessing(true)
        }
        const data = canvas.toDataURL()
        canvas.toBlob(blobProp => {
          if (!blobProp) {
            return
          }
          const blobWithName = blobProp as BlobWithName
          if (!blobWithName.name && fileName) {
            blobWithName.name = fileName
          }
          if (onFileReady) {
            onFileReady({ data, raw: blobWithName })
          }
          setImageSource(undefined)
        })
      }
    }

    useImperativeHandle(ref, () => ({
      crop,
      open: () => fileSelectorRef.current?.click(),
      clear: () => setImageSource(undefined),
    }))

    return (
      <div css={styles.container}>
        {imageSource && enableCropper && (
          <KibCropper
            aspectRatio={aspectRatio}
            imageSrc={imageSource}
            onChange={setCropperData}
          />
        )}

        {!imageSource && (
          <SingleFileSelector
            accept={accept}
            onlyImages={onlyImages}
            ref={fileSelectorRef}
            onDragOverChange={setIsDragOver}
            onError={setError}
            onSelected={onSelected}
          >
            <FileInputArea
              Icon={onlyImages && <KibImageIcon />}
              error={error}
              fileInputMobileText={fileInputMobileText}
              fileInputText={fileInputText}
              isDragOver={isDragOver}
            />
          </SingleFileSelector>
        )}
      </div>
    )
  },
)

export default FileInput
