import { gql } from '@apollo/client/core'

export const GET_PRESCRIPTION_DETAILS = gql`
  query GetPrescriptionDetails($id: ID!, $timezone: String!) {
    prescription(id: $id) {
      id
      title
      date
      timelineDetails(timezone: $timezone) {
        refill
        instructions
        subtitle
      }
      business {
        id
        primaryPhone
        secondaryPhone
      }
      refillsLeft
    }
  }
`
