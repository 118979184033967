/** @jsxImportSource @emotion/react */
import { KibModal, KibModalProps } from '@chewy/kib-overlays-react'

export type ModalProps = Omit<KibModalProps, 'title' | 'children'> & {
  children?: React.ReactNode
  title?: string
}

const Modal = ({ title = '', children, ...rest }: ModalProps) => (
  <KibModal title={title} {...rest}>
    {children}
  </KibModal>
)

export default Modal
