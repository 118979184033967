import i18n from '../locales/i18n'

export const TaskState = {
  TODO: 'TO DO',
  OVERDUE: 'OVERDUE',
  DONE: 'DONE',
  IN_PROGRESS: 'IN_PROGRESS',
  WAITING_FOR_REPLY: 'WAITING_FOR_REPLY',
}

export const TaskRecurrenceType = {
  ONCE: 'ONCE',
  REPEAT: 'REPEAT',
  CHRONIC: 'CHRONIC',
}

export const TaskRepeatPeriod = {
  NEVER: 'Never',
  EVERY_DAY: 'Every day',
  EVERY_WEEK: 'Every week',
  EVERY_OTHER_WEEK: 'Every other week',
  EVERY_MONTH: 'Every month',
  EVERY_YEAR: 'Every year',
  CUSTOM: 'Custom',
}

export const TaskTimeUnits = {
  HOUR: {
    name: () => i18n.t('Common:HOUR'),
    unit: 'Hours',
  },
  DAY: {
    name: () => i18n.t('Common:DAY'),
    unit: 'Days',
  },
  WEEK: {
    name: () => i18n.t('Common:WEEK'),
    unit: 'Weeks',
  },
  MONTH: {
    name: () => i18n.t('Common:MONTH'),
    unit: 'Months',
  },
  YEAR: {
    name: () => i18n.t('Common:YEAR'),
    unit: 'Years',
  },
}
