/** @jsxImportSource @emotion/react */
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import BusinessFragment from './BusinessFragment'
import PatientsFragment from './PatientsFragment'

const Home = () => (
  <KibGrid align="center" css={css({ height: '100%' })}>
    <KibGridItem offset="2@min-lg" span="4@min-xs 3@min-md 3@min-lg">
      <BusinessFragment />

      {mediaQueryMatches(MediaQuery.MAX_SM) && <PatientsFragment />}
    </KibGridItem>

    {mediaQueryMatches(MediaQuery.MIN_MD) && (
      <KibGridItem push="2@min-lg" span="5@min-md 5@min-lg">
        <PatientsFragment />
      </KibGridItem>
    )}
  </KibGrid>
)

export default Home
