/** @jsxImportSource @emotion/react */
import { KibCameraAddIcon, KibPlaceholderIcon } from '@chewy/kib-icons-react'
import { KibSkeletonShape } from '@chewy/kib-skeleton-react'
import { css, SerializedStyles } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import Icon, { IconProps } from './Icon'

type AvatarProps = IconProps & {
  avatarBackgroundColor?: string
  avatarSize?: number
  borderColor?: string
  borderSelectedColor?: string
  editable?: boolean
  iconStyle?: SerializedStyles
  imageStyle?: SerializedStyles
  loading?: boolean
  photo: string | undefined
  selected?: boolean
}

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  }),
  enabledContainer: css({
    cursor: 'pointer',
  }),
  disabledContainer: css({
    pointerEvents: 'none',
  }),
  placeholder: css({
    width: '65%',
    height: '65%',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  editContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: ColorVariables.UI_BG_BRAND_PRIMARY,
    borderRadius: 12,
  }),
  editIcon: css({
    width: 16,
    height: 16,
    color: ColorVariables.STATIC_GRAY1_50,
  }),
}

const Avatar = ({
  avatarSize,
  avatarBackgroundColor = ColorVariables.UI_BG_02,
  borderColor = ColorVariables.UI_BG_09,
  borderSelectedColor = ColorVariables.UI_BG_BRAND_PRIMARY,
  photo,
  editable,
  loading,
  selected,
  iconStyle,
  imageStyle,
  ...rest
}: AvatarProps) =>
  loading ? (
    <KibSkeletonShape circle style={{ width: avatarSize }} />
  ) : (
    <div
      css={[
        styles.container,
        !editable && styles.disabledContainer,
        editable && styles.enabledContainer,
        avatarSize && css({ width: avatarSize, height: avatarSize }),
      ]}
      {...rest}
    >
      <Icon
        circle
        Placeholder={
          rest?.Placeholder ? (
            rest.Placeholder
          ) : (
            <KibPlaceholderIcon css={styles.placeholder} />
          )
        }
        css={[
          css({
            width: avatarSize,
            height: avatarSize,
            backgroundColor: avatarBackgroundColor,
            border: selected
              ? `2px solid ${borderSelectedColor}`
              : `1px solid ${borderColor}`,
          }),
          iconStyle,
        ]}
        imageStyle={imageStyle}
        logo={photo}
      />
      {editable && (
        <div css={styles.editContainer}>
          <KibCameraAddIcon css={styles.editIcon} />
        </div>
      )}
    </div>
  )

export default Avatar
