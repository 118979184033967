/** @jsxImportSource @emotion/react */
import { patternFormatter } from 'react-number-format'
import { KibSkeletonText } from '@chewy/kib-skeleton-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../../constants/colors'
import { MediaQuery } from '../../../../constants/mediaQuery'
import { Spacing } from '../../../../constants/spacing'
import { mediaQuery, mediaQueryMatches } from '../../../../utils/mediaQuery'
import { spacing } from '../../../../utils/spacing'
import Text, { TextVariant } from '../../typography/Text/Text'

type InfoFormItemProps = {
  alwaysVertical?: boolean
  format?: string
  hideDivider?: boolean
  loading?: boolean
  title?: string
  value?: string | undefined
}

const styles = {
  container: css({
    display: 'flex',
    minHeight: 40,
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 50,
    }),
    ...mediaQuery(MediaQuery.MIN_MD, {
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(Spacing.S3, 0),
    }),
  }),
  verticalContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 50,
  }),
  containerWithDivider: css({
    borderBottom: `1px solid ${ColorVariables.UI_BG_04}`,
  }),
  titleContainer: css({
    flex: 1,
    paddingRight: spacing(Spacing.S2),
  }),
  valueContainer: css({
    ...mediaQuery(MediaQuery.MIN_MD, {
      flex: 4,
    }),
  }),
}

const InfoFormItem = ({
  title,
  value,
  format,
  loading,
  hideDivider,
  alwaysVertical = false,
  ...rest
}: InfoFormItemProps) => (
  <div
    css={[
      alwaysVertical ? styles.verticalContainer : styles.container,
      !hideDivider && styles.containerWithDivider,
    ]}
    {...rest}
  >
    {title && (
      <div
        css={
          mediaQueryMatches(MediaQuery.MIN_MD)
          && !alwaysVertical
          && styles.titleContainer
        }
      >
        {loading ? (
          <KibSkeletonText
            style={
              alwaysVertical || mediaQueryMatches(MediaQuery.MAX_SM)
                ? { height: 15, width: 100 }
                : {}
            }
          />
        ) : (
          <Text variant={TextVariant.CAPTION}>{title}</Text>
        )}
      </div>
    )}
    <div css={styles.valueContainer}>
      {loading ? (
        <KibSkeletonText
          style={
            alwaysVertical || mediaQueryMatches(MediaQuery.MAX_SM)
              ? { height: 15 }
              : {}
          }
        />
      ) : (
        <Text variant={TextVariant.PARAGRAPH_1}>
          {format && value ? patternFormatter(value, { format }) : value}
        </Text>
      )}
    </div>
  </div>
)

export default InfoFormItem
