/** @jsxImportSource @emotion/react */
import { HTMLAttributes } from 'react'
import { KibPlaceholderIcon } from '@chewy/kib-icons-react'
import { css, SerializedStyles } from '@emotion/react'

export type IconProps = HTMLAttributes<HTMLSpanElement> & {
  Component?: React.ReactNode
  Placeholder?: React.ReactNode
  alt?: string
  circle?: boolean
  imageStyle?: SerializedStyles
  logo?: string | undefined
}

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  roundedContainer: css({
    borderRadius: '50%',
    overflow: 'hidden',
  }),
  logo: css({
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    aspectRatio: '1/1',
    objectFit: 'cover',
  }),
  placeholder: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

const Icon = ({
  alt,
  logo,
  circle,
  imageStyle,
  Placeholder,
  Component,
  ...rest
}: IconProps) => (
  <div css={[styles.container, circle && styles.roundedContainer]} {...rest}>
    {logo || Component ? (
      logo ? (
        <img alt={alt || ''} css={[styles.logo, imageStyle]} src={logo} />
      ) : (
        Component
      )
    ) : Placeholder ? (
      <div css={styles.placeholder}>{Placeholder}</div>
    ) : (
      <KibPlaceholderIcon />
    )}
  </div>
)

export default Icon
