/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import Text, { TextVariant } from '../typography/Text/Text'

export interface BusinessLogoProps {
  logo?: string | null
  name?: string | null
}

const styles = {
  container: css({
    display: 'flex',
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  logo: css({
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  }),
  text: css({
    textAlign: 'center',
  }),
}

const BusinessLogo = ({ logo, name, ...rest }: BusinessLogoProps) => (
  <div css={styles.container} {...rest}>
    {logo ? (
      <img alt={logo} css={styles.logo} src={logo} />
    ) : (
      <Text css={styles.text} variant={TextVariant.DISPLAY_6}>
        {name}
      </Text>
    )}
  </div>
)

export default BusinessLogo
