/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { SAVE_QUESTION_ANSWERS } from '../../../api/mutations/appointment'
import {
  ANSWER_INPUT_TYPES,
  GET_QUESTIONS,
} from '../../../api/queries/appointment'
import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { Question } from '../../../types/dto/Appointment'
import { SaveFormHandle, SaveFormProps } from '../../../types/forms'
import {
  convertToResults,
  getQuestionsAnswersTextMaps,
} from '../../../utils/appointment'
import { spacing } from '../../../utils/spacing'
import QuestionView from '../checkIn/QuestionView'
import Spinner from '../spinner/Spinner'
import Text, { TextVariant } from '../typography/Text/Text'

const ID = 'questionsForm'

type CheckInQuestionsFormProps = SaveFormProps & {
  appointmentId?: string
  businessId?: string
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  spinner: css({
    marginTop: spacing(Spacing.S4),
    alignSelf: 'center',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  questionsContainer: css({
    padding: spacing(Spacing.S4, 0, 0, 0),
  }),
}

const CheckInQuestionsForm = forwardRef<
SaveFormHandle,
CheckInQuestionsFormProps
>(function CheckInQuestionsForm(
  {
    appointmentId,
    businessId,
    onReady,
    onSaveCompleted,
    onSaveLoadingStateChange,
    ...rest
  },
  ref,
) {
  const { t } = useTranslation('Home')

  const [selectedAnswersMap, setSelectedAnswersMap] = useState<
  Record<string, Record<string, Record<string, string>>>
  >({})

  const { data: { questions = [] } = {}, loading: questionsLoading } = useQuery(
    GET_QUESTIONS,
    {
      variables: {
        businessId,
        appointmentId,
      },
    },
  )

  const { data: { answerInputTypes = {} } = {}, loading: inputTypesLoading } =
    useQuery(ANSWER_INPUT_TYPES)

  const [saveAnswers, { loading: saveLoading }] = useMutation(
    SAVE_QUESTION_ANSWERS,
    {
      onCompleted: () => onSaveCompleted?.(ID),
    },
  )

  useEffect(() => {
    if (!questionsLoading) {
      onReady?.(ID)
    }
  }, [questionsLoading])

  useEffect(() => {
    onSaveLoadingStateChange?.({ id: ID, loading: saveLoading })
  }, [saveLoading])

  const loading = questionsLoading || inputTypesLoading

  const orderedQuestions = R.sortBy(
    it => it?.rank || true,
    questions,
  ) as Question[]

  const handleOnAnswer = (
    questionId: string,
    answerId: string,
    inputId: string | undefined,
    value: string | undefined,
  ) => {
    if (selectedAnswersMap[questionId]?.[answerId] && !value) {
      setSelectedAnswersMap(R.omit([questionId], selectedAnswersMap))
    } else {
      setSelectedAnswersMap({
        ...selectedAnswersMap,
        [questionId]: {
          [answerId]: {
            ...selectedAnswersMap?.[questionId]?.[answerId],
            ...(inputId ? { [inputId]: value } : {}),
          },
        },
      } as Record<string, Record<string, Record<string, string>>>)
    }
  }

  const handleSave = () => {
    const { questionsMap, answersMap } = getQuestionsAnswersTextMaps(questions)
    const answerInputs = convertToResults(
      selectedAnswersMap,
      questionsMap,
      answersMap,
    )

    if (Object.keys(answerInputs).length > 0) {
      saveAnswers({
        variables: {
          businessId,
          appointmentId,
          input: answerInputs,
        },
      })
    } else {
      onSaveCompleted?.(ID)
    }
  }

  useImperativeHandle(ref, () => ({
    save: handleSave,
  }))

  return (
    <div css={styles.container} {...rest}>
      {loading ? (
        <Spinner css={styles.spinner} />
      ) : (
        <>
          <Text variant={TextVariant.SECTION_1}>
            {t('Home:ANSWER_QUESTIONS')}
          </Text>

          <div css={styles.questionsContainer}>
            {orderedQuestions.map((question, index) => (
              <QuestionView
                {...question}
                answerInputTypes={answerInputTypes}
                index={index + 1}
                key={question.id}
                selectedAnswersMap={selectedAnswersMap?.[question.id]}
                onAnswer={(...params) => handleOnAnswer(question.id, ...params)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
})

export default CheckInQuestionsForm
