/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { ColorVariables } from '../../constants/colors'
import { Spacing } from '../../constants/spacing'
import { TaskState } from '../../constants/task'
import { Task } from '../../types/dto/Task'
import { spacing } from '../../utils/spacing'
import {
  getFormattedTaskCompletionDate,
  getFormattedTaskDueDate,
} from '../../utils/task'
import Button, { ButtonEmphasis, ButtonSize } from '../common/button/Button'
import Card from '../common/card/Card/Card'
import Text, { TextVariant } from '../common/typography/Text/Text'

type ChildTaskViewProps = {
  loading?: boolean
  onMarkAsDone: (task: Task) => void
  task: Task
}

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(Spacing.S2),
    columnGap: spacing(Spacing.S2),
  }),
  completedText: css({
    color: ColorVariables.MESSAGE_SUCCESS_PRIMARY,
  }),
}

const ChildTaskView = ({
  task,
  loading,
  onMarkAsDone,
  ...rest
}: ChildTaskViewProps) => {
  const { t } = useTranslation('Tasks')

  const dueDate = getFormattedTaskDueDate(task?.dueDate)
  const completionDate = getFormattedTaskCompletionDate(task?.completionDate)
  const isDone = task?.state?.name === TaskState.DONE

  return (
    <Card css={styles.container} {...rest}>
      <Text variant={TextVariant.SECTION_3}>{dueDate}</Text>

      {isDone ? (
        <Text css={styles.completedText} variant={TextVariant.FORM_LABEL}>
          {`${t('Tasks:COMPLETED')} ${completionDate}`}
        </Text>
      ) : (
        <Button
          disabled={loading}
          emphasis={ButtonEmphasis.SECONDARY}
          loading={loading}
          size={ButtonSize.SMALL}
          onClick={() => onMarkAsDone(task)}
        >
          {t('Tasks:MARK_AS_COMPLETED')}
        </Button>
      )}
    </Card>
  )
}

export default ChildTaskView
