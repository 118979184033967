/** @jsxImportSource @emotion/react */
import moment from 'moment-timezone'

import { DateFormat } from '../../../../constants/dateFormat'
import i18n from '../../../../locales/i18n'
import {
  ProblemDiagnosisEnum,
  TimelineDiagnosis,
  TimelineProblemDiagnosis,
} from '../../../../types/dto/Patient'
import { getPersonName, getTimezone } from '../../../../utils'
import TimelineDetailsView, {
  TimelineDetailsContentItem,
} from './TimelineDetailsView'

type Diagnosis = TimelineDiagnosis | TimelineProblemDiagnosis

type TimelineDiagnosisDetailsViewProps = {
  diagnosis: Diagnosis
}

const isProblemDiagnosis = (
  diagnosis?: Diagnosis,
): diagnosis is TimelineProblemDiagnosis =>
  (diagnosis as TimelineProblemDiagnosis)?.modifier !== undefined
  || (diagnosis as TimelineProblemDiagnosis)?.creator !== undefined

const getContent = (diagnosis?: Diagnosis) => {
  const content = [
    {
      title: i18n.t('Common:STATUS'),
      content: diagnosis?.state?.name,
    },
  ] as TimelineDetailsContentItem[]

  if (isProblemDiagnosis(diagnosis)) {
    diagnosis?.enums?.forEach((item: ProblemDiagnosisEnum) => {
      content.push({
        title: item.enumName.friendlyName,
        content: item?.enumValue?.friendlyName,
      })
    })
  } else {
    content.push({
      title: i18n.t('Common:LOCATION'),
      content: diagnosis?.location?.friendlyName,
    })
  }

  return content
}

const composeSubtitle = (
  veterinarianName: string,
  diagnosisDate: string,
  timezone?: string,
) => {
  const modifier = veterinarianName
    ? `${i18n.t('Pet:DIAGNOSED_BY')} ${veterinarianName}\n`
    : ''
  const date = moment
    .tz(diagnosisDate, timezone || getTimezone())
    .format(
      moment(diagnosisDate).year() !== moment().year()
        ? DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_COMMA_YEAR_AT_FULL_TIME
        : DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME,
    )
  return `${modifier || ''}${date}`
}

const TimelineDiagnosisDetailsView = ({
  diagnosis,
  ...rest
}: TimelineDiagnosisDetailsViewProps) => {
  const contentItems = getContent(diagnosis)
  const veterinarian = isProblemDiagnosis(diagnosis)
    ? diagnosis?.modifier || diagnosis?.creator
    : undefined
  const veterianName = isProblemDiagnosis(diagnosis)
    ? getPersonName(veterinarian)
    : undefined
  const subtitle = isProblemDiagnosis(diagnosis)
    ? composeSubtitle(
      veterianName || '',
      diagnosis?.modificationDate
          || diagnosis?.creationDate
          || diagnosis?.date,
      diagnosis?.business?.timezone,
    )
    : undefined
  const logo = isProblemDiagnosis(diagnosis)
    ? veterinarian?.photoThumbnail || veterinarian?.photo
    : undefined

  return (
    <TimelineDetailsView
      contentHeaderLogo={logo}
      contentHeaderTitle={veterianName}
      contentItems={contentItems}
      headerLogo={logo}
      headerSubtitle={subtitle}
      headerTitle={diagnosis?.title}
      showHeaderLogo={isProblemDiagnosis(diagnosis)}
      timelineType={diagnosis?.type?.name}
      {...rest}
    />
  )
}

export default TimelineDiagnosisDetailsView
