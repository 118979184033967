/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'

import { UPDATE_TASK_STATE } from '../../api/mutations/tasks'
import { GET_TASK_COMPLETED, GET_TASK_READINESS } from '../../api/queries/tasks'
import { ColorVariables } from '../../constants/colors'
import { Spacing } from '../../constants/spacing'
import { TaskState } from '../../constants/task'
import { Constant } from '../../types/dto/Constants'
import { Task } from '../../types/dto/Task'
import { findConstantByName } from '../../utils'
import { spacing } from '../../utils/spacing'
import Pager from '../common/navigation/Pager'
import Spinner from '../common/spinner/Spinner'
import Text, { TextVariant } from '../common/typography/Text/Text'
import ChildTaskView from './ChildTaskView'

export const BATCH_SIZE = 3

type MarkTaskState = {
  loading: boolean
  taskId?: string
}

type ChildTasksListProps = {
  currentPage: number
  loading?: boolean
  onMarkDoneCompleted?: (task: Task) => void
  onPageChanged: (page: number) => void
  parentTask: Task
  showTotalCount?: boolean
  taskStates: Constant[]
  tasks: Task[]
  title: string
  totalCount?: number
}

const styles = {
  childTaskContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(Spacing.S2),
    paddingTop: spacing(Spacing.S4),
  }),
  spinner: css({
    marginTop: spacing(Spacing.S4),
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    alignSelf: 'center',
  }),
  pager: css({
    paddingTop: spacing(Spacing.S2),
    paddingBottom: 0,
    '.kib-pagination-new__list-item--previous': {
      display: 'none',
    },
    '.kib-pagination-new__list-item--next': {
      display: 'none',
    },
  }),
}

const ChildTasksList = ({
  parentTask,
  tasks,
  taskStates,
  totalCount,
  title,
  currentPage,
  loading,
  showTotalCount,
  onPageChanged,
  onMarkDoneCompleted,
  ...rest
}: ChildTasksListProps) => {
  const [markTaskState, setMarkTaskState] = useState<MarkTaskState>({
    loading: false,
  })

  const [updateTaskState] = useMutation(UPDATE_TASK_STATE, {
    onCompleted: ({ updateTaskState: data }) => {
      setMarkTaskState({ loading: false })

      onMarkDoneCompleted?.(data)
    },
    onError: () => {
      setMarkTaskState({ loading: false })
    },
    refetchQueries: [
      {
        query: GET_TASK_COMPLETED,
        variables: {
          id: parentTask?.id,
          offset: 0,
          limit: BATCH_SIZE,
        },
      },
      { query: GET_TASK_READINESS, variables: { id: parentTask?.id } },
    ],
  })

  const hasTasks = tasks && tasks?.length > 0
  const totalPages = Math.ceil((totalCount || 0) / BATCH_SIZE)

  const showLoading = loading && !hasTasks

  const handleMarkAsDone = (taskToMark: Task) => {
    setMarkTaskState({ loading: true, taskId: taskToMark?.id })

    updateTaskState({
      variables: {
        taskId: taskToMark.id,
        stateId: findConstantByName(TaskState.DONE, taskStates)?.id,
      },
    })
  }

  return (
    <div {...rest}>
      <Text variant={TextVariant.SECTION_1}>
        {`${title} ${(!loading && showTotalCount) ? `(${totalCount})` : ''}`}
      </Text>

      {(showLoading || hasTasks) && (
        <div css={styles.childTaskContainer}>
          {showLoading && <Spinner css={styles.spinner} />}

          {tasks?.map((childTask: Task) => (
            <ChildTaskView
              key={childTask?.id}
              loading={
                childTask?.id === markTaskState?.taskId
                && markTaskState?.loading
              }
              task={childTask}
              onMarkAsDone={handleMarkAsDone}
            />
          ))}

          {(showTotalCount && totalPages > 1) && (
            <Pager
              css={styles.pager}
              current={currentPage}
              pageSlots={5}
              total={totalPages}
              onPageChange={onPageChanged}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ChildTasksList
