/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { Patient } from '../../../types/dto/Patient'
import { clickable } from '../../../utils/component'
import { spacing } from '../../../utils/spacing'
import Avatar from '../icon/Avatar'
import Spinner from '../spinner/Spinner'
import Text, { TextVariant } from '../typography/Text/Text'

type SelectPatientHorizontalListProps = {
  loading?: boolean
  onPatientSelected?: (patient: Patient) => void
  patients?: Patient[]
  selectedPatient?: Patient
}

const styles = {
  spinner: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    alignSelf: 'center',
  }),
  patientsContainer: css({
    display: 'flex',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    columnGap: spacing(Spacing.S3),
    rowGap: spacing(Spacing.S3),
    marginTop: spacing(Spacing.S3),
  }),
  singlePatientsContainer: css({
    justifyContent: 'center',
  }),
  patientContainer: css({
    display: 'flex',
    width: 75,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': css({
      cursor: 'pointer',
    }),
  }),
  singlePatientContainer: css({
    '&:hover': css({
      cursor: 'default',
    }),
  }),
  patientContainerUnselected: css({
    opacity: 0.5,
  }),
  patientLabel: css({
    width: 75,
    marginTop: spacing(Spacing.S1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  }),
  patientLabelSelected: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    fontWeight: 'bold',
  }),
}

const SelectPatientHorizontalList = ({
  patients,
  selectedPatient,
  loading,
  onPatientSelected,
  ...rest
}: SelectPatientHorizontalListProps) => {
  const { t } = useTranslation('Pet')

  const hasSinglePatient = (patients?.length || 0) === 1

  return (
    <div {...rest}>
      {!hasSinglePatient && !loading && (
        <Text variant={TextVariant.SECTION_1}>{t('Pet:CHOOSE_A_PET')}</Text>
      )}

      {loading ? (
        <Spinner css={styles.spinner} />
      ) : (
        <div
          css={[
            styles.patientsContainer,
            hasSinglePatient && styles.singlePatientsContainer,
          ]}
        >
          {patients?.map((patient: Patient) => {
            const selected = selectedPatient?.id === patient?.id

            return (
              <div
                css={[
                  styles.patientContainer,
                  hasSinglePatient && styles.singlePatientContainer,
                  selectedPatient
                    && !selected
                    && styles.patientContainerUnselected,
                ]}
                key={patient?.id}
                {...(!hasSinglePatient
                  ? { ...clickable(() => onPatientSelected?.(patient)) }
                  : {})}
              >
                <Avatar
                  avatarSize={72}
                  photo={patient?.photoThumbnail || patient?.photo}
                  selected={!hasSinglePatient && selected}
                />

                <Text
                  css={[
                    styles.patientLabel,
                    !hasSinglePatient
                      && selected
                      && styles.patientLabelSelected,
                  ]}
                  variant={TextVariant.PARAGRAPH}
                >
                  {patient?.name}
                </Text>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SelectPatientHorizontalList
