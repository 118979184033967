/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { KibAddIcon } from '@chewy/kib-icons-react'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Chat } from '../../../types/dto/Chat'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button from '../../common/button/Button'
import CreateChatModal from '../../common/chat/CreateChatModal'
import EmptyState from '../../common/empty/EmptyState'
import EmptyImage from '../../common/images/404.svg'
import { CertificatesTypeProp } from '../../common/patient/certificates/CertificatesSheet'
import Text, { TextVariant } from '../../common/typography/Text/Text'
import ChatFragment from './ChatFragment'
import ChatListFragment from './ChatListFragment'

type ShowCreateChatState = {
  certificateType?: CertificatesTypeProp
  patientId?: string
  prescriptionTitle?: string
  show: boolean
}

const styles = {
  leftContainer: css({
    height: '100%',
    overflow: 'visible',
  }),
  header: css({
    display: 'flex',
    width: '100%',
    marginTop: spacing(Spacing.S4),
    padding: spacing(0, Spacing.S2),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S7),
    }),
    height: 48,
  }),
  headerLeft: css({
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...mediaQuery(MediaQuery.MAX_SM, {
      justifyContent: 'space-between',
      padding: spacing(0, Spacing.S4),
    }),
  }),
  headerRight: css({
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  }),
  emptyGridContainer: css({
    height: '100%',
  }),
  emptyContainer: css({
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}

const ChatListPage = () => {
  const { t } = useTranslation('Chat')

  const navigate = useNavigate()
  const { state } = useLocation()

  const [selectedChat, setSelectedChat] = useState<Chat | undefined>()
  const [showCreateChatState, setShowCreateChatState] =
    useState<ShowCreateChatState>({
      show: false,
    })
  const [showEmpty, setShowEmpty] = useState(false)

  const handleChatSelected = (chat: Chat) => {
    if (mediaQueryMatches(MediaQuery.MAX_SM)) {
      navigate(`/chat/${chat.id}`, {
        state: { chat, from: window.location.pathname },
      })
    } else {
      setSelectedChat(chat)
    }
  }

  const handleChatsLoaded = (chats: Chat[] | undefined) => {
    if (!selectedChat && chats?.length) {
      setSelectedChat(chats[0])
    }

    if (!chats?.length) {
      setShowEmpty(true)
    } else if (showEmpty) {
      setShowEmpty(false)
    }
  }

  const handleChatCreated = (chat: Chat) => {
    setShowCreateChatState({ show: false })
    handleChatSelected(chat)
  }

  const handleCreateNewChat = (
    patientId?: string,
    certificateType?: CertificatesTypeProp,
    prescriptionTitle?: string,
  ) => {
    if (mediaQueryMatches(MediaQuery.MAX_SM)) {
      navigate('/chat/new', {
        state: { from: window.location.pathname, patientId, certificateType, prescriptionTitle },
      })
    } else {
      setShowCreateChatState({ show: true, patientId, certificateType, prescriptionTitle })
    }
  }

  useEffect(() => {
    if (state?.newMessage) {
      handleCreateNewChat(state?.patientId, state?.certificateType, state?.prescriptionTitle)
    }
  }, [])

  return showEmpty ? (
    <KibGrid align="center" css={styles.emptyGridContainer} valign="center">
      <KibGridItem
        offset="2@min-lg"
        push="2@min-lg"
        span="4@min-xs 8@min-md 8@min-lg"
      >
        <EmptyState
          actions={
            <Button id="clp-nm-empty" onClick={() => handleCreateNewChat()}>
              {t('Chat:START_NEW_MESSAGE')}
            </Button>
          }
          header={t('Chat:YOU_DONT_HAVE_ANY_MESSAGES_YET')}
          media={<img alt="" src={EmptyImage} />}
        />
      </KibGridItem>

      <CreateChatModal
        certificateType={showCreateChatState.certificateType}
        open={showCreateChatState.show}
        prescriptionTitle={showCreateChatState.prescriptionTitle}
        selectedPatientId={showCreateChatState.patientId}
        onChatCreated={handleChatCreated}
        onClose={() => setShowCreateChatState({ show: false })}
      />
    </KibGrid>
  ) : (
    <KibGrid align="center">
      <KibGridItem offset="2@min-lg" span="4@max-sm 3@min-md 3@min-lg">
        <div css={[styles.header, styles.headerLeft]}>
          <Text variant={TextVariant.SECTION_1}>{t('Chat:CHAT')}</Text>

          {mediaQueryMatches(MediaQuery.MAX_SM) && (
            <Button
              iconOnly
              icon={<KibAddIcon />}
              id="clp-nm"
              onClick={() => handleCreateNewChat()}
            />
          )}
        </div>

        <ChatListFragment
          selectedChat={selectedChat}
          onChatSelected={handleChatSelected}
          onChatsLoaded={handleChatsLoaded}
        />
      </KibGridItem>

      {mediaQueryMatches(MediaQuery.MIN_MD) && (
        <KibGridItem
          css={styles.leftContainer}
          push="2@min-lg"
          span="5@min-md 5@min-lg"
        >
          {mediaQueryMatches(MediaQuery.MIN_MD) && (
            <div css={[styles.header, styles.headerRight]}>
              <Button
                iconOnly
                icon={<KibAddIcon />}
                id="clp-nm"
                onClick={() => handleCreateNewChat()}
              />
            </div>
          )}

          <ChatFragment
            chat={selectedChat}
            onCreateNewChat={handleCreateNewChat}
          />
        </KibGridItem>
      )}

      <CreateChatModal
        certificateType={showCreateChatState.certificateType}
        open={showCreateChatState.show}
        prescriptionTitle={showCreateChatState.prescriptionTitle}
        selectedPatientId={showCreateChatState.patientId}
        onChatCreated={handleChatCreated}
        onClose={() => setShowCreateChatState({ show: false })}
      />
    </KibGrid>
  )
}

export default ChatListPage
