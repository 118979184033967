/** @jsxImportSource @emotion/react */
import { KibSkeletonShape, KibSkeletonText } from '@chewy/kib-skeleton-react'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Card from '../card/Card/Card'
import Divider from '../divider/Divider'

const styles = {
  container: css({
    backgroundColor: 'var(--chirp-ui-bg-brand-accent-07, #ffffff)',
    width: '100%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  buttonsContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(Spacing.S3, Spacing.S4, Spacing.S2, Spacing.S4),
  }),
  statusContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  detailsContainer: css({
    padding: spacing(Spacing.S4, 0),
  }),
  secondaryButtonContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: spacing(0, Spacing.S4),
    ...mediaQuery(MediaQuery.MD, {
      flexWrap: 'wrap',
      rowGap: spacing(Spacing.S2),
    }),
  }),
  addressContainer: css({
    marginTop: spacing(Spacing.S6),
    padding: spacing(0, Spacing.S4),
  }),
  hoursContainer: css({
    marginTop: spacing(Spacing.S7),
    padding: spacing(0, Spacing.S4),
  }),
}

const BusinessCardSkeleton = ({ ...rest }) => (
  <Card css={styles.container} {...rest}>
    <div style={{ height: 72 }} />

    <Divider />

    <div css={styles.buttonsContainer}>
      <div css={styles.statusContainer}>
        <KibSkeletonShape
          style={{
            width: 130,
            height: 30,
            borderRadius: 10,
            marginTop: spacing(Spacing.S2),
          }}
        />

        {mediaQueryMatches(MediaQuery.MAX_SM) && (
          <KibSkeletonText
            lines={1}
            style={{ width: 80, marginLeft: spacing(Spacing.S4) }}
          />
        )}
      </div>
      <KibSkeletonShape style={{ width: 100, height: 50, borderRadius: 30 }} />
    </div>

    <Divider />

    {mediaQueryMatches(MediaQuery.MIN_MD) && (
      <div css={styles.detailsContainer}>
        <div css={styles.secondaryButtonContainer}>
          <KibSkeletonShape
            style={{ width: 100, height: 50, borderRadius: 30 }}
          />
          <KibSkeletonShape
            style={{ width: 100, height: 50, borderRadius: 30 }}
          />
          <KibSkeletonShape
            style={{ width: 100, height: 50, borderRadius: 30 }}
          />
        </div>

        <div css={styles.addressContainer}>
          <KibSkeletonText lines={1} size="medium" style={{ width: 100 }} />

          <KibSkeletonText lines={2} />
        </div>

        <div css={styles.hoursContainer}>
          <KibSkeletonText lines={1} size="medium" style={{ width: 100 }} />

          <KibSkeletonText
            lines={7}
            short={false}
            style={{ marginTop: spacing(Spacing.S1) }}
          />
        </div>
      </div>
    )}
  </Card>
)

export default BusinessCardSkeleton
