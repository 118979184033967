/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Patient } from '../../../types/dto/Patient'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import InputDatepicker from '../input/InputDatepicker'
import InputText from '../input/InputText'
import Text, { TextVariant } from '../typography/Text/Text'

type PatientLicenseFormProps = {
  loading?: boolean
  onChange: (changed: boolean) => void
  patient?: Patient
}

export type PatientLicenseFormHandle = {
  getData: () => {
    license: string | undefined
    microchipNumber: string | undefined
    rabiesTag: string | undefined
    rabiesTagExpiration: string | undefined
  }
  validate: () => boolean
}

const styles = {
  title: css({
    marginTop: spacing(Spacing.S4),
  }),
  fieldRow: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  halfRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '48%',
  }),
  firstField: css({
    marginTop: spacing(Spacing.S4),
  }),
  field: css({
    width: '100%',
  }),
  halfField: css({
    width: '48%',
  }),
}

const PatientLicenseForm = forwardRef<
PatientLicenseFormHandle,
PatientLicenseFormProps
>(function PatientBasicInfoForm({ patient, loading, onChange, ...rest }, ref) {
  const { t } = useTranslation('Pet')

  const [microchipNumber, setMicrochipNumber] = useState<string | undefined>()
  const [rabiesTag, setRabiesTag] = useState<string | undefined>()
  const [rabiesTagExpiration, setRabiesTagExpiration] = useState<
  string | Date | undefined
  >()
  const [licenseNumber, setLicenseNumber] = useState<string | undefined>()

  useEffect(() => {
    if (patient) {
      setMicrochipNumber(patient.microchipNumber)
      setRabiesTag(patient.rabiesTag)
      setRabiesTagExpiration(patient.rabiesTagExpiration)
      setLicenseNumber(patient.license)
    }
  }, [patient, loading])

  useEffect(() => {
    onChange(
      !R.equals(patient?.microchipNumber || '', microchipNumber || '')
        || !R.equals(patient?.rabiesTag || '', rabiesTag || '')
        || (rabiesTagExpiration
          && !moment(patient?.rabiesTagExpiration).isSame(rabiesTagExpiration))
        || !R.equals(patient?.license || '', licenseNumber || ''),
    )
  }, [microchipNumber, rabiesTag, rabiesTagExpiration, licenseNumber])

  useImperativeHandle(ref, () => ({
    getData: () => ({
      microchipNumber,
      rabiesTag,
      rabiesTagExpiration: rabiesTagExpiration
        ? moment(rabiesTagExpiration)
          .utc(true)
          .format(DateFormat.BACKEND_FULL_DATE)
        : undefined,
      license: licenseNumber,
    }),
    validate: R.T,
  }))

  return (
    <div {...rest}>
      <Text
        css={styles.title}
        skeletonWidth={160}
        variant={TextVariant.SECTION_1}
      >
        {`${t('Pet:REGISTRATION')}/${t('Pet:LICENSE')}`}
      </Text>

      <div
        css={[
          styles.fieldRow,
          mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfRow,
          styles.firstField,
        ]}
      >
        <InputText
          css={styles.halfField}
          id="epc-rab-tag"
          label={t('Pet:RABIES_TAG')}
          value={rabiesTag}
          onChangeText={setRabiesTag}
        />

        <InputDatepicker
          inputId="epc-tag-exp"
          inputStyle={styles.halfField}
          label={t('Pet:TAG_EXPIRATION')}
          value={
            rabiesTagExpiration ? moment(rabiesTagExpiration).toDate() : null
          }
          onChange={date =>
            setRabiesTagExpiration(moment(date).utc(true).toISOString())
          }
        />
      </div>

      <InputText
        css={[
          mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
          mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
        ]}
        id="epc-mchip-num"
        label={t('Pet:MICROCHIP_NUMBER')}
        value={microchipNumber}
        onChangeText={setMicrochipNumber}
      />

      <InputText
        css={[
          mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
          mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
        ]}
        help={t('Pet:LICENSE_TOOLTIP')}
        helpPlacement="bottom"
        id="epc-license"
        label={t('Pet:LICENSE_NUMBER')}
        value={licenseNumber}
        onChangeText={setLicenseNumber}
      />
    </div>
  )
})

export default PatientLicenseForm
