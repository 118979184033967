/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_PATIENT_SECONDARY_DETAILS } from '../../../api/queries/patient'
import { Spacing } from '../../../constants/spacing'
import { spacing } from '../../../utils/spacing'
import Card from '../card/Card/Card'
import PatientSecondaryDetailsForm, {
  PatientSecondaryDataField,
  PatientSecondaryDataFieldType,
} from '../form/PatientSecondaryDetailsForm'

type PatientSecondaryDetailsCardProps = {
  patientId: string | undefined
}

const styles = {
  container: css({
    padding: spacing(Spacing.S5, Spacing.S4),
  }),
  infoForm: css({
    marginTop: spacing(Spacing.S3),
  }),
}

const PatientSecondaryDetailsCard = ({
  patientId,
  ...rest
}: PatientSecondaryDetailsCardProps) => {
  const { t } = useTranslation('Pet')
  const { data: { pet: patient = {} } = {}, loading } = useQuery(
    GET_PATIENT_SECONDARY_DETAILS,
    {
      variables: { id: patientId },
    },
  )

  return (
    <Card css={styles.container} {...rest}>
      <PatientSecondaryDetailsForm
        fieldType={PatientSecondaryDataFieldType.TAGS}
        loading={loading}
        patient={patient}
        propName={PatientSecondaryDataField.ALLERGIES}
        title={t('Pet:ALLERGIES')}
      />

      <PatientSecondaryDetailsForm
        css={styles.infoForm}
        fieldType={PatientSecondaryDataFieldType.TEXT}
        loading={loading}
        patient={patient}
        propName={PatientSecondaryDataField.DIET}
        title={t('Pet:DIET')}
      />

      <PatientSecondaryDetailsForm
        css={styles.infoForm}
        fieldType={PatientSecondaryDataFieldType.TEXT}
        loading={loading}
        patient={patient}
        propName={PatientSecondaryDataField.SCHEDULE}
        title={t('Pet:SCHEDULE')}
      />

      <PatientSecondaryDetailsForm
        css={styles.infoForm}
        fieldType={PatientSecondaryDataFieldType.TEXT}
        loading={loading}
        patient={patient}
        propName={PatientSecondaryDataField.NOTES}
        showDivider={false}
        title={t('Pet:GOOD_TO_KNOW')}
      />
    </Card>
  )
}

export default PatientSecondaryDetailsCard
