/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Answer } from '../../../types/dto/Appointment'
import { Constant } from '../../../types/dto/Constants'
import Text, { TextVariant } from '../typography/Text/Text'
import MultiChoiseAnswerView from './MultiChoiceAnswerView'

type QuestionViewProps = {
  answerInputTypes?: Constant[]
  answers?: Answer[]
  index?: number
  onAnswer?: (
    answerId: string,
    inputId: string | undefined,
    value: string | undefined,
  ) => void
  selectedAnswersMap?: Record<string, any>
  text?: string
}

const styles = {
  titleContainer: css({
    flexShrink: 1,
  }),
}

const QuestionView = ({
  index,
  text,
  answers,
  selectedAnswersMap,
  answerInputTypes,
  onAnswer,
}: QuestionViewProps) => (
  <div>
    <div css={styles.titleContainer}>
      <Text variant={TextVariant.SECTION_2}>{`${index}. ${text}`}</Text>
    </div>

    <MultiChoiseAnswerView
      answerInputTypes={answerInputTypes}
      answers={answers}
      selectedAnswersMap={selectedAnswersMap}
      onAnswer={onAnswer}
    />
  </div>
)

export default QuestionView
