/** @jsxImportSource @emotion/react */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { css } from '@emotion/react'
import SignaturePad from 'signature_pad'

import { ColorVariables } from '../../../constants/colors'

type SignatureViewProps = {
  onEdit?: () => void
}

export type SignatureViewHandle = {
  clear: () => void
  getData: () => string
  getIsValid: () => boolean
}

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
    height: 250,
    maxWidth: '100%',
  }),
  canvas: css({
    width: '100%',
    height: '100%',
    borderStyle: 'dashed',
    borderColor: ColorVariables.UI_BG_07,
    borderWidth: 2,
  }),
}

const aspectRatio = Math.max(window.devicePixelRatio || 1, 1)

const updateCanvasAspectRatio = (canvas: HTMLCanvasElement) => {
  canvas.width = canvas.offsetWidth * aspectRatio
  canvas.height = canvas.offsetHeight * aspectRatio
  const context = canvas.getContext('2d')
  if (context) {
    context.scale(aspectRatio, aspectRatio)
  }
}

const exportSignatureToSvg = (padInstance?: SignaturePad) =>
  padInstance?.toDataURL('image/svg+xml') || ''

const clearSignature = (padInstance?: SignaturePad) => padInstance?.clear()

const SignatureView = forwardRef<SignatureViewHandle, SignatureViewProps>(
  function SignatureView({ onEdit, ...rest }, ref) {
    const [padInstance, setPadInstance] = useState<SignaturePad>()

    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
      if (!padInstance && canvasRef.current) {
        const initialSignaturePad = new SignaturePad(canvasRef.current)
        setPadInstance(initialSignaturePad)
      }
    }, [canvasRef.current])

    useEffect(() => {
      if (canvasRef.current) {
        updateCanvasAspectRatio(canvasRef.current)
      }

      clearSignature(padInstance)
    }, [aspectRatio, padInstance])

    const getIsValid = () => !padInstance?.isEmpty()

    useImperativeHandle(ref, () => ({
      getIsValid,
      getData: () => exportSignatureToSvg(padInstance),
      clear: () => clearSignature(padInstance),
    }))

    return (
      <div css={styles.container} {...rest}>
        <canvas css={styles.canvas} ref={canvasRef} onPointerUp={onEdit} />
      </div>
    )
  },
)

export default SignatureView
