/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Spacing } from '../../../constants/spacing'
import { Answer } from '../../../types/dto/Appointment'
import { Constant } from '../../../types/dto/Constants'
import { spacing } from '../../../utils/spacing'
import AnswerView from './AnswerView'

type MultiChoiseAnswerViewProps = {
  answerInputTypes?: Constant[]
  answers?: Answer[]
  onAnswer?: (
    answerId: string,
    inputId: string | undefined,
    value: string | undefined,
  ) => void
  selectedAnswersMap?: Record<string, any>
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(Spacing.S4, Spacing.S4),
  }),
}

const MultiChoiseAnswerView = ({
  answers,
  selectedAnswersMap,
  answerInputTypes,
  onAnswer,
}: MultiChoiseAnswerViewProps) => (
  <div css={styles.container}>
    {answers?.map(answer => (
      <AnswerView
        answerInputTypes={answerInputTypes}
        key={answer.id}
        selected={Boolean(selectedAnswersMap?.[answer?.id || ''])}
        selectedInputsMap={selectedAnswersMap?.[answer?.id || '']}
        onChange={(inputId, value) => onAnswer?.(answer.id, inputId, value)}
        {...answer}
      />
    ))}
  </div>
)

export default MultiChoiseAnswerView
