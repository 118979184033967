/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { UPDATE_PATIENT_SECONDARY_INFO } from '../../../api/mutations/patient'
import { GET_PATIENT_SECONDARY_DETAILS } from '../../../api/queries/patient'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Patient } from '../../../types/dto/Patient'
import { distinct } from '../../../utils'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis, ButtonTheme } from '../button/Button'
import InputChip from '../chips/InputChip'
import Divider from '../divider/Divider'
import EditIcon from '../icon/EditIcon'
import InputText from '../input/InputText'
import InputTextArea from '../input/InputTextArea'
import Text, { TextVariant } from '../typography/Text/Text'
import InfoFormItem from './item/InfoFormItem'

const TAGS_DELIMITER = '|'

export const PatientSecondaryDataField = {
  ALLERGIES: 'allergies',
  DIET: 'diet',
  SCHEDULE: 'schedule',
  NOTES: 'notes',
} as const

export const PatientSecondaryDataFieldType = {
  TAGS: 'tags',
  TEXT: 'text',
} as const

type PatientSecondaryDataFieldProp =
  (typeof PatientSecondaryDataField)[keyof typeof PatientSecondaryDataField]
type PatientSecondaryDataFieldTypeProp =
  (typeof PatientSecondaryDataFieldType)[keyof typeof PatientSecondaryDataFieldType]
type PropType = string | string[] | undefined

type PatientSecondaryDetailsFormProps = {
  fieldType: PatientSecondaryDataFieldTypeProp
  loading?: boolean
  patient: Patient
  propName: PatientSecondaryDataFieldProp
  showDivider?: boolean
  title: string
}

const styles = {
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  editModeContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: spacing(Spacing.S2),
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  saveButton: css({
    marginRight: spacing(Spacing.S2),
  }),
  divider: css({
    marginTop: spacing(Spacing.S4),
  }),
  editTagsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  inputTagsContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  tagInput: css({
    ...mediaQuery(MediaQuery.MIN_MD, {
      width: '60%',
    }),
    marginRight: spacing(Spacing.S4),
  }),
  tagsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: spacing(Spacing.S2),
  }),
}

const getInitialProp = (
  patient: Patient,
  propName: PatientSecondaryDataFieldProp,
  fieldType: PatientSecondaryDataFieldTypeProp,
): PropType => {
  const patientProp = patient?.[propName]
  switch (fieldType) {
    case PatientSecondaryDataFieldType.TAGS:
      return !R.isNil(patientProp) && !R.isEmpty(patientProp)
        ? patientProp?.split(TAGS_DELIMITER)
        : []
    default:
      return patientProp
  }
}

const isPropChanged = (
  patient: Patient,
  prop: PropType,
  propName: PatientSecondaryDataFieldProp,
  fieldType: PatientSecondaryDataFieldTypeProp,
): boolean => {
  const patientProp = patient?.[propName]
  switch (fieldType) {
    case PatientSecondaryDataFieldType.TAGS:
      const tagsProp = patientProp || ''
      return (
        (R.isEmpty(tagsProp) && !R.isEmpty(prop))
        || (!R.isEmpty(tagsProp)
          && !R.equals(tagsProp?.split(TAGS_DELIMITER), prop))
      )
    default:
      return patientProp !== prop
  }
}

const PatientSecondaryDetailsForm = ({
  patient,
  loading,
  title,
  propName,
  fieldType,
  showDivider = true,
  ...rest
}: PatientSecondaryDetailsFormProps) => {
  const { t } = useTranslation(['Common', 'Pet'])

  const [prop, setProp] = useState<PropType>()
  const [newTag, setNewTag] = useState<string | undefined>()

  const [editMode, setEditMode] = useState(false)
  const [tagError, setTagError] = useState(false)
  const [changed, setChanged] = useState(false)

  const [updateSecondaryInfo, { loading: updateLoading }] = useMutation(
    UPDATE_PATIENT_SECONDARY_INFO,
    {
      refetchQueries: [
        {
          query: GET_PATIENT_SECONDARY_DETAILS,
          variables: { id: patient?.id },
        },
      ],
      onCompleted: () => {
        setEditMode(false)
      },
    },
  )

  const setInitialData = () => {
    setProp(getInitialProp(patient, propName, fieldType))
  }

  useEffect(() => {
    setInitialData()
  }, [patient])

  useEffect(() => {
    setChanged(isPropChanged(patient, prop, propName, fieldType))
  }, [prop])

  const handleSave = async () => {
    const propToUpdate = !Array.isArray(prop)
      ? prop
      : !R.isEmpty(prop)
        ? prop.join(TAGS_DELIMITER)
        : ''

    const updatedPatient = {
      [propName]: propToUpdate,
    }

    const patientDistinction = distinct(patient, updatedPatient)

    if (!R.isEmpty(patientDistinction)) {
      await updateSecondaryInfo({
        variables: {
          id: patient?.id,
          input: {
            name: patient?.name,
            [propName]: propToUpdate,
          },
        },
      })
    }
  }

  const handleAddTag = () => {
    if (newTag && Array.isArray(prop)) {
      if (R.includes(newTag, prop)) {
        setTagError(true)
      } else {
        setProp([...prop, newTag])
        // TODO change to undefined after fix
        setNewTag('')
      }
    }
  }

  const isPropEmpty = R.isNil(prop) || R.isEmpty(prop)

  return (
    <div {...rest}>
      <div css={styles.header}>
        <Text variant={TextVariant.SECTION_1}>{title}</Text>

        {!editMode && !loading && <EditIcon onEdit={() => setEditMode(true)} />}
      </div>

      {!editMode && (!isPropEmpty || loading) && (
        <InfoFormItem
          hideDivider={!showDivider}
          loading={loading}
          value={Array.isArray(prop) ? prop.join(', ') : prop}
        />
      )}

      {editMode && (
        <div css={styles.editModeContainer}>
          {fieldType === PatientSecondaryDataFieldType.TEXT ? (
            <InputTextArea
              hiddenLabel
              id={`psd-${title?.substring(0, 3)}`}
              label={title}
              value={prop}
              onChangeText={setProp}
            />
          ) : (
            <div css={styles.editTagsContainer}>
              <div css={styles.inputTagsContainer}>
                <InputText
                  hiddenLabel
                  css={styles.tagInput}
                  id={`psd-${title?.substring(0, 3)}`}
                  invalid={tagError}
                  label={title}
                  messagesContent={t('Pet:ALLERGY_ALREADY_EXISTS')}
                  value={newTag}
                  onChangeText={tag => {
                    setNewTag(tag?.trim())
                    if (tagError) {
                      setTagError(false)
                    }
                  }}
                />

                <Button
                  disabled={!newTag}
                  emphasis={ButtonEmphasis.SECONDARY}
                  id={`psd-${title?.substring(0, 3)}-add`}
                  onClick={handleAddTag}
                >
                  {t('Common:ADD')}
                </Button>
              </div>
              <div css={styles.tagsContainer}>
                {Array.isArray(prop)
                  && prop?.map((tag, index) => (
                    <InputChip
                      id={`psd-chip${index}`}
                      key={tag}
                      onRemove={() => {
                        setProp(prop.filter(item => item !== tag))
                        if (tagError) {
                          setTagError(false)
                        }
                      }}
                    >
                      {tag}
                    </InputChip>
                  ))}
              </div>
            </div>
          )}
          <div
            css={[
              styles.buttonContainer,
              fieldType === PatientSecondaryDataFieldType.TAGS
                && css({ marginTop: spacing(Spacing.S5) }),
            ]}
          >
            <Button
              css={styles.saveButton}
              disabled={!changed || updateLoading}
              id={`psd-${title?.substring(0, 3)}-save`}
              loading={updateLoading}
              onClick={handleSave}
            >
              {t('Common:SAVE')}
            </Button>
            <Button
              emphasis={ButtonEmphasis.TERTIARY}
              id={`psd-${title?.substring(0, 3)}-can`}
              theme={ButtonTheme.UTILITY}
              onClick={() => {
                if (!updateLoading) {
                  setEditMode(false)
                  setInitialData()
                }
              }}
            >
              {t('Common:CANCEL')}
            </Button>
          </div>
        </div>
      )}

      {showDivider && (isPropEmpty || editMode) && (
        <Divider css={styles.divider} />
      )}
    </div>
  )
}

export default PatientSecondaryDetailsForm
