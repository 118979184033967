/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_COMPLETED_TASKS } from '../../api/queries/tasks'
import { ColorVariables } from '../../constants/colors'
import { MediaQuery } from '../../constants/mediaQuery'
import { Spacing } from '../../constants/spacing'
import { Task, TasksData } from '../../types/dto/Task'
import { mediaQuery } from '../../utils/mediaQuery'
import { spacing } from '../../utils/spacing'
import Button from '../common/button/Button'
import EmptyState from '../common/empty/EmptyState'
import EmptyImage from '../common/images/404.svg'
import Pager from '../common/navigation/Pager'
import Spinner from '../common/spinner/Spinner'
import TaskListItem from './TaskListItem'

export const BATCH_SIZE = 10

type CompletedTasksResponse = {
  allTasksInDoneState?: TasksData
}

type CompletedTasksListProps = {
  onCreateNewTask: () => void
  onTaskSelected: (task: Task) => void
  onTasksLoaded: (tasks?: Task[]) => void
  selectedTask?: Task
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: spacing(Spacing.S4),
    padding: spacing(Spacing.S4, 0, 0, 0),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, Spacing.S2, 0, Spacing.S2),
    }),
  }),
  spinner: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    margin: spacing(Spacing.S4, 0),
  }),
  pager: css({
    marginTop: spacing(Spacing.S4),
    '.kib-pagination-new__list-item--previous': {
      display: 'none',
    },
    '.kib-pagination-new__list-item--next': {
      display: 'none',
    },
  }),
  emptyState: css({
    marginTop: spacing(Spacing.S6),
  }),
}

const CompletedTasksList = ({
  selectedTask,
  onTaskSelected,
  onTasksLoaded,
  onCreateNewTask,
  ...rest
}: CompletedTasksListProps) => {
  const { t } = useTranslation('Tasks')

  const [currentPage, setCurrentPage] = useState(1)

  const { data, loading: tasksLoading } = useQuery<CompletedTasksResponse>(
    GET_COMPLETED_TASKS,
    {
      variables: {
        offset: (currentPage - 1) * BATCH_SIZE,
        limit: BATCH_SIZE,
      },
    },
  )

  const hasTasks =
    data?.allTasksInDoneState?.data
    && data?.allTasksInDoneState?.data?.length > 0

  const totalPages = Math.ceil(
    (data?.allTasksInDoneState?.totalCount || 0) / BATCH_SIZE,
  )

  useEffect(() => {
    if (data) {
      onTasksLoaded(data?.allTasksInDoneState?.data)
    }
  }, [data])

  return (data?.allTasksInDoneState?.data?.length || 0) > 0 || tasksLoading ? (
    <div css={styles.container} {...rest}>
      {tasksLoading && !hasTasks && <Spinner css={styles.spinner} />}

      {data?.allTasksInDoneState?.data?.map((task: Task) => (
        <TaskListItem
          key={task?.id}
          selected={task?.id === selectedTask?.id}
          task={task}
          onTaskSelected={onTaskSelected}
        />
      ))}

      {totalPages > 1 && (
        <Pager
          css={styles.pager}
          current={currentPage}
          pageSlots={5}
          total={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  ) : (
    <EmptyState
      actions={
        <Button id="clp-nm-empty" onClick={onCreateNewTask}>
          {t('Tasks:CREATE_NEW_TASK')}
        </Button>
      }
      css={styles.emptyState}
      header={t('Tasks:YOU_DONT_HAVE_ANY_TASKS_YET')}
      media={<img alt="" src={EmptyImage} />}
    />
  )
}

export default CompletedTasksList
