/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { Spacing } from '../../../../constants/spacing'
import { Prescription } from '../../../../types/dto/Prescription'
import { spacing } from '../../../../utils/spacing'
import Card from '../../card/Card/Card'
import Tag, { TagTheme } from '../../tag/Tag'
import PrescriptionWidgetItem from './PrescriptionWidgetItem'

type PrescriptionsWidgetProps = {
  onOrder?: (prescription: Prescription) => void
  prescriptions: Prescription[]
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    rowGap: spacing(Spacing.S3),
    padding: spacing(Spacing.S7, Spacing.S3, Spacing.S3, Spacing.S3),
  }),
  label: css({
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 0,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
  }),
}

const PrescriptionsWidget = ({
  prescriptions,
  onOrder,
  ...rest
}: PrescriptionsWidgetProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  return (
    <Card css={styles.container} {...rest}>
      <Tag css={styles.label} theme={TagTheme.CAUTION}>
        {t('Home:PRESCRIPTIONS').toUpperCase()}
      </Tag>

      {prescriptions?.map(prescription => (
        <PrescriptionWidgetItem
          key={prescription.id}
          prescription={prescription}
          onOrder={onOrder}
        />
      ))}
    </Card>
  )
}

export default PrescriptionsWidget
