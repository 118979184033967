/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { SuggestedAppointment } from '../../../../types/dto/Appointment'
import { Business } from '../../../../types/dto/Business'
import { Patient } from '../../../../types/dto/Patient'
import SuggestedAppointmentWidget, {
  SUGGESTED_WIDGET_HEIGHT,
} from './SuggestedAppointmentWidget'

const SUGGESTED_WIDGET_MARGIN = 5

type SuggestedAppointmentsStackWidgetProps = {
  businesses?: Business[]
  onBooked: (suggestedAppointment?: SuggestedAppointment) => void
  onChange: (SuggestedAppointment?: SuggestedAppointment) => void
  onClose: () => void
  onDecline: (suggestedAppointment?: SuggestedAppointment) => void
  onError: (message: string) => void
  patient?: Patient
  suggestedAppointments?: SuggestedAppointment[]
  supportedReminderStateIds: string[]
}

const styles = {
  container: css({
    position: 'relative',
  }),
  suggestedWidget: css({
    position: 'absolute',
    width: '100%',
  }),
}

const getVisibilityMap = (suggestedAppointments: SuggestedAppointment[]) =>
  suggestedAppointments.reduce((map, _suggestedAppointment, index) => {
    map[index] = true
    return map
  }, {} as Record<number, boolean>)

const SuggestedAppointmentsStackWidget = ({
  suggestedAppointments,
  businesses,
  patient,
  supportedReminderStateIds,
  onBooked,
  onChange,
  onDecline,
  onClose,
  onError,
  ...rest
}: SuggestedAppointmentsStackWidgetProps) => {
  const [visibilityMap, setVisibilityMap] = useState<Record<number, boolean>>(
    getVisibilityMap(suggestedAppointments || []),
  )

  const amountShown = R.filter(entry => entry, R.values(visibilityMap)).length

  const getBusiness = (businessId?: string) =>
    R.find(R.propEq(businessId, 'id'), businesses || [])

  const handleHide = (index: number) =>
    setVisibilityMap({ ...visibilityMap, [index]: false })

  const handleDecline = (index: number, suggestion?: SuggestedAppointment) => {
    onDecline(suggestion)
    const closeSuggestions = amountShown <= 1
    handleHide(index)

    if (closeSuggestions) {
      onClose()
    }
  }

  return (
    <div css={styles.container} {...rest}>
      <div
        css={[
          css({
            height: Math.max(
              SUGGESTED_WIDGET_HEIGHT,
              SUGGESTED_WIDGET_HEIGHT + amountShown * SUGGESTED_WIDGET_MARGIN,
            ),
          }),
        ]}
      >
        {suggestedAppointments
          ?.filter((_, index) => visibilityMap[index])
          ?.map((suggestedAppointment, index) => (
            <div
              css={[
                styles.suggestedWidget,
                css({
                  marginTop: index * SUGGESTED_WIDGET_MARGIN,
                }),
              ]}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SuggestedAppointmentWidget
                business={getBusiness(suggestedAppointment?.businessId)}
                patient={patient}
                suggestedAppointment={suggestedAppointment}
                supportedReminderStateIds={supportedReminderStateIds}
                onBooked={onBooked}
                onChange={onChange}
                onDecline={suggestion => handleDecline(index, suggestion)}
                onError={onError}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default SuggestedAppointmentsStackWidget
