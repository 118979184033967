/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { KibFilterIcon } from '@chewy/kib-icons-react'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import { GET_TIMELINE_CONSTANTS } from '../../../api/queries/constants'
import { GET_TIMELINE } from '../../../api/queries/patient'
import { ColorVariables } from '../../../constants/colors'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import {
  TimelineItemType,
  TranslatedTimelineFilters,
} from '../../../constants/timeline'
import { Constant } from '../../../types/dto/Constants'
import { TimelineItem, TimelinePrescription } from '../../../types/dto/Patient'
import { sortAlphabeticallyByName } from '../../../utils'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Icon from '../icon/Icon'
import CatalogImage from '../images/catalog.svg'
import Pager from '../navigation/Pager'
import Spinner from '../spinner/Spinner'
import Text, { TextVariant } from '../typography/Text/Text'
import TimelineDetailsSheet from './timeline/TimelineDetailsSheet'
import TimelineFilterSheet from './timeline/TimelineFilterSheet'
import TimelineItemCard from './timeline/TimelineItemCard'

const BATCH_SIZE = 25

type PatientTimelineCardProps = {
  patientId: string | undefined
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: spacing(Spacing.S3, Spacing.S4),
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
      padding: spacing(0, Spacing.S2),
    }),
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0, Spacing.S2),
    }),
  }),
  filterIcon: css({
    width: 24,
    height: 24,
    '&:hover': css({
      cursor: 'pointer',
    }),
  }),
  filterIconComponent: css({
    // safari fix
    width: '100%',
    height: '100%',
  }),
  spinner: css({
    marginTop: spacing(Spacing.S4),
    alignSelf: 'center',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  emptyImage: css({
    width: 200,
    height: 'auto',
    alignSelf: 'center',
    marginTop: spacing(Spacing.S4),
  }),
  emptyText: css({
    alignSelf: 'center',
    marginTop: spacing(Spacing.S4),
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  groupContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(Spacing.S2),
  }),
  pager: css({
    marginTop: spacing(Spacing.S4),
  }),
}

const PatientTimelineCard = ({
  patientId,
  ...rest
}: PatientTimelineCardProps) => {
  const navigate = useNavigate()

  const { t } = useTranslation('Pet')

  const [filterOpen, setFilterOpen] = useState(false)
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [selectedTimelineItem, setSelectedTimelineItem] = useState<
  TimelineItem | undefined
  >(undefined)
  const [timelineTypeFilters, setTimelineTypeFilters] = useState<Constant[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  const {
    data: {
      constants: { timelineTypes: TimelineTypes = [] } = {},
      loading: constantsLoading,
    } = {},
  } = useQuery(GET_TIMELINE_CONSTANTS)

  const supportedTranslatedTimelineTypes = TimelineTypes.filter(
    (type: Constant) => R.includes(type?.name, Object.values(TimelineItemType)),
  )
    .map((type: Constant) => ({
      id: type.id,
      name: TranslatedTimelineFilters[type.name]?.(),
    }))
    .sort(sortAlphabeticallyByName) as Constant[]

  const timelineTypeIds = R.pluck(
    'id',
    R.isEmpty(timelineTypeFilters)
      ? supportedTranslatedTimelineTypes
      : timelineTypeFilters,
  )

  const {
    data: { pet: { timelines = {} } = {} } = {},
    loading: timelinesLoading,
  } = useQuery(GET_TIMELINE, {
    variables: {
      id: patientId,
      types: timelineTypeIds,
      from: (currentPage - 1) * BATCH_SIZE,
      to: (currentPage - 1) * BATCH_SIZE + (BATCH_SIZE - 1),
    },
    skip: !patientId || timelineTypeIds?.length === 0,
  })

  useEffect(() => {
    setCurrentPage(1)
    setTotalCount(1)
    setTimelineTypeFilters([])
  }, [patientId])

  useEffect(() => {
    if (!timelinesLoading) {
      setTotalCount(timelines?.totalCount || 1)
    }
  }, [timelinesLoading, timelineTypeFilters])

  const loading = constantsLoading || timelinesLoading

  const totalPages = Math.ceil(totalCount / BATCH_SIZE)

  const timelineGroups = R.groupBy(
    (timelineItem: TimelineItem) =>
      moment(timelineItem.date).format(DateFormat.BACKEND_FULL_DATE),
    timelines?.data || [],
  )
  const timelineEntries = Object.entries(timelineGroups)

  const handleFilter = (types: Constant[]) => {
    setTimelineTypeFilters(types)
    setFilterOpen(false)
  }

  const handleItemSelect = (item: TimelineItem) => {
    if (mediaQueryMatches(MediaQuery.MIN_MD)) {
      flushSync(() => {
        setSelectedTimelineItem(item)
        setDetailsOpen(true)
      })
    } else {
      navigate('/timelineDetails', {
        state: {
          timelineItem: item,
          patientId,
          from: window.location.pathname,
        },
      })
    }
  }

  const handleRequestRefill = (prescription: TimelinePrescription) => {
    navigate('/chat', {
      state: {
        newMessage: true,
        prescriptionTitle: prescription?.title,
        patientId,
      },
    })
  }

  return (
    <div css={styles.container} {...rest}>
      <div css={styles.header}>
        <Text variant={TextVariant.SECTION_1}>{t('Pet:TIMELINE')}</Text>

        <Icon
          Component={<KibFilterIcon css={styles.filterIconComponent} />}
          css={styles.filterIcon}
          id="pt-filter"
          onClick={() => setFilterOpen(true)}
        />
      </div>

      {loading && <Spinner css={styles.spinner} />}

      {R.isEmpty(timelineEntries) && !loading ? (
        <>
          <img alt="" css={styles.emptyImage} src={CatalogImage} />

          <Text css={styles.emptyText} variant={TextVariant.SECTION_1}>
            {t('Pet:NOTHING_ON_TIMELINE_YET')}
          </Text>
        </>
      ) : (
        <div css={styles.content}>
          {timelineEntries.map(([key, value], index) => (
            <TimelineItemCard
              date={key}
              hasNextPage={currentPage < totalPages}
              items={value}
              key={key}
              nextDate={timelineEntries?.[index - 1]?.[0]}
              previousDate={timelineEntries?.[index + 1]?.[0]}
              onSelect={handleItemSelect}
            />
          ))}

          {totalPages > 1 && (
            <Pager
              css={styles.pager}
              current={currentPage}
              pageSlots={7}
              total={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      )}

      <TimelineFilterSheet
        open={filterOpen}
        supportedTimelineTypes={supportedTranslatedTimelineTypes}
        onClose={() => setFilterOpen(false)}
        onFilter={handleFilter}
      />

      {mediaQueryMatches(MediaQuery.MIN_MD) && (
        <TimelineDetailsSheet
          open={detailsOpen}
          patientId={patientId}
          timelineItem={selectedTimelineItem}
          onClose={() => setDetailsOpen(false)}
          onRequestRefill={handleRequestRefill}
        />
      )}
    </div>
  )
}

export default PatientTimelineCard
