/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'

import { GET_APPOINTMENT_DETAILS } from '../../../../api/queries/appointment'
import { DateFormat } from '../../../../constants/dateFormat'
import i18n from '../../../../locales/i18n'
import { Appointment } from '../../../../types/dto/Appointment'
import { TimelineItem } from '../../../../types/dto/Patient'
import { getPersonName } from '../../../../utils'
import TimelineDetailsView from './TimelineDetailsView'

type AppointmentDetailsResponse = {
  appointment?: Appointment
}

type TimelineAppointmentDetailsViewProps = {
  appointment: TimelineItem
}

const getContent = (appointment?: Appointment) => [
  {
    title: i18n.t('Pet:DISCHARGE_NOTES'),
    content: (appointment?.dischargeNotes || []).filter(Boolean).join('\n'),
    html: true,
  },
  {
    title: i18n.t('Pet:WHY_YOU_VISITED_US'),
    content: appointment?.notes,
  },
]

const composeSubtitle = (attendedByName: string, appointmentDate: string) => {
  const attendedBy = attendedByName
    ? `${i18n.t('Pet:ATTENDED_BY')} ${attendedByName}\n`
    : ''
  return `${attendedBy}${appointmentDate}`
}

const TimelineAppointmentDetailsView = ({
  appointment: appointmentProp,
  ...rest
}: TimelineAppointmentDetailsViewProps) => {
  const { t } = useTranslation('Pet')

  const { data: { appointment } = {}, loading } =
    useQuery<AppointmentDetailsResponse>(GET_APPOINTMENT_DETAILS, {
      variables: { id: appointmentProp?.id },
    })

  const veterianName = getPersonName(
    appointment?.veterinarianInfo || appointment?.vetTechInfo,
  )
  const appointmentDate =
    moment
      .tz(
        appointment?.scheduledStartDatetime,
        appointment?.business?.timezone || 'UTC',
      )
      .format(
        moment(appointment?.scheduledStartDatetime).year() !== moment().year()
          ? DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_COMMA_YEAR_AT_FULL_TIME
          : DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME,
      ) || ''
  const subtitle = composeSubtitle(veterianName, appointmentDate)
  const contentItems = getContent(appointment)
  const logo = appointment?.veterinarianInfo
    ? appointment?.veterinarianInfo?.photoThumbnail
      || appointment?.veterinarianInfo?.photo
    : appointment?.vetTechInfo?.photoThumbnail
      || appointment?.vetTechInfo?.photo
  const businessPhone =
    appointment?.business?.primaryPhone
    || appointment?.business?.secondaryPhone
    || ''
  const showActionHeader = Boolean(
    appointment?.timelineDetails?.isSickVisit && businessPhone,
  )

  const handleCallUs = () => {
    window.open(`tel:${businessPhone}`, '_self')
  }

  return (
    <TimelineDetailsView
      showHeaderLogo
      actionHeaderActionTitle={t('Pet:CALL_US')}
      actionHeaderTitle={t('Pet:IF_PET_CONDITION_WORSENS', {
        patientName: appointment?.patient?.name || '',
      })}
      contentHeaderLogo={logo}
      contentHeaderTitle={veterianName}
      contentItems={contentItems}
      headerLogo={logo}
      headerSubtitle={subtitle}
      headerTitle={appointment?.businessReason?.name}
      loading={loading}
      showActionHeader={showActionHeader}
      timelineType={appointmentProp?.type?.name}
      onHeaderAction={handleCallUs}
      {...rest}
    />
  )
}

export default TimelineAppointmentDetailsView
