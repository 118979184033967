/** @jsxImportSource @emotion/react */
import { usePatternFormat } from 'react-number-format'
import * as R from 'ramda'

import { FormatPattern } from '../../../constants/format'
import InputText, { InputTextProps } from './InputText'

type PhoneInputProps = Omit<InputTextProps, 'value'> & {
  format?: string
  mask?: string | string[]
  value?: string | number
}

const PhoneInput = ({ value, format, mask, ...rest }: PhoneInputProps) => {
  const { format: formatText, removeFormatting } = usePatternFormat({
    format: format || FormatPattern.PHONE,
    mask,
  })

  return (
    <InputText
      value={value ? formatText?.(`${value}`) : value}
      onChange={event => {
        if (rest.onChangeText) {
          const changedValue = event.target.value

          const formatLengthReached =
            format && event.target.value.length > format.length

          const newValue = removeFormatting?.(
            formatLengthReached
              ? changedValue.substring(0, changedValue.length - 1)
              : changedValue,
          )

          const index = R.findLastIndex(
            it => /^\d+$/.test(it),
            // formatted returns incorrect value from event.target.value
            // therefore we need to format it again here from fresh value
            formatText?.(newValue || '')?.split('') || [],
          )
          window.requestAnimationFrame(() => {
            event.target.selectionStart = index + 1
            event.target.selectionEnd = index + 1
          })

          rest.onChangeText(newValue)
        }
      }}
      {...rest}
    />
  )
}

export default PhoneInput
