/** @jsxImportSource @emotion/react */
import KibDatepicker, { KibDatepickerProps } from '@chewy/kib-datepicker-react'
import { KibField } from '@chewy/kib-fields-new-react'
import { Interpolation, Theme } from '@emotion/react'

import styles from './InputDatepicker.module.scss'

export type InputDatepickerProps = KibDatepickerProps & {
  className?: string
  inputId?: string
  inputStyle?: Interpolation<Theme>
}

const InputDatepicker = ({
  inputStyle,
  inputId,
  className,
  ...rest
}: InputDatepickerProps) => (
  <KibDatepicker
    className={`${styles.datepicker}${className ? ` ${className}` : ''}`}
    {...rest}
    renderInputNew={props => (
      <KibField crossOrigin css={inputStyle} id={inputId} {...props} />
    )}
  />
)

export default InputDatepicker
