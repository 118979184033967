/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import PatientTimelineCard from '../../common/patient/PatientTimelineCard'

type PatientTimelineFragmentProps = {
  patientId: string | undefined
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(Spacing.S4, 0, 0, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S6, Spacing.S4, 0),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0),
    }),
  }),
}

const PatientTimelineFragment = ({
  patientId,
  ...rest
}: PatientTimelineFragmentProps) => (
  <div css={styles.container} {...rest}>
    <PatientTimelineCard patientId={patientId} />
  </div>
)

export default PatientTimelineFragment
