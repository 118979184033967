/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_TASK_OVERDUE } from '../../api/queries/tasks'
import { Spacing } from '../../constants/spacing'
import { Constant } from '../../types/dto/Constants'
import { Task } from '../../types/dto/Task'
import { spacing } from '../../utils/spacing'
import Divider from '../common/divider/Divider'
import ChildTasksList, { BATCH_SIZE } from './ChildTasksList'

type OverdueChildTasksListProps = {
  parentTask: Task
  taskStates: Constant[]
}

const styles = {
  divider: css({
    margin: spacing(Spacing.S4, 0),
  }),
}

const OverdueChildTasksList = ({
  parentTask,
  taskStates,
  ...rest
}: OverdueChildTasksListProps) => {
  const { t } = useTranslation('Common')

  const [currentPage, setCurrentPage] = useState(1)

  const getVariables = () => ({
    id: parentTask?.id,
    offset: (currentPage - 1) * BATCH_SIZE,
    limit: BATCH_SIZE,
  })

  const {
    data,
    loading: overdueLoading,
    refetch,
  } = useQuery(GET_TASK_OVERDUE, {
    variables: {
      id: parentTask?.id,
      offset: (currentPage - 1) * BATCH_SIZE,
      limit: BATCH_SIZE,
    },
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [parentTask])

  return (data?.task?.overdue?.data?.length > 0 || overdueLoading) ? (
    <>
      <Divider css={styles.divider} />

      <ChildTasksList
        showTotalCount
        currentPage={currentPage}
        loading={overdueLoading}
        parentTask={parentTask}
        taskStates={taskStates}
        tasks={data?.task?.overdue?.data || []}
        title={t('Common:OVERDUE')}
        totalCount={data?.task?.overdue?.totalCount}
        onMarkDoneCompleted={() => refetch(getVariables())}
        onPageChanged={setCurrentPage}
        {...rest}
      />
    </>
  ) : undefined
}

export default OverdueChildTasksList
