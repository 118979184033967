import { gql } from '@apollo/client/core'

export const GET_REMINDER_STATES = gql`
  query GetReminderStates {
    constants {
      reminderStates {
        id
        name
      }
    }
  }
`

export const GET_PATIENT_DETAILS_CONSTANTS = gql`
  query GetPatientDetailsConstants {
    constants {
      spayedNeuteredStatuses {
        id
        name
      }
    }
  }
`

export const GET_PATIENT_ADDITIONAL_INFO_CONSTANTS = gql`
  query GetPatientAdditionalInfoConstants {
    constants {
      environments {
        id
        name
      }
      fitnessLevels {
        id
        name
      }
      serviceDesignations {
        id
        name
      }
      purchasedFromTypes {
        id
        name
      }
    }
  }
`

export const GET_PATIENT_BASIC_INFO_CONSTANTS = gql`
  query GetPatientBasicInfoConstants {
    constants {
      spayedNeuteredStatuses {
        id
        name
      }
      genders {
        id
        name
        friendlyName
      }
      sortedSpecies {
        id
        name
        humanReadable
        breeds {
          id
          name
        }
      }
    }
  }
`

export const GET_TIMELINE_CONSTANTS = gql`
  query GetTimelineConstants {
    constants {
      timelineTypes {
        id
        name
      }
    }
  }
`

export const GET_TASK_STATES_CONSTANTS = gql`
  query GetTaskStatesConstants {
    constants {
      taskStates {
        id
        name
      }
    }
  }
`

export const GET_TASK_TYPES_CONSTANTS = gql`
  query GetTaskTypesConstants {
    constants {
      taskTypes {
        id
        name
      }
    }
  }
`
