/** @jsxImportSource @emotion/react */
import { KibIngressCard } from '@chewy/kib-cards-react'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { ColorVariables } from '../../../../constants/colors'
import { DateFormat } from '../../../../constants/dateFormat'
import { Spacing } from '../../../../constants/spacing'
import {
  TimelineImages,
  TimelineItemType,
  TranslatedTimelineType,
} from '../../../../constants/timeline'
import {
  TimelineItem,
  TimelinePrescription,
} from '../../../../types/dto/Patient'
import { spacing } from '../../../../utils/spacing'
import Card from '../../card/Card/Card'
import Icon from '../../icon/Icon'
import Text, { TextVariant } from '../../typography/Text/Text'

export const DASH_LINE_DAYS_DIFF = 14

type TimelineItemCardProps = {
  date: string
  hasNextPage?: boolean
  items: TimelineItem[] | undefined
  nextDate?: moment.MomentInput
  onSelect?: (item: TimelineItem) => void
  previousDate?: moment.MomentInput
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    columnGap: spacing(Spacing.S3),
  }),
  leftContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  }),
  rightContainer: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  line: css({
    border: `1px solid ${ColorVariables.UI_BG_05}`,
    display: 'flex',
    flexGrow: 1,
  }),
  topLineNext: css({
    marginTop: 24,
  }),
  topLinePrevious: css({
    flexGrow: 0,
    height: 24,
  }),
  dot: css({
    position: 'absolute',
    top: 0,
    width: 9,
    height: 9,
    backgroundColor: ColorVariables.UI_BG_05,
    borderRadius: '50%',
    marginTop: 22,
  }),
  transparent: css({
    opacity: 0,
  }),
  dateContainer: css({
    display: 'flex',
    height: 40,
    alignItems: 'flex-end',
    padding: spacing(0, 0, Spacing.S1, 0),
  }),
  parentCard: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(Spacing.S2),
    padding: spacing(Spacing.S2),
  }),
  childCard: css({
    height: 'auto',
  }),
  imageContainer: css({
    width: 32,
    height: 32,
    backgroundColor: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  image: css({
    width: 20,
    height: 'auto',
  }),
}

const TimelineItemCard = ({
  date,
  items,
  nextDate,
  previousDate,
  hasNextPage,
  onSelect,
  ...rest
}: TimelineItemCardProps) => {
  const displayDate = moment
    .tz(date, items?.[0]?.business?.timezone || 'UTC')
    .format(DateFormat.FULL_MONTH_SHORT_DAY_COMMA_YEAR)

  return (
    <div css={styles.container} {...rest}>
      <div css={styles.leftContainer}>
        <div
          css={[
            styles.line,
            !nextDate && styles.topLineNext,
            !previousDate && styles.topLinePrevious,
            !previousDate && !nextDate && styles.transparent,
          ]}
        />

        <div css={styles.dot} />

        <div
          css={[
            styles.line,
            !previousDate && !hasNextPage && styles.transparent,
          ]}
        />
      </div>

      <div css={styles.rightContainer}>
        <div css={styles.dateContainer}>
          <Text variant={TextVariant.PARAGRAPH_2}>{displayDate}</Text>
        </div>

        <Card css={styles.parentCard}>
          {items?.map((item: TimelineItem) => {
            const timelineType = TranslatedTimelineType[item.type.name]?.()
            const image = TimelineImages[item.type.name]
            const refills =
              item.type.name === TimelineItemType.PRESCRIPTION
              && (item as TimelinePrescription).refills

            return (
              <KibIngressCard
                css={styles.childCard}
                id={`pt-item-${timelineType.substring(0, 3).toLowerCase()}`}
                image={
                  <Icon
                    circle
                    Component={
                      <img alt={timelineType} css={styles.image} src={image} />
                    }
                    alt={timelineType}
                    css={styles.imageContainer}
                  />
                }
                key={item.id}
                title={item.title}
                {...(refills ? { subtitle: refills } : {})}
                onClick={() => onSelect?.(item)}
              />
            )
          })}
        </Card>
      </div>
    </div>
  )
}

export default TimelineItemCard
