/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { KibSticky } from '@chewy/kib-behaviors-react'
import {
  KibAccountCircleIcon,
  KibArrowLeftIcon,
  KibCloseIcon,
  KibMenuIcon,
} from '@chewy/kib-icons-react'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Icon from '../icon/Icon'
import BrandIcon from '../images/boop.svg'
import NavigationBar, { NavigationBarHandle } from '../navigation/NavigationBar'

type ToolbarProps = {
  isAuthenticated: boolean
  onLogout?: () => void
  onRouteChanged?: (route: string) => void
}

const styles = {
  stickyContainer: css({
    backgroundColor: ColorVariables.UI_BG_BRAND_PRIMARY,
    zIndex: 2,
  }),
  container: css({
    display: 'flex',
    alignItems: 'center',
  }),
  gridItem: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S4),
    padding: spacing(0, Spacing.S2),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S7, 0, Spacing.S7),
    }),
  }),
  gridItemLeft: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0, 0, 0, Spacing.S4),
    }),
  }),
  gridItemMiddle: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      justifyContent: 'center',
    }),
  }),
  gridItemRight: css({
    justifyContent: 'flex-end',
  }),
  icon: css({
    width: 40,
    height: 40,
    padding: spacing(Spacing.S1),
    color: ColorVariables.UI_BG_PRIMARY,
    '&:hover': css({
      cursor: 'pointer',
    }),
  }),
  iconComponent: css({
    // safari support
    width: '100%',
    height: '100%',
  }),
}

const Toolbar = ({
  isAuthenticated,
  onRouteChanged,
  onLogout,
}: ToolbarProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location?.state?.from

  const { t } = useTranslation('Common')

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const ref = useRef<NavigationBarHandle>(null)

  return (
    <KibSticky css={styles.stickyContainer}>
      <KibGrid css={styles.container}>
        {mediaQueryMatches(MediaQuery.MAX_SM) && (
          <KibGridItem
            css={[styles.gridItem, styles.gridItemLeft]}
            span="1@max-sm"
            onClick={() => (from ? navigate(from) : ref?.current?.toggle())}
          >
            {isAuthenticated
              && (from ? (
                <KibArrowLeftIcon css={styles.icon} />
              ) : isMenuOpen ? (
                <KibCloseIcon css={styles.icon} />
              ) : (
                <KibMenuIcon css={styles.icon} />
              ))}
          </KibGridItem>
        )}

        <KibGridItem
          css={[styles.gridItem, styles.gridItemMiddle]}
          offset="2@min-lg"
          span="2@max-sm 4@min-md 3@min-lg"
        >
          <img alt={t('Common:BOOP')} src={BrandIcon} />
        </KibGridItem>

        <KibGridItem
          css={[styles.gridItem, styles.gridItemRight]}
          span="1@max-sm 4@min-md 5@min-lg"
        >
          {isAuthenticated && mediaQueryMatches(MediaQuery.MIN_MD) && (
            <Icon
              Component={<KibAccountCircleIcon css={styles.iconComponent} />}
              css={styles.icon}
              onClick={() => navigate('/account')}
            />
          )}
        </KibGridItem>
      </KibGrid>

      {isAuthenticated && (
        <NavigationBar
          ref={ref}
          onItemSelected={path => onRouteChanged && onRouteChanged(path)}
          onLogout={onLogout}
          onToggle={setIsMenuOpen}
        />
      )}
    </KibSticky>
  )
}

export default Toolbar
