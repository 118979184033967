/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { GET_PATIENT_CARD_DATA } from '../../../api/queries/patient'
import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import {
  Appointment,
  SuggestedAppointment,
} from '../../../types/dto/Appointment'
import { Patient } from '../../../types/dto/Patient'
import { Prescription } from '../../../types/dto/Prescription'
import { getPatientCardQueryVariables } from '../../../utils/patient'
import { spacing } from '../../../utils/spacing'
import {
  LOCAL_SUGGESTED_APPOINTMENTS,
  useLocalStorage,
} from '../../../utils/useLocalStorage'
import Button, { ButtonSize } from '../button/Button'
import Card from '../card/Card/Card'
import Divider from '../divider/Divider'
import Avatar from '../icon/Avatar'
import Link from '../link/Link'
import Text, { TextVariant } from '../typography/Text/Text'
import PrescriptionsWidget from './widget/PrescriptionsWidget'
import RemindersWidget from './widget/RemindersWidget'
import SuggestedAppointmentsStackWidget from './widget/SuggestedAppointmentsStackWidget'
import UpcomingAppointmentSkeleton from './widget/UpcomingAppointmentSkeleton'
import UpcomingAppointmentWidget from './widget/UpcomingAppointmentWidget'

type SuggestedAppointmentsState = {
  show: boolean
  suggestedAppointments?: SuggestedAppointment[]
}

type GetPatientCardDataResponse = {
  pet: Patient
}

interface PatientCardProps {
  index?: number
  onArrived: () => void
  onBook: (patient: Patient) => void
  onChangeSuggestedAppointment: (
    patient?: Patient,
    suggestedAppointment?: SuggestedAppointment,
  ) => void
  onCheckedIn: (appointment: Appointment) => void
  onError: (error: string) => void
  onOrderPrescription?: (
    prescription?: Prescription,
    patientId?: string,
  ) => void
  onPatientDetails: (patientId?: string) => void
  onSuggestedAppointmentBooked: (
    suggestedAppointment?: SuggestedAppointment,
  ) => void
  patient: Patient
  supportedReminderStateIds: string[]
}

const styles = {
  container: css({
    display: 'flex',
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
    flexDirection: 'column',
    paddingBottom: spacing(Spacing.S3),
    width: '100%',
  }),
  header: css({
    display: 'flex',
    // TODO change color to var
    backgroundColor: '#F5F9FB',
    padding: spacing(Spacing.S3),
    flexDirection: 'row',
    alignItems: 'center',
  }),
  avatar: css({
    backgroundColor: ColorVariables.UI_BG_02,
  }),
  nameContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: spacing(Spacing.S3),
  }),
  content: css({
    padding: spacing(Spacing.S4, Spacing.S4, Spacing.S2, Spacing.S4),
  }),
  upToDateContainer: css({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(Spacing.S3, Spacing.S4),
  }),
  divider: css({
    marginTop: spacing(Spacing.S2),
    marginBottom: spacing(Spacing.S3),
  }),
  bookButton: css({
    marginLeft: 'auto',
  }),
  prescriptionsContainer: css({
    marginTop: spacing(Spacing.S4),
  }),
}

const getSuggestedAppointmentLocalStorageKey = (
  businessId?: string,
  patientId?: string,
  reasonId?: string,
  startDate?: string,
) => `sugg_appt_${businessId}_${patientId}_${reasonId}_${startDate}`

const PatientCard = ({
  patient,
  supportedReminderStateIds,
  index = 1,
  onBook,
  onChangeSuggestedAppointment,
  onSuggestedAppointmentBooked,
  onOrderPrescription,
  onPatientDetails,
  onArrived,
  onCheckedIn,
  onError,
  ...rest
}: PatientCardProps) => {
  const { t } = useTranslation('Home')

  const [
    suggestedAppointmentDeclinedList,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSuggestedAppointmentDeclinedList,
  ] = useLocalStorage<Array<string>>(LOCAL_SUGGESTED_APPOINTMENTS, [])

  const [suggestedAppointmentState, setSuggestedAppointmentState] =
    useState<SuggestedAppointmentsState>({ show: false })

  const { data, loading: loadingPatientWithExtra } =
    useQuery<GetPatientCardDataResponse>(GET_PATIENT_CARD_DATA, {
      variables: getPatientCardQueryVariables(
        patient?.id,
        supportedReminderStateIds,
      ),
      skip:
        !supportedReminderStateIds || supportedReminderStateIds?.length === 0,
    })

  const { pet: patientWithExtra } = data || {}

  const sortedAppointments = R.pipe(
    R.sortBy(R.prop('scheduledStartDatetime')),
    R.take(10),
  )(patientWithExtra?.upcomingAppointments || []) as Appointment[]

  const isUpToDate = true
  const hasAppointments = sortedAppointments?.length > 0
  const hasPrescriptions =
    patientWithExtra?.prescriptions?.data
    && patientWithExtra?.prescriptions?.data?.length > 0
  const hasReminders =
    patientWithExtra?.reminders?.data
    && patientWithExtra?.reminders?.data?.length > 0
  const showSuggestionAppointments = suggestedAppointmentState.show

  const isNotDeclinedSuggestion = (
    suggestedAppointment: SuggestedAppointment,
  ) => {
    const key = getSuggestedAppointmentLocalStorageKey(
      suggestedAppointment.businessId,
      patient?.id,
      suggestedAppointment.businessAppointmentReason?.businessAppointmentTypeId,
      suggestedAppointment.scheduledStartDatetime,
    )
    return !R.includes(key, suggestedAppointmentDeclinedList)
  }

  const updateSuggestions = () => {
    const notDeclinedSuggestions = R.filter(
      isNotDeclinedSuggestion,
      patientWithExtra?.suggestedAppointments || [],
    )

    const shouldShow =
      notDeclinedSuggestions?.length > 0
      && !hasAppointments
      && !loadingPatientWithExtra

    if (shouldShow) {
      setSuggestedAppointmentState({
        ...suggestedAppointmentState,
        show: true,
        suggestedAppointments: notDeclinedSuggestions,
      })
    } else {
      setSuggestedAppointmentState({
        ...suggestedAppointmentState,
        show: false,
      })
    }
  }

  const handleSuggestedAppointmentsDecline = (
    suggestedAppointment?: SuggestedAppointment,
  ) => {
    if (suggestedAppointment) {
      const key = getSuggestedAppointmentLocalStorageKey(
        suggestedAppointment?.businessId,
        patient?.id,
        suggestedAppointment?.businessAppointmentReason
          ?.businessAppointmentTypeId,
        suggestedAppointment?.scheduledStartDatetime,
      )
      setSuggestedAppointmentDeclinedList([
        ...suggestedAppointmentDeclinedList,
        key,
      ])
    }
  }

  const handleSuggestedAppointmentsClose = () => {
    setSuggestedAppointmentState({
      show: false,
    })
  }

  useEffect(() => {
    updateSuggestions()
  }, [
    patientWithExtra?.upcomingAppointments,
    patientWithExtra?.suggestedAppointments,
    loadingPatientWithExtra,
  ])

  return (
    <Card css={styles.container} {...rest}>
      <div css={styles.header}>
        <Avatar
          avatarSize={72}
          photo={patient?.photoThumbnail || patient?.photo}
        />

        <div css={styles.nameContainer}>
          <Text variant={TextVariant.DISPLAY_6}>{patient?.name}</Text>
          <Link
            showNavigationIcon
            id={`pc${index}-pd`}
            onClick={() => {
              onPatientDetails(patient?.id)
            }}
          >
            {t('Home:PET_PROFILE')}
          </Link>
        </div>
      </div>

      <div css={styles.content}>
        {loadingPatientWithExtra && <UpcomingAppointmentSkeleton />}

        {showSuggestionAppointments && (
          <SuggestedAppointmentsStackWidget
            businesses={patientWithExtra?.businesses}
            patient={patient}
            suggestedAppointments={
              suggestedAppointmentState.suggestedAppointments
            }
            supportedReminderStateIds={supportedReminderStateIds}
            onBooked={onSuggestedAppointmentBooked}
            onChange={suggestedAppointment =>
              onChangeSuggestedAppointment(patient, suggestedAppointment)
            }
            onClose={handleSuggestedAppointmentsClose}
            onDecline={handleSuggestedAppointmentsDecline}
            onError={onError}
          />
        )}

        {hasAppointments && !loadingPatientWithExtra && (
          <UpcomingAppointmentWidget
            appointments={sortedAppointments}
            parentIndex={index}
            supportedReminderStateIds={supportedReminderStateIds}
            onArrived={onArrived}
            onCheckedIn={onCheckedIn}
          />
        )}

        {hasPrescriptions && !loadingPatientWithExtra && (
          <PrescriptionsWidget
            css={
              (hasAppointments || showSuggestionAppointments)
              && styles.prescriptionsContainer
            }
            prescriptions={patientWithExtra?.prescriptions?.data || []}
            onOrder={prescription =>
              onOrderPrescription?.(prescription, patient?.id)
            }
          />
        )}

        {hasReminders && !loadingPatientWithExtra && (
          <RemindersWidget
            css={
              (hasAppointments
                || hasPrescriptions
                || showSuggestionAppointments)
              && styles.prescriptionsContainer
            }
            hasUpcomingAppointments={hasAppointments}
            reminders={patientWithExtra?.reminders?.data || []}
            onBook={() => onBook(patient)}
          />
        )}

        {isUpToDate
          && !hasAppointments
          && !hasPrescriptions
          && !loadingPatientWithExtra
          && !showSuggestionAppointments && (
            <Card css={styles.upToDateContainer}>
              <Text variant={TextVariant.PARAGRAPH}>
                {t('Home:YOUR_ALL_PET_UP_TO_DATE', { name: patient?.name })}
              </Text>

              <Divider css={styles.divider} />

              <Button
                css={styles.bookButton}
                id={`pc${index}-book`}
                size={ButtonSize.MEDIUM}
                onClick={() => onBook(patient)}
              >
                {t('Home:BOOK_A_VISIT')}
              </Button>
            </Card>
        )}
      </div>
    </Card>
  )
}

export default PatientCard
