/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import { GET_AVAILABLE_STAFF } from '../../../api/queries/appointment'
import { ColorVariables } from '../../../constants/colors'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { AvailableStaff, TimeSlot } from '../../../types/dto/Appointment'
import { Business } from '../../../types/dto/Business'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import BookTimeSlots from '../chips/BookTimeSlots'
import InputDatepicker from '../input/InputDatepicker'
import SelectInput, { SelectInputOption } from '../input/SelectInput'
import Spinner from '../spinner/Spinner'
import { Reason } from './BookPatientVisitTypeForm'

type AvailableStaffResponse = {
  availableStaffByBusinessAppointmentType?: AvailableStaff[]
}

type BookProviderDateFormProps = {
  business?: Business
  onDoctorSelected: (doctor: AvailableStaff) => void
  onTimeSelected: (time: TimeSlot | undefined) => void
  reason?: Reason
  suggestedDoctorId?: string
  suggestedEndTime?: string
  suggestedStartTime?: string
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  contentRow: css({
    display: 'flex',
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
      margin: spacing(Spacing.S4, 0, 0, 0),
    }),
  }),
  contentContainerLeft: css({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '100%',
    }),
    ...mediaQuery(MediaQuery.MIN_MD, {
      padding: spacing(0, Spacing.S4, 0, 0),
    }),
  }),
  contentContainerRight: css({
    width: '50%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '100%',
    }),
    ...mediaQuery(MediaQuery.MIN_MD, {
      padding: spacing(0, Spacing.S4, 0, Spacing.S4),
    }),
  }),
  centerContainer: css({
    alignItems: 'center',
    justifyContent: 'center',
  }),
  datePickerContainer: css({
    alignItems: 'center',
  }),
  datePicker: css({
    marginTop: spacing(Spacing.S4),
  }),
  timeSlots: css({
    marginTop: spacing(Spacing.S4),
  }),
  spinnerContainer: css({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }),
  spinner: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    ...mediaQuery(MediaQuery.MAX_SM, {
      marginTop: spacing(Spacing.S6),
    }),
  }),
}

const BookProviderDateForm = ({
  reason,
  business,
  suggestedDoctorId,
  suggestedStartTime,
  suggestedEndTime,
  onDoctorSelected,
  onTimeSelected,
  ...rest
}: BookProviderDateFormProps) => {
  const { t } = useTranslation('Home')

  const [selectedDoctor, setSelectedDoctor] = useState<
  AvailableStaff | undefined
  >()
  const [selectedDate, setSelectedDate] = useState<
  string | Date | undefined | null
  >(suggestedStartTime)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<
  TimeSlot | undefined
  >()

  const {
    data: { availableStaffByBusinessAppointmentType: doctors = [] } = {},
    loading,
  } = useQuery<AvailableStaffResponse>(GET_AVAILABLE_STAFF, {
    variables: {
      businessId: business?.id,
      day: moment(selectedDate).format(DateFormat.BACKEND_FULL_DATE),
      businessAppointmentTypeId: reason?.id,
    },
    fetchPolicy: 'network-only',
  })

  const doctorOptions = doctors.map(
    (doctor: AvailableStaff) =>
      ({
        value: doctor?.personInfo?.id,
        label:
          `${doctor?.personInfo?.firstName} ${doctor?.personInfo?.lastName}`.trim(),
        disabled: !doctor?.timeSlots?.length,
      } as SelectInputOption),
  )

  const handleDoctorSelected = (id: string | undefined) => {
    const doctor = doctors.find(
      (item: AvailableStaff) => item?.personInfo?.id === id,
    )

    if (doctor) {
      setSelectedDoctor(doctor)
      onDoctorSelected(doctor)
    }
  }

  const handleTimeSelected = (time: TimeSlot | undefined) => {
    setSelectedTimeSlot(time)
    onTimeSelected(time)
  }

  const handleDoctorAndTimeSelected = (
    id: string | undefined,
    timeSlot: TimeSlot | undefined,
  ) => {
    flushSync(() => {
      handleDoctorSelected(id)
      handleTimeSelected(timeSlot)
    })
  }

  const handleDateSelected = (date: Date | null) => {
    setSelectedDate(date)
    handleDoctorSelected(undefined)
    handleTimeSelected(undefined)
  }

  useEffect(() => {
    if (!loading && !R.isEmpty(doctors)) {
      const firstAvailableDoctor = suggestedDoctorId
        ? R.find(doctor => doctor.personInfo?.id === suggestedDoctorId, doctors)
        : R.find(doctor => !R.isEmpty(doctor.timeSlots), doctors)

      if (suggestedStartTime) {
        handleDoctorAndTimeSelected(firstAvailableDoctor?.personInfo?.id, {
          from: suggestedStartTime,
          to: suggestedEndTime,
        })
      } else {
        handleDoctorSelected(firstAvailableDoctor?.personInfo?.id)
      }
    }
  }, [loading])

  return (
    <div css={styles.container} {...rest}>
      <div css={styles.contentRow}>
        <div css={styles.contentContainerLeft}>
          <SelectInput
            id="ba-staff"
            invalid={!loading && R.isEmpty(doctorOptions)}
            label={t('Home:SELECT_PROVIDER')}
            messagesContent={t('Home:NO_AVAILABLE_STAFF_FOR_SELECTED_DAY')}
            options={doctorOptions}
            value={selectedDoctor?.personInfo?.id}
            onChange={event => {
              handleDoctorSelected(event.target.value)
            }}
          />
        </div>
      </div>

      <div css={styles.contentRow}>
        <div css={[styles.contentContainerLeft, styles.datePickerContainer]}>
          <InputDatepicker
            disablePast
            staticMode
            css={styles.datePicker}
            value={moment(selectedDate).toDate()}
            onChange={handleDateSelected}
          />
        </div>

        <div
          css={[
            styles.contentContainerRight,
            loading && styles.centerContainer,
          ]}
        >
          {loading ? (
            <div css={styles.spinnerContainer}>
              <Spinner css={styles.spinner} />
            </div>
          ) : (
            <BookTimeSlots
              multiTime
              css={styles.timeSlots}
              selectedTimeSlot={selectedTimeSlot}
              timeSlots={selectedDoctor?.timeSlots || []}
              timezone={business?.timezone || 'UTC'}
              onTimeSlotSelected={handleTimeSelected}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default BookProviderDateForm
