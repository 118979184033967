/** @jsxImportSource @emotion/react */
import { KibSelect, KibSelectProps } from '@chewy/kib-fields-new-react'

export type SelectInputOption = {
  disabled?: boolean
  label: string
  value: string
}

type SelectInputProps = KibSelectProps & {
  emptyOption?: boolean
  options: SelectInputOption[]
}

const SelectInput = ({ options, emptyOption, ...rest }: SelectInputProps) => (
  <KibSelect crossOrigin {...rest}>
    {emptyOption && (
      <option key="empty" value={undefined}>
        {' '}
      </option>
    )}
    {options.map(option => (
      <option
        disabled={option.disabled}
        key={option.value}
        value={option.value}
      >
        {option.label}
      </option>
    ))}
  </KibSelect>
)

export default SelectInput
