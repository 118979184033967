/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import { SIGN_DOCUMENT } from '../../../api/mutations/appointment'
import { GET_APPOINTMENT_DOCUMENTS } from '../../../api/queries/appointment'
import { DateFormat } from '../../../constants/dateFormat'
import { Spacing } from '../../../constants/spacing'
import { Business } from '../../../types/dto/Business'
import { Client } from '../../../types/dto/Client'
import { Document } from '../../../types/dto/Document'
import { SaveFormProps } from '../../../types/forms'
import { getPersonName } from '../../../utils'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis } from '../button/Button'
import Modal, { ModalProps } from './Modal'
import SignatureModal from './SignatureModal'

const DIV_DATA_ID = 'document-data'
const IMAGE_CLASS = 'SIGNATURE_signed_image'

type DocumentModalProps = ModalProps &
SaveFormProps & {
  appointmentId?: string
  business?: Business
  document?: Document
  signer?: Client
}

const SIGNATURE_CLASS = 'SIGNATURE'
const SIGNATURE_SIGNED_CLASS = 'SIGNATURE_SIGNED'
const SIGNATURE_CONTAINER_CLASS = 'SIGNATURE_signature'
const SIGNATURE_SIGNED_CONTAINER_CLASS = 'SIGNATURE_signed'

const styles = {
  documentContent: css({
    minHeight: 500,
    padding: spacing(Spacing.S4),
  }),
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
}

const DocumentModal = ({
  document: documentProp,
  signer,
  business,
  appointmentId,
  onSaveCompleted,
  ...rest
}: DocumentModalProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const [hasSignature, setHasSignature] = useState(false)
  const [signed, setSigned] = useState(false)
  const [signModalOpen, setSignModalOpen] = useState(false)

  const [signDocument, { loading }] = useMutation(SIGN_DOCUMENT, {
    onCompleted: () => {
      onSaveCompleted?.(documentProp?.id || '')
    },
    refetchQueries: [
      {
        query: GET_APPOINTMENT_DOCUMENTS,
        variables: {
          id: appointmentId,
        },
      },
    ],
    awaitRefetchQueries: true,
  })

  const html = documentProp?.file || ''

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (rest?.open) {
      setTimeout(() => {
        const nodeList = ref?.current?.querySelectorAll('input[type=checkbox]')
        const checkBoxes = Array.from(nodeList || [])

        checkBoxes.forEach(checkbox =>
          checkbox.addEventListener('change', event => {
            const element = event?.target as HTMLInputElement
            if (!element?.hasAttribute('checked')) {
              element.setAttribute('checked', '')
            } else {
              element.removeAttribute('checked')
            }
          }),
        )

        const signatureBox = ref?.current?.querySelector(
          `table.${SIGNATURE_CLASS}`,
        )
        const signatureSignedBox = ref?.current?.querySelector(
          `table.${SIGNATURE_SIGNED_CLASS}`,
        )
        setHasSignature(Boolean(signatureBox) || Boolean(signatureSignedBox))
      }, 1)
    }
  }, [html])

  const handleSave = async (close: () => void) => {
    if (hasSignature && !documentProp?.signed) {
      const bodyHtml = document.querySelector(`[id='${DIV_DATA_ID}'`)?.innerHTML
      await signDocument({
        variables: {
          businessId: business?.id,
          body: bodyHtml,
          id: documentProp?.id,
        },
      })
    }

    onSaveCompleted?.(documentProp?.id || '')

    close()
  }

  const handleSign = () => {
    setSignModalOpen(true)
  }

  const handleSigned = (data: string) => {
    const signTables = Array.from(
      ref?.current?.querySelectorAll(`table.${SIGNATURE_CLASS}`) || [],
    )

    if (!R.isEmpty(signTables)) {
      R.forEach((element: Element) => {
        element?.classList?.add(SIGNATURE_SIGNED_CLASS)
        element?.classList?.remove(SIGNATURE_CLASS)

        const signatureContainer = ref?.current?.querySelector(
          `td.${SIGNATURE_CONTAINER_CLASS}`,
        )
        signatureContainer?.classList?.add(SIGNATURE_SIGNED_CONTAINER_CLASS)
        signatureContainer?.classList?.remove(SIGNATURE_CONTAINER_CLASS)

        const signatureImage = document.createElement('img')
        signatureImage.setAttribute('class', IMAGE_CLASS)
        signatureImage.setAttribute('src', data)
        signatureImage.setAttribute('width', '200')
        signatureImage.setAttribute('alt', 'signature_img')

        signatureContainer?.appendChild(signatureImage)

        const signerRow = document.createElement('tr')

        const signerCell = document.createElement('td')
        signerCell.setAttribute('colspan', '2')
        signerCell.style.borderColor = '#EDECEC'

        const signatureSigner = document.createElement('p')
        const time = moment
          .tz(moment(), business?.timezone || 'UTC')
          .format(DateFormat.FULL_DATE_TIME)
        signatureSigner.innerText = `${t('Common:SIGNED')} ${time} ${t(
          'Common:BY',
        )} ${getPersonName(signer)}`
        signatureSigner.style.fontSize = '14px'

        signerCell.appendChild(signatureSigner)
        signerRow.appendChild(signerCell)
        element?.appendChild(signerRow)
      }, signTables)
    } else {
      const images = Array.from(
        ref?.current?.querySelectorAll(`img.${IMAGE_CLASS}`) || [],
      )

      R.forEach((image: Element) => {
        if (image?.classList?.contains(IMAGE_CLASS)) {
          image?.setAttribute('src', data)
        }
      }, images)
    }

    setSigned(true)
  }

  return (
    <Modal
      {...rest}
      size="large"
      title={documentProp?.name}
      // eslint-disable-next-line
      controls={({ close }) =>
        !documentProp?.signed ? (
          <div css={styles.buttonsContainer}>
            {hasSignature && (
              <Button
                emphasis={ButtonEmphasis.SECONDARY}
                id="dm-sign"
                onClick={handleSign}
              >
                {t('Common:SIGN')}
              </Button>
            )}

            <Button
              disabled={hasSignature && !signed}
              id="dm-save"
              loading={loading}
              onClick={() => handleSave(close)}
            >
              {hasSignature ? t('Common:SAVE') : t('Home:REVIEWED')}
            </Button>
          </div>
        ) : undefined
      }
      onClose={() => {
        setSigned(false)
        setSignModalOpen(false)
        rest.onClose?.()
      }}
    >
      {documentProp?.file && (
        <div
          css={styles.documentContent}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          id={DIV_DATA_ID}
          ref={ref}
        />
      )}

      <SignatureModal
        open={signModalOpen}
        onClose={() => setSignModalOpen(false)}
        onSigned={handleSigned}
      />
    </Modal>
  )
}

export default DocumentModal
