/** @jsxImportSource @emotion/react */
import { HTMLAttributes } from 'react'

import { clickable } from '../../../../utils/component'

import styles from './Card.module.scss'

export type CardProps = HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

const Card = ({ children, className, onClick, ...rest }: CardProps) => (
  <div
    className={`${styles.card} ${className}`}
    {...rest}
    {...(onClick ? clickable(onClick) : {})}
  >
    {children}
  </div>
)

export default Card
