import { Environment } from '../constants/environmentVariables'

export const isLocalEnvironment = () => {
  const { hostname } = window.location
  return hostname.includes('localhost') || hostname.includes('127.0.0.1')
}

export const getEnvironment = () => {
  const { hostname } = window.location
  if (isLocalEnvironment()) {
    return Environment.LOCAL
  }

  if (hostname.includes('stage')) {
    return Environment.STAGE
  }

  if (hostname.includes('master')) {
    return Environment.MASTER
  }

  return Environment.PROD
}
