import { gql } from '@apollo/client/core'

export const GET_VACCINATION_DETAILS = gql`
  query GetVaccinationDetails($id: ID!) {
    vaccinationDetails(id: $id) {
      title
      date
      dueDate
      administeredBy {
        id
        firstName
        lastName
      }
      lotNumber
      lotExpired
      serialNumber
      manufacturedBy
      amount
    }
  }
`
