/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useResize from '../../../utils/useResize'
import TaskDetailsView from '../../tasks/TaskDetailsView'

const TaskDetailsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useResize()

  const { task } = location.state

  useEffect(() => {
    if (isMobile === false) {
      navigate(-1)
    }
  }, [isMobile])

  return <div>{task && <TaskDetailsView task={task} />}</div>
}

export default TaskDetailsPage
