/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { KibTabsNew, KibTabsNewItem } from '@chewy/kib-content-groups-react'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import {
  GET_CERTIFICATES,
  GET_PATIENT_DETAILS,
} from '../../../api/queries/patient'
import { ColorVariables } from '../../../constants/colors'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import i18n from '../../../locales/i18n'
import { PatientVaccinationCertificates } from '../../../types/dto/Certificate'
import { Constant } from '../../../types/dto/Constants'
import { Patient } from '../../../types/dto/Patient'
import { FilePreviewTemplate } from '../../../types/entities'
import {
  dateDiffInMonths,
  dateDiffInWeeks,
  dateDiffInYears,
  findConstantByName,
} from '../../../utils'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { joinBreeds } from '../../../utils/patient'
import { spacing } from '../../../utils/spacing'
import Card from '../../common/card/Card/Card'
import Divider from '../../common/divider/Divider'
import InfoFormItem from '../../common/form/item/InfoFormItem'
import Avatar from '../../common/icon/Avatar'
import EditIcon from '../../common/icon/EditIcon'
import CertificatesCard from '../../common/patient/certificates/CertificatesCard'
import CertificatesSheet, {
  CertificatesType,
  CertificatesTypeProp,
} from '../../common/patient/certificates/CertificatesSheet'
import PatientSecondaryDetailsCard from '../../common/patient/PatientSecondaryDetailsCard'
import PatientTimelineCard from '../../common/patient/PatientTimelineCard'
import FilePreview from '../../common/preview/FilePreview'
import Text, { TextVariant } from '../../common/typography/Text/Text'

const AVATAR_SIZE = 72
const INTACT_NAME = 'Intact'

type CertificatesResponse = {
  certificates?: PatientVaccinationCertificates
}

type CertificatesSheetState = {
  open: boolean
  type: CertificatesTypeProp
}

type FilePreviewState = {
  file?: FilePreviewTemplate
  open: boolean
}

type PatientDetailsFragmentProps = {
  patientId: string | undefined
  spayedNeuteredStatuses: Constant[] | undefined
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(Spacing.S4, 0, Spacing.S8, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, 0, 0, Spacing.S6),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0),
    }),
  }),
  headerCard: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  fragmentCard: css({
    marginTop: spacing(Spacing.S4),
  }),
  header: css({
    display: 'flex',
    padding: spacing(Spacing.S4),
  }),
  tabsContainer: css({
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
  }),
  tabsItem: css({
    backgroundColor: ColorVariables.UI_BG_02,
    padding: spacing(Spacing.S4, 0, 0, 0),
  }),
  tabsItemWithSidePadding: css({
    padding: spacing(Spacing.S4, Spacing.S2, 0, Spacing.S2),
  }),
  nameContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: spacing(0, Spacing.S3),
  }),
  details: css({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(Spacing.S2, Spacing.S4),
  }),
  moreInfoContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(Spacing.S4),
  }),
  linkIcon: {
    width: 16,
    height: 16,
    color: ColorVariables.LINK_ACTIVE_PRIMARY,
  },
  certificatesCardContainer: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0, Spacing.S4, Spacing.S4, Spacing.S4),
    }),
  }),
}

const getAgeDobText = (patient: Patient) => {
  if (patient.dateOfBirth) {
    const diffInYears = dateDiffInYears(patient.dateOfBirth)
    if (diffInYears > 0) {
      return `${diffInYears} ${i18n.t('Common:YEAR_SHORT')} (${moment(
        patient.dateOfBirth,
      ).format(DateFormat.FULL_DATE_YEAR_SHORT)})`
    }

    const diffInMonths = dateDiffInMonths(patient.dateOfBirth)
    if (diffInMonths > 0) {
      return `${diffInMonths} ${i18n.t('Common:MONTH_SHORT')} (${moment(
        patient.dateOfBirth,
      ).format(DateFormat.FULL_DATE_YEAR_SHORT)})`
    }

    const diffInWeeks = dateDiffInWeeks(patient.dateOfBirth)
    if (diffInWeeks > 0) {
      return `${diffInWeeks} ${i18n.t('Common:WEEK_SHORT')} (${moment(
        patient.dateOfBirth,
      ).format(DateFormat.FULL_DATE_YEAR_SHORT)})`
    }

    return moment(patient.dateOfBirth).format(DateFormat.FULL_DATE_YEAR_SHORT)
  }

  return undefined
}

const getPatientDetailedInfo = (
  patient: Patient,
  spayedNeuteredStatuses: Constant[],
) => {
  const intact = findConstantByName(INTACT_NAME, spayedNeuteredStatuses)
  const isIntact =
    Boolean(patient?.spayedNeuteredStatus?.id)
    && (patient?.spayedNeuteredStatus?.id === intact?.id || false)
  const rabiesTagText = patient?.rabiesTag
    ? `${patient?.rabiesTag}${
      patient?.rabiesTagExpiration
        ? ` (${i18n.t('Common:EXPIRES')} ${moment(
          patient?.rabiesTagExpiration,
        ).format(DateFormat.FULL_DATE)})`
        : ''
    }`
    : undefined
  const ageDobText = getAgeDobText(patient)

  return [
    {
      title: i18n.t('Pet:AGE_DOB'),
      value: ageDobText || '-',
    },
    {
      title: i18n.t('Pet:SEX'),
      value: patient?.gender?.friendlyName || '-',
    },
    {
      title: i18n.t('Pet:INTACT'),
      value: isIntact ? i18n.t('Common:YES') : i18n.t('Common:NO'),
    },
    {
      title: i18n.t('Pet:MICROCHIP_NUMBER'),
      value: patient?.microchipNumber || '-',
    },
    {
      title: i18n.t('Pet:RABIES_TAG'),
      value: rabiesTagText || '-',
    },
    {
      title: i18n.t('Pet:LICENSE_NUMBER'),
      value: patient?.license || '-',
    },
    {
      title: i18n.t('Pet:INSURANCE'),
      value: patient?.insuranceProvider || '-',
    },
    {
      title: i18n.t('Pet:POLICY_NUMBER'),
      value: patient?.insurancePolicyNumber || '-',
    },
    {
      title: i18n.t('Pet:ENVIRONMENT'),
      value: patient?.environment?.name || '-',
    },
    {
      title: i18n.t('Pet:SERVICE_DESIGNATION'),
      value: patient?.serviceDesignation?.name || '-',
    },
    {
      title: i18n.t('Pet:FITNESS_LEVEL'),
      value: patient?.fitnessLevel?.name || '-',
    },
    {
      title: i18n.t('Pet:RECEIVED_FROM'),
      value: patient?.purchasedFrom?.name || '-',
    },
  ]
}

const PatientDetailsFragment = ({
  patientId,
  spayedNeuteredStatuses,
  ...rest
}: PatientDetailsFragmentProps) => {
  const navigate = useNavigate()

  const { t } = useTranslation(['Common', 'Pet'])

  const [filePreviewState, setFilePreviewState] = useState<FilePreviewState>({
    open: false,
  })
  const [certificatesState, setCertificatesState] =
    useState<CertificatesSheetState>({
      open: false,
      type: CertificatesType.VACCINATION,
    })

  const { data: { pet: patient = {} } = {}, loading } = useQuery(
    GET_PATIENT_DETAILS,
    {
      variables: { id: patientId },
    },
  )

  const { data: { certificates = {} } = {}, loading: certificatesLoading } =
    useQuery<CertificatesResponse>(GET_CERTIFICATES, {
      variables: { patientId },
    })

  const detailedInfo = getPatientDetailedInfo(
    patient,
    spayedNeuteredStatuses || [],
  )

  const openCertificatePreview = (file: FilePreviewTemplate) => {
    flushSync(() => {
      setCertificatesState({ ...certificatesState, open: false })
      setFilePreviewState({ file, open: true })
    })
  }

  const showCertificates = (type: CertificatesTypeProp) => {
    const certificatesList =
      type === CertificatesType.VACCINATION
        ? certificates?.proofOfVaccinationsCertificates
        : certificates?.rabiesCertificates
    if (certificatesList?.length === 1) {
      const certificate = certificatesList?.[0]
      if (certificate) {
        openCertificatePreview({
          ...certificate,
          url: certificate?.fileUrl,
          contentType: 'application/pdf',
        })
      }
    } else {
      setCertificatesState({
        open: true,
        type,
      })
    }
  }

  const handleShowVaccination = () =>
    showCertificates(CertificatesType.VACCINATION)

  const handleShowRabies = () => showCertificates(CertificatesType.RABIES)

  const handleStartNewMessage = () => {
    navigate('/chat', {
      state: {
        newMessage: true,
        patientId,
        certificateType: certificatesState?.type,
      },
    })
  }

  return (
    <div css={styles.container} {...rest}>
      <Card css={styles.headerCard}>
        <div css={styles.header}>
          <Avatar
            avatarSize={AVATAR_SIZE}
            loading={loading}
            photo={patient?.photoThumbnail || patient?.photo}
          />

          <div css={styles.nameContainer}>
            <Text loading={loading} variant={TextVariant.SECTION_1}>
              {patient?.name}
            </Text>

            <Text loading={loading} variant={TextVariant.PARAGRAPH_1}>
              {joinBreeds(patient?.breeds)}
            </Text>
          </div>

          <div>
            <EditIcon
              id="pdc-edit"
              onEdit={() => navigate(`/patient/${patientId}/edit`)}
            />
          </div>
        </div>

        {mediaQueryMatches(MediaQuery.MIN_MD) && (
          <>
            <Divider />

            <CertificatesCard
              patient={patient}
              onRabies={handleShowRabies}
              onVaccination={handleShowVaccination}
            />

            <Divider />

            <div css={styles.details}>
              {detailedInfo.map((info: { title: string; value: string }) => (
                <InfoFormItem
                  alwaysVertical
                  hideDivider
                  key={info.title}
                  loading={loading}
                  title={info.title}
                  value={info.value}
                />
              ))}
            </div>
          </>
        )}
      </Card>

      {mediaQueryMatches(MediaQuery.MAX_SM) && (
        <KibTabsNew css={styles.tabsContainer}>
          <KibTabsNewItem css={styles.tabsItem} label={t('Pet:HEALTH')}>
            <div css={styles.certificatesCardContainer}>
              <CertificatesCard
                patient={patient}
                onRabies={handleShowRabies}
                onVaccination={handleShowVaccination}
              />
            </div>

            <PatientTimelineCard patientId={patientId} />
          </KibTabsNewItem>

          <KibTabsNewItem
            css={[styles.tabsItem, styles.tabsItemWithSidePadding]}
            label={t('Common:PROFILE')}
          >
            <Card css={styles.details}>
              {detailedInfo.map((info: { title: string; value: string }) => (
                <InfoFormItem
                  alwaysVertical
                  hideDivider
                  key={info.title}
                  loading={loading}
                  title={info.title}
                  value={info.value}
                />
              ))}
            </Card>

            <PatientSecondaryDetailsCard
              css={styles.fragmentCard}
              patientId={patientId}
            />
          </KibTabsNewItem>
        </KibTabsNew>
      )}

      {mediaQueryMatches(MediaQuery.MIN_MD) && (
        <PatientSecondaryDetailsCard
          css={styles.fragmentCard}
          patientId={patientId}
        />
      )}

      <CertificatesSheet
        certificates={certificates}
        loading={certificatesLoading}
        open={certificatesState.open}
        patient={patient}
        type={certificatesState.type}
        onCertificateSelected={openCertificatePreview}
        onClose={() =>
          setCertificatesState({
            ...certificatesState,
            open: false,
          })
        }
        onStartNewMessage={handleStartNewMessage}
      />

      <FilePreview
        file={filePreviewState?.file}
        open={filePreviewState?.open}
        onClose={() => setFilePreviewState({ open: false })}
      />
    </div>
  )
}

export default PatientDetailsFragment
