/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_DASHBOARD_PATIENTS } from '../../../api/queries/client'
import { GET_REMINDER_STATES } from '../../../api/queries/constants'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import {
  Appointment,
  SuggestedAppointment,
} from '../../../types/dto/Appointment'
import { Patient } from '../../../types/dto/Patient'
import { Prescription } from '../../../types/dto/Prescription'
import { SnackbarState } from '../../../types/entities'
import { mediaQuery } from '../../../utils/mediaQuery'
import { getReminderStatesForWidgets } from '../../../utils/patient'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis } from '../../common/button/Button'
import PatientCard from '../../common/patient/PatientCard'
import PatientCardSkeleton from '../../common/patient/PatientCardSkeleton'
import Snackbar from '../../common/snackbar/Snackbar'

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: spacing(Spacing.S4),
    padding: spacing(Spacing.S4, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S6, 0, 0),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, Spacing.S2),
    }),
  }),
  addPet: css({
    marginTop: spacing(Spacing.S4),
  }),
}

const PatientsFragment = () => {
  const navigate = useNavigate()

  const { t } = useTranslation('Home')

  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    show: false,
  })

  const { data: { me } = {}, loading } = useQuery(GET_DASHBOARD_PATIENTS)

  const { data: { constants: { reminderStates = [] } = {} } = {} } =
    useQuery(GET_REMINDER_STATES)

  const supportedReminderStateIds = getReminderStatesForWidgets(reminderStates)

  const handleBook = (patient: Patient) => {
    navigate('/booking', { state: { patient } })
  }

  const handlePatientDetails = (patientId?: string) => {
    if (patientId) {
      navigate(`/patient/${patientId}`, {
        state: { from: window.location.pathname },
      })
    }
  }

  const handleAddPatient = () => {
    navigate('/patient/new')
  }

  const handleCheckedIn = (appointment: Appointment) => {
    navigate('/checkIn', {
      state: { appointment, clientNeedsUpdate: me?.needsUpdate },
    })
  }

  const handleArrived = () => {
    setSnackbarState({
      message: t('Home:ARRIVED'),
      show: true,
    })
  }

  const handleSuggestedAppointmentBooked = () => {
    setSnackbarState({
      message: t('Home:VISIT_BOOKED'),
      show: true,
    })
  }

  const handleChangeSuggestedAppointment = (
    patient?: Patient,
    suggestedAppointment?: SuggestedAppointment,
  ) => {
    navigate('/booking', {
      state: { from: window.location.pathname, patient, suggestedAppointment },
    })
  }

  const handleOrderPrescription = (prescription?: Prescription, patientId?: string) => {
    navigate('/chat', {
      state: {
        newMessage: true,
        prescriptionTitle: prescription?.title,
        patientId,
      },
    })
  }

  return (
    <div css={styles.container}>
      {loading && <PatientCardSkeleton />}

      {!loading
        && me?.patients?.map((patient: Patient, index: number) => (
          <PatientCard
            index={index + 1}
            key={patient?.id}
            patient={patient}
            supportedReminderStateIds={supportedReminderStateIds}
            onArrived={handleArrived}
            onBook={handleBook}
            onChangeSuggestedAppointment={handleChangeSuggestedAppointment}
            onCheckedIn={handleCheckedIn}
            onError={message => setSnackbarState({ message, show: true })}
            onOrderPrescription={handleOrderPrescription}
            onPatientDetails={handlePatientDetails}
            onSuggestedAppointmentBooked={handleSuggestedAppointmentBooked}
          />
        ))}

      <Button
        css={styles.addPet}
        emphasis={ButtonEmphasis.SECONDARY}
        id="add-pet"
        onClick={handleAddPatient}
      >
        {t('Home:ADD_PET')}
      </Button>

      <Snackbar
        align="start"
        message={snackbarState.message || ''}
        visible={snackbarState.show}
        onClose={() => setSnackbarState({ show: false })}
      />
    </div>
  )
}

export default PatientsFragment
