/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { CANCEL_APPOINTMENT } from '../../../api/mutations/appointment'
import { GET_PATIENT_CARD_DATA } from '../../../api/queries/patient'
import { DateFormat } from '../../../constants/dateFormat'
import { Appointment } from '../../../types/dto/Appointment'
import { addYearToFormatIfNotCurrent } from '../../../utils/date'
import { getPatientCardQueryVariables } from '../../../utils/patient'
import Button, { ButtonEmphasis } from '../button/Button'
import Modal, { ModalProps } from './Modal'

type CancelAppointmentModalProps = ModalProps & {
  appointment: Appointment
  supportedReminderStateIds: string[]
}

const styles = {
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
}

const CancelAppointmentModal = ({
  appointment,
  supportedReminderStateIds,
  ...rest
}: CancelAppointmentModalProps) => {
  const { t } = useTranslation('Home')

  const [cancelAppointment, { loading }] = useMutation(CANCEL_APPOINTMENT, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: GET_PATIENT_CARD_DATA,
        variables: getPatientCardQueryVariables(
          appointment?.patient?.id,
          supportedReminderStateIds,
        ),
      },
    ],
    awaitRefetchQueries: true,
  })

  const date = addYearToFormatIfNotCurrent(
    DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME_SHORT,
    moment.tz(
      appointment.scheduledStartDatetime,
      appointment?.business?.timezone || '',
    ),
  )

  const handleCancelAppoitment = async (close: () => void) => {
    await cancelAppointment({
      variables: {
        businessId: appointment?.business?.id,
        appointmentId: appointment.id,
      },
    })
    close()
  }

  return (
    <Modal
      {...rest}
      // eslint-disable-next-line
      controls={({ close }) => (
        <div css={styles.buttonsContainer}>
          <Button
            disabled={loading}
            emphasis={ButtonEmphasis.SECONDARY}
            id="acan-keep"
            onClick={close}
          >
            {t('Home:NO_KEEP_IT')}
          </Button>
          <Button
            id="acan-cancel"
            loading={loading}
            onClick={() => handleCancelAppoitment(close)}
          >
            {t('Home:YES_CANCEL_APPOINTMENT_NOW')}
          </Button>
        </div>
      )}
      title={t('Home:CANCELLING_APPOINTMENT')}
    >
      {t('Home:ARE_YOU_SURE_CANCEL_VISIT', {
        appointment: appointment?.subType?.friendlyName || '',
        date,
      })}
    </Modal>
  )
}

export default CancelAppointmentModal
