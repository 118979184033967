import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Client as TwilioChatClient } from '@twilio/conversations'

import { GET_CHAT_ACCESS_TOKEN } from '../../../api/queries/chat'
import { TOKEN_ABOUT_TO_EXPIRE, TOKEN_EXPIRED } from '../../../constants/twilio'
import ChatClientContext from './ChatClientContext'

export interface ChatClientProviderProps {
  children: React.ReactNode
}

const ChatClientProvider = ({ children }: ChatClientProviderProps) => {
  const apolloClient = useApolloClient()
  const { isAuthenticated } = useAuth0()

  const [client, setClient] = useState<TwilioChatClient | undefined>()

  const createOrUpdateToken = async (
    twilioToken: string,
    forceNewInstance: boolean,
  ) => {
    try {
      if (twilioToken) {
        if (client && !forceNewInstance) {
          await client.updateToken(twilioToken)
        } else {
          const newClient = new TwilioChatClient(twilioToken)
          setClient(newClient)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while create or update token', error)
    }
  }

  const createClient = async (forceNewInstance = false) => {
    try {
      const { data: { twilioToken } = {} } = await apolloClient.query({
        query: GET_CHAT_ACCESS_TOKEN,
        fetchPolicy: 'no-cache',
      })

      createOrUpdateToken(twilioToken, forceNewInstance)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while get twilio token', error)
    }
  }

  const handleTokenAboutToExpire = () => {
    createClient()
  }

  const handleTokenExpired = () => {
    createClient(true)
  }

  const cleanup = () => {
    if (client) {
      client.off(TOKEN_ABOUT_TO_EXPIRE, handleTokenAboutToExpire)
      client.off(TOKEN_EXPIRED, handleTokenExpired)
      client.shutdown()
      setClient(undefined)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      createClient()
    } else {
      cleanup()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (client) {
      client.on(TOKEN_ABOUT_TO_EXPIRE, handleTokenAboutToExpire)
      client.on(TOKEN_EXPIRED, handleTokenExpired)
    }
  }, [client])

  return (
    <ChatClientContext.Provider value={client}>
      {children}
    </ChatClientContext.Provider>
  )
}

export default ChatClientProvider
