/** @jsxImportSource @emotion/react */
import { Route, Routes } from 'react-router-dom'
import { css } from '@emotion/react'

import { AUTH_ROUTES } from '../../constants/routes'
import Toolbar from '../common/toolbar/Toolbar'

const styles = {
  container: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  }),
}

const AuthPage = () => (
  <div css={styles.container}>
    <Toolbar isAuthenticated={false} />

    <Routes>
      {Object.values(AUTH_ROUTES).map(route =>
        Array.isArray(route.path) ? (
          route.path.map(pathItem => (
            <Route
              element={<route.Component />}
              key={pathItem}
              path={pathItem}
            />
          ))
        ) : (
          <Route
            element={<route.Component />}
            key={route.path}
            path={route.path}
          />
        ),
      )}
    </Routes>
  </div>
)

export default AuthPage
