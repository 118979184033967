export const DateFormat = {
  FULL_DATE: 'MM/DD/YYYY',
  FULL_DATE_YEAR_SHORT: 'MM/DD/YY',
  FULL_DATE_TIME: 'MM/DD/YYYY hh:mma',
  DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME_SHORT: 'dddd MMM D [at] h:mma',
  DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME: 'dddd MMMM D [at] h:mma',
  DAY_OF_WEEK_MONTH_DAY_SHORT_COMMA_YEAR_AT_FULL_TIME:
    'dddd MMMM D, YYYY [at] h:mma',
  DAY_OF_WEEK_SHORT_COMMA_MONTH_DAY_COMMA_YEAR_SHORT_AT_FULL_TIME:
    'ddd, MMM DD, YY [at] h:mma',
  DAY_OF_WEEK_SHORT_AT_FULL_TIME_MERIDIAN_UPPER: 'ddd [at] h:mmA',
  DAY_OF_WEEK_SHORT_COMMA_MONTH_DAY_COMMA_FULL_TIME_MERIDIAN_UPPER:
    'ddd, MMM DD, h:mmA',
  DAY_OF_WEEK_SHORT_COMMA_MONTH_DAY_COMMA_FULL_TIME_MERIDIAN_UPPER_COMMA_YEAR:
    'ddd, MMM DD, h:mmA, YYYY',
  DAY_OF_WEEK_COMMA_FULL_TIME_MERIDIAN_UPPER: 'dddd, h:mmA',
  DAY_OF_WEEK: 'dddd',
  MONTH_DAY_SHORT: 'MMM D',
  MONTH_DAY_COMMA_YEAR: 'MMM DD, YYYY',
  MONTH_DAY_SHORT_YEAR: 'MMM D YYYY',
  MONTH_DAY_AT_FULL_TIME_MERIDIAN_UPPER: 'MMM DD [at] h:mmA',
  MONTH_DAY_AT_FULL_TIME_MERIDIAN_UPPER_COMMA_YEAR: 'MMM DD [at] h:mmA, YYYY',
  FULL_MONTH_SHORT_DAY_COMMA_YEAR: 'MMMM D, YYYY',
  FULL_TIME_SHORT: 'h:mm',
  FULL_TIME_SHORT_MERIDIAN: 'h:mma',
  FULL_TIME_MERIDIAN_UPPER: 'h:mmA',
  DATE_WITHOUT_YEAR_FULL_TIME: 'MM/DD h:mma',
  // reserved special formats
  BACKEND_FULL_DATE: 'YYYY-MM-DD',
  GOOGLE_CALENDAR_DATE: 'YYYYMMDDTHHmmss',
}
