/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  TimelineItemType,
  TranslatedTimelineType,
} from '../../../constants/timeline'
import { TimelineItem, TimelinePrescription } from '../../../types/dto/Patient'
import useResize from '../../../utils/useResize'
import TimelineAppointmentDetailsView from '../../common/patient/timeline/TimelineAppointmentDetailsView'
import TimelineDiagnosisDetailsView from '../../common/patient/timeline/TimelineDiagnosisDetailsView'
import TimelinePrescriptionDetailsView from '../../common/patient/timeline/TimelinePrescriptionDetailsView'
import TimelineVaccinationDetailsView from '../../common/patient/timeline/TimelineVaccinationDetailsView'

type TimelineDetailsLocationState = {
  patientId: string
  timelineItem: TimelineItem
}

const TimelineDetailsPage = () => {
  const navigate = useNavigate()

  const { isMobile } = useResize()

  const location = useLocation()
  const state = location.state as TimelineDetailsLocationState
  const timelineItem = state?.timelineItem

  const timelineType =
    TranslatedTimelineType[timelineItem?.type?.name || '']?.()

  useEffect(() => {
    if (isMobile === false) {
      navigate(-1)
    }
  }, [isMobile])

  const handleRequestRefill = (prescription: TimelinePrescription) => {
    navigate('/chat', {
      state: {
        newMessage: true,
        prescriptionTitle: prescription?.title,
        patientId: state.patientId,
      },
    })
  }

  return (
    <>
      {timelineItem && timelineType === TimelineItemType.APPOINTMENT && (
        <TimelineAppointmentDetailsView appointment={timelineItem} />
      )}
      {timelineItem && timelineType === TimelineItemType.PRESCRIPTION && (
        <TimelinePrescriptionDetailsView
          prescription={timelineItem}
          onRequestRefill={handleRequestRefill}
        />
      )}
      {timelineItem && timelineType === TimelineItemType.VACCINATION && (
        <TimelineVaccinationDetailsView vaccination={timelineItem} />
      )}
      {timelineItem && timelineType === TimelineItemType.DIAGNOSIS && (
        <TimelineDiagnosisDetailsView diagnosis={timelineItem} />
      )}
    </>
  )
}

export default TimelineDetailsPage
