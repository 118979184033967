/** @jsxImportSource @emotion/react */
import {
  KibButtonNew,
  KibButtonNewEmphasisValue,
  KibButtonNewIconPositionValue,
  KibButtonNewProps,
  KibButtonNewSizeValue,
  KibButtonNewThemeValue,
} from '@chewy/kib-controls-react'
import { KibSpinner } from '@chewy/kib-interstitials-react'

export const ButtonTheme = {
  ACTION: 'action',
  UTILITY: 'utility',
  DESTRUCTIVE: 'destructive',
  TRANSACTIONAL: 'transactional',
} as const

export const ButtonEmphasis = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
} as const

export const ButtonSize = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
} as const

export const ButtonIconPosition = {
  START: 'start',
  END: 'end',
} as const

export type ButtonProps = Omit<
KibButtonNewProps,
'emphasis' | 'theme' | 'size' | 'iconPosition'
> & {
  children?: React.ReactNode
  emphasis?: KibButtonNewEmphasisValue
  iconPosition?: KibButtonNewIconPositionValue
  size?: KibButtonNewSizeValue
  theme?: KibButtonNewThemeValue
}

const Button = ({
  theme,
  emphasis,
  size,
  iconPosition,
  children,
  ...rest
}: ButtonProps) => (
  <KibButtonNew
    emphasis={emphasis}
    icon={rest.loading && <KibSpinner />}
    iconPosition={iconPosition}
    size={size}
    theme={theme}
    {...rest}
  >
    {children}
  </KibButtonNew>
)

export default Button
