/* eslint-disable */
import Modal, { ModalProps } from './Modal'

const TermsAndConditionsModal = ({ ...rest }: ModalProps) => (
  <Modal {...rest} title="TERMS OF USE AGREEMENT" size="large">
    <div
      dangerouslySetInnerHTML={{
        __html: `<a href="#introduction">
        <h5>1. INTRODUCTION</h5>
      </a><a href="#license">
        <h5>2. LICENSE; ACCOUNT</h5>
      </a><a href="#services">
        <h5>3. SERVICES</h5>
      </a><a href="#eligibility">
        <h5>4. ELIGIBILITY</h5>
      </a><a href="#modifications">
        <h5>5. MODIFICATIONS</h5>
      </a><a href="#term">
        <h5>6. TERM</h5>
      </a><a href="#conduct">
        <h5>7. USER CONDUCT</h5>
      </a><a href="#privacy">
        <h5>8. DATA USE, PRIVACY AND PROTECTION</h5>
      </a><a href="#intellectual">
        <h5>9. INTELLECTUAL PROPERTY</h5>
      </a><a href="#fees">
        <h5>10. FEES</h5>
      </a><a href="#wireless">
        <h5>11. DATA AND WIRELESS ACCESS CHARGES</h5>
      </a><a href="#assignment">
        <h5>12. ASSIGNMENT</h5>
      </a><a href="#indemnity">
        <h5>13. INDEMNITY</h5>
      </a><a href="#disclaimer">
        <h5>14. DISCLAIMER AND LIMITATIONS OF LIABILITY</h5>
      </a><a href="#support">
        <h5>15. CUSTOMER SUPPORT</h5>
      </a><a href="#law">
        <h5>16. GOVERNING LAW; MISCELLANEOUS</h5>
      </a><br><br>
      <section id="introduction">
        <p role="heading"><b>1. INTRODUCTION.</b></p>
        <p>
          Boop is operated by Petabyte Technology, Inc., which has offices at 11235
          SE 6th Street, Bellevue, Washington 98004 (“<u>Company</u>”, “<u>we</u>”, “<u>our</u>” or “<u>us</u>”).
          Please read this Terms of Use Agreement (“<u>Terms of Use</u>”) carefully, as it constitutes legally binding
          terms and conditions and applies to your use of (a) Company’s mobile, web, console, desktop and other
          applications (e.g., Apple iOS Apps, etc.) (collectively and individually, “<u>Company App</u>”), and (b) any
          other content, applications, features, functionality, information and services offered by us through the
          Company App, including, without limitation, viral, embeddable or application/device-based features and
          related technology (e.g., mobile, web, console, desktop and other APIs, widgets, plugins, applications,
          etc.) ((a) and (b) shall be referred to herein as the "Services"). These Terms of Use apply whether you are
          accessing the Services via a wireless or mobile device, a personal computer or any other technology or
          device (each, a “<u>Device</u>”). These Terms of Use do not cover other services, websites or any
          corresponding content, features, and activities made available by any other company or third party, unless
          specifically stated. These Terms of Use apply to all users of the Services (“<u>User</u>”, “<u>you</u>” or
          “<u>your</u>”), and by using the Services you expressly understand, acknowledge and agree to comply with
          these Terms of Use and any additional terms and conditions that we may provide to you in connection with
          your use of or access to same, including, without limitation, in connection with related technology and
          other products and services we may offer or make available to you (“<u>Additional Terms</u>”), are hereby
          incorporated into these Terms of Use by reference as though fully set forth herein. To the extent that there
          is a conflict between these Terms of Use and the Additional Terms, the Additional Terms shall govern.
        </p>
      </section>
      <section id="license">
        <p role="heading"><b>2. LICENSE; ACCOUNT.</b></p>
        <p>
          The Company hereby grants you a nonexclusive license to reproduce and use one copy of the Company App on
          your mobile device, solely as a component of the Services, provided you comply with the restrictions set
          forth herein. The license in the preceding sentence does not include use by any third party, and you shall
          not permit any such use. The Company shall create an account for the User (“Account” and shall provide to
          the User personal login details and credentials for that Account (“<b>Login Credentials</b>”) solely for use
          by User. User may only access and use the Services through the Login Credentials issued to him or her by the
          Company. User is solely responsible for maintaining the confidentiality of the Login Credentials. Any
          instruction, action or activity occurring through any such Login Credentials shall be deemed to be provided
          and/or taken by User, and User shall be solely responsible for all activities that occur under such Login
          Credentials, including for any unauthorized use of such Login Credentials or any other breach of security,
          or any related damage or loss.
        </p>
      </section>
      <section id="services">
        <p role="heading"><b>3. SERVICES.</b></p>
        <p>
          <b>A. General.</b> Subject to the terms and conditions herein, the Services shall enable Users to access
          certain features, functionality, information and services provided by us, generally providing Users with the
          ability to, among other things: a) obtain telehealth consultation; b) interact with a veterinary practice
          (including but not limited to scheduling and billing); c) access electronic medical records; d) purchase
          goods and services offered by the Company or third parties; e) access additional pet and pet care related
          information; and f) participate in social sharing.
        </p>
        <p>
          <b>B. The Company’s Rhapsody Platform.</b> Some of the Services are dependent on and/or interoperate with
          the Company’s proprietary software as a service platform known as “<u>Rhapsody</u>”, which among other
          things, assists veterinary practices and/or licensed veterinary professionals who are enrolled in Rhapsody
          (“<u>Providers</u>”) with the management and operation of their veterinary practices, including but not
          limited to scheduling and billing, and storage of electronic medical records. Providers use interface in
          Rhapsody to communicate with Boop’s Users to provide various Services, including telehealth consultation,
          general pet telehealth information, sharing of medical records, establishing reminders, communication about
          health and health plans, and offering various additional services.
        </p>
        <p>
          <b>C. Telehealth Services by Providers.</b> The Company permits Providers to offer telehealth services
          through the Company App to their customers who are Boop Users, which services may include all veterinary
          medicine services, advice, instructions, diagnosis, treatment decisions, record-keeping, prescription of
          medicine, and other veterinary health care services (collectively “Provider Telehealth Services”). With
          respect to Provider Telehealth Services, User acknowledges and agrees that Provider Telehealth Services are
          not provided by the Company, and Company is acting strictly as a technology platform and infrastructure for
          connecting pet owners with independent third-party Providers. User acknowledges and agrees that the
          Providers, and not the Company, are solely responsible for and will have complete authority, responsibility,
          supervision, and control over the provision of Provider Telehealth Services, and that all such services will
          be provided and performed exclusively by or under the supervision of the Providers, in their sole discretion
          as they deem appropriate. Petabyte make no representation that all products, services, and/or material
          described on the Company App and Services are appropriate or available for use in locations outside the
          United States. The Company does not interfere with the practice of veterinary medicine by Providers, each of
          whom is responsible for his or her services and compliance with the requirements applicable to his or her
          profession, license, and any and all applicable laws. During User’s telehealth consultation with a Provider,
          details of User’s pet’s health history and health status information may be shared with the Provider and
          discussed with User through the use of interactive video, audio and other telecommunications technology, and
          User’s Provider may ask for User’s assistance to conduct an assessment of User’s pet through these
          technologies. User understands that the Company shall retain and own a copy of any communications, including
          but not limited to User’s pet’s health history, and interactive video and audio, between User and Provider
          facilitated through the Company App for telehealth consultations. As with any veterinary medicine service,
          there are potential risks associated with the use of Provider Telehealth Services, including without
          limitation, the possibility that information transmitted may not be sufficient (e.g., poor resolution of
          images) to allow for appropriate care decision making by the Provider. By accepting these Terms of Use, User
          acknowledges that User understands and agrees that no results can be guaranteed or assured. User’s Provider
          may determine that the Services are not appropriate for some or all of User’s pet’s treatment needs, and
          accordingly may elect not to provide telehealth for User’s pet through the Company App or Services.
        </p>
        <p>
          <b>D. Telehealth Services by Company.</b> The Company offers a service through the Company App whereby
          veterinarians and other veterinary professionals employed or engaged by the Company answer pet health and
          medical questions about User’s pets for general pet information and educational purposes (“Company
          Telehealth Services”). The Company Telehealth Services do not include any clinical service, diagnosis, or
          suggestions of specific treatment, and should not be used for diagnosing or treating a health problem. This
          is so even if the veterinarians and veterinary professionals providing the Company Telehealth Services
          mention professional licensure or other credentials. The information exchanged in Company Telehealth
          Services is not comprehensive, does not include all the potential information regarding the subject matter,
          and is merely intended to serve as a resource for general and educational purposes. Company Telehealth
          Services does not create any professional relationship between the User and the Company (or any of its
          officers, directors, employees, consultants, independent contractors, affiliates or agents), and does not
          create any veterinarian-client-patient relationship between such parties. User understands that the Company
          is not a medical provider. Any content or information stated by the Company, its representatives, employees,
          contractors or affiliates, is not intended to be, and must not be taken to be, the practice or provision of
          veterinary medicine or veterinary medical care. The information provided by the Company is not a substitute
          for medical diagnosis, advice, treatment, or other professional veterinary healthcare. If your pet has, or
          you suspect your pet may have a medical problem, you should consult a veterinarian at your local veterinary
          clinic. Company advises to always consult your veterinarian before seeking any new treatment, or before you
          alter, suspend, or initial any change in your pet’s medical treatment, medication or herbal supplement,
          routing, procedure or otherwise.
        </p>
        <p>
          <b>E. Third Party Platforms.</b> Some of the Services may be dependent on and/or interoperate with
          third-party owned and/or operated platforms and services (e.g., Apple (iTunes, etc.), Google, Facebook,
          Twitter, etc.) (each, a “<u>Third Party Platform</u>”) and may require that you be a registered member of
          such Third Party Platforms and provide certain account credentials and other information in order to access
          such Services. Such Third Party Platforms, in addition to providing application hosting, content
          distribution, support and other related services, may provide us with certain additional information about
          you, which may include, without limitation, your email address, legal name, country of residence, location,
          date of birth, music/artist/band and other preferences and usage data, all as more fully described herein.
          Our access to, use of, and disclosure of such information shall be subject to the terms and conditions
          hereof.
        </p>
      </section>
      <section id="eligibility">
        <p role="heading"><b>4. ELIGIBILITY.</b></p>
        <p>
          The Services are not intended for or directed at children under 13 years of age (or 16 years of age or older
          if User resides in the European Economic Area). By using the Services, User represents, warrants and
          covenants that User is 13 years of age or older (or 16 years of age or older if User resides in the European
          Economic Area).
        </p>
      </section>
      <section id="modifications">
        <p role="heading"><b>5. MODIFICATIONS.</b></p>
        <p>
          We may modify these Terms of Use from time to time, and at any time, without notice to you, for any reason,
          in our sole discretion. If we make material changes to these Terms of Use, we will notify you by posting or
          displaying a notice on the Services. The form of such notice is at our discretion. Once we post or make them
          available on the Services, these changes become effective immediately and if you use the Services after they
          become effective, it will signify your agreement to be bound by the changes. We recommend that you check
          back frequently and review these Terms of Use regularly so you are aware of the most current rights and
          obligations that apply to you.
        </p>
      </section>
      <section id="term">
        <p role="heading"><b>6. TERM.</b></p>
        <p>
          These Terms of Use, and any posted revision or modification thereto, shall remain in full force and effect
          while you use the Services. You may terminate your use of the Services at any time, for any reason, and
          Company may terminate your use of the Services at any time, for any or no reason, with or without prior
          notice or explanation, and without liability or obligation to you or any third party. Even after your use is
          terminated, your obligations under these Terms of Use, and the Additional Terms, including, without
          limitation, any indemnifications, warranties and limitations of liability contained therein, shall remain in
          effect.
        </p>
      </section>
      <section id="conduct">
        <p role="heading"><b>7. USER CONDUCT.</b></p>
        <p>
          You are solely responsible for your conduct on and in connection with the Services. You represent, warrant
          and agree that, while using the Services, you shall not:
        </p>
        <p>
          • intentionally or unintentionally engage in or encourage conduct that would violate any applicable local,
          state, national or international law, rule, regulation, judicial or government order or treaty or give rise
          to civil liability or violate or infringe upon any intellectual property, proprietary, privacy, moral,
          publicity or other rights of ours or of any other person or entity;
        </p>
        <p>
          • intentionally or unintentionally engage in or encourage conduct that adversely affects, or reflects
          negatively on, Company, the Services, our goodwill, name or reputation or causes duress, distress or
          discomfort to us or anyone else, or discourages any person or entity from using all or any portion, features
          or functions of the Services, or from advertising, linking or becoming a supplier to us in connection with
          the Services;
        </p>
        <p>
          • use the Services for commercial or business purposes, including, without limitation, engaging in barter
          arrangements, pyramid schemes, advertising, marketing or offering goods or services or exploiting
          information or material obtained on, through or in connection with the Services, whether or not for
          financial or any other form of compensation or through linking with another website or web page;
        </p>
        <p>
          • modify, disrupt, impair, alter or interfere with the use, features, function, operation or maintenance of
          the Services or the rights or use or enjoyment of the Services by any other User; or
        </p>
        <p>
          • reproduce, modify, create derivative works from, display, perform, publish, distribute, disseminate,
          broadcast or circulate to any third party (including, without limitation, on or via a third party website or
          platform), or otherwise use any of the Company’s Intellectual Property (as defined below) or any content
          contained on the Company App or Services without the express, prior written consent of
        </p>
        <p>
          Company Company may prevent or prohibit a User from accessing the Services if Company determines, in its
          sole discretion, that such User has violated these Terms of Use. Company reserves the right to investigate
          and take appropriate legal action against anyone who, in Company’s sole discretion, violates, or is
          suspected of violating, this section, including, without limitation, reporting you to law enforcement
          authorities. Further, you acknowledge, consent and agree that Company may access, preserve and disclose any
          information if required to do so by law or if based on a good faith belief that such access, preservation or
          disclosure is reasonably necessary to (a) comply with the legal process; (b) enforce these Terms of Use; (c)
          respond to claims that any content or information violates the rights of any third party; (d) respond to
          your requests for customer or technical service; or (e) protect the rights, property or personal safety of
          Company, its Users or any third parties.
        </p>
      </section>
      <section id="privacy">
        <p role="heading"><b>8. DATA USE, PRIVACY AND PROTECTION.</b></p>
        <p>
          User may be required to upload User Data (as defined immediately below) onto the Company App in order for
          the Company to provide the Services contemplated hereunder. “<b><u>User Data</u></b>” shall mean all data, works, materials: (i) directly or indirectly collected by the Company during the
          Term whether or not voluntarily provided by the User, and which may include, without limitation, unique
          device identifiers (UDIs) and Personal Information (as defined below); and (ii) uploaded to, transmitted by,
          or stored on the Company App by the User and derivatives thereof. User shall retain ownership of all User
          Data it provides or makes accessible to the Company in the scope of the Services; provided however that User
          hereby grants the Company an irrevocable, non-exclusive, sub-licensable (solely to the Company’s hosting,
          connectivity, telecommunications, customer relations management, and other third party service providers to
          the extent applicable), worldwide, royalty-free license, for as long as the Company conducts its business,
          to use, copy, reproduce, transfer, store, process, publish, distribute and edit such User Data: (i) solely
          as necessary for the Company to provide the Services and to allow the Company to perform in accordance with
          these Terms; (ii) to compile aggregated statistics, reports and research for internal or marketing use and
          for development and/or evaluation of existing and/or additional or modified services, features and
          functionality with respect to the Company App and the Services; (iii) for the purpose of assisting the
          Company with its performance of services relating to such other platforms, such as Rhapsody; and (iv) to
          disclose any User Data if required by law or valid order of a competent court in any applicable
          jurisdiction, or other governmental authority. “<u>Personal Information</u>” means sensitive and personally
          identifiable information that can be used, with or without other sources, to uniquely identify, contact, or
          locate a User, including name, email address, phone number, physical address, insurance information,
          emergency contact information, and the User’s pet information. You acknowledge and agree that you are solely
          responsible for the accuracy and content of Personal Information. User understands that the Company shall
          retain a copy of any communications between User and Provider facilitated through the Company’s platforms
          (e.g. the Company App and Rhapsody).
        </p>
        <p>
          The Company represents and warrants that it will comply with all applicable laws, rules, regulations,
          directives and guidelines regarding the collection, use and disclosure of User Data which apply to the
          services to be provided hereunder (collectively, the “<b>Rules</b>”). The term “Rules” shall include,
          without limitation, (a) all United States Federal Trade Commission rules and guidelines regarding the
          collection, use and disclosure of data from or about Users and/or specific devices; (b) all United States
          federal and state laws regarding data collection and data privacy; (c) industry best practice guidance
          provided to the Company from time to time; and (d) if applicable, the Rules of any other jurisdiction,
          including European Union Directives 95/46/EC and GDPR, rules, regulations and/or directives promulgated by a
          pertinent data protection authority, and all amendments and updates to them or regulations as replaced or
          superseded from time to time.
        </p>
        <p>
          The Company may use User’s Personal Information to inform User, by email, postal mail, text messages,
          Company App messages, or other forms of communication, of certain products or services available from the
          Company, or about the functionality and administration of the App and Services. The Company may also contact
          User via surveys to conduct research about User’s opinion of the Company App or Services or of potential new
          services that may be offered. The Company may, from time to time, contact User on behalf of external
          business partners about a particular offering that may be of interest to User. In those cases, User’s
          Personal Information will not be transferred to the third party. The Company may share data with trusted
          partners to help perform statistical analysis, send User email or postal mail, provide customer support, or
          arrange for deliveries.
        </p>
        <p>
          The Company has the right to disclose User Data including Personal Information to third parties for the
          purpose of administering and maintaining the Services. These third parties shall be referred to as "<b><u>Third-Party Service Providers</u></b>".
        </p>
        <p>
          These Third-Party Service Providers may temporarily store certain User Data on their servers, but they may
          only use User’s Personal Information to provide the Company or User with a specific service and not for any
          other purpose, and they are required to maintain confidentiality. Other than as set forth herein, the
          Company does not, and will not, share or sell User Data including Personal Information with or to other
          third parties, apart from those acting as the Company’s agents in providing Services to User, and which
          agree to use it only for that purpose and to keep the information secure and confidential.
        </p>
        <p>
          The Parties agree that with respect to all User Data, it shall be the responsibility of the User to devise,
          safeguard, transfer, share and use the User Data in compliance with applicable laws, directives, and
          regulations.
        </p>
      </section>
      <section id="intellectual">
        <p role="heading"><b>9. INTELLECTUAL PROPERTY.</b></p>
        <p>
          For the purpose of these Terms, “<u>Intellectual Property</u>” shall mean proprietary and intellectual
          property rights, including without limitation copyrights (including moral rights, where applicable),
          patents, designs, trademarks, and all applications thereof, whether or not registered or capable of being
          registered, inventions, know-how, trade secrets, confidential or proprietary information, data and
          databases, algorithms, methods, processes, software, materials, drawings, flow charts, layout, design, look
          and feel, techniques, source code and object code, all modifications, update, and enhancements and
          derivative works thereof, all documentation and manuals related thereto and all other aspects of, and all
          rights corresponding to, the foregoing. User understands and acknowledges that, other than the right to use
          the Company App and Services, as provided hereunder, these Terms do not convey any right, title or interest
          in and to the Company App and Services. Any updates, upgrades, improvements, modifications, adaptations,
          derivatives or enhancements made to the Company App and Services, any of the Company’s Intellectual
          Property, or to any part thereof, or other work which includes, contains, substitutes, emulates, exposes the
          functionalities, or incorporates the Company App and Services, or any part thereof, made by the User, the
          Company or jointly by the User and the Company, shall be solely owned by the Company.
        </p>
      </section>
      <section id="fees">
        <p role="heading"><b>10. FEES.</b></p>
        <p>
          In consideration for the grant of the License and the provision of the Services, the User shall pay all fees
          or charges in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is
          due and payable. In some cases, the Company may receive a percentage of a fee charged by a Provider to User.
          By providing the Company with a credit card number or bank ACH transfer information, User agrees that the
          Company is authorized to immediately charge User for all fees and charges due and payable to the Company or
          a Provider hereunder and that no additional notice or consent is required.
        </p>
        <p>
          The Company offers certain fee-based products and services available to Users. For example, User may be able
          to purchase Wellness Plans, pet food, supplies, or Company Telehealth Services through the Company App.
          These products and services may be offered directly by the Company, or by a Provider through the Company’s
          platforms. Additional Terms, which shall be disclosed, may apply to your use of, access to and purchase of
          such fee-based products or services and such additional terms are incorporated herein by reference. User may
          only purchase such fee-based products and/or services if, and User hereby represents, warrants and agrees
          that User (a) is 18 years of age or older and (b) shall pay in full the fees (including, without limitation,
          all applicable taxes) for any purchases User, or anyone using User’s Account, make via credit, debit or
          charge card or other payment means acceptable to the Company concurrent with your online order. If payment
          is not received by the Company from User’s credit, debit or charge card issuer or its agents or other
          payment service provider, User agrees to promptly pay all amounts due upon demand by the Company. The
          Company may revise any or all of the fees at any time for any or no reason. Further, the Company does not
          guarantee that product descriptions or other content and products will be available, accurate, complete,
          reliable, current or error-free. Descriptions and images of, and references to, products or services do not
          imply the Company’s endorsement of such products or services. Moreover, the Company and its ThirdParty
          Service Providers reserve the right, with or without prior notice, for any or no reason, to change product
          descriptions, images, and references; to limit the available quantity of any product; to honor, or impose
          conditions on the honoring of, any coupon, coupon code, promotional code or other similar promotions; to bar
          any user from conducting any or all transaction(s); and/or to refuse to provide any User with any product.
        </p>
        <p>
          Late payments shall accrue interest at a rate of one and one-half percent (1.5%) per month, or the highest
          rate allowed by applicable law, whichever is lower. All fees are stated and payable in US Dollars, and are
          exclusive of any taxes, customs or charges including VAT. User shall pay (or reimburse the Company as the
          case may be) for all taxes related to or arising out of these Terms, including any sales, use, excise, gross
          receipts, property, privilege, value-added, or other federal, state or local taxes or tariffs (including any
          interest or penalties thereto) now in force or enacted in the future, except for those taxes collected from
          the Company and based on the Company’s income. User may not withhold or set off any fees due to the Company
          hereunder. The Company may suspend the provision of the Services or any part thereof, if any fees due to be
          paid by the User to the Company under these Terms are overdue.
        </p>
      </section>
      <section id="wireless">
        <p role="heading"><b>11. DATA AND WIRELESS ACCESS CHARGES.</b></p>
        <p>
          The Company App and other Services may require data access, and the provider of data access (e.g., network
          operator, wireless carrier, etc.) for your Device may charge you data access fees in connection with your
          use of the Company App and other Services. Under no circumstances will Company be responsible for any such
          data access fees and charges in connection with your use of the Company App or other Services, including
          wireless internet, email, text messaging or other charges or fees incurred by you (or any person that has
          access to your Device, telephone number, email address, or other similar information). Further, the use or
          availability of the Company App and other Services may be prohibited or restricted by your wireless carrier
          and/or data access provider, and the Company App and other Services may not work with all wireless carriers,
          networks, platforms, services or Devices.
        </p>
      </section>
      <section id="assignment">
        <p role="heading"><b>12. ASSIGNMENT.</b></p>
        <p>
          These Terms of Use, and any rights, licenses and privileges granted herein, may not be transferred or
          assigned by you, but may be assigned or transferred by Company without restriction and without notice to
          you.
        </p>
      </section>
      <section id="indemnity">
        <p role="heading"><b>13. INDEMNITY.</b></p>
        <p>
          You agree to defend, indemnify and hold the Company Parties (as defined below) harmless from any and all
          claims, liabilities, damages, losses, costs and expenses (including reasonable attorneys’ fees), arising in
          any way out of or in connection with (a) your use of the Services, or (b) your breach or violation these
          Terms of Use. Company reserves the right, at its own expense, to assume the exclusive defense and control of
          any matter otherwise subject to indemnification by you and all negotiation for its settlement or compromise
          (as applicable), and in each such case, you agree to fully cooperate with us upon our request.
        </p>
      </section>
      <section id="disclaimer">
        <p role="heading"><b>14. DISCLAIMER AND LIMITATIONS OF LIABILITY.</b></p>
        <p>
          THESE SERVICES, AND ALL COMPANY CONTENT, PRODUCTS, SERVICES MADE AVAILABLE ON, THROUGH OR IN CONNECTION
          THEREWITH, ARE MADE AVAILABLE ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY REPRESENTATION OR WARRANTY
          OF ANY KIND, EXPRESS OR IMPLIED, OR ANY GUARANTY OR ASSURANCE THE SERVICES WILL BE AVAILABLE FOR USE, OR
          THAT ALL PRODUCTS, FEATURES, FUNCTIONS, SERVICES OR OPERATIONS WILL BE AVAILABLE OR PERFORM AS DESCRIBED.
          WITHOUT LIMITING THE FOREGOING, WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY MALICIOUS CODE, DELAYS,
          INACCURACIES, ERRORS, OR OMISSIONS ARISING OUT OF YOUR USE OF THE SERVICES.
        </p>
        <p>
          YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT YOU ARE ASSUMING THE ENTIRE RISK AS TO THE QUALITY, ACCURACY,
          PERFORMANCE, TIMELINESS, ADEQUACY, COMPLETENESS, CORRECTNESS, AUTHENTICITY, SECURITY AND VALIDITY OF ANY AND
          ALL FEATURES AND FUNCTIONS OF THE SERVICES, INCLUDING, WITHOUT LIMITATION, CONTENT ASSOCIATED WITH YOUR USE
          OF THE SERVICES. YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, COMPANY, ITS
          PARENT COMPANIES, SUCCESSORS AND ASSIGNS, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES,
          LICENSORS, OPERATIONAL SERVICE PROVIDERS, AND SUPPLIERS (COLLECTIVELY, THE “<u>COMPANY PARTIES</u>”) SHALL
          NOT BE LIABLE FOR ANY LOSS OR DAMAGE, OF ANY KIND, DIRECT OR INDIRECT, IN CONNECTION WITH OR ARISING FROM
          USE OF THE SERVICES OR FROM THESE TERMS OF USE, INCLUDING, WITHOUT LIMITATION, COMPENSATORY, CONSEQUENTIAL,
          INCIDENTAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES.
        </p>
        <p>
          YOU FURTHER UNDERSTAND AND ACKNOWLEDGE THAT THE CAPACITY OF THE SERVICES, IN THE AGGREGATE AND FOR EACH
          USER, IS LIMITED. CONSEQUENTLY, SOME CONTENT AND MAY NOT BE PROCESSED IN A TIMELY FASHION OR AT ALL, AND
          SOME FEATURES OR FUNCTIONS MAY BE RESTRICTED OR DELAYED OR BECOME COMPLETELY INOPERABLE. AS A RESULT, YOU
          ACKNOWLEDGE AND AGREE THAT COMPANY ASSUMES NO LIABILITY, RESPONSIBILITY OR OBLIGATION TO TRANSMIT, PROCESS,
          STORE, RECEIVE OR DELIVER TRANSACTIONS AND YOU ARE HEREBY EXPRESSLY ADVISED NOT TO RELY UPON THE TIMELINESS
          OR PERFORMANCE OF THE SERVICES FOR ANY TRANSACTIONS. SOME JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OF
          CERTAIN WARRANTIES OR CERTAIN LIMITATIONS ON DAMAGES AND REMEDIES, ACCORDINGLY SOME OF THE EXCLUSIONS AND
          LIMITATIONS DESCRIBED IN THESE TERMS OF USE MAY NOT APPLY TO YOU. IF, IN A RELEVANT JURISDICTION, THE
          LIMITATIONS AND EXCLUSIONS ON LIABILITY CONTAINED HEREIN ARE NOT PERMITTED, THEN THE COMPANY PARTIES’
          LIABILITY SHALL BE LIMITED AND EXCLUDED TO THE FULLEST EXTENT PERMITTED BY LAW.
        </p>
      </section>
      <section id="support">
        <p role="heading"><b>15. CUSTOMER SUPPORT.</b></p>
        <p>
          For assistance with technical issues or customer support inquiries, please send an email to
          support@boop.pet.
        </p>
      </section>
      <section id="law">
        <p role="heading"><b>16. GOVERNING LAW; MISCELLANEOUS.</b></p>
        <p>
          A. These Terms of Use, together with any Additional Terms, and any other regulations, procedures and
          policies which we refer to and which are hereby incorporated by reference, contain the entire understanding
          and agreement between you and Company concerning the Services and your use thereof, and supersede any and
          all prior or inconsistent understandings relating to the Services and your use thereof. These Terms of Use
          cannot be changed or terminated orally. If any provision of these Terms of Use is held to be illegal,
          invalid or unenforceable, this shall not affect any other provisions and these Terms of Use shall be deemed
          amended to the extent necessary to make it legal, valid and enforceable. Any provision which must survive in
          order to allow us to enforce its meaning shall survive the termination of these Terms of Use; however, no
          action arising out of these Terms of Use or your use of the Services, regardless of form or the basis of the
          claim, may be brought by you more than one year after the cause of action has arisen (or if multiple causes,
          from the date the first such cause arose). Any failure by us to exercise or enforce any right or provision
          of these Terms of Use will not operate as a waiver of such right or provision.
        </p>
        <p>
          B. These Terms of Use and your use of the Services is governed by, construed and enforced in accordance with
          the internal substantive laws of the State of New York (notwithstanding the state’s conflict of laws
          provisions) applicable to contracts made, executed and wholly performed in New York, and, for the purposes
          of any and all legal or equitable actions, you specifically agree and submit to the exclusive jurisdiction
          and venue of the State and Federal Courts situated in the State and County of New York and agree you shall
          not object to such jurisdiction or venue on the grounds of lack of personal jurisdiction, forum non
          conveniens or otherwise. IN ANY ACTION OR PROCEEDING COMMENCED TO ENFORCE ANY RIGHT OR OBLIGATION OF THE
          PARTIES UNDER THIS AGREEMENT, YOUR USE OF THE SERVICES OR WITH RESPECT TO THE SUBJECT MATTER HEREOF, YOU
          HEREBY WAIVE ANY RIGHT YOU MAY NOW HAVE OR HEREAFTER POSSESS TO A TRIAL BY JURY.
        </p>
        <p>
          C. You and Company agree that the resolution of any disputes, claims or actions arising hereunder,
          including, without limitation, in connection with any use of the Services, shall be conducted in each of
          your and Company’s individual capacities only and not as a class action or other representative action, and
          you and Company expressly waive any right to file a class action or seek relief on a class basis. These
          Terms of Use were last modified on the date indicated above and are effective immediately.
        </p>
        <p>Copyright © 2019 Petabyte Technology, Inc.</p>
      </section>`,
      }}
    />
  </Modal>
)

export default TermsAndConditionsModal
