import { useAuth0 } from '@auth0/auth0-react'

import { FileTemplate } from '../types/entities'

export type UploadedImageType = {
  thumbnailUrl: string
  url: string
}

const getUrlParams = (paramsObj = {}) =>
  Object.entries(paramsObj)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

const upload = (token: string, file: FileTemplate, params: any) => {
  const formData = new FormData()
  if (file.raw) {
    formData.append('file', file.raw, 'file')
  }

  const url = '/apigw-boop/asset/avatar'

  return fetch(`${url}?${getUrlParams(params)}`, {
    method: 'PUT',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const useAvatarUpload = () => {
  const { getAccessTokenSilently } = useAuth0()

  const uploadImage = (file: FileTemplate, params: any): Promise<UploadedImageType> =>
    new Promise<any>((resolve, reject) => {
      getAccessTokenSilently()
        .then(token => upload(token, file, params))
        .then(response =>
          Promise.all([response.status === 200, response.json()]),
        )
        .then(([ok, data]) => (ok ? resolve(data) : reject(data)))
        .catch(reject)
    })

  return {
    uploadImage,
  }
}

export default useAvatarUpload
