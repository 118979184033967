/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import { CREATE_TASK } from '../../../api/mutations/tasks'
import { GET_NEW_TASK_CLIENT_INFO } from '../../../api/queries/client'
import { GET_TASK_TYPES_CONSTANTS } from '../../../api/queries/constants'
import { GET_UPCOMING_TASKS } from '../../../api/queries/tasks'
import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { TaskRepeatPeriod, TaskTimeUnits } from '../../../constants/task'
import i18n from '../../../locales/i18n'
import { Patient } from '../../../types/dto/Patient'
import { Task } from '../../../types/dto/Task'
import { ClientResponse, ConstantsResponse } from '../../../types/response'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import { BATCH_SIZE } from '../../tasks/UpcomingTasksList'
import Checkbox from '../choice/Checkbox'
import InputDatepicker from '../input/InputDatepicker'
import InputText from '../input/InputText'
import InputTextArea from '../input/InputTextArea'
import SelectInput from '../input/SelectInput'
import SelectPatientHorizontalList from '../patient/SelectPatientHorizontalList'
import Text, { TextVariant } from '../typography/Text/Text'

type TaskRepeatPeriodType = {
  id: string
  name: string
  recurrencePeriod?: {
    amount?: number
    unit?: string
  }
}

type CreateTaskFormProps = {
  onDataChanged?: (canAddTask: boolean) => void
  onLoading?: (loading: boolean) => void
  onTaskCreated: (task: Task) => void
}

export type CreateTaskFormHandle = {
  add: () => void
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }),
  input: css({
    marginTop: spacing(Spacing.S2),
  }),
  spinner: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    alignSelf: 'center',
  }),
  patientsContainer: css({
    display: 'flex',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    columnGap: spacing(Spacing.S3),
    rowGap: spacing(Spacing.S3),
    marginTop: spacing(Spacing.S3),
  }),
  singlePatientsContainer: css({
    justifyContent: 'center',
  }),
  patientContainer: css({
    display: 'flex',
    width: 75,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': css({
      cursor: 'pointer',
    }),
  }),
  singlePatientContainer: css({
    '&:hover': css({
      cursor: 'default',
    }),
  }),
  patientContainerUnselected: css({
    opacity: 0.5,
  }),
  patientLabel: css({
    width: 75,
    marginTop: spacing(Spacing.S1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  }),
  patientLabelSelected: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    fontWeight: 'bold',
  }),
  firstInput: css({
    marginTop: spacing(Spacing.S6),
  }),
  customContainer: css({
    marginTop: spacing(Spacing.S2),
  }),
  customPeriodContainer: css({
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacing(Spacing.S6),
  }),
  datesContainer: css({
    display: 'flex',
    justifyContent: 'center',
    columnGap: spacing(Spacing.S6),
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }),
  }),
  dateLabel: css({
    marginTop: spacing(Spacing.S2),
  }),
}

// Rx Request/Vet Diet Request (https://petabyte.atlassian.net/browse/BOOP-1670)
const IgnoredTaskTypes = ['2PEwQLVb', 'qR1YDlVw']

const TaskRepeatPeriods = [
  {
    id: TaskRepeatPeriod.NEVER,
    name: i18n.t('Tasks:NEVER'),
  },
  {
    id: TaskRepeatPeriod.EVERY_DAY,
    name: i18n.t('Tasks:EVERY_DAY'),
    recurrencePeriod: {
      amount: 1,
      unit: TaskTimeUnits.DAY.unit,
    },
  },
  {
    id: TaskRepeatPeriod.EVERY_WEEK,
    name: i18n.t('Tasks:EVERY_WEEK'),
    recurrencePeriod: {
      amount: 1,
      unit: TaskTimeUnits.WEEK.unit,
    },
  },
  {
    id: TaskRepeatPeriod.EVERY_OTHER_WEEK,
    name: i18n.t('Tasks:EVERY_OTHER_WEEK'),
    recurrencePeriod: {
      amount: 2,
      unit: TaskTimeUnits.WEEK.unit,
    },
  },
  {
    id: TaskRepeatPeriod.EVERY_MONTH,
    name: i18n.t('Tasks:EVERY_MONTH'),
    recurrencePeriod: {
      amount: 1,
      unit: TaskTimeUnits.MONTH.unit,
    },
  },
  {
    id: TaskRepeatPeriod.EVERY_YEAR,
    name: i18n.t('Tasks:EVERY_YEAR'),
    recurrencePeriod: {
      amount: 1,
      unit: TaskTimeUnits.YEAR.unit,
    },
  },
  {
    id: TaskRepeatPeriod.CUSTOM,
    name: i18n.t('Tasks:CUSTOM'),
    recurrencePeriod: {
      amount: 1,
      unit: TaskTimeUnits.DAY.unit,
    },
  },
] as TaskRepeatPeriodType[]

const CreateTaskForm = forwardRef<CreateTaskFormHandle, CreateTaskFormProps>(
  function CreateTaskForm(
    { onDataChanged, onTaskCreated, onLoading, ...rest },
    ref,
  ) {
    const { t } = useTranslation(['Home', 'Tasks'])

    const [selectedBusinessId, setSelectedBusinessId] = useState<
    string | undefined
    >()
    const [selectedPatient, setSelectedPatient] = useState<
    Patient | undefined
    >()
    const [name, setName] = useState<string | undefined>()
    const [typeId, setTypeId] = useState<string | undefined>()
    const [repeatPeriod, setRepeatPeriod] = useState<
    TaskRepeatPeriodType | undefined
    >()
    const [dueDate, setDueDate] = useState<string | Date | undefined | null>(moment().toDate())
    const [recurrenceEndDate, setRecurrenceEndDate] = useState<
    string | Date | undefined | null
    >()
    const [instructions, setInstructions] = useState<string | undefined>()
    const [share, setShare] = useState(true)

    const { data: { me } = {}, loading: clientLoading } =
      useQuery<ClientResponse>(GET_NEW_TASK_CLIENT_INFO)
    const { data: { constants: { taskTypes = [] } = {} } = {} } =
      useQuery<ConstantsResponse>(GET_TASK_TYPES_CONSTANTS)

    const [createTask, { loading: createTaskLoading }] = useMutation(
      CREATE_TASK,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_UPCOMING_TASKS,
            variables: {
              offset: 0,
              limit: BATCH_SIZE,
            },
          },
        ],
        onCompleted: ({ createTask: task }) => {
          onTaskCreated(task)
        },
      },
    )

    const hasSinglePatient = (me?.patients?.length || 0) === 1
    const hasMultipleBusinesses = (me?.businesses?.length || 0) > 1
    const isCustomRepeatPeriod = repeatPeriod?.id === TaskRepeatPeriod.CUSTOM
    const isRecurrence =
      repeatPeriod && repeatPeriod?.id !== TaskRepeatPeriod.NEVER

    const filteredTaskTypesOptions =
      R.filter(
        it => !R.includes(it.id, IgnoredTaskTypes),
        taskTypes || [],
      )?.map(it => ({ value: it.id, label: it.name })) || []
    const repeatPeriodOptions = TaskRepeatPeriods.map(it => ({
      value: it.id,
      label: it.name,
    }))
    const customRepeatOptions = Object.values(TaskTimeUnits).map(item => ({
      value: item.unit,
      label: item.name(),
    }))

    useEffect(() => {
      if (me) {
        if (hasSinglePatient) {
          setSelectedPatient(me?.patients?.[0])
        }

        setSelectedBusinessId(me?.businesses?.[0]?.id)
      }
    }, [me])

    useEffect(() => {
      const canAddTask =
        Boolean(name)
        && Boolean(typeId)
        && Boolean(selectedPatient)
        && Boolean(selectedBusinessId)
        && Boolean(dueDate)
      onDataChanged?.(canAddTask)
    }, [name, typeId, selectedPatient, selectedBusinessId, dueDate])

    useEffect(() => {
      onLoading?.(createTaskLoading)
    }, [createTaskLoading])

    const businessOptions =
      me?.businesses?.map(business => ({
        value: business.id,
        label: business.name || '',
      })) || []

    const handlePatientSelected = (patient: Patient | undefined) => {
      setSelectedPatient(patient)
    }

    const handleBusinessSelected = (businessId: string) => {
      setSelectedBusinessId(businessId)
      if (!R.includes({ id: businessId }, selectedPatient?.businesses || [])) {
        handlePatientSelected(undefined)
      }
    }

    const handleRepeatPeriodChanged = (repeatPeriodId: string) => {
      const period = TaskRepeatPeriods.find(it => it.id === repeatPeriodId)

      flushSync(() => {
        setRepeatPeriod(period)
        if (!repeatPeriodId || period?.id === TaskRepeatPeriod.NEVER) {
          setRecurrenceEndDate(undefined)
        }
      })
    }

    const handleDueDateSelected = (date: Date | null) => {
      setDueDate(date)

      if (moment(date).isAfter(moment(recurrenceEndDate))) {
        setRecurrenceEndDate(date)
      }
    }

    const handleAddNewTask = () => {
      createTask({
        variables: {
          input: {
            name,
            instructions,
            businessId: selectedBusinessId,
            patientId: selectedPatient?.id,
            dueDatetime: moment(dueDate).endOf('day').toISOString(),
            typeId,
            share,
            ...(isRecurrence && {
              recurrencePeriod: {
                amount: repeatPeriod?.recurrencePeriod?.amount,
                unit: repeatPeriod?.recurrencePeriod?.unit,
              },
            }),
            ...(Boolean(recurrenceEndDate) && {
              recurrenceEndDatetime: moment(recurrenceEndDate).endOf('day').toISOString(),
            }),
          },
        },
      })
    }

    useImperativeHandle(ref, () => ({
      add: handleAddNewTask,
    }))

    return (
      <div css={styles.container} {...rest}>
        <SelectPatientHorizontalList
          loading={clientLoading}
          patients={me?.patients}
          selectedPatient={selectedPatient}
          onPatientSelected={handlePatientSelected}
        />

        <SelectInput
          css={styles.firstInput}
          disabled={
            !hasMultipleBusinesses || clientLoading || createTaskLoading
          }
          id="ccf-to"
          label={t('Home:CHOOSE_A_CLINIC')}
          options={businessOptions}
          value={selectedBusinessId}
          onChange={event => {
            handleBusinessSelected(event.target.value)
          }}
        />

        <InputText
          css={styles.input}
          disabled={createTaskLoading}
          id="ctf-name-td"
          label={`${t('Tasks:NAME_YOUR_TO_DO')}*`}
          value={name}
          onChangeText={setName}
        />

        <SelectInput
          emptyOption
          css={styles.input}
          id="ctf-ttype"
          label={`${t('Tasks:TO_DO_TYPE')}*`}
          options={filteredTaskTypesOptions}
          value={typeId}
          onChange={event => {
            setTypeId(event.target.value)
          }}
        />

        <SelectInput
          emptyOption
          css={styles.input}
          id="ctf-repeat"
          label={t('Tasks:REPEAT')}
          options={repeatPeriodOptions}
          value={repeatPeriod?.id}
          onChange={event => {
            handleRepeatPeriodChanged(event.target.value)
          }}
        />

        {isCustomRepeatPeriod && (
          <div css={styles.customContainer}>
            <Text variant={TextVariant.FORM_LABEL}>
              {t('Tasks:REPEAT_EVERY')}
            </Text>

            <div css={styles.customPeriodContainer}>
              <InputText
                hiddenLabel
                id="ctf-rep-eve"
                label=""
                min={1}
                type="number"
                value={repeatPeriod?.recurrencePeriod?.amount}
                onChangeText={value =>
                  setRepeatPeriod({
                    ...repeatPeriod,
                    recurrencePeriod: {
                      unit: repeatPeriod?.recurrencePeriod?.unit,
                      amount: Number(value),
                    },
                  })
                }
              />

              <SelectInput
                id="ctf-rep-ev-u"
                label=""
                options={customRepeatOptions}
                value={repeatPeriod?.recurrencePeriod?.unit}
                onChange={event => {
                  setRepeatPeriod({
                    ...repeatPeriod,
                    recurrencePeriod: {
                      unit: event?.target?.value,
                      amount: repeatPeriod?.recurrencePeriod?.amount,
                    },
                  })
                }}
              />
            </div>
          </div>
        )}

        <div css={styles.datesContainer}>
          <div>
            <Text css={styles.dateLabel} variant={TextVariant.FORM_LABEL}>
              {`${
                isRecurrence
                  ? t('Tasks:RECURRENCE_START_DATE')
                  : t('Tasks:DUE_DATE')
              }*`}
            </Text>

            <InputDatepicker
              disablePast
              css={styles.input}
              inputId="ctf-start-d"
              label=""
              staticMode={mediaQueryMatches(MediaQuery.MIN_MD)}
              value={moment(dueDate).toDate()}
              onChange={handleDueDateSelected}
            />
          </div>

          {isRecurrence && (
            <div>
              <Text css={styles.dateLabel} variant={TextVariant.FORM_LABEL}>
                {t('Tasks:RECURRENCE_END_DATE')}
              </Text>

              <InputDatepicker
                disablePast
                css={styles.input}
                inputId="ctf-end-d"
                label=""
                minDate={moment(dueDate).toDate()}
                staticMode={mediaQueryMatches(MediaQuery.MIN_MD)}
                value={
                  recurrenceEndDate ? moment(recurrenceEndDate).toDate() : null
                }
                onChange={setRecurrenceEndDate}
              />
            </div>
          )}
        </div>

        <InputTextArea
          css={styles.input}
          disabled={createTaskLoading}
          id="ctf-instr"
          label={t('Tasks:INSTRUCTIONS')}
          value={instructions}
          onChangeText={setInstructions}
        />

        <Checkbox
          css={styles.input}
          id="ctf-share-v"
          label={t('Tasks:SHARE_TO_DO_WITH_VET')}
          selectedValue={share}
          onChange={value => setShare(Boolean(value))}
        />
      </div>
    )
  },
)

export default CreateTaskForm
