/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { DateFormat } from '../../../../constants/dateFormat'
import { ReminderState } from '../../../../constants/reminders'
import { Spacing } from '../../../../constants/spacing'
import { Reminder } from '../../../../types/dto/Reminder'
import { spacing } from '../../../../utils/spacing'
import Card from '../../card/Card/Card'
import Tag, { TagTheme } from '../../tag/Tag'
import Text, { TextVariant } from '../../typography/Text/Text'

type ReminderWidgetItemProps = {
  reminder: Reminder
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: spacing(Spacing.S3, Spacing.S4),
  }),
  overdueContainer: css({
    padding: spacing(Spacing.S6, Spacing.S4, Spacing.S3, Spacing.S4),
  }),
  overdue: css({
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 0,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
  }),
}

const ReminderWidgetItem = ({ reminder, ...rest }: ReminderWidgetItemProps) => {
  const { t } = useTranslation('Common')

  const isOverdue = reminder.state.name === ReminderState.OVERDUE
  const dueDateTime = moment(reminder.dueDatetime).format(
    DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_COMMA_YEAR_AT_FULL_TIME,
  )

  return (
    <Card
      css={[styles.container, isOverdue && styles.overdueContainer]}
      {...rest}
    >
      {isOverdue && (
        <Tag css={styles.overdue} theme={TagTheme.ERROR}>
          {t('Common:OVERDUE')}
        </Tag>
      )}

      <Text variant={TextVariant.SECTION_2}>{reminder?.name}</Text>

      <Text variant={TextVariant.PARAGRAPH}>
        {`${t('Common:DUE')}: ${dueDateTime}`}
      </Text>
    </Card>
  )
}

export default ReminderWidgetItem
