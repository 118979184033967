import SignInPage from '../components/auth/signIn/SignInPage'
import SignOutPage from '../components/auth/signOut/SignOutPage'
import AccountPage from '../components/main/account/AccountPage'
import BookAppointmentPage from '../components/main/book/BookAppointmentPage'
import ChatListPage from '../components/main/chat/ChatListPage'
import ChatPage from '../components/main/chat/ChatPage'
import CreateChatPage from '../components/main/chat/CreateChatPage'
import CheckInPage from '../components/main/checkIn/CheckInPage'
import AddPatientPage from '../components/main/editPatientDetails/AddPatientPage'
import EditPatientDetailsPage from '../components/main/editPatientDetails/EditPatientDetailsPage'
import HomePage from '../components/main/home/HomePage'
import PatientDetailsPage from '../components/main/patientDetails/PatientDetailsPage'
import SignInErrorPage from '../components/main/signUp/SignInErrorPage'
import CreateTaskPage from '../components/main/tasks/CreateTaskPage'
import TaskDetailsPage from '../components/main/tasks/TaskDetailsPage'
import TasksPage from '../components/main/tasks/TasksPage'
import TimelineDetailsPage from '../components/main/timelineDetails/TimelineDetailsPage'
import i18n from '../locales/i18n'

export const RouteNames = {
  HOME: 'home',
  TASKS: 'tasks',
  TASK_DETAILS: 'task_details',
  CREATE_TASK: 'create_task',
  ACCOUNT: 'account',
  CHAT_LIST: 'chat_list',
  CHAT: 'chat',
  CREATE_CHAT: 'create_chat',
  ADD_PATIENT: 'addPatient',
  PATIENT_DETAILS: 'patientDetails',
  EDIT_PATIENT_DETAILS: 'editPatientDetails',
  TIMELINE_DETAILS: 'timelineDetails',
  BOOK: 'book',
  CHECK_IN: 'checkIn',
  SIGN_IN_ERROR: 'signInError',
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut',
}

export const RouteType = {
  MAIN: 'main',
  AUTH: 'auth',
}

export type MainRoute = {
  Component: React.JSXElementConstructor<any>
  path: string
  title: string
  type: string[]
}

export const ROUTES = {
  [RouteNames.HOME]: {
    Component: HomePage,
    path: '/dashboard',
    title: i18n.t('Home:HOME'),
    type: [RouteType.MAIN],
  },
  [RouteNames.TASKS]: {
    Component: TasksPage,
    path: '/tasks',
    title: i18n.t('Tasks:TASKS'),
    type: [RouteType.MAIN],
  },
  [RouteNames.TASK_DETAILS]: {
    Component: TaskDetailsPage,
    path: '/task/:taskId',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.CREATE_TASK]: {
    Component: CreateTaskPage,
    path: '/task/new',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.ACCOUNT]: {
    Component: AccountPage,
    path: '/account',
    title: i18n.t('Account:ACCOUNT'),
    type: [RouteType.MAIN],
  },
  [RouteNames.CHAT_LIST]: {
    Component: ChatListPage,
    path: '/chat',
    title: i18n.t('Chat:CHAT'),
    type: [RouteType.MAIN],
  },
  [RouteNames.CHAT]: {
    Component: ChatPage,
    path: '/chat/:chatId',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.CREATE_CHAT]: {
    Component: CreateChatPage,
    path: '/chat/new',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.ADD_PATIENT]: {
    Component: AddPatientPage,
    path: '/patient/new',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.PATIENT_DETAILS]: {
    Component: PatientDetailsPage,
    path: '/patient/:patientId',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.EDIT_PATIENT_DETAILS]: {
    Component: EditPatientDetailsPage,
    path: '/patient/:patientId/edit',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.BOOK]: {
    Component: BookAppointmentPage,
    path: '/booking',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.CHECK_IN]: {
    Component: CheckInPage,
    path: '/checkIn',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.TIMELINE_DETAILS]: {
    Component: TimelineDetailsPage,
    path: '/timelineDetails',
    title: '',
    type: [RouteType.MAIN],
  },
  [RouteNames.SIGN_IN_ERROR]: {
    Component: SignInErrorPage,
    path: '/sign-in-error',
    title: i18n.t('Auth:SIGN_IN'),
    type: [RouteType.MAIN],
  },
  [RouteNames.SIGN_IN]: {
    Component: SignInPage,
    path: '/sign-in',
    title: i18n.t('Auth:SIGN_IN'),
    type: [RouteType.AUTH],
  },
  [RouteNames.SIGN_OUT]: {
    Component: SignOutPage,
    path: '/sign-out',
    title: i18n.t('Auth:SIGN_OUT'),
    type: [RouteType.AUTH],
  },
}

export const MAIN_ROUTES = Object.fromEntries(
  Object.entries(ROUTES).filter(([, value]) =>
    value.type.includes(RouteType.MAIN),
  ),
)

export const AUTH_ROUTES = Object.fromEntries(
  Object.entries(ROUTES).filter(([, value]) =>
    value.type.includes(RouteType.AUTH),
  ),
)
