/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { SCHEDULE_APPOINTMENT } from '../../../api/mutations/appointment'
import { GET_BOOKING_BUSINESSES } from '../../../api/queries/client'
import { GET_REMINDER_STATES } from '../../../api/queries/constants'
import { GET_PATIENT_CARD_DATA } from '../../../api/queries/patient'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import i18n from '../../../locales/i18n'
import {
  AvailableStaff,
  SuggestedAppointment,
  TimeSlot,
} from '../../../types/dto/Appointment'
import { Business } from '../../../types/dto/Business'
import { Patient } from '../../../types/dto/Patient'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import {
  getPatientCardQueryVariables,
  getReminderStatesForWidgets,
} from '../../../utils/patient'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis } from '../button/Button'
import Card from '../card/Card/Card'
import BookPatientVisitTypeForm, {
  Reason,
  VisitType,
} from '../form/BookPatientVisitTypeForm'
import BookProviderDateForm from '../form/BookProviderDateForm'
import BookProviderMultiDateForm from '../form/BookProviderMultiDateForm'
import BookVisitDetailsForm from '../form/BookVisitDetailsForm'
import ProgressPath from '../navigation/ProgressPath'
import Text, { TextVariant } from '../typography/Text/Text'

const Steps = {
  PATIENT_AND_VISIT_TYPE: {
    index: 0,
    label: i18n.t('Home:PATIENT_AND_VISIT_TYPE'),
  },
  VISIT_DETAILS: {
    index: 1,
    label: i18n.t('Home:VISIT_DETAILS'),
  },
  PROVIDER_DATE_TIME: {
    index: 2,
    label: i18n.t('Home:PROVIDER_DATE_AND_TIME'),
  },
}

type BookAppointmentCardProps = {
  businessId?: string
  onBooked: () => void
  onError: () => void
  patient?: Patient
  suggestedAppointment?: SuggestedAppointment
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  innerContainer: css({
    justifyContent: 'center',
    padding: spacing(Spacing.S4, 0),
  }),
  title: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      margin: spacing(0, Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MD, {
      margin: spacing(0, Spacing.S8),
    }),
  }),
  subtitle: css({
    margin: spacing(Spacing.S2, Spacing.S4, 0, Spacing.S4),
  }),
  content: css({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: spacing(Spacing.S6),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0, Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S8),
    }),
  }),
  pathProgress: css({
    marginTop: spacing(Spacing.S8),
  }),
  stepContainerHalf: css({
    width: '50%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '100%',
    }),
  }),
  stepContainer: css({
    width: '100%',
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S6),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0, Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S8),
    }),
  }),
  backButton: css({
    marginRight: spacing(Spacing.S2),
  }),
}

const isBoarding = (reason?: Reason) =>
  reason?.staticTypeName?.toLowerCase() === 'boarding'

const BookAppointmentCard = ({
  businessId,
  patient: initialPatient,
  suggestedAppointment,
  onBooked,
  onError,
}: BookAppointmentCardProps) => {
  const { t } = useTranslation(['Common', 'Home', 'Pet'])

  const [state, setState] = useState<(typeof Steps)[keyof typeof Steps]>(
    Steps.PATIENT_AND_VISIT_TYPE,
  )
  const [selectedBusinessId, setSelectedBusinessId] = useState<
  string | undefined
  >(businessId || suggestedAppointment?.businessId)
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
    initialPatient,
  )
  const [selectedVisitType, setSelectedVisitType] = useState<
  VisitType | undefined
  >()
  const [selectedReason, setSelectedReason] = useState<Reason | undefined>()
  const [notes, setNotes] = useState<string | undefined>(
    suggestedAppointment?.notes,
  )
  const [selectedDoctor, setSelectedDoctor] = useState<
  AvailableStaff | undefined
  >()
  const [selectedTimeFrom, setSelectedTimeFrom] = useState<
  TimeSlot | undefined
  >()
  const [selectedTimeTo, setSelectedTimeTo] = useState<TimeSlot | undefined>()

  const { data: { me } = {} } = useQuery(GET_BOOKING_BUSINESSES)
  const { data: { constants: { reminderStates = [] } = {} } = {} } =
    useQuery(GET_REMINDER_STATES)

  const supportedReminderStateIds = getReminderStatesForWidgets(reminderStates)

  const [scheduleAppointment, { loading: bookingLoading }] = useMutation(
    SCHEDULE_APPOINTMENT,
    {
      refetchQueries: [
        {
          query: GET_PATIENT_CARD_DATA,
          variables: getPatientCardQueryVariables(
            selectedPatient?.id,
            supportedReminderStateIds,
          ),
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        onBooked()
      },
      onError: () => {
        onError()
      },
    },
  )

  const selectedBusiness = R.find(
    (it: Business) => it.id === selectedBusinessId,
    me?.businesses || [],
  )

  const previousStep = R.find(
    R.propEq(state.index - 1, 'index'),
    R.values(Steps),
  )
  const nextStep = R.find(R.propEq(state.index + 1, 'index'), R.values(Steps))
  const nextButtonEnabled =
    Boolean(
      state.index === Steps.PATIENT_AND_VISIT_TYPE.index
        && selectedPatient
        && selectedVisitType,
    )
    || Boolean(state.index === Steps.VISIT_DETAILS.index && selectedReason)
    || Boolean(
      state.index === Steps.PROVIDER_DATE_TIME.index
        && selectedTimeFrom
        && (isBoarding(selectedReason) ? selectedTimeTo : selectedDoctor),
    )
  const onNext = () => {
    if (nextStep) {
      setState(nextStep)
    } else {
      scheduleAppointment({
        variables: {
          input: {
            businessId: selectedBusinessId,
            businessAppointmentTypeId: selectedReason?.id,
            patientId: selectedPatient?.id,
            ...(selectedDoctor?.personInfo?.id
              ? { veterinarianId: selectedDoctor.personInfo.id }
              : {}),
            scheduledStartDatetime: selectedTimeFrom?.from,
            scheduledEndDatetime: isBoarding(selectedReason)
              ? selectedTimeTo?.from
              : selectedTimeFrom?.to,
            notes,
          },
        },
      })
    }
  }

  const onBack = () => {
    if (previousStep) {
      setState(previousStep)
    }
  }

  const resetDoctorAndDates = () => {
    setSelectedTimeFrom(undefined)
    setSelectedTimeTo(undefined)
    setSelectedDoctor(undefined)
  }

  const handlePatientSelected = (patient: Patient | undefined) => {
    setSelectedPatient(patient)

    if (mediaQueryMatches(MediaQuery.MIN_MD) && selectedVisitType && patient) {
      setState(Steps.VISIT_DETAILS)
    }
  }

  const handleVisitTypeSelected = (visitType: VisitType | undefined) => {
    setSelectedVisitType(visitType)
    setSelectedReason(undefined)

    if (mediaQueryMatches(MediaQuery.MIN_MD) && visitType && selectedPatient) {
      setState(Steps.VISIT_DETAILS)
    }
  }

  const handleBusinessSelected = (id: string) => {
    setSelectedBusinessId(id)

    if (
      mediaQueryMatches(MediaQuery.MIN_MD)
      && (state.index === Steps.VISIT_DETAILS.index
        || state.index === Steps.PROVIDER_DATE_TIME.index)
    ) {
      setState(Steps.PATIENT_AND_VISIT_TYPE)
    }
  }

  const handleReasonSelected = (reason: Reason | undefined) => {
    setSelectedReason(reason)
    resetDoctorAndDates()

    if (mediaQueryMatches(MediaQuery.MIN_MD) && reason) {
      setState(Steps.PROVIDER_DATE_TIME)
    }
  }

  return (
    <Card css={styles.container}>
      <KibGrid>
        {mediaQueryMatches(MediaQuery.MIN_LG) && (
          <KibGridItem span="1@min-lg" />
        )}

        <KibGridItem
          css={styles.innerContainer}
          span="4@max-sm 8@min-md 10@min-lg"
        >
          <Text css={styles.title} variant={TextVariant.DISPLAY_5}>
            {t('Home:BOOK_AN_APPOINTMENT')}
          </Text>

          {mediaQueryMatches(MediaQuery.MAX_SM)
            && state.index !== Steps.PATIENT_AND_VISIT_TYPE.index && (
              <Text css={styles.subtitle} variant={TextVariant.SECTION_2}>
                {t('Home:VISIT_WITH_PATIENT', {
                  type: selectedVisitType?.id,
                  name: selectedPatient?.name,
                })}
              </Text>
          )}

          {mediaQueryMatches(MediaQuery.MAX_SM) && (
            <ProgressPath
              css={styles.pathProgress}
              currentStep={state.index + 1}
              steps={Object.values(Steps).map(step => step.label)}
            />
          )}

          <div css={styles.content}>
            {Object.values(Steps)
              .filter(step =>
                mediaQueryMatches(MediaQuery.MIN_MD)
                  ? step.index <= state.index
                  : step.index === state.index,
              )
              .map(step => (
                <>
                  {step.index === Steps.PATIENT_AND_VISIT_TYPE.index && (
                    <BookPatientVisitTypeForm
                      businesses={me?.businesses}
                      css={styles.stepContainerHalf}
                      preselectedBusinessId={selectedBusinessId}
                      selectedPatient={selectedPatient}
                      selectedVisitType={selectedVisitType}
                      suggestedAppointmentCategory={
                        suggestedAppointment?.businessAppointmentReason
                          ?.staticAppointmentType?.category
                      }
                      onBusinessSelected={handleBusinessSelected}
                      onPatientSelected={handlePatientSelected}
                      onVisitTypeSelected={handleVisitTypeSelected}
                    />
                  )}

                  {step.index === Steps.VISIT_DETAILS.index && (
                    <BookVisitDetailsForm
                      css={styles.stepContainerHalf}
                      notes={notes}
                      reasons={selectedVisitType?.reasons}
                      selectedReason={selectedReason}
                      suggestedAppointmentReasonId={
                        suggestedAppointment?.businessAppointmentReason
                          ?.businessAppointmentTypeId
                      }
                      onNotesChanged={setNotes}
                      onReasonSelected={handleReasonSelected}
                    />
                  )}

                  {step.index === Steps.PROVIDER_DATE_TIME.index
                    && (isBoarding(selectedReason) ? (
                      <BookProviderMultiDateForm
                        business={selectedBusiness}
                        css={styles.stepContainer}
                        reason={selectedReason}
                        onTimeFromSelected={setSelectedTimeFrom}
                        onTimeToSelected={setSelectedTimeTo}
                      />
                    ) : (
                      <BookProviderDateForm
                        business={selectedBusiness}
                        css={styles.stepContainer}
                        reason={selectedReason}
                        suggestedDoctorId={
                          suggestedAppointment?.veterinarian?.id
                        }
                        suggestedEndTime={
                          suggestedAppointment?.scheduledEndDatetime
                        }
                        suggestedStartTime={
                          suggestedAppointment?.scheduledStartDatetime
                        }
                        onDoctorSelected={setSelectedDoctor}
                        onTimeSelected={setSelectedTimeFrom}
                      />
                    ))}
                </>
              ))}
          </div>

          {(state.index === Steps.PROVIDER_DATE_TIME.index
            || mediaQueryMatches(MediaQuery.MAX_SM)) && (
              <div css={styles.buttonContainer}>
                {previousStep && mediaQueryMatches(MediaQuery.MAX_SM) && (
                  <Button
                    css={styles.backButton}
                    emphasis={ButtonEmphasis.SECONDARY}
                    id="ba-back"
                    onClick={onBack}
                  >
                    {t('Common:BACK')}
                  </Button>
                )}

                <Button
                  disabled={!nextButtonEnabled || bookingLoading}
                  id={nextStep ? 'ba-back' : 'ba-finish'}
                  loading={bookingLoading}
                  onClick={onNext}
                >
                  {nextStep ? t('Common:NEXT') : t('Home:FINISH_BOOKING')}
                </Button>
              </div>
          )}
        </KibGridItem>

        {mediaQueryMatches(MediaQuery.MIN_LG) && (
          <KibGridItem span="1@min-lg" />
        )}
      </KibGrid>
    </Card>
  )
}

export default BookAppointmentCard
