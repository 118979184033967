/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { UPDATE_TASK_NOTES, UPDATE_TASK_STATE } from '../../api/mutations/tasks'
import { GET_TASK_STATES_CONSTANTS } from '../../api/queries/constants'
import { GET_TASK_READINESS } from '../../api/queries/tasks'
import { ColorVariables } from '../../constants/colors'
import { MediaQuery } from '../../constants/mediaQuery'
import { Spacing } from '../../constants/spacing'
import { TaskRecurrenceType, TaskState } from '../../constants/task'
import { Task } from '../../types/dto/Task'
import { findConstantByName } from '../../utils'
import { mediaQuery, mediaQueryMatches } from '../../utils/mediaQuery'
import { spacing } from '../../utils/spacing'
import {
  getTaskRecurrencePeriodLabel,
  getTaskStateLabel,
} from '../../utils/task'
import Button, { ButtonEmphasis, ButtonTheme } from '../common/button/Button'
import Card from '../common/card/Card/Card'
import Divider from '../common/divider/Divider'
import Avatar from '../common/icon/Avatar'
import EditIcon from '../common/icon/EditIcon'
import InputTextArea from '../common/input/InputTextArea'
import Tag from '../common/tag/Tag'
import Text, { TextVariant } from '../common/typography/Text/Text'
import CompletedChildTasksList from './CompletedChildTasksList'
import OverdueChildTasksList from './OverdueChildTasksList'
import UpcomingChildTasksList from './UpcomingChildTasksList'

type TaskDetailsViewProps = {
  task: Task
}

const styles = {
  container: css({
    padding: spacing(Spacing.S4),
    position: 'relative',
    ...mediaQuery(MediaQuery.MAX_SM, {
      backgroundColor: ColorVariables.UI_BG_PRIMARY,
    }),
  }),
  headerContainer: css({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: spacing(Spacing.S4),
  }),
  header: css({
    display: 'flex',
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 20,
  }),
  label: css({
    borderRadius: 0,
    borderBottomLeftRadius: 12,
    ...mediaQuery(MediaQuery.MIN_MD, {
      borderTopRightRadius: 12,
    }),
  }),
  headerTaskTitleProgressContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacing(Spacing.S3),
  }),
  readiness: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  progress: css({
    width: 70,
    height: 70,
  }),
  headerTaskTitleContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  headerPatientContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  divider: css({
    margin: spacing(Spacing.S4, 0),
  }),
  instructions: css({
    marginTop: spacing(Spacing.S2),
  }),
  notesContainerHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  notesInput: css({
    marginTop: spacing(Spacing.S2),
  }),
  buttonContainer: css({
    display: 'flex',
    columnGap: spacing(Spacing.S2),
    flexDirection: 'row-reverse',
    alignItems: 'center',
  }),
}

const TaskDetailsView = ({ task: taskProp, ...rest }: TaskDetailsViewProps) => {
  const { t } = useTranslation(['Common', 'Tasks'])

  const [task, setTask] = useState<Task>(taskProp)
  const [notesEditMode, setNotesEditMode] = useState(false)
  const [notes, setNotes] = useState(task?.notes)

  const { data: readinessData } = useQuery(GET_TASK_READINESS, {
    variables: { id: taskProp?.id },
  })

  const { data: { constants: { taskStates = [] } = {} } = {} } = useQuery(
    GET_TASK_STATES_CONSTANTS,
  )

  const [updateTaskNotes, { loading: updateTaskNotesLoading }] = useMutation(
    UPDATE_TASK_NOTES,
    {
      onCompleted: ({ updateTaskNotes: data }) => {
        flushSync(() => {
          setNotes(data?.notes)
          setTask({ ...task, notes: data?.notes })
        })

        setNotesEditMode(false)
      },
    },
  )

  const [updateTaskState, { loading: updateTaskStateLoading }] = useMutation(
    UPDATE_TASK_STATE,
    {
      onCompleted: ({ updateTaskState: data }) => {
        setTask({ ...task, ...data })
      },
    },
  )

  useEffect(() => {
    if (taskProp?.id !== task?.id) {
      setNotes(taskProp?.notes)
      setTask(taskProp)
      setNotesEditMode(false)
    } else if (readinessData?.task?.id === taskProp?.id) {
      setTask({ ...task, ...readinessData?.task })
    }
  }, [taskProp, readinessData])

  const photo = task?.patient?.photoThumbnail || task?.patient?.photo
  const avatarImageObjectFit = task?.patient ? 'cover' : 'contain'
  const avatarBackgroundColor = task?.patient
    ? ColorVariables.UI_BG_02
    : ColorVariables.UI_BG_PRIMARY
  const notesUnchanged =
    (R.isEmpty(notes || '') && R.isEmpty(task?.notes || ''))
    || notes === task?.notes
  const isDone = task?.aggregatedState?.name === TaskState.DONE
  const isOnce = task?.recurrenceType === TaskRecurrenceType.ONCE
  const isRepeatOrChronic =
    task?.recurrenceType === TaskRecurrenceType.REPEAT
    || task?.recurrenceType === TaskRecurrenceType.CHRONIC
  const taskLabel = getTaskStateLabel(task)
  const period = getTaskRecurrencePeriodLabel(task)

  const handleSaveNotes = () => {
    updateTaskNotes({
      variables: {
        taskId: task.id,
        notes,
      },
    })
  }

  const handleMarkAsDone = async (taskToMark: Task) => {
    setNotesEditMode(false)

    updateTaskState({
      variables: {
        taskId: taskToMark.id,
        stateId: findConstantByName(TaskState.DONE, taskStates)?.id,
      },
    })
  }

  const Container = mediaQueryMatches(MediaQuery.MIN_MD) ? Card : 'div'

  return (
    <Container css={styles.container} {...rest}>
      <div css={styles.header}>
        <Tag css={styles.label} theme={taskLabel.theme}>
          {taskLabel.title}
        </Tag>
      </div>

      <div css={styles.headerContainer}>
        <div css={styles.headerTaskTitleProgressContainer}>
          {isRepeatOrChronic && (
            <CircularProgressbarWithChildren
              css={styles.progress}
              styles={buildStyles({
                textColor: ColorVariables.UI_BG_BRAND_PRIMARY,
                pathColor: ColorVariables.UI_BG_BRAND_PRIMARY,
                trailColor: ColorVariables.UI_BG_09,
              })}
              value={task?.readiness || 0}
            >
              <Text css={styles.readiness} variant={TextVariant.SECTION_2}>
                {`${task?.readiness}%`}
              </Text>
            </CircularProgressbarWithChildren>
          )}

          <div css={styles.headerTaskTitleContainer}>
            <Text variant={TextVariant.PARAGRAPH_2}>
              {`${task.business?.name} - ${task.patient?.name}`}
            </Text>

            <Text variant={TextVariant.DISPLAY_5}>{task.name}</Text>

            <Text variant={TextVariant.PARAGRAPH}>{period || ''}</Text>
          </div>
        </div>

        {task?.patient && (
          <div css={styles.headerPatientContainer}>
            <Avatar
              avatarBackgroundColor={avatarBackgroundColor}
              avatarSize={36}
              imageStyle={css({ objectFit: avatarImageObjectFit })}
              photo={photo}
            />
          </div>
        )}
      </div>

      <Divider css={styles.divider} />

      <Text variant={TextVariant.SECTION_1}>{t('Tasks:INSTRUCTIONS')}</Text>

      <Text css={styles.instructions} variant={TextVariant.PARAGRAPH}>
        {task.instructions || '-'}
      </Text>

      <Divider css={styles.divider} />

      <div>
        <div css={styles.notesContainerHeader}>
          <Text variant={TextVariant.SECTION_1}>{t('Tasks:NOTES')}</Text>

          {!isDone && !notesEditMode && (
            <EditIcon onEdit={() => setNotesEditMode(true)} />
          )}
        </div>

        {notesEditMode ? (
          <div>
            <InputTextArea
              hiddenLabel
              css={styles.notesInput}
              label={t('Tasks:NOTES')}
              value={notes}
              onChangeText={setNotes}
            />

            <div css={styles.buttonContainer}>
              <Button
                disabled={
                  updateTaskNotesLoading
                  || notesUnchanged
                  || updateTaskStateLoading
                }
                loading={updateTaskNotesLoading}
                onClick={handleSaveNotes}
              >
                {t('Common:SAVE')}
              </Button>

              <Button
                disabled={updateTaskNotesLoading || updateTaskStateLoading}
                emphasis={ButtonEmphasis.TERTIARY}
                theme={ButtonTheme.UTILITY}
                onClick={() => {
                  setNotesEditMode(false)
                }}
              >
                {t('Common:CANCEL')}
              </Button>
            </div>
          </div>
        ) : (
          <Text variant={TextVariant.PARAGRAPH}>{task.notes || '-'}</Text>
        )}

        {!isOnce && (
          <>
            {!isDone && (
              <>
                <UpcomingChildTasksList
                  parentTask={task}
                  taskStates={taskStates}
                />

                <OverdueChildTasksList
                  parentTask={task}
                  taskStates={taskStates}
                />
              </>
            )}

            <CompletedChildTasksList
              parentTask={task}
              taskStates={taskStates}
            />
          </>
        )}

        {isOnce && !isDone && (
          <>
            <Divider css={styles.divider} />

            <div css={styles.buttonContainer}>
              <Button
                disabled={updateTaskNotesLoading || updateTaskStateLoading}
                loading={updateTaskStateLoading}
                onClick={() => handleMarkAsDone(task)}
              >
                {t('Tasks:MARK_AS_COMPLETED')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}

export default TaskDetailsView
