/** @jsxImportSource @emotion/react */
import { KibSkeletonShape, KibSkeletonText } from '@chewy/kib-skeleton-react'
import { css } from '@emotion/react'

import { Spacing } from '../../../../constants/spacing'
import { spacing } from '../../../../utils/spacing'

const styles = {
  visitContainer: css({
    marginTop: spacing(Spacing.S3),
  }),
  businessName: {
    marginTop: spacing(Spacing.S1),
    width: 200,
  },
  buttonsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: spacing(Spacing.S6),
  }),
  button: {
    marginLeft: spacing(Spacing.S3),
  },
}
const UpcomingAppointmentSkeleton = () => (
  <div css={styles.visitContainer}>
    <KibSkeletonText lines={4} style={{ width: 350 }} />

    <div css={styles.buttonsContainer}>
      <KibSkeletonShape style={{ width: 150, height: 50, borderRadius: 30 }} />

      <KibSkeletonShape
        style={{
          width: 100,
          height: 50,
          borderRadius: 30,
          marginLeft: spacing(Spacing.S3),
        }}
      />

      <KibSkeletonShape
        style={{
          width: 100,
          height: 50,
          borderRadius: 30,
          marginLeft: spacing(Spacing.S3),
        }}
      />
    </div>
  </div>
)

export default UpcomingAppointmentSkeleton
