/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { FilePreviewTemplate } from '../../../types/entities'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button from '../button/Button'
import Modal, { ModalProps } from '../modal/Modal'
import Spinner from '../spinner/Spinner'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

type PdfFilePreviewProps = ModalProps & {
  file?: FilePreviewTemplate
  onDownload?: (file?: FilePreviewTemplate) => void
}

const styles = {
  modal: css({
    '.kib-modal__content': {
      ...mediaQuery(MediaQuery.MAX_SM, {
        padding: 0,
      }),
    },
  }),
  document: css({
    marginBottom: 16,
  }),
  page: css({
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '&:not(:last-of-type)': {
      marginBottom: spacing(Spacing.S4),
    },
    '.react-pdf__Page__canvas': {
      ...mediaQuery(MediaQuery.MIN_MD, {
        width: '100% !important',
      }),
    },
    '.react-pdf__Page__textContent': {
      display: 'none',
    },
    '.react-pdf__Page__annotations': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100% !important',
  }),
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
}

const PdfFilePreview = ({ file, onDownload, ...rest }: PdfFilePreviewProps) => {
  const { t } = useTranslation('Common')

  const [pageCount, setPageCount] = useState(1)

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageCount(numPages)
  }

  return (
    <Modal
      css={styles.modal}
      title={file?.name}
      {...rest}
      // eslint-disable-next-line
      controls={({ close }) => (
        <div css={styles.buttonsContainer}>
          <Button onClick={() => onDownload?.(file)}>
            {t('Common:DOWNLOAD')}
          </Button>
        </div>
      )}
    >
      <Document
        css={styles.document}
        error={t('Common:ERROR_WHILE_LOADING_DOCUMENT')}
        file={file?.url}
        loading={<Spinner />}
        onLoadSuccess={onLoadSuccess}
      >
        {Array.from({ length: pageCount }, (item, index) => (
          <Page css={styles.page} key={index} pageNumber={index + 1} />
        ))}
      </Document>
    </Modal>
  )
}

export default PdfFilePreview
