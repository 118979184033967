/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { GET_TIME_SLOTS } from '../../../api/queries/appointment'
import { ColorVariables } from '../../../constants/colors'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { TimeSlot } from '../../../types/dto/Appointment'
import { Business } from '../../../types/dto/Business'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import BookTimeSlots from '../chips/BookTimeSlots'
import InputDatepicker from '../input/InputDatepicker'
import Spinner from '../spinner/Spinner'
import { Reason } from './BookPatientVisitTypeForm'

type BookProviderMultiDateFormProps = {
  business?: Business
  onTimeFromSelected: (time: TimeSlot | undefined) => void
  onTimeToSelected: (time: TimeSlot | undefined) => void
  reason?: Reason
}

const styles = {
  container: css({
    display: 'flex',
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
      ...mediaQuery(MediaQuery.MAX_SM, {
        padding: spacing(Spacing.S4, 0, 0, 0),
      }),
    }),
  }),
  contentColumn: css({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '100%',
    }),
  }),
  contentLeft: css({
    ...mediaQuery(MediaQuery.MIN_MD, {
      padding: spacing(0, Spacing.S4, 0, 0),
    }),
  }),
  contentRight: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, 0, 0, 0),
    }),

    ...mediaQuery(MediaQuery.MIN_MD, {
      padding: spacing(0, 0, 0, Spacing.S4),
    }),
  }),
  spinnerContainer: css({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }),
  spinner: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    marginTop: spacing(Spacing.S6),
  }),
}

const BookProviderMultiDateForm = ({
  reason,
  business,
  onTimeFromSelected,
  onTimeToSelected,
  ...rest
}: BookProviderMultiDateFormProps) => {
  const { t } = useTranslation('Home')

  const [selectedDateFrom, setSelectedDateFrom] = useState<
  string | Date | undefined | null
  >()
  const [selectedDateTo, setSelectedDateTo] = useState<
  string | Date | undefined | null
  >()
  const [selectedTimeSlotFrom, setSelectedTimeSlotFrom] = useState<
  TimeSlot | undefined
  >()
  const [selectedTimeSlotTo, setSelectedTimeSlotTo] = useState<
  TimeSlot | undefined
  >()

  const { data: { timeSlotsByBusinessAppointmentType: timeSlotsFrom = [] } = {}, loading: fromLoading } =
    useQuery(GET_TIME_SLOTS, {
      variables: {
        businessId: business?.id,
        businessAppointmentTypeId: reason?.id,
        day: moment(selectedDateFrom).format(DateFormat.BACKEND_FULL_DATE),
      },
      fetchPolicy: 'network-only',
    })

  const { data: { timeSlotsByBusinessAppointmentType: timeSlotsTo = [] } = {}, loading: toLoading } =
    useQuery(GET_TIME_SLOTS, {
      variables: {
        businessId: business?.id,
        businessAppointmentTypeId: reason?.id,
        day: moment(selectedDateTo).format(DateFormat.BACKEND_FULL_DATE),
      },
      fetchPolicy: 'network-only',
    })

  const timezone = business?.timezone || 'UTC'

  const handleTimeFromSelected = (time: TimeSlot | undefined) => {
    setSelectedTimeSlotFrom(time)
    onTimeFromSelected(time)

    if (moment(time?.from).isAfter(moment(selectedTimeSlotTo?.from))) {
      setSelectedTimeSlotTo(undefined)
      onTimeToSelected(undefined)
    }
  }

  const handleTimeToSelected = (time: TimeSlot | undefined) => {
    setSelectedTimeSlotTo(time)
    onTimeToSelected(time)
  }

  const handleDateFromSelected = (date: Date | null) => {
    setSelectedDateFrom(date)
    handleTimeFromSelected(undefined)
    handleTimeToSelected(undefined)

    if (moment(date).isAfter(moment(selectedDateTo))) {
      setSelectedDateTo(date)
    }
  }

  const handleDateToSelected = (date: Date | null) => {
    setSelectedDateTo(date)
    handleTimeToSelected(undefined)
  }

  return (
    <div css={styles.container} {...rest}>
      <div css={[styles.contentColumn, styles.contentLeft]}>
        <InputDatepicker
          disablePast
          label={t('Home:CHECK_IN')}
          value={moment(selectedDateFrom).toDate()}
          onChange={handleDateFromSelected}
        />

        <div>
          {fromLoading ? (
            <div css={styles.spinnerContainer}>
              <Spinner css={styles.spinner} />
            </div>
          ) : (
            <BookTimeSlots
              selectedTimeSlot={selectedTimeSlotFrom}
              timeSlots={timeSlotsFrom}
              timezone={timezone}
              onTimeSlotSelected={handleTimeFromSelected}
            />
          )}
        </div>
      </div>

      <div css={[styles.contentColumn, styles.contentRight]}>
        <InputDatepicker
          disablePast
          label={t('Home:CHECK_OUT')}
          value={moment(selectedDateTo).toDate()}
          onChange={handleDateToSelected}
        />

        <div>
          {toLoading ? (
            <div css={styles.spinnerContainer}>
              <Spinner css={styles.spinner} />
            </div>
          ) : (
            <BookTimeSlots
              selectedTimeSlot={selectedTimeSlotTo}
              timeSlots={timeSlotsTo.filter((timeSlot: TimeSlot) =>
                moment(timeSlot?.from).isAfter(
                  moment(selectedTimeSlotFrom?.from),
                ),
              )}
              timezone={timezone}
              onTimeSlotSelected={handleTimeToSelected}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default BookProviderMultiDateForm
