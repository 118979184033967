/** @jsxImportSource @emotion/react */
const SignInImage = ({ ...rest }) => (
  <svg
    height={487}
    viewBox="0 0 282 229"
    width={600}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <style>{'.a{fill:#fff}.b{fill:#534f58}'}</style>
    <path className="a" d="M281.5 68.8h-80.9v3.1h80.9z" />
    <path
      className="b"
      d="M281.8 72.2h-81.5v-3.7h81.5zm-80.9-.6h80.3v-2.4h-80.3z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M254.4 103.8q-3.8 0-5.9-2.2c-2.3-2.4-2.1-5.9-2.1-6.2V75.5c0-.3.3-.6.6-.6.4 0 .7.3.7.6v19.9c0 .1-.2 3.2 1.8 5.3q1.7 1.8 4.9 1.8c7.1 0 7.4-6.8 7.4-7.1v-7.9c0-.3.3-.6.6-.6.4 0 .7.3.7.6v7.9c0 .1-.3 8.4-8.7 8.4z"
    />
    <path className="b" d="M248.8 68.4h-3.5v7.1h3.5z" />
    <path
      className="b"
      d="M249.1 75.9h-4.2v-7.8h4.2zm-3.5-.7h2.9v-6.4h-2.9z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M247 69.1c-.9 0-8.9-.2-9.5-4.2V52.1c0-.5-.3-4 3.6-7.1.2-.2.6-.2.9.1.2.3.1.7-.1.9-3.5 2.8-3.1 5.8-3.1 5.9v12.9c.4 2.4 6.2 3 8.2 3 .4 0 .7.3.7.6 0 .4-.3.7-.7.7z"
    />
    <path
      className="b"
      d="M242.6 46.5q-.2 0-.5-.1c-1-.2-1.7-1.3-1.5-2.3.2-.5.5-1 .9-1.3.5-.2 1-.3 1.5-.2 1.1.2 1.7 1.3 1.5 2.3-.1.6-.4 1-.9 1.3q-.5.3-1 .3zm0-3.3q-.4 0-.7.2c-.3.2-.5.5-.6.8-.2.7.3 1.5 1 1.6.3.1.7 0 1-.2.3-.2.5-.4.6-.8.1-.7-.3-1.4-1-1.6h-.3z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M247 69.1c-.3 0-.6-.3-.6-.7 0-.3.3-.6.6-.6 2 0 7.8-.6 8.3-3V51.9c0-.1.3-3.1-3.1-5.9-.3-.2-.3-.6-.1-.9.2-.3.6-.3.9-.1 3.8 3.1 3.6 6.6 3.6 7.1v12.8h-.1q-.3 2.7-5.1 3.7c-2.2.5-4.3.5-4.4.5z"
    />
    <path
      className="b"
      d="M251.5 46.5q-.6 0-1.1-.3c-.4-.3-.7-.7-.8-1.3-.3-1 .4-2.1 1.5-2.3.5-.1 1 0 1.5.2.4.3.7.8.8 1.3.3 1-.4 2.1-1.5 2.3q-.2.1-.4.1zm0-3.3h-.3c-.7.2-1.2.9-1 1.6q.1.5.6.8.4.3 1 .2c.7-.1 1.1-.9 1-1.6-.1-.3-.3-.6-.6-.8q-.3-.2-.7-.2z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M262.4 93.6c3.4 0 6.1-2.7 6.1-6.1 0-3.3-2.7-6.1-6.1-6.1-3.3 0-6.1 2.8-6.1 6.1 0 3.4 2.8 6.1 6.1 6.1z"
    />
    <path
      className="b"
      d="M262.4 94c-3.5 0-6.4-2.9-6.4-6.5 0-3.5 2.9-6.4 6.4-6.4 3.5 0 6.4 2.9 6.4 6.4 0 3.6-2.9 6.5-6.4 6.5zm0-12.3c-3.2 0-5.8 2.6-5.8 5.8 0 3.2 2.6 5.8 5.8 5.8 3.2 0 5.8-2.6 5.8-5.8 0-3.2-2.6-5.8-5.8-5.8z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M262.4 90.6c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1z"
    />
    <path
      className="b"
      d="M262.4 91c-1.9 0-3.4-1.6-3.4-3.5s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.5-3.4 3.5zm0-6.3c-1.5 0-2.8 1.3-2.8 2.8 0 1.6 1.3 2.8 2.8 2.8 1.5 0 2.8-1.2 2.8-2.8 0-1.5-1.3-2.8-2.8-2.8z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M211.2 197.4v-36.6s-.3-3.8 2.7-8.8c3-4.9 25-57.2 25-57.2s3.6 9.7 4.6 14.7c.9 5-.6 14.4-3.6 16.9-3 2.5-3.6 3.7-3.6 6.1 0 2.3.2 5-2.8 7.5s-5.1 4.4-5 6.8c.2 2.4 3.3 7.7-1 9.1-4.3 1.3-9.8 1.5-11.5 3.8-1.7 2.2-2.8 4.4-2.7 5.6.2 1.2 0 32.1 0 32.1h-2.1z"
    />
    <path
      className="b"
      d="M213.7 197.7h-2.8v-36.8c0-.4-.3-4.1 2.7-9s24.8-56.7 25-57.2l.3-.8.3.8c0 .1 3.7 9.8 4.6 14.7.8 4.8-.5 14.6-3.7 17.3-2.7 2.2-3.5 3.3-3.5 5.8v.5c0 2.3 0 4.8-2.9 7.2-2.8 2.4-5 4.3-4.8 6.6 0 .5.2 1.3.4 2.2.8 2.6 1.8 6.1-1.7 7.2-1.4.4-2.9.7-4.4 1-3 .6-5.9 1.2-7 2.7-1.6 2.1-2.7 4.3-2.5 5.4.1 1.2 0 30.8 0 32.1zm-.7-.7c.1-10.7.1-30.8 0-31.7-.1-1.2.9-3.5 2.7-5.8 1.2-1.7 4.2-2.3 7.4-2.9 1.4-.3 2.9-.7 4.3-1.1 2.7-.8 2.2-3.2 1.3-6.4-.2-.8-.5-1.7-.5-2.3-.1-2.6 2.1-4.6 5.1-7.1 2.7-2.2 2.7-4.5 2.6-6.7v-.5c0-2.5.8-3.8 3.8-6.3 1.3-1.1 2.5-3.8 3.1-7.4.7-3.3.8-6.8.3-9.3-.7-4.1-3.4-11.5-4.2-13.8-2.9 6.6-21.9 51.8-24.8 56.5-2.9 4.8-2.5 8.6-2.5 8.6V197z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M224.5 88.9s-13.1 9.2-16.7 24.4c-3.5 15.2-2.7 36.9-2.7 36.9l2.1 49.8h2.1l-2.1-42.7s-.7-11.2 4-12.9c4.8-1.7 6.3-3 5.4-6.3-.9-3.3.3-6 2.9-7.5 2.6-1.4 2-6.3 1.2-8.2-.8-2-1.1-4.8 2.7-7 3.8-2.2 5.6-9.6 4.1-12.1-1.6-2.5-3.7-11.4-3-14.4z"
    />
    <path
      className="b"
      d="M209.7 200.3h-2.8l-2.1-50.1c0-.2-.8-21.9 2.7-37 3.5-15.1 16.6-24.5 16.8-24.6l.7-.5-.2.8c-.6 2.9 1.4 11.8 3 14.2.7 1.2.8 3.3.2 5.7-.6 2.4-2.1 5.5-4.4 6.9-2.9 1.7-3.7 3.8-2.6 6.5.7 1.8 1.6 7.1-1.4 8.7-2.5 1.4-3.5 4.1-2.7 7.1 1 3.7-1 5.1-5.6 6.7-4.4 1.6-3.8 12.5-3.8 12.6zm-2.2-.7h1.5l-2.1-42.3c0-.5-.7-11.4 4.2-13.2 4.9-1.8 6-3 5.2-5.9-.9-3.4.2-6.3 3.1-7.9 2.4-1.3 1.7-6.1 1-7.8-1.2-3.1-.3-5.6 2.9-7.4 2.1-1.3 3.5-4.2 4.1-6.5.5-2.2.4-4.1-.2-5.1-1.5-2.4-3.4-10.4-3.1-13.9-2.6 2-12.9 10.7-15.9 23.7-3.5 15.1-2.8 36.7-2.8 36.9z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M167.1 110.4s19.1 13.9 22.9 18.9c3.8 5 7.2 16.2 8 19.7.8 3.6 4.1 8.4 5.1 11.8 1.1 3.5 3.9 34.1 3.9 34.1l.2 5.1h-2.9s-1.6-25.8-2.7-30.6c-1.2-4.9-1.9-9.5-7.4-11.1-5.5-1.5-5.7-6.8-6.4-9.7-.6-2.9-1.8-2.7-4-4.2-2.3-1.4-4.1-5.1-4.4-9.7-.2-4.6-4.9-6-6.8-8-1.8-2-6.3-7.6-5.5-16.3z"
    />
    <path
      className="b"
      d="M207.5 200.3H204v-.3c0-.3-1.6-25.8-2.8-30.5l-.2-1.2c-1.1-4.4-2-8.2-6.9-9.6-5-1.5-5.8-5.9-6.4-8.8q-.1-.7-.2-1.2c-.5-2.1-1.2-2.5-2.5-3.2q-.6-.3-1.4-.8c-2.5-1.6-4.2-5.5-4.5-10-.2-3.1-2.5-4.7-4.5-6.1-.9-.5-1.7-1.1-2.2-1.7-2-2.1-6.4-7.9-5.6-16.5l.1-.6.4.4c.8.5 19.2 14 23 18.9 3.9 5.1 7.3 16.5 8 19.9.5 1.9 1.6 4.2 2.8 6.5 1 1.9 1.9 3.7 2.3 5.3 1.1 3.4 3.8 32.8 3.9 34zm-2.9-.6h2.2l-.2-4.8c0-.3-2.8-30.6-3.8-33.9-.5-1.5-1.4-3.3-2.3-5.2-1.2-2.3-2.4-4.7-2.8-6.7-.8-3.3-4.1-14.5-7.9-19.5-3.4-4.5-19.5-16.4-22.4-18.5-.5 8 3.6 13.4 5.4 15.4.5.6 1.3 1.1 2.1 1.6 2.1 1.4 4.6 3.2 4.8 6.6.3 4.3 1.9 8 4.2 9.5q.8.5 1.4.8c1.4.7 2.3 1.2 2.8 3.6q.1.5.2 1.2c.6 2.9 1.4 7 6 8.3 5.2 1.5 6.2 5.7 7.3 10.1q.1.6.2 1.1c1.2 4.6 2.6 27.3 2.8 30.4z"
      fillRule="evenodd"
    />
    <path className="b" d="M201.9 192.2h13l5.8 36.4H198z" />
    <path
      className="b"
      d="M221.1 228.9h-23.4l4-37h13.5zm-22.7-.6h21.9l-5.6-35.8h-12.4z"
      fillRule="evenodd"
    />
    <path className="a" d="m150 80 8.6 35h16.7l-8.1-35z" />
    <path
      className="b"
      d="M175.7 115.3h-17.3l-8.8-35.7h17.9zm-16.9-.6h16.1l-8-34.4h-16.5z"
      fillRule="evenodd"
    />
    <path className="a" d="M109.7 37.3h101.2l-8.1 65.5H102.6z" />
    <path
      className="b"
      d="M203.1 103.1H102.3l7.1-66.1h101.9zm-100.1-.6h99.5l8-64.8H110z"
      fillRule="evenodd"
    />
    <path className="b" d="m151.7 115-5 5h40.7l-7.1-5z" />
    <path
      className="b"
      d="M188.4 120.4h-42.5l5.7-5.7h28.8zm-41-.7h39l-6.2-4.4h-28.3z"
      fillRule="evenodd"
    />
    <path className="a" d="m114.4 41.8 91.3.4-7 55.4h-90.8z" />
    <path
      className="b"
      d="M199 97.9h-91.5l6.6-56.4 92 .4zm-90.8-.6h90.2l6.9-54.8-90.6-.3z"
      fillRule="evenodd"
    />
    <path className="b" d="m175.3 115-19.7-12.2h16.9z" />
    <path
      className="b"
      d="m175.8 115.7-21.3-13.2h18.3zm-19.1-12.6 18.1 11.2-2.6-11.2z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M158 40.9c.6 0 1.1-.5 1.1-1.1 0-.7-.5-1.2-1.1-1.2-.7 0-1.2.5-1.2 1.2 0 .6.5 1.1 1.2 1.1z"
    />
    <path
      className="b"
      d="M158 41.2c-.9 0-1.5-.6-1.5-1.4 0-.9.6-1.5 1.5-1.5.8 0 1.4.6 1.4 1.5 0 .8-.6 1.4-1.4 1.4zm0-2.3c-.5 0-.9.4-.9.9 0 .4.4.8.9.8.4 0 .8-.4.8-.8 0-.5-.4-.9-.8-.9z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="m141.3 88.7-2.4 8.7h42l-3.7-10.7s-13.1 5-19-4.3c0 0-7.9 10.2-16.9 6.3z"
    />
    <path
      className="b"
      d="M181.4 97.7h-42.9l2.6-9.4.3.1c1.4.6 2.8.9 4.1.9 6.9 0 12.4-7 12.4-7.1l.3-.3.3.3c2.3 3.8 6.2 5.6 11.4 5.5 3.9-.1 7.1-1.3 7.2-1.3l.3-.1zm-42.1-.7h41.2L177 87c-1 .4-3.8 1.2-7.1 1.3-5.2.1-9.3-1.7-11.8-5.4-.6.8-2.4 2.7-4.8 4.3-4 2.7-8.1 3.4-11.8 1.9z"
      fillRule="evenodd"
    />
    <path className="b" d="m157.3 97.4 13.1-22-25.7-1.6-5.8 23.6z" />
    <path
      className="b"
      d="M157.5 97.7h-19l6-24.3 26.4 1.8zm-18.2-.7h17.8l12.7-21.4-24.9-1.6z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M145.5 50.1s-14-6-23.9 15.4c-6.9 14.9-2.4 26.3 6.7 27 9.2.7 22.1-36.3 17.2-42.4z"
    />
    <path
      className="b"
      d="M128.6 92.8h-.3c-3.5-.3-6.5-2.1-8.3-5.3-3.2-5.4-2.7-13.5 1.3-22.2 2.8-6.1 7.9-13.9 15.9-15.9 4.8-1.1 8.3.3 8.5.4l.1.1c3.3 4.1-1.2 21.4-7.2 32.7-1.7 3.2-3.4 5.7-5 7.4q-2.7 2.8-5 2.8zM141 49.6c-1.1 0-2.3.1-3.7.5-6.1 1.5-11.4 6.9-15.4 15.5-3.9 8.4-4.4 16.4-1.4 21.6 1.7 2.9 4.5 4.7 7.9 5 2.7.2 6.2-3.3 9.7-9.9 5.7-10.8 10.3-27.9 7.2-32-.3-.1-2-.7-4.3-.7z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M165.8 49.1s14-5.9 23.9 15.4c6.9 14.9 2.4 26.3-6.8 27-9.1.8-22.1-36.3-17.1-42.4z"
    />
    <path
      className="b"
      d="M182.7 91.9q-2.3 0-5-2.8c-1.6-1.8-3.4-4.3-5.1-7.5-5.9-11.2-10.4-28.6-7.1-32.7l.1-.1c.2-.1 3.7-1.5 8.5-.3 8 2 13.1 9.7 15.9 15.9 4 8.6 4.5 16.7 1.3 22.1-1.8 3.2-4.8 5.1-8.3 5.3q-.2.1-.3.1zM166 49.3c-3.1 4.1 1.5 21.2 7.2 32 3.4 6.4 6.8 9.9 9.5 9.9h.2c3.4-.3 6.2-2 7.9-5 3-5.2 2.5-13.2-1.4-21.6-4-8.6-9.3-14-15.4-15.5-4.2-1.1-7.5 0-8 .2z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M158.2 88.4s-6.5 11.8-20.7 4.8c-15.3-7.5-9.2-24.5 2.2-26 0 0-3.5-19.9 16.8-20.4s17.8 22.9 17.8 22.9 7.9.5 9.1 10.4c1.3 9.9-4.7 13.3-12.6 14.1-7.9.8-12.6-5.8-12.6-5.8z"
    />
    <path
      className="b"
      d="M146.1 95.8c-3.3 0-6.4-1.1-8.8-2.3-6.7-3.3-10.1-9-9.1-15.3 1-5.8 5.5-10.4 11.1-11.3-.2-1.7-.9-9.3 3.5-14.7 3-3.6 7.6-5.5 13.7-5.7 5.6-.1 10 1.5 13.1 4.9 5.6 6.1 5.2 16.1 5 18 1.6.2 8 1.8 9.1 10.7.6 4.1-.1 7.3-2 9.7-2.1 2.6-5.7 4.2-10.8 4.7-6.9.7-11.5-4.1-12.7-5.5-.6.9-2.1 3.1-4.9 4.7-2.4 1.5-4.8 2.1-7.2 2.1zm11-48.6h-.6c-5.9.1-10.3 1.9-13.2 5.4-4.8 5.8-3.3 14.4-3.3 14.5v.4h-.3c-5.5.7-9.9 5.2-10.9 10.8-.9 6 2.3 11.5 8.8 14.6 5.8 2.9 11 3 15.4.3 3.3-2 4.9-4.9 4.9-4.9l.2-.5.3.4c.1.1 4.3 5.7 11.1 5.7q.6 0 1.3-.1c5-.4 8.4-1.9 10.4-4.4 1.7-2.2 2.4-5.3 1.9-9.3-1.2-9.5-8.5-10.1-8.8-10.1h-.4l.1-.3c0-.2 1.1-11.4-4.9-17.9-2.9-3.1-6.9-4.6-12-4.6z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M158.2 88.4s4.7 6.6 12.6 5.8c7.9-.8 13.9-4.2 12.6-14.1-1.2-9.9-9.1-10.4-9.1-10.4s-13.2-.5-13.3-12.3c0-2.7.9-5.8 5.2-8.3-2.4-1.5-5.6-2.4-9.7-2.3-4.9.2-8.4 1.4-11 3.3 0 0 8.3 2 8.2 7.4-.1 5.8.6 10.6-14 9.7-3.5.4-6.4 2.3-8.4 4.9q-.4.4-.7.9c-4 6.1-3.2 15.2 6.9 20.2 14.2 7 20.7-4.8 20.7-4.8z"
    />
    <path
      className="b"
      d="M146.1 95.8c-3.3 0-6.4-1.1-8.8-2.3-5.9-2.9-8-6.9-8.8-9.7-1-3.7-.3-7.7 1.8-11q.3-.4.7-.9c2.2-2.8 5.3-4.6 8.6-5h.1c6.3.4 10.1-.3 12-2 1.7-1.6 1.7-3.9 1.7-6.6v-.8q0-3.1-3.9-5.4c-2-1.2-4-1.7-4-1.7l-.7-.2.6-.4c2.8-2.1 6.5-3.2 11.1-3.3 3.9-.1 7.2.7 9.9 2.3l.5.3-.5.3c-4.5 2.6-5 5.8-5 8 0 4.7 2.2 8.2 6.4 10.3 3.2 1.5 6.5 1.7 6.5 1.7.1 0 8.2.6 9.4 10.7.6 4.1-.1 7.3-2 9.7-2.1 2.6-5.7 4.2-10.8 4.7-6.9.7-11.5-4.1-12.7-5.5-.6.9-2.1 3.1-4.8 4.7-2.5 1.5-4.9 2.1-7.3 2.1zm-6.4-28.3c-3.2.4-6.1 2.1-8.2 4.8q-.3.4-.6.9c-2.1 3.1-2.7 6.9-1.8 10.4 1.1 3.9 4 7.1 8.5 9.3 5.8 2.9 11 3 15.4.3 3.3-2 4.9-4.9 4.9-4.9l.2-.5.3.4c.1.1 4.3 5.7 11.1 5.7q.7 0 1.3-.1c5-.4 8.4-1.9 10.4-4.4 1.7-2.2 2.4-5.3 1.9-9.3-1.2-9.5-8.5-10.1-8.8-10.1-.2 0-3.4-.1-6.7-1.7-3.1-1.5-6.8-4.6-6.9-10.9 0-3.6 1.5-6.2 4.9-8.3-2.5-1.4-5.6-2-9.1-1.9-4.1.1-7.6 1-10.2 2.7 2 .7 7.8 2.9 7.8 7.6q0 .4-.1.8c0 2.7 0 5.3-1.9 7.1-2 1.9-5.9 2.5-12.4 2.1z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M163 71.5c-2.8-1.8-6.3-2.1-9.3-.7-2.3 1-4.7 2.4-4.7 4.1 0 8.2 9.2 8.2 9.2 8.2s6.5-.3 7.4-6.3c.4-2.5-.9-4.2-2.6-5.3z"
    />
    <path
      className="b"
      d="M158.2 83.4c-.1 0-2.4 0-4.7-1-2.2-1-4.8-3.1-4.8-7.5q0-2.3 4.8-4.4c3.2-1.4 6.8-1.1 9.6.7q3.3 2.2 2.8 5.6c-.9 6.3-7.6 6.6-7.7 6.6zm-.5-13.2c-1.3 0-2.7.3-3.9.9-2.9 1.2-4.4 2.6-4.4 3.8 0 3.2 1.4 5.5 4.3 6.8 2.2 1 4.5 1.1 4.5 1.1s1.6-.1 3.2-.9q3.3-1.6 3.9-5.2.4-3-2.5-4.9c-1.5-1-3.3-1.6-5.1-1.6z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M145.4 63.5c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.3-2.4-2.3-1.3 0-2.3 1-2.3 2.3 0 1.3 1 2.4 2.3 2.4z"
    />
    <path
      className="b"
      d="M145.4 63.8c-1.4 0-2.6-1.2-2.6-2.7 0-1.5 1.2-2.7 2.6-2.7 1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7zm0-4.7c-1.1 0-2 .9-2 2 0 1.2.9 2.1 2 2.1 1.2 0 2.1-.9 2.1-2.1 0-1.1-.9-2-2.1-2z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M168 63.5c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.3-2.4-2.3-1.3 0-2.3 1-2.3 2.3 0 1.3 1 2.4 2.3 2.4z"
    />
    <path
      className="b"
      d="M168 63.8c-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7zm0-4.7c-1.1 0-2 .9-2 2 0 1.2.9 2.1 2 2.1s2-.9 2-2.1c0-1.1-.9-2-2-2z"
      fillRule="evenodd"
    />
    <path className="a" d="M173 87.6s-10.4 3.7-14.8-4.5c0 0-.5 5.9-12.8 4.2" />
    <path
      className="b"
      d="M167.8 89.2h-.6c-3.8-.2-6.8-1.7-8.8-4.4-.3.5-.8 1-1.4 1.6-2.4 1.8-6.3 2.4-11.7 1.6-.4 0-.7-.4-.6-.8.1-.4.4-.7.9-.7 6 .9 9-.2 10.4-1.3 1.3-1 1.4-2.1 1.4-2.2l.3-2.4 1.1 2.1c1.7 3.1 4.6 4.8 8.4 5 3 .1 5.5-.8 5.6-.8.4-.1.8.1.9.5.2.4 0 .8-.4.9-.1.1-2.5.9-5.5.9zM53.3 57.1s12 5.2 15.6 16.9c3.6 11.8 11.8 87.5 11.8 87.5l-30.2 10.1S45.8 58 53.3 57.1z"
    />
    <path
      className="b"
      d="M50.2 172v-.4c0-.3-1.2-28.8-1.4-56.9-.2-16.6 0-29.8.6-39.4.3-5.6.7-10 1.3-13.1.6-3.6 1.4-5.3 2.5-5.4h.2c.1.1 12.2 5.4 15.8 17.1C72.8 85.6 81 160.7 81 161.5l.1.2zm3-114.5c-.9.2-2.4 3-3.2 17.9-.6 9.5-.8 22.8-.6 39.3.2 26.3 1.2 52.8 1.4 56.5l29.5-9.9c-.5-4.9-8.3-75.9-11.8-87.1-3.3-11-14.2-16.2-15.3-16.7z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="m54.9 120 56.7 17.5c4.1 1.3 7.1 5.1 7 9.5v.4c-.4 9 .6 63.9.6 63.9h-10l-11.7-57.2-51.7-11.7 4.7-22.3h4.4z"
    />
    <path
      className="b"
      d="M119.5 211.6H109l-11.8-57.2-51.8-11.8 4.8-22.9h4.7l56.8 17.5c4.3 1.3 7.3 5.3 7.2 9.8v.4c-.3 8.9.6 63.3.6 63.9zm-10-.7h9.4c-.1-4.6-1-55-.6-63.5v-.5c0-4.1-2.8-7.9-6.8-9.1l-56.7-17.5h-4l-4.7 21.8 51.6 11.7z"
      fillRule="evenodd"
    />
    <path className="b" d="m.7 228.3 36.4-104.4h5.7L11.7 228.3z" />
    <path
      className="b"
      d="M11.9 228.6H.2l36.6-105h6.5zm-10.8-.7h10.3l30.9-103.7h-5.1z"
      fillRule="evenodd"
    />
    <path className="b" d="m251.6 228.3-36.4-104.4h-5.8l31.2 104.4z" />
    <path
      className="b"
      d="M252 228.6h-11.7l-.1-.3L209 123.6h6.4l.1.2zm-11.3-.7h10.4l-36.2-103.7h-5.1z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M36.7 57.1c.6-.1 11-1.3 16.6 0 5.5 1.3 8.9 9.8 10 15.2 1 5.3 5.4 52 5.4 52l-37.8 6.4-1.5-53.1s-4.9-18.1 7.3-20.5z"
    />
    <path
      className="b"
      d="m30.5 131.1-1.4-53.4c-.1-.4-.7-2.9-.9-6-.2-3 0-7.4 2-10.6 1.4-2.3 3.6-3.8 6.4-4.3.7-.1 11.1-1.3 16.7 0 6 1.4 9.3 10.7 10.3 15.4 1 5.3 5.2 50.2 5.4 52.1v.3zm6.2-73.7-.1-.3zm-6 4c-3.6 5.9-1 16-1 16.1l.1.1 1.4 52.7 37.1-6.3C68 120 64 77.3 63 72.3c-1-4.6-4.2-13.6-9.8-14.9-5.4-1.3-15.9-.1-16.5 0-2.6.5-4.6 1.8-6 4z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M50.5 85.5c.4-.6-13.8-28.4-13.8-28.4s-12.2.9-16 6.9c-3.8 5.9-8.1 29.3-7.1 40 .9 11.1 12 18 12 18L2.1 169.4l33.7 16.7 14.7-64.5z"
    />
    <path
      className="b"
      d="m36 186.6-34.3-17 23.5-47.5c-1.7-1.1-11.1-7.9-12-18.1-.9-10.7 3.3-34.1 7.2-40.2 3.9-6.1 15.7-7 16.2-7h.2l.1.2c.1 0 3.7 7 7.1 13.9 6.1 12.2 6.8 14.2 6.8 14.6v36.2zM2.5 169.3l33.1 16.3 14.6-64V85.4c-.4-1.5-8.3-17.4-13.7-27.9-.8 0-3.4.3-6.3 1.1-3.2.9-7.3 2.5-9.2 5.5-3.9 6-8 29.4-7.1 39.9.9 10.8 11.7 17.6 11.8 17.7l.3.2z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M49 81.9 36.7 57.1c-5.1.5-7.1 1.3-7.1 1.3l9.8 15.8-1.9 2.1z"
    />
    <path
      className="b"
      d="M49.8 82.7 37 76.4l2-2.2-9.9-15.9.4-.2s2.1-.9 7.1-1.3h.2zm-11.7-6.4 10.3 5-11.8-23.8c-3.7.3-5.7.9-6.4 1.1l9.7 15.7z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="m66.8 69.3 16.7 23 10.6-5.1 8.5 15.4s-8.9 11.7-15.1 11.7c-6.1 0-13.7-6.6-13.7-6.6s-4.1-33.2-7-38.4z"
    />
    <path
      className="b"
      d="M87.5 114.7c-6.2 0-13.6-6.5-14-6.7l-.1-.1v-.1c0-.4-4-33.2-6.9-38.3l.6-.4 16.5 22.8 10.7-5.1 8.7 15.8-.1.2s-2.3 3-5.3 5.9c-4 4-7.4 6-10.1 6zm-13.4-7.1c.8.7 7.8 6.4 13.4 6.4 5.6 0 13.6-10 14.8-11.4l-8.3-15-10.6 5.2L68 71.5c2.7 8.9 5.8 33.8 6.1 36.1z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="m76.2 126.6-10.8-3.3-11.3-37.8 5.1-14.2h-3.4l.9-12.7s3.8 1.3 9.6 9.6c5.9 8.3 9.4 47.3 9.9 58.4z"
    />
    <path
      className="b"
      d="m76.5 127.1-11.4-3.6-11.3-38 5-13.9h-3.3l.9-13.4.4.1c.1.1 3.9 1.4 9.8 9.7 1.8 2.5 4.4 9.5 7 28.3 1.7 12.2 2.7 24.8 2.9 30.3zm-10.9-4 10.2 3.1c-.6-12.7-4.1-49.8-9.8-57.8-4.6-6.6-8-8.7-9.1-9.3L56.1 71h3.6l-5.3 14.6z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M86.7 139.9 49 128l-5.6 24.6 23.9 1.2 17.4 58.8 9.6-1.3-2.5-62.2s.4-7.3-5.1-9.2z"
    />
    <path
      className="b"
      d="m84.5 212.9-17.4-58.7-24.1-1.3 5.8-25.3 38 11.9c5.6 2 5.3 9.2 5.3 9.6l2.6 62.5zm-40.7-60.7 23.7 1.3.1.2 17.3 58.4 9.1-1.2-2.6-61.8c.1-.1.4-7.1-4.9-9l-37.2-11.7z"
      fillRule="evenodd"
    />
    <path className="a" d="m118.9 227.9-24.4-12-8.3.9 2.6 11.1z" />
    <path
      className="b"
      d="M120.3 228.3H88.5l-2.7-11.7 8.8-1zm-31.3-.7h28.5l-23.1-11.3-7.8.9z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M142.4 227.9c-.6-.2-23.1-12.6-23.1-12.6h-9l1.8 9.1 6.8 3.5z"
    />
    <path
      className="b"
      d="M142.4 228.3h-23.6l-7-3.6-1.9-9.7h9.5c6.2 3.5 22.5 12.4 23.1 12.6l1.7.6zm-23.5-.6H141c-4.5-2.4-19.6-10.7-21.9-12h-8.5l1.7 8.6z"
      fillRule="evenodd"
    />
    <path className="a" d="m42.4 157.2 1-4.6 23.9 1.2 1 3.4z" />
    <path
      className="b"
      d="M68.7 157.5H42l1.2-5.2 24.4 1.2zm-26-.6h25.1l-.8-2.7-23.4-1.2z"
      fillRule="evenodd"
    />
    <path className="b" d="M57.1 157.2H49v62.4h8.1z" />
    <path
      className="b"
      d="M57.4 219.9h-8.7v-63h8.7zm-8-.6h7.4v-61.7h-7.4z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M75.6 219.7H30.5c-2.3 0-4.3 1.9-4.3 4.3 0 2.3 2 4.3 4.3 4.3h45.1c2.4 0 4.3-2 4.3-4.3 0-2.4-1.9-4.3-4.3-4.3z"
    />
    <path
      className="b"
      d="M75.6 228.6H30.5c-2.5 0-4.6-2.1-4.6-4.6 0-2.5 2.1-4.6 4.6-4.6h45.1c2.6 0 4.6 2.1 4.6 4.6 0 2.5-2 4.6-4.6 4.6zM30.5 220c-2.2 0-3.9 1.8-3.9 4s1.7 3.9 3.9 3.9h45.1c2.2 0 4-1.7 4-3.9s-1.8-4-4-4z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M40 23.5s5.9-1.8 10.5 0 7.3 11.2 7.3 11.2l3.5 2.9-2.8 1.7s3.5 8.2-4.6 10.9l-3.6 6.6-3.4 12.5-10.2-12.2-.5-13.3S28.8 29.3 40 23.5z"
    />
    <path
      className="b"
      d="M47 70 36.3 57.2l-.4-13.3c-.3-.5-1.9-3.9-2.3-8-.6-5.9 1.6-10.3 6.3-12.7.3-.1 6-1.8 10.7 0 4.5 1.7 7.2 10.2 7.5 11.3l3.8 3.1-3 1.9c.3.9 1.3 4.1.1 6.9q-1.3 2.8-4.9 4l-3.5 6.5zM37 57l9.7 11.7 3.3-12 3.7-6.8h.1q3.4-1.1 4.5-3.7c1.4-3.1-.1-6.7-.1-6.7l-.1-.3 2.7-1.6-3.3-2.7v-.1c0-.1-2.7-9.3-7.1-11-4.3-1.6-9.8-.1-10.2 0-4.6 2.3-6.6 6.4-6 12 .5 4.3 2.3 7.9 2.3 7.9v.1z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M41.6 34.9s13-.2 14.6-6.6c0 0-.9-10.6-16.2-7.5-15.2 3-3.7 25.2-3.7 25.2s4.9-3.1 5.4-4.6c.5-1.4-2.8-6.4-.1-6.5z"
    />
    <path
      className="b"
      d="m36.2 46.5-.2-.3c0-.1-1.7-3.3-2.9-7.5-1.7-5.6-1.7-10.1-.2-13.2 1.3-2.7 3.7-4.4 7.1-5.1 5.6-1.1 9.9-.5 12.9 1.7 3.3 2.5 3.6 6 3.7 6.2v.1c-1.7 6.5-14.4 6.8-15 6.8q-.5 0-.6.3c-.4.5.1 2 .5 3.2.4 1.2.7 2.2.5 2.8-.5 1.5-5 4.5-5.5 4.8zm9-26q-2.4 0-5.1.6c-3.2.6-5.4 2.2-6.6 4.7-3.2 6.6 1.9 17.7 2.9 19.8 2-1.3 4.7-3.3 5-4.3.1-.4-.2-1.5-.5-2.4-.5-1.5-1-3-.5-3.8q.4-.5 1.1-.6c.2 0 12.8-.2 14.4-6.2-.1-.5-.5-3.5-3.4-5.7q-2.8-2.1-7.3-2.1z"
      fillRule="evenodd"
    />
    <path className="a" d="m86.2 216.8-1.5-4.2 9.6-1.3.2 4.6z" />
    <path
      className="b"
      d="m86 217.2-1.7-4.9 10.4-1.4.1 5.3zm-.8-4.3 1.3 3.6 7.7-.8-.1-4z"
      fillRule="evenodd"
    />
    <path className="a" d="m109.2 211.3 1.1 4h9l-.1-4z" />
    <path
      className="b"
      d="M119.6 215.7h-9.5l-1.3-4.8h10.7zm-9-.6h8.4l-.1-3.4h-9.2z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M324.3 226.2H27.1c-.2 0-.4-.2-.4-.3 0-.2.2-.4.4-.4h297.2c.2 0 .3.2.3.4 0 .1-.1.3-.3.3z"
    />
    <path
      className="a"
      d="m95.9 90.2 14.9-8.1s2.3-6.2 8.9-6.9c4.9-.6 5.8 4.8 2.4 6.9-3.3 2.1-8.4 4.3-8.4 4.3l-12 14.5z"
    />
    <path
      className="b"
      d="m101.7 101.5-6.3-11.4 15.2-8.2c.3-.9 2.9-6.4 9-7.1 2.3-.2 4 .7 4.6 2.5.7 1.8-.1 4-1.9 5.1-3.1 1.9-7.8 4-8.4 4.2zm-5.3-11.1 5.4 10 11.8-14.3c.1 0 5.1-2.2 8.4-4.2 1.7-1.1 2.1-3 1.7-4.3-.5-1.5-2-2.3-3.9-2.1-6.3.7-8.6 6.7-8.6 6.8l-.1.1h-.1z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="m46.6 110.5 22.6 3.6s6.6-2.5 11.5-1.7 7.9 7.7 7.9 7.7l-44.3-.5z"
    />
    <path
      className="b"
      d="m89.1 120.4-45.2-.4 2.4-9.9 22.8 3.7c.9-.3 7-2.5 11.7-1.7 5 .8 7.9 7.5 8.1 7.8zm-44.4-1 43.4.4c-.4-.7-1-1.8-1.9-3.1-1.2-1.6-3.1-3.6-5.5-4-4.8-.7-11.3 1.8-11.4 1.8h-.1l-22.4-3.6z"
      fillRule="evenodd"
    />
    <path className="a" d="m50.5 56.7 6.2 1.9-.9 12.7h3.4l-5.1 14.2z" />
    <path
      className="b"
      d="m54 86.9-3.9-30.7 6.9 2.2-.8 12.6h3.5zm-3.1-29.8 3.4 27 4.5-12.5h-3.3l.8-12.8z"
      fillRule="evenodd"
    />
    <path className="a" d="M243 120H15.5v3.9H243z" />
    <path
      className="b"
      d="M15.2 124.2v-4.5h228.1v4.5zm.7-.6h226.8v-3.2H15.9z"
      fillRule="evenodd"
    />
    <path className="a" d="m23.8 109.8-.8 10s5.1 5.8 19.8 6.1l2.4-9.8z" />
    <path
      className="b"
      d="M40.2 126.4c-1.1 0-2.4-.1-4-.4-3.8-.6-9.4-2.4-15.4-7.5-.2-.1-.2-.3-.1-.5.2-.1.4-.1.5 0 5.8 5 11.4 6.8 15 7.4 3.3.6 5.7.3 6.4.2l3.9-16.3c-1.7 0-8.2-.4-11.9-1.7-2.6-1-3.3-5.6-2.1-13.8.8-5.9 2.4-11.7 2.4-11.8.1-.2.3-.3.4-.2.2 0 .3.2.3.4-1.8 6.3-5.3 23.1-.7 24.8 4 1.5 11.9 1.7 12 1.7h.4l-4.2 17.4-.2.1c-.1 0-1 .2-2.7.2z"
    />
    <path className="a" d="M194.1 98.8h13.6l-1 20.8h-10.1z" />
    <path
      className="b"
      d="M207 120h-10.7l-2.6-21.5h14.4zm-10.2-.6h9.6l.9-20.2h-12.9z"
      fillRule="evenodd"
    />
    <path className="b" d="M195.8 87.2h2.2l1 11.6h-1.6z" />
    <path
      className="b"
      d="M199.4 99.2h-2.3l-1.6-12.4h2.8zm-1.7-.6h1l-1-11h-1.5z"
      fillRule="evenodd"
    />
    <path className="b" d="M203.3 87.2h2.4l-2.4 11.6h-2.4z" />
    <path
      className="b"
      d="M203.6 99.2h-3.1l2.5-12.4h3.1zm-2.3-.6h1.8l2.2-11h-1.7z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M199.2 94.9c-.2-.1-.3-.2-.3-.4 0 0 .2-4.4.2-5.2 0-.5-1-.5-1-.5-.2 0-.3-.2-.3-.4s.1-.3.3-.3c.6 0 1.6.3 1.6 1.2 0 .8-.2 5.1-.2 5.2 0 .2-.1.4-.3.4zM205 95.4q-.1 0-.1-.1c-.2 0-.3-.2-.2-.3 0-.1 1-4.2 1.1-5 .1-.4-.7-.6-.7-.6-.1-.1-.3-.2-.2-.4 0-.2.2-.3.4-.3.5.1 1.4.6 1.2 1.5-.2.8-1.2 4.7-1.2 4.9 0 .2-.2.3-.3.3zM196.6 119.6l11.1-20.8v20.8z"
    />
    <path
      className="b"
      d="M208.1 120h-12l12-22.5zm-11-.6h10.3v-19.2z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M91.8 208.2c-.2 0-.4-.1-.4-.3l-2.1-47.6c0-.1.1-.3.3-.3.2 0 .3.1.3.3l2.2 47.6c0 .2-.1.3-.3.3zM116.9 206.2c-.2 0-.3-.1-.3-.3l-1.3-51c0-.2.2-.3.3-.3.2 0 .4.1.4.3l1.2 51c0 .1-.1.3-.3.3z"
    />
    <path
      className="a"
      d="M33.8 228.3c-.2 0-.3-.2-.3-.4V224c0-.2.1-.3.3-.3.2 0 .3.1.3.3v3.9c0 .2-.1.4-.3.4zM39.3 228.3c-.2 0-.3-.2-.3-.4V224c0-.2.1-.3.3-.3.2 0 .3.1.3.3v3.9c0 .2-.1.4-.3.4zM44.3 228.5c-.2 0-.3-.1-.3-.3v-3.9c0-.2.1-.4.3-.4.2 0 .3.2.3.4v3.9c0 .2-.1.3-.3.3z"
    />
    <path
      className="b"
      d="M49 228.5c-.1 0-.3-.1-.3-.3v-3.9c0-.2.2-.4.3-.4.2 0 .4.2.4.4v3.9c0 .2-.2.3-.4.3zM49 128l5.1 25.2-10.7-.6z"
    />
    <path
      className="b"
      d="m54.5 153.5-11.5-.6 6.1-26.4zm-10.7-1.3 9.9.6-4.7-23.3z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M54.1 37.9c.6 0 1.2-.5 1.2-1.2 0-.6-.6-1.1-1.2-1.1-.7 0-1.2.5-1.2 1.1 0 .7.5 1.2 1.2 1.2z"
    />
    <path
      className="b"
      d="M54.1 38.3c-.8 0-1.5-.7-1.5-1.6 0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 .9-.7 1.6-1.5 1.6zm0-2.4c-.5 0-.9.4-.9.8 0 .5.4.9.9.9s.8-.4.8-.9c0-.4-.3-.8-.8-.8z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M214.7 228.3c-.1 0-.3-.1-.3-.3l-1.9-12.1c0-.2.1-.3.3-.4.1 0 .3.1.3.3l2 12.1c0 .2-.1.4-.3.4h-.1zM194.5 230.3c-.2 0-.4-.1-.3-.3l3.1-36.2h10.1c.2 0 .4.1.4.3 0 .2-.2.3-.4.3h-9.5l-3.1 35.6c0 .2-.1.3-.3.3z"
    />
    <path
      className="b"
      d="M216.3 36.9s-.1 0 0 0c-.7-7.4-8.3-24.9-26.5-26.5-18.2-1.5-22.7 10.7-22.7 10.7l-1.5-4.7-4 6.9c-6.1-1.9-8.2.9-8.2.9l-7.9-6.9.5 13.2c-2.9 1.4-2.9 6.5-2.9 6.5l66.2.3c2 5.7 4.3 12.7 7.6 17.1 4 5.3 13.3 11.4 14.7 8.9 2.3-3.8-6.3-6.6-10.3-14.7-2.2-4.5-5.1-12.3-5-11.7z"
    />
    <path
      className="b"
      d="M230.5 64.2c-3.1 0-10.4-5-13.8-9.6-3.2-4.1-5.3-10.4-7.3-15.9q-.1-.5-.3-1l-66.3-.3V37c0-.2 0-5.1 2.9-6.7l-.5-13.7 8.2 7.1c.7-.7 3-2.3 8.1-.8l4.2-7.2 1.5 4.5c.5-1.1 1.8-3.2 4.2-5.3 5.7-4.8 13.2-5.2 18.4-4.8 10.1.9 16.5 6.6 20.1 11.2 4.1 5.3 6.3 11.6 6.7 15.5.2.3.4.9.9 2 1 2.6 2.7 6.8 4.1 9.7 1.8 3.7 4.6 6.3 6.9 8.4 2.7 2.4 4.8 4.3 3.4 6.6-.2.3-.5.7-1.3.7h-.1zm-87-27.5 66 .3.1.2q.2.7.5 1.3c1.9 5.5 4 11.7 7.1 15.7 3.3 4.5 10.6 9.5 13.4 9.4q.6-.1.8-.4c1-1.8-.7-3.4-3.3-5.9-2.3-2.1-5.2-4.7-7.1-8.5-1.4-2.9-3.1-7.2-4.1-9.7-.4-1-.8-1.9-.8-2.1q0 .1.2.2v-.3h-.3c-.4-3.8-2.6-10-6.7-15.2-3.5-4.5-9.7-10.1-19.6-10.9-5.1-.5-12.3 0-17.9 4.6-3.2 2.8-4.4 5.8-4.4 5.8l-.3.9-1.6-4.9-3.7 6.5-.3-.1c-3-.9-5-.7-6.1-.3-1.2.4-1.7 1.1-1.7 1.1l-.2.2-.3-.2-7.3-6.4.5 12.7-.2.1c-2.3 1.1-2.7 4.8-2.7 5.9zm72.6.3h-.1.1z"
      fillRule="evenodd"
    />
    <path className="a" d="M143.6 30.6s6.6-7.2 12.5.9c0 0-5.4 7.1-12.5-.9z" />
    <path
      className="a"
      d="M150.5 34.7c-2.4 0-4.8-1.3-7.1-3.9-.1-.2-.1-.3 0-.5.1-.1 3-3.2 6.7-3 2.3.2 4.4 1.5 6.3 4 .1.1.1.3 0 .4-.1.1-2.2 2.9-5.7 3h-.2zm-6.4-4.1q3.3 3.6 6.6 3.5c2.6-.1 4.5-2 5-2.6q-2.5-3.3-5.6-3.5c-2.9-.2-5.3 1.9-6 2.6z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M148.7 31.1c0-2.1-.5-3.8-1.1-3.8-.6 0-1.2 1.7-1.2 3.7 0 2.1.4 3.8 1.1 3.8.6 0 1.2-1.7 1.2-3.7z"
    />
    <path
      className="b"
      d="M147.5 35.1c-.3 0-.8-.2-1.1-1.3-.2-.8-.3-1.7-.3-2.8 0-1.9.5-4 1.5-4 .3 0 .8.2 1.1 1.3.2.8.3 1.7.3 2.8 0 1-.1 2-.4 2.7-.3 1.1-.8 1.3-1.1 1.3zm.1-7.4c-.3 0-.9 1.1-.9 3.3 0 1 .1 1.9.3 2.6.2.6.4.9.5.9.1 0 .3-.2.5-.9s.4-1.6.4-2.5c0-1-.1-1.9-.3-2.6-.2-.6-.4-.8-.5-.8z"
      fillRule="evenodd"
    />
    <path className="a" d="M157.7 30.7s5.6-7.9 12.5-.6c0 0-4.5 7.7-12.5.6z" />
    <path
      className="a"
      d="M164.2 34q-3.3 0-6.8-3.1c-.1-.1-.1-.3 0-.4.1-.1 2.6-3.5 6.3-3.8 2.3-.2 4.6.9 6.7 3.2.1.1.1.2.1.4-.1.1-1.9 3.1-5.3 3.7h-1zm-6.1-3.3c2.5 2.1 4.8 3 7 2.6 2.6-.4 4.2-2.5 4.7-3.2q-3-3-6.1-2.7c-2.8.2-5 2.5-5.6 3.3z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M163.2 30.5c.1-2.1-.3-3.8-.9-3.8-.7-.1-1.3 1.5-1.4 3.6-.1 2.1.3 3.8.9 3.8.6 0 1.3-1.6 1.4-3.6z"
    />
    <path
      className="b"
      d="M161.8 34.4c-1 0-1.4-2.2-1.2-4.1 0-1 .2-2 .5-2.7.4-1.1.9-1.3 1.2-1.3 1 .1 1.3 2.2 1.2 4.2-.1 1.9-.7 3.9-1.7 3.9zm.5-7.4c-.1 0-.4.2-.6.8-.3.7-.4 1.6-.5 2.5-.1 2.3.4 3.5.6 3.5.3 0 .9-1.1 1.1-3.4.1-2.2-.4-3.4-.6-3.4z"
      fillRule="evenodd"
    />
    <path className="a" d="m173.3 14 4.3 12.7 2.6-15.7z" />
    <path
      className="b"
      d="m177.7 28-4.8-14.2 7.7-3.3zm-4-13.9 3.8 11.2 2.3-13.8z"
      fillRule="evenodd"
    />
    <path className="a" d="m186.9 10.2 1.4 21.3 6-20.4s-6.4-.9-7.4-.9z" />
    <path
      className="b"
      d="m188.1 33.4-1.5-23.5h.3c1 0 7.2.8 7.4.9h.4zm-.8-22.8 1.2 19.1 5.4-18.3c-1.3-.2-5.2-.7-6.6-.8z"
      fillRule="evenodd"
    />
    <path className="a" d="m204.1 15.9-10 17.2 17-9.7s-3.4-5.4-7-7.5z" />
    <path
      className="b"
      d="m193.2 34 10.7-18.6.3.2c3.7 2.2 7 7.4 7.2 7.7l.1.2zm11-17.6L195 32.3l15.7-8.9c-.7-1.1-3.5-5.1-6.5-7z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M167 25.3c-.2 0-.3-.1-.3-.3l-1.6-4.9c-.1-.2 0-.4.2-.4.1-.1.3 0 .4.2l1.6 4.9c0 .2-.1.4-.2.4l-.1.1zM185.8 37.7c-.2 0-.3-.2-.4-.3 0-.1-.3-2.4 1.1-3.8q1.1-1.2 3.3-.9c2.7.3 15.8 3.3 18.4 3.9l-.3-3.2c-.1-.1.1-.3.2-.3.2 0 .4.1.4.3l.4 4.1-.4-.2c-.2 0-15.9-3.6-18.7-4q-1.9-.2-2.9.7c-1.1 1.2-.8 3.3-.8 3.3 0 .2-.1.3-.3.3v.1zM204.9 214.7s16.2-3.4 18.1-24.1c0 0 9.5 10.9 2.2 21.6-7.9 11.7-20.5 12.6-25.3 12.3-7.1-.4-5.5-8.2 5-9.8z"
    />
    <path
      className="b"
      d="M201.3 224.9q-.9 0-1.5-.1c-2.7-.1-4.5-1.4-4.6-3.4-.1-2.7 3.1-6 9.7-7.1.1 0 4.1-.9 8.2-4.2 3.8-3.1 8.6-9 9.6-19.6v-.7l.5.6c.1.1 2.5 2.8 3.9 6.9 1.4 3.8 2.2 9.4-1.6 15.1-7.6 11.1-19.1 12.5-24.2 12.5zm21.9-33.5c-1.1 10.3-5.9 16.1-9.8 19.2-4.3 3.5-8.3 4.3-8.4 4.4-6.1.9-9.3 4-9.1 6.4 0 1.6 1.5 2.6 4 2.8 3.8.2 16.8-.1 25-12.2 3-4.3 3.5-9.2 1.6-14.5-1-2.9-2.5-5.1-3.3-6.1z"
      fillRule="evenodd"
    />
    <path className="b" d="m146.8 142.7 10.1-14.8V144s-7.3-1.5-10.1-1.3z" />
    <path
      className="b"
      d="M157.2 144.4h-.4c0-.1-7.3-1.6-10-1.3h-.7l11.1-16.2zm-9.7-2.1c2.7 0 7.6 1 9.1 1.3v-14.7l-9.2 13.4h.1z"
      fillRule="evenodd"
    />
    <path
      className="a"
      d="M125.2 143.8c1.8-3.1 13.6-.5 13.6-.5s3.3-2.5 10.6-2.2q2.6.1 4.7 1l11.4-11.6-3.1 19.5q.9 1.6 1.7 3.4c2.7 6.7 10.3 23 29.8 37.5 19.4 14.6 17.1 22.8 14.4 29.1-2.8 6.4-10.4 6.9-10.4 6.9h-31.8l3.5-5.6h11.1c-4-1.8-9.6-12.5-9.6-12.5l-8.6-4.3-7.3 22.4h-13.9l1.8-3.9 7.6-1.2 2.5-18.8s-5.3-2.5-8.8-12.3c-3.6-9.9-.5-29.4-.5-29.4s-1.6-1-4.6-.7c-16.2-1.3-16.2-13.3-14.1-16.8z"
    />
    <path
      className="b"
      d="M166.1 227.3h-.6l3.9-6.4h10.1c-3.7-2.7-8.1-10.8-8.7-11.8l-8.1-4.1-7.2 22.3h-14.7l2.1-4.6 7.5-1.2 2.5-18.3c-1.1-.6-5.7-3.5-8.8-12.4-1.5-4.2-2.1-10.4-1.6-18.5.2-5.3.9-9.8 1-10.8-.5-.2-1.9-.8-4.2-.6-8.7-.7-12.4-4.4-14-7.5-2.1-4-1.4-8.1-.4-9.8 1.8-3 12.1-1 13.8-.6.7-.5 4.1-2.5 10.7-2.2q2.5 0 4.7.9l11.9-12.2-3.2 20.5q.8 1.5 1.6 3.3c6.1 14.6 16 27.2 29.7 37.4 8.7 6.5 13.8 12.3 15.6 17.8 1.6 4.8.2 8.5-1.1 11.7-2.9 6.5-10.6 7.1-10.7 7.1zm.6-.7H198s7.4-.5 10-6.7c3-6.9 4.4-14.8-14.3-28.7-13.7-10.3-23.8-22.9-29.9-37.7q-.7-1.8-1.6-3.3l-.1-.1 3-18.7-10.8 11.1-.2-.1q-2.2-.9-4.6-1c-5.7-.2-9.3 1.3-10.5 2.2l-.1.1-.1-.1c-3.2-.7-11.9-2-13.3.4-1 1.6-1.5 5.6.4 9.1 1.5 2.9 5.1 6.5 13.5 7.1 3-.2 4.6.8 4.7.8l.2.2-.1.2c0 .2-3 19.5.5 29.2 1.8 5 4.1 8 5.7 9.7 1.7 1.8 3 2.4 3 2.4l.2.1v.3L151 222l-7.6 1.3-1.5 3.3H155l7.4-22.6 8.9 4.6.1.1c0 .1 5.6 10.6 9.5 12.3l1.5.6h-12.6zm-41.5-82.8z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M144.5 150.4c1.1 0 2.1-.9 2.1-2s-1-2-2.1-2-2 .9-2 2 .9 2 2 2z"
    />
    <path
      className="b"
      d="M144.5 150.7c-.6 0-1.2-.2-1.6-.7-.5-.4-.7-1-.7-1.6 0-.6.2-1.2.7-1.6.9-.9 2.4-.9 3.3 0 .4.4.7 1 .7 1.6 0 .6-.3 1.2-.7 1.6-.5.5-1.1.7-1.7.7zm-1.2-3.5c-.3.4-.5.8-.5 1.2 0 .4.2.9.5 1.2.7.6 1.8.6 2.4 0 .3-.3.5-.8.5-1.2 0-.4-.2-.8-.5-1.2-.6-.6-1.7-.6-2.4 0z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M132.4 142.4s2.4 4.5.4 6.1c-1.8 1.5-6 .8-7.8-1.7-1.9-2.5 2.1-5.6 7.4-4.4z"
    />
    <path
      className="b"
      d="M130.2 149.6q-.5 0-.9-.1c-1.9-.2-3.6-1.2-4.6-2.5-.6-.9-.7-2 0-2.9 1.4-2.2 5-2.7 7.8-2h.1l.1.2c.1.2 2.4 4.8.3 6.4-.7.6-1.7.9-2.8.9zm-.2-7.2c-2.3 0-4.1.9-4.8 2-.3.5-.6 1.3.1 2.3.8 1.1 2.3 1.9 4 2.2 1.4.2 2.6-.1 3.3-.7 1.4-1.1.2-4.2-.4-5.5q-1.1-.3-2.2-.3z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="m149.3 199.9-3.3 19.5-8.3 2.9-1.5 4.6h5.1l1.8-3.9 7.6-1.2 2.5-18.8s-2.2-1.1-3.9-3.1z"
    />
    <path
      className="b"
      d="M141.5 227.3h-5.8l1.8-5.2 8.2-3 3.4-20 .4.6c1.6 1.9 3.8 3 3.8 3l.3.1-.1.3L151 222l-7.7 1.3zm-4.9-.7h4.5l1.8-3.9 7.5-1.2 2.4-18.3c-.5-.3-2-1.2-3.3-2.6l-3.3 19-8.2 3z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M168.3 207.1 167 221l-5.7 1.3-.9 4.6h5.7l3.5-5.6 10.5-.1s-6-5.6-7.7-12.3z"
    />
    <path
      className="b"
      d="M166.3 227.3h-6.2l.9-5.2 5.7-1.3 1.3-14.2 4.7 2.1v.1c1.7 6.5 7.6 12.1 7.7 12.1l.6.6-11.2.1zm-5.4-.7h5l3.6-5.6 9.9-.1c-1.6-1.6-5.8-6.2-7.2-11.7l-3.6-1.6-1.2 13.7-5.8 1.3z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M165.3 156.6s-11.1 6.7-21.4 4.7l-.5 4.9s21.2 2 23.7-5.9z"
    />
    <path
      className="b"
      d="M149 166.8c-3.3 0-5.6-.2-5.7-.2l-.3-.1.6-5.6.3.1c10.1 1.9 21.1-4.6 21.3-4.6l.3-.2 1.9 4.1v.1c-1.1 3.4-5.5 5.5-13.1 6.2-1.9.1-3.7.2-5.3.2zm-5.3-.8c2.7.2 20.6 1.2 23-5.7l-1.5-3.2c-1.9 1-11.8 6.2-21.1 4.6z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M142.5 172.4c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.8 0-3.2 1.4-3.2 3.1 0 1.7 1.4 3.1 3.2 3.1z"
    />
    <path
      className="b"
      d="M142.5 172.7c-2 0-3.5-1.5-3.5-3.4 0-1.8 1.5-3.4 3.5-3.4 1.9 0 3.5 1.6 3.5 3.4 0 1.9-1.6 3.4-3.5 3.4zm0-6.1c-1.6 0-2.9 1.2-2.9 2.7 0 1.6 1.3 2.8 2.9 2.8 1.5 0 2.8-1.2 2.8-2.8 0-1.5-1.3-2.7-2.8-2.7z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="M172.4 209.3c-.2 0-.3-.1-.3-.3 0-.1-.4-1.9-.4-4.2 0-3.1.9-5.6 2.3-7.4 1.9-2.2 4.7-3.2 8.5-3 .2 0 .3.1.3.3 0 .2-.1.3-.3.3-3.6-.2-6.3.8-8 2.8-3.3 4-1.8 11-1.8 11.1.1.1-.1.3-.2.3l-.1.1z"
    />
    <path className="b" d="m172.1 209-2.5 12.3 11.8.1s-6.3-2.6-9.3-12.4z" />
    <path
      className="b"
      d="m183.1 221.8-13.9-.2 2.9-13.9.3 1.2c1.5 5 3.9 8 5.7 9.7 1.9 1.9 3.4 2.5 3.5 2.5zM170 221l10.1.1c-1.9-1.2-5.7-4.2-8-10.7z"
      fillRule="evenodd"
    />
    <path
      className="b"
      d="m153.2 203-5.3 17.5-6.6 2.5-2.2 4.1 2.7-.3 1.6-3.6 7.3-1.4z"
    />
    <path
      className="b"
      d="m138.6 227.5 2.5-4.8 6.5-2.4 5.3-17.4.6.2L151 222l-7.4 1.4-1.5 3.7zm3-4.3-1.9 3.5 1.9-.3 1.5-3.6 7.3-1.4 1.9-14.5-4.1 13.8z"
      fillRule="evenodd"
    />
  </svg>
)

export default SignInImage
