/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { KibIngressCard } from '@chewy/kib-cards-react'
import { KibCheckIcon, KibEditIcon } from '@chewy/kib-icons-react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { GET_APPOINTMENT_DOCUMENTS } from '../../../api/queries/appointment'
import { GET_SIGNER_DATA } from '../../../api/queries/client'
import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { Business } from '../../../types/dto/Business'
import { Document } from '../../../types/dto/Document'
import { SaveFormHandle, SaveFormProps } from '../../../types/forms'
import { spacing } from '../../../utils/spacing'
import Icon from '../icon/Icon'
import DocumentModal from '../modal/DocumentModal'
import Spinner from '../spinner/Spinner'
import Text, { TextVariant } from '../typography/Text/Text'

const ID = 'documentsForm'

type CheckInDocumentsFormProps = SaveFormProps & {
  appointmentId?: string
  business?: Business
  initialCompletionMap: Record<string, boolean>
  onCompletetionMapChange?: (completionMap: Record<string, boolean>) => void
}

type ModalState = {
  document?: Document
  opened: boolean
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  spinner: css({
    marginTop: spacing(Spacing.S4),
    alignSelf: 'center',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  documentsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(Spacing.S2),
    padding: spacing(Spacing.S4, 0),
  }),
  documentContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: spacing(Spacing.S2),
    padding: spacing(Spacing.S4, Spacing.S2),
    '&:hover': css({
      backgroundColor: ColorVariables.UI_BG_OVERLAY_PRIMARY,
      cursor: 'pointer',
    }),
  }),
  icon: css({
    width: 14,
    height: 14,
    color: ColorVariables.UI_BG_PRIMARY,
  }),
}

const CheckInDocumentsForm = forwardRef<
SaveFormHandle,
CheckInDocumentsFormProps
>(function CheckInDocumentsForm(
  {
    appointmentId,
    business,
    initialCompletionMap,
    onReady,
    onSaveCompleted,
    onSaveLoadingStateChange,
    onCompletetionMapChange,
    ...rest
  },
  ref,
) {
  const { t } = useTranslation('Home')

  const [completionMap, setCompletionMap] =
    useState<Record<string, boolean>>(initialCompletionMap)
  const [modalState, setModalState] = useState<ModalState>({ opened: false })

  const { data: { me } = {}, loading: meLoading } = useQuery(GET_SIGNER_DATA)
  const {
    data: { appointment: { documents = [] } = {} } = {},
    loading: documentsLoading,
  } = useQuery(GET_APPOINTMENT_DOCUMENTS, {
    variables: {
      id: appointmentId,
    },
  })

  useEffect(() => {
    if (!documentsLoading) {
      const isReady =
        R.all(Boolean, R.values(completionMap))
        && documents.length === R.keys(completionMap).length

      if (isReady) {
        onReady?.(ID)
      }
    }

    onCompletetionMapChange?.(completionMap)
  }, [documentsLoading, completionMap])

  useEffect(() => {
    if (!R.isEmpty(documents)) {
      setCompletionMap(
        documents.reduce((acc: Record<string, boolean>, document: Document) => {
          if (document.signed) {
            return {
              ...acc,
              [document.id]: true,
            }
          }

          return acc
        }, completionMap),
      )
    }
  }, [documents])

  const loading = meLoading || documentsLoading

  const handleSave = () => {
    onSaveCompleted?.(ID)
  }

  useImperativeHandle(ref, () => ({
    save: handleSave,
  }))

  return (
    <div css={styles.container} {...rest}>
      {loading && <Spinner css={styles.spinner} />}

      {!R.isEmpty(documents) && (
        <Text variant={TextVariant.SECTION_1}>{t('Home:SIGN_FORMS')}</Text>
      )}

      <div css={styles.documentsContainer}>
        {documents?.map((document: Document) => (
          <KibIngressCard
            id="cic-doc"
            image={
              <Icon
                circle
                Component={
                  completionMap[document.id] ? (
                    <KibCheckIcon css={styles.icon} />
                  ) : (
                    <KibEditIcon css={styles.icon} />
                  )
                }
                css={css({
                  width: 24,
                  height: 24,
                  backgroundColor: completionMap[document.id]
                    ? ColorVariables.ICON_FRESH
                    : ColorVariables.UI_BG_BRAND_PRIMARY,
                })}
              />
            }
            key={document?.id}
            title={document?.name}
            onClick={() => setModalState({ opened: true, document })}
          />
        ))}
      </div>

      {modalState?.document && (
        <DocumentModal
          appointmentId={appointmentId}
          business={business}
          document={modalState.document}
          open={modalState?.opened}
          signer={me}
          onClose={() => setModalState({ opened: false })}
          onSaveCompleted={id =>
            setCompletionMap({
              ...completionMap,
              ...(id ? { [id]: true } : {}),
            })
          }
        />
      )}
    </div>
  )
})

export default CheckInDocumentsForm
