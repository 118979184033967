/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'

import { ENVIRONMENT_VARIABLES } from '../../../constants/environmentVariables'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { getEnvironment } from '../../../utils/environment'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis } from '../../common/button/Button'
import ClientDetailsCard from '../../common/client/ClientDetailsCard'

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(Spacing.S4, 0, Spacing.S8, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S6, Spacing.S8, 0),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, Spacing.S2),
    }),
  }),
  signOutButton: css({
    marginTop: spacing(Spacing.S4),
  }),
}

const AccountDetailsFragment = () => {
  const { t } = useTranslation('Auth')
  const { logout } = useAuth0()

  const handleLogout = () => {
    logout({
      clientId: ENVIRONMENT_VARIABLES[getEnvironment()].AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: `${window.location.origin}/auth/sign-out`,
      },
    })
  }

  return (
    <div css={styles.container}>
      <ClientDetailsCard />

      {mediaQueryMatches(MediaQuery.MAX_SM) && (
        <Button
          css={styles.signOutButton}
          emphasis={ButtonEmphasis.TERTIARY}
          id="cdf-sign-out"
          onClick={handleLogout}
        >
          {t('Auth:SIGN_OUT')}
        </Button>
      )}
    </div>
  )
}

export default AccountDetailsFragment
