import gql from 'graphql-tag'

export const CREATE_CHAT = gql`
  mutation CreateChat($input: CreateBoopChatInput!) {
    createChat(input: $input) {
      id
      business {
        id
        name
        logo
      }
      client {
        id
        firstName
        lastName
        photo
        photoThumbnail
      }
      patient {
        id
        name
        photo
        photoThumbnail
      }
      channelSID
      title
      isClosed
      messagesCount
      messages(offset: 0, limit: 1) {
        data {
          id
          createdAt
          body
          files {
            id
            name
          }
        }
      }
    }
  }
`

export const UPDATE_CHAT = gql`
  mutation UPDATE_CHAT($id: ID!, $input: UpdateBoopChatInput!) {
    updateChat(id: $id, input: $input) {
      id
      isClosed
    }
  }
`
