/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import Button, { ButtonEmphasis } from '../button/Button'
import SignatureView, { SignatureViewHandle } from '../signature/SignatureView'
import Modal, { ModalProps } from './Modal'

type SignatureModalProps = ModalProps & {
  onSigned?: (data: string) => void
}

const styles = {
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
}

const SignatureModal = ({ onSigned, ...rest }: SignatureModalProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const [changed, setChanged] = useState(false)

  const signRef = useRef<SignatureViewHandle>(null)

  const handleSign = (close: () => void) => {
    onSigned?.(signRef?.current?.getData() || '')
    close()
  }

  const handleSignatureEdited = () => {
    setChanged(signRef?.current?.getIsValid() || false)
  }

  const clear = () => {
    signRef?.current?.clear()
    setChanged(false)
  }

  return (
    <Modal
      {...rest}
      size="default"
      title={t('Home:PLEASE_SIGN_BELOW')}
      // eslint-disable-next-line
      controls={({ close }) => (
        <div css={styles.buttonsContainer}>
          <Button
            emphasis={ButtonEmphasis.SECONDARY}
            id="sm-clear"
            onClick={clear}
          >
            {t('Common:CLEAR')}
          </Button>

          <Button
            disabled={!changed}
            id="sm-sign"
            onClick={() => handleSign(close)}
          >
            {t('Common:SIGN')}
          </Button>
        </div>
      )}
      onClose={() => {
        clear()
        rest.onClose?.()
      }}
    >
      <SignatureView ref={signRef} onEdit={handleSignatureEdited} />
    </Modal>
  )
}

export default SignatureModal
