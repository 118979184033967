/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_BUSINESS_CARD_DATA } from '../../../api/queries/client'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Business } from '../../../types/dto/Business'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import BusinessCard from '../../common/business/BusinessCard'
import BusinessCardSkeleton from '../../common/business/BusinessCardSkeleton'

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: spacing(Spacing.S4),
    padding: spacing(Spacing.S4, 0, Spacing.S8, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, 0, 0, Spacing.S6),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0),
    }),
  }),
}

const BusinessFragment = () => {
  const navigate = useNavigate()

  const { data: { me } = {}, loading } = useQuery(
    GET_BUSINESS_CARD_DATA,
  )

  const handleBook = (businessId: string) => {
    navigate('/booking', { state: { businessId } })
  }

  return (
    <div css={styles.container}>
      {loading ? (
        <BusinessCardSkeleton />
      ) : (
        me?.businesses.map((business: Business, index: number) => (
          <BusinessCard
            business={business}
            index={index + 1}
            key={business.id}
            onBook={handleBook}
          />
        ))
      )}
    </div>
  )
}

export default BusinessFragment
