/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_CHATS } from '../../../api/queries/chat'
import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { CONVERSATION_ADDED, MESSAGE_ADDED } from '../../../constants/twilio'
import { Chat } from '../../../types/dto/Chat'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import ChatClientContext from '../../common/chat/ChatClientContext'
import ConversationItem from '../../common/chat/ConversationItem'
import Pager from '../../common/navigation/Pager'
import Spinner from '../../common/spinner/Spinner'

export const BATCH_SIZE = 10

type ChatData = {
  data?: Chat[]
  totalCount?: number
}

type ChatListResponse = {
  chats?: ChatData
}

type ChatListFragmentProps = {
  onChatSelected: (chat: Chat) => void
  onChatsLoaded: (chats?: Chat[]) => void
  selectedChat?: Chat
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: spacing(Spacing.S4),
    padding: spacing(Spacing.S4, 0, 0, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, 0, 0, Spacing.S6),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, Spacing.S2, 0, Spacing.S2),
    }),
  }),
  spinner: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    margin: spacing(Spacing.S4, 0),
  }),
  pager: css({
    marginTop: spacing(Spacing.S4),
    '.kib-pagination-new__list-item--previous': {
      display: 'none',
    },
    '.kib-pagination-new__list-item--next': {
      display: 'none',
    },
  }),
}

const ChatListFragment = ({
  selectedChat,
  onChatSelected,
  onChatsLoaded,
  ...rest
}: ChatListFragmentProps) => {
  const client = useContext(ChatClientContext)

  const [currentPage, setCurrentPage] = useState(1)

  const {
    data: { chats } = {},
    loading: chatsLoading,
    refetch,
  } = useQuery<ChatListResponse>(GET_CHATS, {
    variables: { offset: (currentPage - 1) * BATCH_SIZE, limit: BATCH_SIZE },
  })

  const hasChats = chats?.data && chats?.data?.length > 0

  const handleNewMessage = () => {
    refetch()
  }

  const handleNewConversation = () => {
    setTimeout(() => {
      refetch()
    }, 3000)
  }

  useEffect(() => {
    if (client) {
      client.on(MESSAGE_ADDED, handleNewMessage)
      client.on(CONVERSATION_ADDED, handleNewConversation)
    }

    return () => {
      client?.off(MESSAGE_ADDED, handleNewMessage)
      client?.off(CONVERSATION_ADDED, handleNewConversation)
    }
  }, [client])

  useEffect(() => {
    if (chats) {
      onChatsLoaded(chats?.data)
    }
  }, [chats])

  const totalPages = Math.ceil((chats?.totalCount || 0) / BATCH_SIZE)

  return (
    <div css={styles.container} {...rest}>
      {chatsLoading && !hasChats && <Spinner css={styles.spinner} />}

      {chats?.data?.map((chat: Chat) => (
        <ConversationItem
          chat={chat}
          client={client}
          id="clf-ci"
          key={chat?.id}
          selected={chat?.id === selectedChat?.id}
          onChatSelected={onChatSelected}
        />
      ))}

      {totalPages > 1 && (
        <Pager
          css={styles.pager}
          current={currentPage}
          pageSlots={5}
          total={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  )
}

export default ChatListFragment
