import { gql } from '@apollo/client/core'

export const SCHEDULE_APPOINTMENT = gql`
  mutation ScheduleAppointment($input: CreateAppointmentInput!) {
    scheduleAppointment(input: $input) {
      id
    }
  }
`

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($businessId: ID!, $appointmentId: ID!) {
    cancelAppointment(businessId: $businessId, appointmentId: $appointmentId) {
      id
    }
  }
`

export const CHECK_IN = gql`
  mutation CheckIn($businessId: ID!, $appointmentId: ID!, $arrived: Boolean) {
    checkIn(businessId: $businessId, appointmentId: $appointmentId, arrived: $arrived) {
      id
      state {
        appointmentId
        arrived
        checkedIn
      }
      patient {
        id
        needsUpdate
      }
      hasDocuments
      hasQuestions
    }
  }
`

export const SAVE_QUESTION_ANSWERS = gql`
  mutation saveQuestionAnswers($businessId: ID!, $appointmentId: ID!, $input: [AnswerInput!]!) {
    saveQuestionAnswers(businessId: $businessId, appointmentId: $appointmentId, input: $input)
  }
`

export const SIGN_DOCUMENT = gql`
  mutation SignDocument($businessId: ID!, $id: ID!, $body: String!) {
    signDocument(businessId: $businessId, id: $id, body: $body) {
      id
      name
      file
      signed
    }
  }
`
