/** @jsxImportSource @emotion/react */
import { KibIngressCard } from '@chewy/kib-cards-react/legacy'
import { css } from '@emotion/react'

import { ColorVariables } from '../../constants/colors'
import { MediaQuery } from '../../constants/mediaQuery'
import { Spacing } from '../../constants/spacing'
import { Task } from '../../types/dto/Task'
import { mediaQueryMatches } from '../../utils/mediaQuery'
import { spacing } from '../../utils/spacing'
import {
  getTaskRecurrencePeriodLabel,
  getTaskStateLabel,
} from '../../utils/task'
import Card from '../common/card/Card/Card'
import Avatar from '../common/icon/Avatar'
import Tag from '../common/tag/Tag'

type TaskListItemProps = {
  id?: string
  onTaskSelected: (task: Task) => void
  selected?: boolean
  task: Task
}

const styles = {
  container: css({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'visible',
    padding: spacing(Spacing.S2),
    '&:hover': {
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
      cursor: 'pointer',
    },
  }),
  selectedContainer: css({
    border: `2px solid ${ColorVariables.UI_BG_BRAND_PRIMARY}`,
  }),
  header: css({
    display: 'flex',
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
    top: 0,
    left: 0,
    justifyContent: 'space-between',
    height: 20,
  }),
  lastMessageTimeContainer: css({
    display: 'flex',
    alignItems: 'center',
    columnGap: spacing(Spacing.S2),
  }),
  card: css({
    pointerEvents: 'none',
    marginTop: spacing(Spacing.S5),
    zIndex: 0,
    '.kib-ingress-card__content': {
      width: 0,
      flexGrow: 1,
      flex: 1,
    },
    '.kib-ingress-card__image': {
      alignSelf: 'center',
    },
  }),
  selectedCard: css({
    '.kib-ingress-card__title': {
      color: ColorVariables.UI_BG_BRAND_PRIMARY,
    },
  }),
  label: css({
    borderRadius: 0,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
  }),
}

const TaskListItem = ({
  id,
  task,
  selected,
  onTaskSelected,
  ...rest
}: TaskListItemProps) => {
  const title = task?.name || ''
  const period = getTaskRecurrencePeriodLabel(task)
  const photo = task?.patient?.photoThumbnail || task?.patient?.photo
  const avatarImageObjectFit = task?.patient ? 'cover' : 'contain'
  const avatarBackgroundColor = task?.patient
    ? ColorVariables.UI_BG_02
    : ColorVariables.UI_BG_PRIMARY
  const taskLabel = getTaskStateLabel(task)

  const showSelected = selected && mediaQueryMatches(MediaQuery.MIN_MD)

  return (
    <Card
      css={[styles.container, showSelected && styles.selectedContainer]}
      id={id}
      {...rest}
      onClick={() => onTaskSelected(task)}
    >
      <div css={styles.header}>
        <Tag css={styles.label} theme={taskLabel.theme}>
          {taskLabel.title}
        </Tag>
      </div>

      <KibIngressCard
        css={[styles.card, showSelected && styles.selectedCard]}
        image={
          <Avatar
            avatarBackgroundColor={avatarBackgroundColor}
            avatarSize={48}
            imageStyle={css({ objectFit: avatarImageObjectFit })}
            photo={photo}
          />
        }
        subtitle={period || ' '}
        tabIndex={-1}
        title={title}
        onClick={() => {}}
      />
    </Card>
  )
}

export default TaskListItem
