import { gql } from '@apollo/client/core'

export const GET_CLIENT_ID = gql`
  query GetClientId {
    me {
      id
    }
  }
`

export const CLIENT_AUTH = gql`
  query ClientAuth {
    me {
      id
      businesses {
        id
      }
    }
  }
`

export const SEARCH_CLIENT = gql`
  query SearchClient {
    search {
      id
      businesses {
        id
      }
    }
  }
`

export const GET_ACCOUNT_CLIENT_DATA = gql`
  query GetAccountClientData {
    me {
      id
      firstName
      lastName
      photo
      photoThumbnail
    }
  }
`

export const GET_ACCOUNT_CLIENT_DETAILS_DATA = gql`
  query GetAccountClientDetailsData {
    me {
      id
      email
      mobilePhone
      homePhone
      workPhone
      otherPhone
      address
      suite
      city
      country
      county
      state
      zip
      emergencyContact {
        firstName
        lastName
        mobilePhone
      }
    }
  }
`

export const GET_BUSINESS_CARD_DATA = gql`
  query GetBusinessCardData {
    me {
      id
      businesses {
        id
        name
        logo
        primaryPhone
        timezone
        website
        address
        suite
        city
        state
        zip
        openingHours {
          dayOfWeek
          workingHours {
            from
            to
          }
        }
      }
    }
  }
`

export const GET_DASHBOARD_PATIENTS = gql`
  query GetDashboardPatients {
    me {
      id
      needsUpdate
      patients {
        id
        name
        photo
        photoThumbnail
      }
    }
  }
`

export const GET_BOOKING_PATIENTS = gql`
  query GetBookingPatients {
    me {
      id
      patients {
        id
        name
        photo
        photoThumbnail
        businesses {
          id
        }
      }
    }
  }
`

export const GET_BOOKING_BUSINESSES = gql`
  query GetBookingBusinesses {
    me {
      id
      businesses {
        id
        name
        logo
        timezone
      }
    }
  }
`

export const GET_SIGNER_DATA = gql`
  query GetSignerData {
    me {
      id
      firstName
      lastName
    }
  }
`

export const GET_NEW_CHAT_CLIENT_INFO = gql`
  query GetNewChatClientInfo {
    me {
      id
      businesses {
        id
        name
      }
      patients {
        id
        name
        photo
        photoThumbnail
        businesses {
          id
        }
      }
    }
  }
`

export const GET_NEW_TASK_CLIENT_INFO = gql`
  query GetNewTaskClientInfo {
    me {
      id
      businesses {
        id
        name
      }
      patients {
        id
        name
        photo
        photoThumbnail
        businesses {
          id
        }
      }
    }
  }
`
