/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { UPDATE_CONTACT_INFO } from '../../../api/mutations/client'
import { GET_ACCOUNT_CLIENT_DETAILS_DATA } from '../../../api/queries/client'
import { ColorVariables } from '../../../constants/colors'
import { FormatPattern } from '../../../constants/format'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Client } from '../../../types/dto/Client'
import { SaveFormHandle, SaveFormProps } from '../../../types/forms'
import { distinct } from '../../../utils'
import {
  formatPhoneNumberWithCountryCode,
  formatPhoneNumberWithoutCountryCode,
} from '../../../utils/format'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import { isValidPhone } from '../../../utils/validation'
import Button, { ButtonEmphasis, ButtonTheme } from '../button/Button'
import EditIcon from '../icon/EditIcon'
import PhoneInput from '../input/PhoneInput'
import Text, { TextVariant } from '../typography/Text/Text'
import InfoFormItem from './item/InfoFormItem'

const ID = 'clientContact'

type PhoneType = {
  set: (value: string) => void
  title: string
  value?: string
}

type ClientContactInformationFormProps = SaveFormProps & {
  alwaysEditMode?: boolean
  client: Client
  hideBottomDivider?: boolean
  hideEditButtons?: boolean
  hideEmail?: boolean
  loading?: boolean
}

const styles = {
  container: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderBottom: `1px solid ${ColorVariables.UI_BG_04}`,
      padding: spacing(Spacing.S2, 0),
    }),
  }),
  hiddenBorderBottom: css({
    ...mediaQuery(MediaQuery.MIN_XS, {
      borderBottom: 'none',
    }),
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  editModeContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: spacing(Spacing.S2),
    padding: spacing(Spacing.S2, 0),
    ...mediaQuery(MediaQuery.MIN_MD, {
      borderBottom: `1px solid ${ColorVariables.UI_BG_04}`,
    }),
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S2),
  }),
  saveButton: css({
    marginRight: spacing(Spacing.S2),
  }),
  editableInput: css({
    width: '100%',
    ...mediaQuery(MediaQuery.MIN_MD, {
      width: '49%',
    }),
  }),
}

const validate = (phones: Array<PhoneType>) =>
  phones.reduce((acc, phone) => {
    const isValid = !phone.value || isValidPhone(phone.value)
    return {
      ...acc,
      [phone.title]: isValid,
    }
  }, {})

const ClientContactInformationForm = forwardRef<
SaveFormHandle,
ClientContactInformationFormProps
>(function ClientContactInformationForm(
  {
    client,
    loading,
    alwaysEditMode,
    hideEditButtons,
    hideBottomDivider,
    hideEmail,
    onSaveCompleted,
    onSaveLoadingStateChange,
    ...rest
  },
  ref,
) {
  const { t } = useTranslation(['Account', 'Common'])

  const [mobilePhone, setMobilePhone] = useState<string | undefined>()
  const [homePhone, setHomePhone] = useState<string | undefined>()
  const [workPhone, setWorkPhone] = useState<string | undefined>()
  const [otherPhone, setOtherPhone] = useState<string | undefined>()

  const [validation, setValidation] = useState<Record<string, boolean>>()
  const [triedToSave, setTriedToSave] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [changed, setChanged] = useState(false)

  const [updateContactInfo, { loading: updateLoading }] = useMutation(
    UPDATE_CONTACT_INFO,
    {
      refetchQueries: [{ query: GET_ACCOUNT_CLIENT_DETAILS_DATA }],
      onCompleted: () => {
        if (onSaveCompleted) {
          onSaveCompleted(ID)
        }

        setEditMode(false)
      },
    },
  )

  const phones: Array<PhoneType> = [
    {
      title: t('Account:MOBILE_PHONE'),
      value: mobilePhone,
      set: setMobilePhone,
    },
    {
      title: t('Account:HOME_PHONE'),
      value: homePhone,
      set: setHomePhone,
    },
    {
      title: t('Account:WORK_PHONE'),
      value: workPhone,
      set: setWorkPhone,
    },
    {
      title: t('Account:OTHER_PHONE'),
      value: otherPhone,
      set: setOtherPhone,
    },
  ]

  const isInEditMode = alwaysEditMode || editMode

  const updatePhoneNumber = R.curry((setNumber, number) =>
    setNumber(number ? formatPhoneNumberWithCountryCode(number) : ''),
  )

  const setInitialClientData = () => {
    setMobilePhone(client?.mobilePhone)
    setHomePhone(client?.homePhone)
    setWorkPhone(client?.workPhone)
    setOtherPhone(client?.otherPhone)
    setValidation(undefined)
    setTriedToSave(false)
  }

  useEffect(() => {
    setInitialClientData()
  }, [client])

  useEffect(() => {
    if (onSaveLoadingStateChange) {
      onSaveLoadingStateChange({ id: ID, loading: updateLoading })
    }
  }, [updateLoading])

  useEffect(() => {
    setChanged(
      client?.mobilePhone !== mobilePhone
        || client?.homePhone !== homePhone
        || client?.workPhone !== workPhone
        || client?.otherPhone !== otherPhone,
    )
  }, [mobilePhone, homePhone, workPhone, otherPhone])

  const handleSave = async () => {
    setTriedToSave(true)

    const validationData = validate(phones)

    if (R.all(Boolean, Object.values(validationData))) {
      const updatedClient = {
        mobilePhone: mobilePhone || '',
        homePhone: homePhone || '',
        workPhone: workPhone || '',
        otherPhone: otherPhone || '',
      }

      const clientDistinction = distinct(client, updatedClient)

      if (!R.isEmpty(clientDistinction)) {
        updateContactInfo({ variables: { input: updatedClient } })
      } else {
        setInitialClientData()
        setEditMode(false)

        if (onSaveCompleted) {
          onSaveCompleted(ID)
        }
      }
    } else {
      setValidation(validationData)
    }
  }

  useImperativeHandle(ref, () => ({
    save: () => handleSave(),
  }))

  return (
    <div
      css={[styles.container, hideBottomDivider && styles.hiddenBorderBottom]}
      {...rest}
    >
      <div css={styles.header}>
        <Text variant={TextVariant.SECTION_1}>
          {t('Account:CONTACT_INFORMATION')}
        </Text>

        {!isInEditMode && !loading && (
          <EditIcon id="cdc-ci-edit" onEdit={() => setEditMode(true)} />
        )}
      </div>

      {!hideEmail && (
        <InfoFormItem
          hideDivider={mediaQueryMatches(MediaQuery.MAX_SM)}
          loading={loading}
          title={t('Common:EMAIL_ADDRESS')}
          value={client?.email}
        />
      )}

      {!isInEditMode && (
        <div>
          {phones.map(phone =>
            loading || phone.value ? (
              <InfoFormItem
                format={FormatPattern.PHONE}
                hideDivider={mediaQueryMatches(MediaQuery.MAX_SM)}
                key={phone.title}
                loading={loading}
                title={phone.title}
                value={formatPhoneNumberWithoutCountryCode(phone.value)}
              />
            ) : undefined,
          )}
        </div>
      )}

      {isInEditMode && (
        <div
          css={[
            styles.editModeContainer,
            hideBottomDivider && styles.hiddenBorderBottom,
          ]}
        >
          {phones.map((phone, index) => (
            <PhoneInput
              css={styles.editableInput}
              id={`cdc-ci-phone${index}`}
              invalid={validation && !validation[phone.title]}
              key={phone.title}
              label={phone.title}
              messagesContent={t('Account:INVALID_PHONE_NUMBER')}
              value={formatPhoneNumberWithoutCountryCode(phone.value)}
              onChangeText={value => {
                updatePhoneNumber(phone.set, value)
                if (triedToSave) {
                  setValidation({ ...validation, [phone.title]: true })
                }
              }}
            />
          ))}

          {!hideEditButtons && (
            <div css={styles.buttonContainer}>
              <Button
                css={styles.saveButton}
                disabled={!changed || updateLoading}
                id="cdc-ci-save"
                loading={updateLoading}
                onClick={handleSave}
              >
                {t('Common:SAVE')}
              </Button>
              <Button
                emphasis={ButtonEmphasis.TERTIARY}
                id="cdc-ci-can"
                theme={ButtonTheme.UTILITY}
                onClick={() => {
                  if (!updateLoading) {
                    setEditMode(false)
                    setInitialClientData()
                  }
                }}
              >
                {t('Common:CANCEL')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
})

export default ClientContactInformationForm
