/** @jsxImportSource @emotion/react */
import { KibSkeletonShape, KibSkeletonText } from '@chewy/kib-skeleton-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { spacing } from '../../../utils/spacing'
import Card from '../card/Card/Card'
import UpcomingAppointmentSkeleton from './widget/UpcomingAppointmentSkeleton'

const styles = {
  container: css({
    display: 'flex',
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
    flexDirection: 'column',
    marginBottom: spacing(Spacing.S4),
    paddingBottom: spacing(Spacing.S3),
    width: '100%',
  }),
  header: css({
    display: 'flex',
    // TODO change color to var
    backgroundColor: '#F5F9FB',
    padding: spacing(Spacing.S3, Spacing.S3, Spacing.S1, Spacing.S3),
    flexDirection: 'row',
    alignItems: 'center',
  }),
  nameContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: spacing(Spacing.S3),
  }),
  visitContainer: css({
    marginTop: spacing(Spacing.S3),
    padding: spacing(0, Spacing.S3),
  }),
  businessName: {
    marginTop: spacing(Spacing.S1),
    width: 200,
  },
  buttonsContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S6),
  }),
  button: {
    marginLeft: spacing(Spacing.S3),
  },
}

const PatientCardSkeleton = ({ ...rest }) => (
  <Card css={styles.container} {...rest}>
    <div css={styles.header}>
      <KibSkeletonShape circle style={{ width: 72 }} />

      <div css={styles.nameContainer}>
        <KibSkeletonText lines={1} style={{ width: 150 }} />
        <KibSkeletonText />
      </div>
    </div>

    <div css={css({ padding: spacing(0, Spacing.S3) })}>
      <UpcomingAppointmentSkeleton />
    </div>
  </Card>
)

export default PatientCardSkeleton
