/* eslint-disable no-console */
import { initReactI18next } from 'react-i18next'
import { createInstance, InitOptions } from 'i18next'
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector'
import ICU from 'i18next-icu'

import {
  TRANSLATIONS_CA_ES,
  TRANSLATIONS_EN_US,
  TRANSLATIONS_ES_ES,
  TRANSLATIONS_PT_BR,
  TRANSLATIONS_PT_PT,
} from './languages'

const LanguageCodes = {
  'en-US': 'en-US',
  'ca-ES': 'ca-ES',
  'es-ES': 'es-ES',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
}

/**
 * This will be only used on the scope of the i18next module,
 * for other purposes we have Languages Constant
 */
const SupportedLngCodesList = Object.values(LanguageCodes)

const FallbackLanguageCode = LanguageCodes['en-US']

const NameSpaces = ['Common']

const langDetectorOptions: DetectorOptions = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'sessionStorage',
    'localStorage',
    'navigator',
  ],
  // keys or params to lookup language from
  lookupQuerystring: 'language',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // languages to not persist (cookie, localStorage)
  excludeCacheFor: ['cimode'],
}

const enabledI18nDebug = localStorage.getItem('enabledI18nDebug')
const i18nextOptions: InitOptions = {
  debug: enabledI18nDebug ? JSON.parse(enabledI18nDebug) : false,
  defaultNS: 'Common',
  fallbackNS: 'Common',
  ns: NameSpaces,
  detection: langDetectorOptions,
  fallbackLng: FallbackLanguageCode,
  supportedLngs: SupportedLngCodesList,
  load: 'currentOnly',
  react: {
    useSuspense: true,
  },
  resources: {
    [LanguageCodes['en-US']]: TRANSLATIONS_EN_US,
    [LanguageCodes['es-ES']]: TRANSLATIONS_ES_ES,
    [LanguageCodes['ca-ES']]: TRANSLATIONS_CA_ES,
    [LanguageCodes['pt-PT']]: TRANSLATIONS_PT_PT,
    [LanguageCodes['pt-BR']]: TRANSLATIONS_PT_BR,
  },
  returnEmptyString: false, // avoid empty string when there's no available translations yet
  returnNull: false,
}

const i18n = createInstance()
i18n
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nextOptions, err => {
    if (err) {
      throw new Error(
        `[i18next]: something went wrong loading: ${err.toString()}`,
      )
    }
  })

export default i18n
