/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { spacing } from '../../../utils/spacing'
import Link from '../link/Link'
import Text, { TextVariant } from '../typography/Text/Text'

export const FOOTER_HEIGHT = '40px'

type FooterProps = {
  onPrivacy: () => void
  onTerms: () => void
}

const styles = {
  container: css({
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
    borderTop: `1px solid ${ColorVariables.UI_BG_04}`,
    minHeight: FOOTER_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    width: '100%',
  }),
  link: css({
    color: ColorVariables.LINK_ACTIVE_SECONDARY,
  }),
  separator: css({
    color: ColorVariables.LINK_ACTIVE_SECONDARY,
    margin: spacing(0, Spacing.S3),
  }),
}

const Footer = ({ onPrivacy, onTerms }: FooterProps) => {
  const { t } = useTranslation('Common')

  return (
    <div css={styles.container}>
      <Link css={styles.link} id="foot-priv" onClick={onPrivacy}>
        {t('Common:PRIVACY')}
      </Link>

      <Text css={styles.separator} variant={TextVariant.CAPTION}>
        |
      </Text>

      <Link css={styles.link} id="foot-tnc" onClick={onTerms}>
        {t('Common:TERMS_AND_CONDITIONS')}
      </Link>
    </div>
  )
}

export default Footer
