/** @jsxImportSource @emotion/react */
import { Popup as PopupLib } from 'reactjs-popup'
import { PopupProps as PopupPropsLib } from 'reactjs-popup/dist/types'

export const PopupPosition = {
  TOP_LEFT: 'top left',
  TOP_CENTER: 'top center',
  TOP_RIGHT: 'top right',
  RIGHT_TOP: 'right top',
  RIGHT_CENTER: 'right center',
  RIGHT_BOTTOM: 'right bottom',
  BOTTOM_LEFT: 'bottom left',
  BOTTOM_CENTER: 'bottom center',
  BOTTOM_RIGHT: 'bottom right',
  LEFT_TOP: 'left top',
  LEFT_CENTER: 'left center',
  LEFT_BOTTOM: 'left bottom',
  CENTER_CENTER: 'center center',
} as const

export const PopupEventType = {
  HOVER: 'hover',
  CLICK: 'click',
  FOCUS: 'focus',
  RIGHT_CLICK: 'right-click',
} as const

export type PopupPositionProp =
  (typeof PopupPosition)[keyof typeof PopupPosition]
export type PopupEventTypeProp =
  (typeof PopupEventType)[keyof typeof PopupEventType]

type PopupProps = Omit<PopupPropsLib, 'position' | 'on'> & {
  children: React.ReactNode
  on?: PopupEventTypeProp | PopupEventTypeProp[]
  position?: PopupPositionProp | PopupPositionProp[]
}

const Popup = ({ on, position, children, ...rest }: PopupProps) => (
  <PopupLib
    contentStyle={{
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: 0,
      ...rest.contentStyle,
    }}
    on={on}
    position={position}
    {...rest}
  >
    {children}
  </PopupLib>
)

export default Popup
