import { ApolloClient } from '@apollo/client'

import { SIGN_UP } from '../api/mutations/client'
import { CLIENT_AUTH, SEARCH_CLIENT } from '../api/queries/client'
import i18n from '../locales/i18n'
import { Client } from '../types/dto/Client'

export const ClientAuthState = {
  CLIENT_FOUND: 'client_found',
  CLIENT_WITHOUT_BUSINESS_FOUND: 'client_without_business_found',
  SINGLE_PERSON_FOUND: 'single_person_found',
  MULTIPLE_PERSONS_FOUND: 'multiple_persons_found',
  NO_PERSON_FOUND: 'no_person_found',
  ERROR: 'error',
}

export type ClientAuth = {
  client?: Client
  header?: string
  message?: string
  state: (typeof ClientAuthState)[keyof typeof ClientAuthState]
}

export const checkClientAuth = async (
  apolloClient: ApolloClient<object>,
): Promise<ClientAuth> => {
  try {
    const { data: { me } = {}, errors: meErrors } = await apolloClient.query({
      query: CLIENT_AUTH,
      fetchPolicy: 'network-only',
    })

    if ((meErrors?.length || 0) > 0) {
      return {
        state: ClientAuthState.ERROR,
        header: meErrors?.[0]?.name,
        message: meErrors?.[0]?.message,
      }
    }

    if (me) {
      if (me?.businesses?.length > 0) {
        return {
          state: ClientAuthState.CLIENT_FOUND,
          client: me,
        }
      }

      return {
        state: ClientAuthState.CLIENT_WITHOUT_BUSINESS_FOUND,
        header: i18n.t('Auth:NO_BUSINESS_FOUND_TITLE'),
        message: i18n.t('Auth:NO_BUSINESS_FOUND_MESSAGE'),
      }
    }

    const { data: { search: persons } = {}, errors: searchErrors } =
      await apolloClient.query({
        query: SEARCH_CLIENT,
        fetchPolicy: 'network-only',
      })

    if ((searchErrors?.length || 0) > 0) {
      return {
        state: ClientAuthState.ERROR,
        header: searchErrors?.[0]?.name,
        message: searchErrors?.[0]?.message,
      }
    }

    if (
      !persons
      || persons?.length === 0
      || persons?.[0]?.businesses?.length === 0
    ) {
      return {
        state: ClientAuthState.NO_PERSON_FOUND,
        header: i18n.t('Auth:NO_PERSON_FOUND_TITLE'),
        message: i18n.t('Auth:NO_PERSON_FOUND_MESSAGE'),
      }
    }

    if ((persons?.length || 0) > 1) {
      return {
        state: ClientAuthState.MULTIPLE_PERSONS_FOUND,
        header: i18n.t('Auth:MULTIPLE_ACCOUNTS_FOUND_TITLE'),
        message: i18n.t('Auth:MULTIPLE_ACCOUNTS_FOUND_MESSAGE'),
      }
    }

    await apolloClient.mutate({
      mutation: SIGN_UP,
      variables: {
        clientId: persons?.[0]?.id,
      },
    })

    return {
      state: ClientAuthState.SINGLE_PERSON_FOUND,
      client: persons?.[0],
    }
  } catch (error: any) {
    if (error?.name === 'ApolloError' && error?.message?.includes('401')) {
      return {
        state: ClientAuthState.ERROR,
        header: i18n.t('Auth:SESSION_EXPIRED'),
        message: i18n.t('Auth:SESSION_EXPIRED_MESSAGE'),
      }
    }

    return {
      state: ClientAuthState.ERROR,
      header: error.name,
      message: error.message,
    }
  }
}
