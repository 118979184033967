/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { KibSheet, KibSheetProps } from '@chewy/kib-overlays-react'

import {
  TimelineItemType,
  TranslatedTimelineType,
} from '../../../../constants/timeline'
import {
  TimelineItem,
  TimelinePrescription,
} from '../../../../types/dto/Patient'
import TimelineAppointmentDetailsView from './TimelineAppointmentDetailsView'
import TimelineDiagnosisDetailsView from './TimelineDiagnosisDetailsView'
import TimelinePrescriptionDetailsView from './TimelinePrescriptionDetailsView'
import TimelineVaccinationDetailsView from './TimelineVaccinationDetailsView'

type TimelineDetailsSheetProps = Omit<KibSheetProps, 'children' | 'title'> & {
  onRequestRefill?: (prescription: TimelinePrescription) => void
  patientId?: string
  timelineItem?: TimelineItem
}

const TimelineDetailsSheet = ({
  timelineItem,
  patientId,
  onRequestRefill,
  ...rest
}: TimelineDetailsSheetProps) => {
  const [title, setTitle] = useState<string>('')

  const timelineType =
    TranslatedTimelineType[timelineItem?.type?.name || '']?.()

  useEffect(() => {
    setTitle(timelineItem ? TranslatedTimelineType[timelineItem.type.name] : '')
  }, [timelineItem])

  return (
    <KibSheet {...rest} bottom size="large" title={title}>
      {timelineItem && timelineType === TimelineItemType.APPOINTMENT && (
        <TimelineAppointmentDetailsView appointment={timelineItem} />
      )}
      {timelineItem && timelineType === TimelineItemType.PRESCRIPTION && (
        <TimelinePrescriptionDetailsView
          prescription={timelineItem}
          onRequestRefill={onRequestRefill}
        />
      )}
      {timelineItem && timelineType === TimelineItemType.VACCINATION && (
        <TimelineVaccinationDetailsView
          patientId={patientId}
          vaccination={timelineItem}
        />
      )}
      {timelineItem && timelineType === TimelineItemType.DIAGNOSIS && (
        <TimelineDiagnosisDetailsView diagnosis={timelineItem} />
      )}
    </KibSheet>
  )
}

export default TimelineDetailsSheet
