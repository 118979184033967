import { useEffect, useState } from 'react'

import { MediaQuery } from '../constants/mediaQuery'
import { mediaQueryMatches } from './mediaQuery'

const useResize = () => {
  const [isMobile, setIsMobile] = useState<boolean | undefined>()

  const handleResize = () => {
    const mobile = mediaQueryMatches(MediaQuery.MAX_SM)
    if (isMobile !== mobile) {
      setIsMobile(mobile)
    }
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [isMobile])

  return { isMobile }
}

export default useResize
