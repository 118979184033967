/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { KibAddIcon } from '@chewy/kib-icons-react'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Task } from '../../../types/dto/Task'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button from '../../common/button/Button'
import Text, { TextVariant } from '../../common/typography/Text/Text'
import CreateTaskModal from '../../tasks/CreateTaskModal'
import TaskDetailsFragment from './TaskDetailsFragment'
import TasksTabsFragment from './TasksTabsFragment'

type ShowCreateTaskState = {
  show: boolean
}

const styles = {
  leftContainer: css({
    height: '100%',
    overflow: 'visible',
  }),
  header: css({
    display: 'flex',
    width: '100%',
    padding: spacing(0, Spacing.S2),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S7),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      backgroundColor: ColorVariables.UI_BG_PRIMARY,
    }),
    ...mediaQuery(MediaQuery.MIN_MD, {
      marginTop: spacing(Spacing.S4),
      height: 48,
    }),
  }),
  headerLeft: css({
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...mediaQuery(MediaQuery.MAX_SM, {
      justifyContent: 'space-between',
      padding: spacing(Spacing.S4),
    }),
  }),
  headerRight: css({
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  }),
  emptyGridContainer: css({
    height: '100%',
  }),
  emptyContainer: css({
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}

const TasksPage = () => {
  const { t } = useTranslation('Tasks')

  const navigate = useNavigate()

  const [selectedTask, setSelectedTask] = useState<Task | undefined>()
  const [showCreateTaskState, setShowCreateTaskState] =
    useState<ShowCreateTaskState>({
      show: false,
    })

  const handleTaskSelected = (task: Task) => {
    if (mediaQueryMatches(MediaQuery.MAX_SM)) {
      navigate(`/task/${task.id}`, {
        state: { task, from: window.location.pathname },
      })
    } else {
      setSelectedTask(task)
    }
  }

  const handleTasksLoaded = (tasks: Task[] | undefined) => {
    if (!selectedTask && tasks?.length) {
      setSelectedTask(tasks[0])
    }
  }

  const handleTaskCreated = (task: Task) => {
    setShowCreateTaskState({ show: false })
    handleTaskSelected(task)
  }

  const handleCreateNewTask = () => {
    if (mediaQueryMatches(MediaQuery.MAX_SM)) {
      navigate('/task/new', {
        state: { from: window.location.pathname },
      })
    } else {
      setShowCreateTaskState({ show: true })
    }
  }

  return (
    <KibGrid align="center">
      <KibGridItem offset="2@min-lg" span="4@max-sm 3@min-md 3@min-lg">
        <div css={[styles.header, styles.headerLeft]}>
          <Text variant={TextVariant.SECTION_1}>{t('Tasks:TASKS')}</Text>

          {mediaQueryMatches(MediaQuery.MAX_SM) && (
            <Button
              iconOnly
              icon={<KibAddIcon />}
              id="clp-nm"
              onClick={() => handleCreateNewTask()}
            />
          )}
        </div>

        <TasksTabsFragment
          selectedTask={selectedTask}
          onCreateNewTask={handleCreateNewTask}
          onTaskSelected={handleTaskSelected}
          onTasksLoaded={handleTasksLoaded}
        />
      </KibGridItem>

      {mediaQueryMatches(MediaQuery.MIN_MD) && (
        <KibGridItem
          css={styles.leftContainer}
          push="2@min-lg"
          span="5@min-md 5@min-lg"
        >
          {mediaQueryMatches(MediaQuery.MIN_MD) && (
            <div css={[styles.header, styles.headerRight]}>
              <Button
                iconOnly
                icon={<KibAddIcon />}
                id="clp-nm"
                onClick={() => handleCreateNewTask()}
              />
            </div>
          )}

          <TaskDetailsFragment task={selectedTask} />
        </KibGridItem>
      )}

      <CreateTaskModal
        open={showCreateTaskState.show}
        onClose={() => setShowCreateTaskState({ show: false })}
        onTaskCreated={handleTaskCreated}
      />
    </KibGrid>
  )
}

export default TasksPage
