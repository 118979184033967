/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Task } from '../../../types/dto/Task'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import TaskDetailsView from '../../tasks/TaskDetailsView'

type TaskDetailsFragmentProps = {
  task?: Task
}

const styles = {
  containerWrapper: css({
    position: 'sticky',
    top: '129px',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    padding: spacing(Spacing.S4, 0, Spacing.S4, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S6, Spacing.S4, 0),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, Spacing.S2),
    }),
  }),
}

const TaskDetailsFragment = ({ task, ...rest }: TaskDetailsFragmentProps) => (
  <div css={styles.containerWrapper}>
    <div css={styles.container} {...rest}>
      {task && <TaskDetailsView task={task} />}
    </div>
  </div>
)

export default TaskDetailsFragment
