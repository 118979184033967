import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import * as R from 'ramda'

export const LOCAL_CHECK_IN_MODAL = 'LOCAL_CHECK_IN_MODAL'
export const LOCAL_SUGGESTED_APPOINTMENTS = 'LOCAL_SUGGESTED_APPOINTMENTS'

function getStorageValue<T>(key: string, defaultValue: T | undefined) {
  const saved = localStorage.getItem(key)
  return !R.isNil(saved) ? JSON.parse(saved) || defaultValue : defaultValue
}

export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(() =>
    getStorageValue<T>(key, defaultValue),
  )

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
