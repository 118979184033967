/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { UPDATE_ME } from '../../../api/mutations/client'
import { GET_ACCOUNT_CLIENT_DETAILS_DATA } from '../../../api/queries/client'
import { FormatPattern } from '../../../constants/format'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Client } from '../../../types/dto/Client'
import { distinct, getPersonName } from '../../../utils'
import {
  formatPhoneNumberWithCountryCode,
  formatPhoneNumberWithoutCountryCode,
} from '../../../utils/format'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import { isValidPhone } from '../../../utils/validation'
import Button, { ButtonEmphasis, ButtonTheme } from '../button/Button'
import EditIcon from '../icon/EditIcon'
import InputText from '../input/InputText'
import PhoneInput from '../input/PhoneInput'
import Text, { TextVariant } from '../typography/Text/Text'
import InfoFormItem from './item/InfoFormItem'

type Validation = {
  firstName: boolean
  lastName: boolean
  phone: boolean
}

type EmergencyContactFormProps = {
  client: Client
  loading?: boolean
}

const styles = {
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  editModeContainer: css({
    display: 'flex',
    padding: spacing(Spacing.S2, 0),
    flexDirection: 'column',
  }),
  inputContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: spacing(Spacing.S2),
    justifyContent: 'space-between',
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S4),
  }),
  saveButton: css({
    marginRight: spacing(Spacing.S2),
  }),
  editableInput: css({
    width: '100%',
    ...mediaQuery(MediaQuery.MIN_MD, {
      width: '49%',
    }),
  }),
}

const validate = (
  firstName: string | undefined,
  lastName: string | undefined,
  phone: string | undefined,
): Validation => ({
  firstName: Boolean(firstName),
  lastName: Boolean(lastName),
  phone: Boolean(isValidPhone(phone)),
})

const EmergencyContactForm = ({
  client,
  loading,
  ...rest
}: EmergencyContactFormProps) => {
  const { t } = useTranslation(['Account', 'Common'])

  const [firstName, setFirstName] = useState<string | undefined>()
  const [lastName, setLastName] = useState<string | undefined>()
  const [phone, setPhone] = useState<string | undefined>()

  const [validation, setValidation] = useState<Validation>()
  const [triedToSave, setTriedToSave] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [changed, setChanged] = useState(false)

  const [updateContactInfo, { loading: updateLoading }] = useMutation(
    UPDATE_ME,
    {
      refetchQueries: [{ query: GET_ACCOUNT_CLIENT_DETAILS_DATA }],
      onCompleted: () => {
        setEditMode(false)
      },
    },
  )

  const setInitialClientData = () => {
    setFirstName(client?.emergencyContact?.firstName)
    setLastName(client?.emergencyContact?.lastName)
    setPhone(client?.emergencyContact?.mobilePhone)
    setValidation(undefined)
    setTriedToSave(false)
  }

  useEffect(() => {
    setInitialClientData()
  }, [client])

  useEffect(() => {
    setChanged(
      client?.emergencyContact?.firstName !== firstName
        || client?.emergencyContact?.lastName !== lastName
        || client?.emergencyContact?.mobilePhone !== phone,
    )
  }, [firstName, lastName, phone])

  const handleSave = async () => {
    setTriedToSave(true)
    const validationData = validate(firstName, lastName, phone)

    if (R.all(Boolean, Object.values(validationData))) {
      const updatedContact = {
        firstName,
        lastName,
        mobilePhone: phone,
      }

      const clientDistinction = distinct(
        client?.emergencyContact,
        updatedContact,
      )

      if (!R.isEmpty(clientDistinction)) {
        updateContactInfo({
          variables: { input: { emergencyContact: updatedContact } },
        })
      } else {
        setInitialClientData()
        setEditMode(false)
      }
    } else {
      setValidation(validationData)
    }
  }

  return (
    <div {...rest}>
      <div css={styles.header}>
        <Text variant={TextVariant.SECTION_1}>
          {t('Account:EMERGENCY_CONTACT')}
        </Text>

        {!editMode && !loading && (
          <EditIcon id="cdc-ec-edit" onEdit={() => setEditMode(true)} />
        )}
      </div>

      {!editMode && (
        <div>
          {(firstName || lastName || loading) && (
            <InfoFormItem
              hideDivider={mediaQueryMatches(MediaQuery.MAX_SM)}
              loading={loading}
              title={t('Common:NAME')}
              value={getPersonName({ firstName, lastName })}
            />
          )}

          {(phone || loading) && (
            <InfoFormItem
              hideDivider
              format={FormatPattern.PHONE}
              loading={loading}
              title={t('Common:PHONE_NUMBER')}
              value={formatPhoneNumberWithoutCountryCode(phone)}
            />
          )}
        </div>
      )}

      {editMode && (
        <div css={styles.editModeContainer}>
          <div css={styles.inputContainer}>
            <InputText
              css={styles.editableInput}
              id="cdc-ec-fn"
              invalid={validation && !validation.firstName}
              label={t('Common:FIRST_NAME')}
              messagesContent={t('Account:PLEASE_ENTER_FIRST_NAME')}
              value={firstName}
              onChangeText={value => {
                setFirstName(value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, firstName: true })
                }
              }}
            />

            <InputText
              css={styles.editableInput}
              id="cdc-ec-ln"
              invalid={validation && !validation.lastName}
              label={t('Common:LAST_NAME')}
              messagesContent={t('Account:PLEASE_ENTER_LAST_NAME')}
              value={lastName}
              onChangeText={value => {
                setLastName(value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, lastName: true })
                }
              }}
            />

            <PhoneInput
              css={styles.editableInput}
              id="cdc-ec-phone"
              invalid={validation && !validation.phone}
              label={t('Common:PHONE_NUMBER')}
              messagesContent={t('Account:INVALID_PHONE_NUMBER')}
              value={formatPhoneNumberWithoutCountryCode(phone)}
              onChangeText={value => {
                setPhone(formatPhoneNumberWithCountryCode(value))
                if (triedToSave && validation) {
                  setValidation({ ...validation, phone: true })
                }
              }}
            />
          </div>
          <div css={styles.buttonContainer}>
            <Button
              css={styles.saveButton}
              disabled={!changed || updateLoading}
              id="cdc-ca-save"
              loading={updateLoading}
              onClick={handleSave}
            >
              {t('Common:SAVE')}
            </Button>
            <Button
              emphasis={ButtonEmphasis.TERTIARY}
              id="cdc-ca-can"
              theme={ButtonTheme.UTILITY}
              onClick={() => {
                if (!updateLoading) {
                  setEditMode(false)
                  setInitialClientData()
                }
              }}
            >
              {t('Common:CANCEL')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default EmergencyContactForm
