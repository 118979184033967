/** @jsxImportSource @emotion/react */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { UPDATE_PATIENT_DETAILS } from '../../../api/mutations/patient'
import { GET_PATIENT_BASIC_DETAILS } from '../../../api/queries/patient'
import { SaveFormHandle, SaveFormProps } from '../../../types/forms'
import PatientBasicInfoForm, {
  PatientBasicInfoFormHandle,
} from './PatientBasicInfoForm'

const ID = 'patientForm'

type CheckInReviewPatientFormProps = SaveFormProps & {
  patientId?: string
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
}

const CheckInReviewPatientForm = forwardRef<
SaveFormHandle,
CheckInReviewPatientFormProps
>(function CheckInReviewPatientForm(
  { patientId, onReady, onSaveCompleted, onSaveLoadingStateChange, ...rest },
  ref,
) {
  const [changed, setChanged] = useState(false)
  const [basicFormReady, setBasicFormReady] = useState(false)

  const patientRef = useRef<PatientBasicInfoFormHandle>(null)

  const { data: { pet: patient } = {}, loading } = useQuery(
    GET_PATIENT_BASIC_DETAILS,
    {
      variables: { id: patientId },
    },
  )

  const [updatePatient, { loading: updateLoading }] = useMutation(
    UPDATE_PATIENT_DETAILS,
    {
      onCompleted: () => {
        onSaveCompleted?.(ID)
      },
    },
  )

  useEffect(() => {
    if (!loading && basicFormReady) {
      onReady?.(ID)
    }
  }, [loading, basicFormReady])

  useEffect(() => {
    onSaveLoadingStateChange?.({ id: ID, loading: updateLoading })
  }, [updateLoading])

  const handleSave = () => {
    if (!changed) {
      onSaveCompleted?.(ID)
    } else {
      const data = patientRef?.current?.getData()
      updatePatient({
        variables: { id: patient.id, input: data },
      })
    }
  }

  useImperativeHandle(ref, () => ({
    save: handleSave,
  }))

  return (
    <div css={styles.container} {...rest}>
      <PatientBasicInfoForm
        loading={loading}
        patient={patient}
        ref={patientRef}
        onChange={setChanged}
        onReady={() => setBasicFormReady(true)}
      />
    </div>
  )
})

export default CheckInReviewPatientForm
