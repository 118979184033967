import AppointmentsImage from '../components/common/images/appointment.svg'
import DiagnosisImage from '../components/common/images/diagnosis.svg'
import PrescriptionImage from '../components/common/images/prescription.svg'
import VaccinationImage from '../components/common/images/vaccination.svg'
import i18n from '../locales/i18n'

export const TimelineItemType = {
  APPOINTMENT: 'Appointment',
  DIAGNOSIS: 'Diagnosis',
  PRESCRIPTION: 'Prescription',
  VACCINATION: 'Vaccination',
}

export const TranslatedTimelineType = {
  [TimelineItemType.APPOINTMENT]: () => i18n.t('Home:APPOINTMENT'),
  [TimelineItemType.DIAGNOSIS]: () => i18n.t('Home:DIAGNOSIS'),
  [TimelineItemType.VACCINATION]: () => i18n.t('Home:VACCINATION'),
  [TimelineItemType.PRESCRIPTION]: () => i18n.t('Home:PRESCRIPTION'),
}

export const TranslatedTimelineFilters = {
  [TimelineItemType.APPOINTMENT]: () => i18n.t('Home:APPOINTMENTS'),
  [TimelineItemType.DIAGNOSIS]: () => i18n.t('Home:DIAGNOSES'),
  [TimelineItemType.VACCINATION]: () => i18n.t('Home:VACCINATIONS'),
  [TimelineItemType.PRESCRIPTION]: () => i18n.t('Home:PRESCRIPTIONS'),
}

export const TimelineImages = {
  [TimelineItemType.APPOINTMENT]: AppointmentsImage,
  [TimelineItemType.DIAGNOSIS]: DiagnosisImage,
  [TimelineItemType.PRESCRIPTION]: PrescriptionImage,
  [TimelineItemType.VACCINATION]: VaccinationImage,
}
