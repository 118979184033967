/** @jsxImportSource @emotion/react */
import { KibTextarea, KibTextareaProps } from '@chewy/kib-fields-new-react'

export type InputTextAreaProps = KibTextareaProps & {
  onChangeText?: (value?: string) => void
  resize?: 'none' | 'vertical' | 'horizontal' | 'both'
}

const InputTextArea = ({
  resize = 'vertical',
  onChangeText,
  ...rest
}: InputTextAreaProps) => (
  <KibTextarea
    crossOrigin="true"
    style={{ resize }}
    onChange={event => {
      if (onChangeText) {
        onChangeText(event.target.value)
      }
    }}
    {...rest}
  />
)

export default InputTextArea
