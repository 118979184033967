/** @jsxImportSource @emotion/react */
import { FilePreviewTemplate } from '../../../types/entities'
import ImagePreview from './ImageFilePreview'
import NoPreview from './NoPreview'
import PdfFilePreview from './PdfFilePreview'

type FilePreviewProps = {
  file?: FilePreviewTemplate
  onClose: () => void
  open?: boolean
}

const FilePreview = ({ file, open, onClose, ...rest }: FilePreviewProps) => {
  const isPdf = file?.contentType === 'application/pdf'
  const isImage = file?.contentType?.startsWith('image/')
  const isNotSupported = !isPdf && !isImage

  const handleDownload = (fileToDownload?: FilePreviewTemplate) => {
    if (fileToDownload && fileToDownload.url) {
      const link = document.createElement('a')
      link.href = fileToDownload.url
      link.download = fileToDownload.name || 'File'
      link.rel = 'noopener noreferrer'
      link.target = '_blank'
      link.click()
    }
  }

  return (
    <div {...rest}>
      {isPdf && (
        <PdfFilePreview
          file={file}
          open={open}
          onClose={onClose}
          onDownload={handleDownload}
        />
      )}

      {isImage && <ImagePreview file={file} open={open} onClose={onClose} />}

      {isNotSupported && (
        <NoPreview
          file={file}
          open={open}
          onClose={onClose}
          onDownload={handleDownload}
        />
      )}
    </div>
  )
}

export default FilePreview
