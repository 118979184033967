/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Chat } from '../../../types/dto/Chat'
import { FilePreviewTemplate, Message } from '../../../types/entities'
import { messageToFilePreview } from '../../../utils/chat'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import ChatView, { ChatViewHandle } from '../../common/chat/ChatView'
import { FOOTER_HEIGHT } from '../../common/footer/Footer'
import FileInputModal, {
  FileInputUsage,
} from '../../common/modal/FileInputModal'
import FilePreview from '../../common/preview/FilePreview'

type ChatFragmentProps = {
  chat?: Chat
  onCreateNewChat: () => void
}

const styles = {
  chatWrapper: css({
    height: `calc(100vh - 154px - ${FOOTER_HEIGHT})`,
    position: 'sticky',
    top: '129px',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    padding: spacing(Spacing.S4, 0, Spacing.S4, 0),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S6, Spacing.S4, 0),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, Spacing.S2),
    }),
  }),
}

const ChatFragment = ({ chat, onCreateNewChat, ...rest }: ChatFragmentProps) => {
  const { t } = useTranslation('Common')

  const [fileInputOpen, setFileInputOpen] = useState(false)
  const [fileModalOpen, setFileModalOpen] = useState(false)
  const [file, setFile] = useState<FilePreviewTemplate | undefined>()

  const chatRef = useRef<ChatViewHandle>(null)

  const handleShowMediaPreview = async (message: Message) => {
    const url = await message?.originalMedia?.getContentTemporaryUrl()

    flushSync(() => {
      setFile(messageToFilePreview(message, url || ''))
      setFileModalOpen(true)
    })
  }

  return (
    <>
      <div css={styles.chatWrapper}>
        <div css={styles.container} {...rest}>
          {chat && (
            <ChatView
              chat={chat}
              ref={chatRef}
              onCreateNewChat={onCreateNewChat}
              onShowFileInput={() => setFileInputOpen(true)}
              onShowMediaPreview={handleShowMediaPreview}
            />
          )}
        </div>
      </div>

      <FilePreview
        file={file}
        open={fileModalOpen}
        onClose={() => setFileModalOpen(false)}
      />

      <FileInputModal
        fileInputUsage={FileInputUsage.ATTACHMENT}
        open={fileInputOpen}
        title={t('Common:CHOOSE_A_FILE')}
        onClose={() => setFileInputOpen(false)}
        onFileReady={data => {
          setFileInputOpen(false)
          chatRef?.current?.sendMediaMessage(data)
        }}
      />
    </>
  )
}

export default ChatFragment
