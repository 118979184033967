/** @jsxImportSource @emotion/react */
import { KibAttachIcon } from '@chewy/kib-icons-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../../constants/colors'
import { MediaQuery } from '../../../../constants/mediaQuery'
import { Spacing } from '../../../../constants/spacing'
import { mediaQueryMatches } from '../../../../utils/mediaQuery'
import { spacing } from '../../../../utils/spacing'
import IconComponent from '../../icon/Icon'
import Text, { TextVariant } from '../../typography/Text/Text'

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    padding: spacing(Spacing.S4),
    borderStyle: 'dashed',
    borderColor: ColorVariables.UI_BG_07,
    borderWidth: 2,
    backgroundColor: 'transparent',
  }),
  uploadImageIcon: css({
    width: 80,
    height: 80,
    marginBottom: spacing(Spacing.S10),
  }),
  uploadImageIconComponent: css({
    // safari fix
    width: '100%',
    height: '100%',
  }),
  iconDragOver: css({
    color: ColorVariables.UI_BG_PRIMARY,
  }),
  iconDragOut: css({
    color: ColorVariables.STATIC_GRAY1_900,
  }),
  areaDragOver: css({
    backgroundColor: ColorVariables.UI_BG_07,
  }),
  areaDragOut: css({
    backgroundColor: 'transparent',
  }),
  text: css({
    textAlign: 'center',
  }),
  errorText: css({
    color: ColorVariables.TEXT_ERROR,
    marginTop: spacing(Spacing.S4),
  }),
}

export interface FileInputAreaProps {
  Icon?: React.ReactNode
  error?: string
  fileInputMobileText?: string
  fileInputText?: string
  isDragOver: boolean
}

const FileInputArea = ({
  isDragOver,
  error,
  fileInputText,
  fileInputMobileText,
  Icon,
}: FileInputAreaProps) => (
  <div
    css={[
      styles.container,
      isDragOver && styles.areaDragOver,
      !isDragOver && styles.areaDragOut,
    ]}
  >
    <IconComponent
      Component={
        Icon || <KibAttachIcon css={styles.uploadImageIconComponent} />
      }
      css={[
        styles.uploadImageIcon,
        isDragOver && styles.iconDragOver,
        !isDragOver && styles.iconDragOut,
      ]}
    />
    <Text css={styles.text} variant={TextVariant.DISPLAY_4}>
      {mediaQueryMatches(MediaQuery.MIN_LG)
        ? fileInputText
        : fileInputMobileText}
    </Text>
    {error && (
      <Text css={styles.errorText} variant={TextVariant.SECTION_3}>
        {error}
      </Text>
    )}
  </div>
)

export default FileInputArea
