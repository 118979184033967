/* eslint-disable simple-import-sort/imports */
// english namespaces imports
import CommonEnUS from './en-US/Common.json'
import HomeEnUS from './en-US/Home.json'
import AccountEnUS from './en-US/Account.json'
import ChatEnUS from './en-US/Chat.json'
import TasksEnUS from './en-US/Tasks.json'
import AuthEnUs from './en-US/Auth.json'
import PetEnUs from './en-US/Pet.json'

// spanish namespaces imports
import CommonEsES from './es-ES/Common.json'
import HomeEsES from './es-ES/Home.json'
import AccountEsES from './es-ES/Account.json'
import ChatEsES from './es-ES/Chat.json'
import TasksEsES from './es-ES/Tasks.json'
import AuthEsES from './es-ES/Auth.json'
import PetEsES from './es-ES/Pet.json'

// spanish (castelian) namespaces imports
import CommonCaES from './ca-ES/Common.json'
import HomeCaES from './ca-ES/Home.json'
import AccountCaES from './ca-ES/Account.json'
import ChatCaES from './ca-ES/Chat.json'
import TasksCaES from './ca-ES/Tasks.json'
import AuthCaES from './ca-ES/Auth.json'
import PetCaES from './ca-ES/Pet.json'

// portuguese namespaces imports
import CommonPtPT from './pt-PT/Common.json'
import HomePtPT from './pt-PT/Home.json'
import AccountPtPT from './pt-PT/Account.json'
import ChatPtPT from './pt-PT/Chat.json'
import TasksPtPT from './pt-PT/Tasks.json'
import AuthPtPT from './pt-PT/Auth.json'
import PetPtPT from './pt-PT/Pet.json'

// portuguese (brazil) namespaces imports
import CommonPtBR from './pt-BR/Common.json'
import HomePtBR from './pt-BR/Home.json'
import AccountPtBR from './pt-BR/Account.json'
import ChatPtBR from './pt-BR/Chat.json'
import TasksPtBR from './pt-BR/Tasks.json'
import AuthPtBR from './pt-BR/Auth.json'
import PetPtBR from './pt-BR/Pet.json'

export const TRANSLATIONS_EN_US = {
  Common: CommonEnUS,
  Home: HomeEnUS,
  Account: AccountEnUS,
  Chat: ChatEnUS,
  Tasks: TasksEnUS,
  Auth: AuthEnUs,
  Pet: PetEnUs,
}

export const TRANSLATIONS_ES_ES = {
  Common: CommonEsES,
  Home: HomeEsES,
  Account: AccountEsES,
  Chat: ChatEsES,
  Tasks: TasksEsES,
  Auth: AuthEsES,
  Pet: PetEsES,
}

export const TRANSLATIONS_CA_ES = {
  Common: CommonCaES,
  Home: HomeCaES,
  Account: AccountCaES,
  Chat: ChatCaES,
  Tasks: TasksCaES,
  Auth: AuthCaES,
  Pet: PetCaES,
}

export const TRANSLATIONS_PT_PT = {
  Common: CommonPtPT,
  Home: HomePtPT,
  Account: AccountPtPT,
  Chat: ChatPtPT,
  Tasks: TasksPtPT,
  Auth: AuthPtPT,
  Pet: PetPtPT,
}

export const TRANSLATIONS_PT_BR = {
  Common: CommonPtBR,
  Home: HomePtBR,
  Account: AccountPtBR,
  Chat: ChatPtBR,
  Tasks: TasksPtBR,
  Auth: AuthPtBR,
  Pet: PetPtBR,
}
