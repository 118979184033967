/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { GET_APPOINTMENT_REASONS } from '../../../api/queries/appointment'
import { GET_BOOKING_PATIENTS } from '../../../api/queries/client'
import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { AppointmentReason } from '../../../types/dto/Appointment'
import { Business } from '../../../types/dto/Business'
import { Patient } from '../../../types/dto/Patient'
import { clickable } from '../../../utils/component'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import ChoiceChips, { ChoiceValue } from '../chips/ChoiceChips'
import Avatar from '../icon/Avatar'
import SelectInput from '../input/SelectInput'
import Spinner from '../spinner/Spinner'
import Text, { TextVariant } from '../typography/Text/Text'

export type VisitType = {
  id: string
  reasons: Reason[]
}

export type Reason = {
  category: string
  id: string
  name: string
  staticTypeName: string
}

type BookPatientVisitTypeFormProps = {
  businesses: Business[]
  onBusinessSelected: (businessId: string) => void
  onPatientSelected?: (patient?: Patient) => void
  onVisitTypeSelected?: (visitType?: VisitType) => void
  preselectedBusinessId?: string
  selectedPatient?: Patient
  selectedVisitType?: VisitType
  suggestedAppointmentCategory?: string
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  businessSelect: css({
    margin: spacing(Spacing.S4, Spacing.S6, 0, 0),
    ...mediaQuery(MediaQuery.MAX_SM, {
      margin: spacing(Spacing.S4, 0, 0, 0),
    }),
  }),
  patientsTitle: css({
    marginTop: spacing(Spacing.S6),
  }),
  patientsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: spacing(Spacing.S3),
    rowGap: spacing(Spacing.S3),
    marginTop: spacing(Spacing.S3),
  }),
  patientContainer: css({
    display: 'flex',
    width: 75,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': css({
      cursor: 'pointer',
    }),
  }),
  patientContainerUnselected: css({
    opacity: 0.5,
  }),
  patientLabel: css({
    marginTop: spacing(Spacing.S1),
    overflow: 'hidden',
    maxHeight: 24,
  }),
  patientLabelSelected: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    fontWeight: 'bold',
  }),
  visitTypeLabel: css({
    marginTop: spacing(Spacing.S6),
  }),
  choiceChip: css({
    marginTop: spacing(Spacing.S4),
  }),
  spinner: css({
    marginTop: spacing(Spacing.S4),
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
}

const mapAppointmentReasons = (reasons: AppointmentReason[]) =>
  R.map(
    ({ businessAppointmentTypeId, name, staticAppointmentType }) => ({
      id: businessAppointmentTypeId,
      name,
      category: staticAppointmentType?.category,
      staticTypeName: staticAppointmentType?.name,
    }),
    reasons,
  ) as Reason[]

const groupAppointmentReasons = (reasons: Reason[]) =>
  R.groupBy(R.prop('category'), reasons)

const BookPatientVisitTypeForm = ({
  preselectedBusinessId,
  businesses,
  suggestedAppointmentCategory,
  selectedPatient: selectedPatientProp,
  selectedVisitType: selectedVisitTypeProp,
  onPatientSelected = R.F,
  onVisitTypeSelected = R.F,
  onBusinessSelected = R.F,
  ...rest
}: BookPatientVisitTypeFormProps) => {
  const { t } = useTranslation(['Home', 'Pet'])

  const [selectedBusinessId, setSelectedBusinessId] = useState<
  string | undefined
  >(preselectedBusinessId)
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
    selectedPatientProp,
  )
  const [selectedVisitType, setSelectedVisitType] = useState<
  VisitType | undefined
  >(selectedVisitTypeProp)

  const { data: { me } = {}, loading: patientsLoading } =
    useQuery(GET_BOOKING_PATIENTS)

  const {
    data: { businessAppointmentReasons: appointmentReasons = [] } = {},
    loading: reasonsLoading,
  } = useQuery(GET_APPOINTMENT_REASONS, {
    variables: {
      businessId: selectedBusinessId,
    },
    skip: !selectedBusinessId,
  })

  const businessOptions = businesses?.map(business => ({
    value: business.id,
    label: business.name || '',
  }))

  const patients =
    (selectedBusinessId
      && me?.patients?.filter((it: Patient) =>
        it?.businesses?.map(R.prop('id'))?.includes(selectedBusinessId),
      ))
    || []

  const reasonsList = mapAppointmentReasons(appointmentReasons)
  const reasonsGroup = groupAppointmentReasons(reasonsList)
  const choices = Object.keys(reasonsGroup).map(key => ({
    value: key,
    label: key,
  }))

  const handlePatientSelected = (patient: Patient | undefined) => {
    setSelectedPatient(patient)
    onPatientSelected(patient)
  }

  const handleVisitTypeSelected = (visitTypeId: ChoiceValue | undefined) => {
    const visitType = visitTypeId
      ? ({
        id: `${visitTypeId}`,
        reasons: reasonsGroup[visitTypeId],
      } as VisitType)
      : undefined
    setSelectedVisitType(visitType)
    onVisitTypeSelected(visitType)
  }

  const handleBusinessSelected = (businessId: string) => {
    setSelectedBusinessId(businessId)
    onBusinessSelected(businessId)
    handlePatientSelected(undefined)
    handleVisitTypeSelected(undefined)
  }

  useEffect(() => {
    if (!R.isEmpty(appointmentReasons) && suggestedAppointmentCategory) {
      handleVisitTypeSelected(suggestedAppointmentCategory)
    }
  }, [appointmentReasons])

  useEffect(() => {
    if (me) {
      if (!selectedBusinessId) {
        const businessId = me?.patients?.[0].businesses?.[0]?.id
        setSelectedBusinessId(businessId)
        onBusinessSelected(businessId)
      }

      if (!selectedPatient && me?.patients?.length === 1) {
        const patient = me?.patients?.[0]
        setSelectedPatient(patient)
        onPatientSelected(patient)
      }
    }
  }, [me])

  return (
    <div css={styles.container} {...rest}>
      {businesses?.length > 1 && (
        <div>
          <SelectInput
            css={styles.businessSelect}
            id="ba-business"
            label={t('Home:CHOOSE_A_CLINIC')}
            options={businessOptions}
            value={selectedBusinessId}
            onChange={event => {
              handleBusinessSelected(event.target.value)
            }}
          />
        </div>
      )}

      {patients?.length > 1 && (
        <div>
          {patientsLoading ? (
            <Spinner css={styles.spinner} />
          ) : (
            <>
              <Text css={styles.patientsTitle} variant={TextVariant.SECTION_1}>
                {t('Pet:CHOOSE_A_PET')}
              </Text>

              <div css={styles.patientsContainer}>
                {patients?.map((patient: Patient) => {
                  const selected = selectedPatient?.id === patient?.id

                  return (
                    <div
                      css={[
                        styles.patientContainer,
                        selectedPatient
                          && !selected
                          && styles.patientContainerUnselected,
                      ]}
                      key={patient?.id}
                      {...clickable(() => handlePatientSelected(patient))}
                    >
                      <Avatar
                        avatarSize={72}
                        photo={patient?.photoThumbnail || patient?.photo}
                        selected={selected}
                      />

                      <Text
                        css={[
                          styles.patientLabel,
                          selected && styles.patientLabelSelected,
                        ]}
                        variant={TextVariant.PARAGRAPH}
                      >
                        {patient?.name}
                      </Text>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      )}

      <div>
        {reasonsLoading ? (
          <Spinner css={styles.spinner} />
        ) : (
          <>
            <Text css={styles.visitTypeLabel} variant={TextVariant.SECTION_1}>
              {t('Home:CHOOSE_A_VISIT_TYPE')}
            </Text>

            {!R.isEmpty(appointmentReasons) && (
              <ChoiceChips
                choices={choices}
                css={styles.choiceChip}
                id="ba-cc-vt"
                selectedValue={selectedVisitType?.id}
                onSelected={handleVisitTypeSelected}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default BookPatientVisitTypeForm
