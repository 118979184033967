/** @jsxImportSource @emotion/react */
import { KibAccountIcon } from '@chewy/kib-icons-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../../constants/colors'
import { MediaQuery } from '../../../../constants/mediaQuery'
import { Spacing } from '../../../../constants/spacing'
import {
  TimelineImages,
} from '../../../../constants/timeline'
import { mediaQuery, mediaQueryMatches } from '../../../../utils/mediaQuery'
import { spacing } from '../../../../utils/spacing'
import Button from '../../button/Button'
import Card from '../../card/Card/Card'
import Avatar from '../../icon/Avatar'
import Icon from '../../icon/Icon'
import Text, { TextVariant } from '../../typography/Text/Text'

export type TimelineDetailsContentItem = {
  content?: string
  html?: boolean
  title: string
}

type TimelineDetailsViewProps = {
  HeaderComponent?: React.ReactNode
  actionHeaderActionTitle?: string
  actionHeaderTitle?: string
  contentHeaderLogo?: string
  contentHeaderTitle?: string
  contentItems?: TimelineDetailsContentItem[]
  headerLogo?: string
  headerSubtitle?: string
  headerTitle?: string
  loading?: boolean
  onHeaderAction?: () => void
  showActionHeader?: boolean
  showHeaderLogo?: boolean
  timelineType: string
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${ColorVariables.UI_BG_02}`,
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  headerMainContainer: css({
    display: 'flex',
    alignItems: 'center',
    ...mediaQuery(MediaQuery.MIN_MD, {
      columnGap: spacing(Spacing.S4),
      paddingBottom: spacing(Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
      padding: spacing(Spacing.S4),
      rowGap: spacing(Spacing.S4),
    }),
  }),
  headerTextContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      rowGap: spacing(Spacing.S2),
    }),
  }),
  headerText: css({
    whiteSpace: 'pre-wrap',
    ...mediaQuery(MediaQuery.MAX_SM, {
      textAlign: 'center',
    }),
  }),
  placeholder: css({
    width: '80%',
    height: '80%',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  headerIcon: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      backgroundColor: ColorVariables.UI_BG_BRAND_PRIMARY,
    }),
  }),
  headerTypeIcon: css({
    width: 24,
    height: 'auto',
  }),
  actionHeaderContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(Spacing.S2),
    padding: spacing(Spacing.S4, 0),
    ...mediaQuery(MediaQuery.MIN_MD, {
      alignItems: 'flex-start',
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
      alignItems: 'center',
    }),
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4, Spacing.S2),
    }),
  }),
  detailsContent: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  detailsContentItem: css({
    padding: spacing(Spacing.S4, 0),
    borderBottom: `1px solid ${ColorVariables.UI_BG_02}`,
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4),
    }),
  }),
  detailsContentItemHeaderContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  detailsContentItemHeaderTextContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(Spacing.S1),
  }),
  detailsContentText: css({
    marginTop: spacing(Spacing.S1),
  }),
  htmlDetailsContentText: css({
    marginTop: spacing(Spacing.S4),
  }),
}

const TimelineDetailsView = ({
  actionHeaderTitle,
  actionHeaderActionTitle,
  showActionHeader,
  showHeaderLogo,
  headerLogo,
  HeaderComponent,
  headerTitle,
  headerSubtitle,
  contentItems,
  contentHeaderTitle,
  contentHeaderLogo,
  timelineType,
  loading,
  onHeaderAction,
  ...rest
}: TimelineDetailsViewProps) => {
  const ContentContainer = mediaQueryMatches(MediaQuery.MAX_SM) ? Card : 'div'

  return (
    <div css={styles.container} {...rest}>
      <ContentContainer css={styles.header}>
        <div
          css={[
            styles.headerMainContainer,
            !loading
              && showActionHeader
              && css({ borderBottom: `1px solid ${ColorVariables.UI_BG_02}` }),
          ]}
        >
          {mediaQueryMatches(MediaQuery.MIN_MD) && showHeaderLogo ? (
            <Avatar
              Placeholder={<KibAccountIcon css={styles.placeholder} />}
              avatarSize={56}
              loading={loading}
              photo={headerLogo}
            />
          ) : mediaQueryMatches(MediaQuery.MAX_SM) || HeaderComponent ? (
            <Icon
              circle
              Component={
                mediaQueryMatches(MediaQuery.MIN_MD) ? (
                  HeaderComponent
                ) : (
                  <img
                    alt={timelineType}
                    css={styles.headerTypeIcon}
                    src={TimelineImages[timelineType]}
                  />
                )
              }
              css={[styles.headerIcon, css({ width: 40, height: 40 })]}
            />
          ) : undefined}

          <div css={styles.headerTextContainer}>
            <Text
              css={styles.headerText}
              loading={loading}
              {...(mediaQueryMatches(MediaQuery.MAX_SM)
                ? { skeletonWidth: '100%' }
                : { skeletonWidth: '50%' })}
              variant={TextVariant.SECTION_2}
            >
              {headerTitle}
            </Text>

            <Text
              css={styles.headerText}
              loading={loading}
              skeletonLines={2}
              variant={TextVariant.PARAGRAPH_1}
              {...(mediaQueryMatches(MediaQuery.MAX_SM)
                ? { skeletonWidth: '100%' }
                : { skeletonWidth: '50%' })}
            >
              {headerSubtitle}
            </Text>
          </div>
        </div>

        {!loading && showActionHeader && (
          <div css={styles.actionHeaderContainer}>
            {actionHeaderTitle && (
              <Text variant={TextVariant.PARAGRAPH_2}>{actionHeaderTitle}</Text>
            )}

            <Button onClick={onHeaderAction}>{actionHeaderActionTitle}</Button>
          </div>
        )}
      </ContentContainer>

      {(contentItems?.length || 0) > 0 && (
        <div css={styles.content}>
          <ContentContainer css={styles.detailsContent}>
            {contentItems?.map(
              (item: TimelineDetailsContentItem, index: number) => {
                const showHeader =
                  index === 0
                  && mediaQueryMatches(MediaQuery.MAX_SM)
                  && contentHeaderTitle

                return (
                  <div css={styles.detailsContentItem} key={item.title}>
                    <div css={styles.detailsContentItemHeaderContainer}>
                      <div css={styles.detailsContentItemHeaderTextContainer}>
                        {showHeader && (
                          <Text variant={TextVariant.PARAGRAPH}>
                            {contentHeaderTitle}
                          </Text>
                        )}

                        <Text variant={TextVariant.SECTION_1}>
                          {item.title}
                        </Text>
                      </div>

                      {showHeader && (
                        <Avatar
                          Placeholder={
                            <KibAccountIcon css={styles.placeholder} />
                          }
                          avatarSize={44}
                          loading={loading}
                          photo={contentHeaderLogo}
                        />
                      )}
                    </div>

                    {!item?.html ? (
                      <Text
                        css={styles.detailsContentText}
                        loading={loading}
                        variant={TextVariant.PARAGRAPH}
                      >
                        {item.content || '-'}
                      </Text>
                    ) : (
                      <div
                        css={styles.htmlDetailsContentText}
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: item.content || '-',
                        }}
                      />
                    )}
                  </div>
                )
              },
            )}
          </ContentContainer>
        </div>
      )}
    </div>
  )
}

export default TimelineDetailsView
