/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { CREATE_CHAT } from '../../../api/mutations/chat'
import { GET_CHATS } from '../../../api/queries/chat'
import { GET_NEW_CHAT_CLIENT_INFO } from '../../../api/queries/client'
import { setAllMessagesRead } from '../../../api/twilio'
import { Spacing } from '../../../constants/spacing'
import { Chat } from '../../../types/dto/Chat'
import { Patient } from '../../../types/dto/Patient'
import { ClientResponse } from '../../../types/response'
import { spacing } from '../../../utils/spacing'
import { BATCH_SIZE } from '../../main/chat/ChatListFragment'
import Button from '../button/Button'
import ChatClientContext from '../chat/ChatClientContext'
import InputText from '../input/InputText'
import InputTextArea from '../input/InputTextArea'
import SelectInput from '../input/SelectInput'
import {
  CertificatesType,
  CertificatesTypeProp,
} from '../patient/certificates/CertificatesSheet'
import SelectPatientHorizontalList from '../patient/SelectPatientHorizontalList'

type CreateChatFormProps = {
  certificateType?: CertificatesTypeProp
  onChatCreated: (chat: Chat) => void
  prescriptionTitle?: string
  selectedPatientId?: string
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }),
  firstInput: css({
    marginTop: spacing(Spacing.S6),
  }),
  input: css({
    marginTop: spacing(Spacing.S4),
  }),
  button: css({
    alignSelf: 'flex-end',
    marginTop: spacing(Spacing.S4),
  }),
}

const CreateChatForm = ({
  selectedPatientId,
  certificateType,
  prescriptionTitle,
  onChatCreated,
  ...rest
}: CreateChatFormProps) => {
  const { t } = useTranslation(['Chat', 'Common', 'Home', 'Pet'])
  const client = useContext(ChatClientContext)

  const [selectedBusinessId, setSelectedBusinessId] = useState<
  string | undefined
  >()
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>()
  const [subject, setSubject] = useState<string | undefined>()
  const [message, setMessage] = useState<string | undefined>()

  const { data: { me } = {}, loading: clientLoading } =
    useQuery<ClientResponse>(GET_NEW_CHAT_CLIENT_INFO)

  const [createChat, { loading: createChatLoading }] = useMutation(
    CREATE_CHAT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_CHATS,
          variables: {
            offset: 0,
            limit: BATCH_SIZE,
          },
        },
      ],
    },
  )

  const hasSinglePatient = (me?.patients?.length || 0) === 1
  const hasMultipleBusinesses = (me?.businesses?.length || 0) > 1
  const canSendMessage =
    selectedBusinessId && selectedPatient && subject && message

  useEffect(() => {
    if (me) {
      if (hasSinglePatient) {
        setSelectedPatient(me?.patients?.[0])
      } else if (selectedPatientId) {
        const selectedPet = me?.patients?.find(
          patient => patient.id === selectedPatientId,
        )
        if (selectedPet) {
          setSelectedPatient(selectedPet)
          if (certificateType) {
            setSubject(
              certificateType === CertificatesType.VACCINATION
                ? t('Pet:VACCINATION_REQUEST_SUBJECT', {
                  patientName: selectedPet?.name,
                })
                : t('Pet:RABIES_REQUEST_SUBJECT', {
                  patientName: selectedPet?.name,
                }),
            )

            setMessage(
              t('Pet:VACCINATION_REQUEST_MESSAGE', {
                patientName: selectedPet?.name,
              }),
            )
          } else if (prescriptionTitle) {
            setSubject(`${t('Home:REQUEST_REFILL')} ${prescriptionTitle}`)
          }
        }
      }

      setSelectedBusinessId(me?.businesses?.[0]?.id)
    }
  }, [me])

  const businessOptions =
    me?.businesses?.map(business => ({
      value: business.id,
      label: business.name || '',
    })) || []

  const handlePatientSelected = (patient: Patient | undefined) => {
    setSelectedPatient(patient)
  }

  const handleBusinessSelected = (businessId: string) => {
    setSelectedBusinessId(businessId)
    if (!R.includes({ id: businessId }, selectedPatient?.businesses || [])) {
      handlePatientSelected(undefined)
    }
  }

  const handleCreateChat = async () => {
    const {
      data: { createChat: chat },
    } = await createChat({
      variables: {
        input: {
          businessId: selectedBusinessId,
          title: subject,
          patientId: selectedPatient?.id,
          message,
        },
      },
    })

    if (chat?.channelSID) {
      await setAllMessagesRead(chat.channelSID, client)
    }

    onChatCreated(chat)
  }

  return (
    <div css={styles.container} {...rest}>
      <SelectPatientHorizontalList
        loading={clientLoading}
        patients={me?.patients}
        selectedPatient={selectedPatient}
        onPatientSelected={handlePatientSelected}
      />

      <SelectInput
        css={styles.firstInput}
        disabled={!hasMultipleBusinesses || clientLoading || createChatLoading}
        id="ccf-to"
        label={t('Chat:TO')}
        options={businessOptions}
        value={selectedBusinessId}
        onChange={event => {
          handleBusinessSelected(event.target.value)
        }}
      />

      <InputText
        css={styles.input}
        disabled={createChatLoading}
        id="ccf-subj"
        label={t('Chat:SUBJECT')}
        value={subject}
        onChangeText={setSubject}
      />

      <InputTextArea
        css={styles.input}
        disabled={createChatLoading}
        id="ccf-msg"
        label={t('Chat:MESSAGE')}
        placeholder={t('Chat:PLEASE_LET_US_KNOW_YOUR_CONCERN')}
        resize="none"
        value={message}
        onChangeText={setMessage}
      />

      <Button
        css={styles.button}
        disabled={!canSendMessage || createChatLoading}
        id="ccf-send"
        loading={createChatLoading}
        onClick={handleCreateChat}
      >
        {t('Chat:SEND')}
      </Button>
    </div>
  )
}

export default CreateChatForm
