/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { KibAttachIcon } from '@chewy/kib-icons-react/legacy'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { ColorVariables } from '../../../constants/colors'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Business } from '../../../types/dto/Business'
import { Client } from '../../../types/dto/Client'
import { Message, MessageContentType } from '../../../types/entities'
import { mapLinkToAlias } from '../../../utils/chat'
import { clickable } from '../../../utils/component'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import { linkify } from '../../../utils/text'
import Avatar from '../icon/Avatar'
import Text, { TextVariant } from '../typography/Text/Text'

type MessageViewProps = {
  business?: Business
  client?: Client
  index: number
  message: Message
  nextMessage?: Message
  onShowMediaPreview?: (message: Message) => void
  previousMessage?: Message
}

const styles = {
  outerContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  container: css({
    display: 'flex',
    width: '100%',
  }),
  otherContainer: css({
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    columnGap: spacing(Spacing.S2),
  }),
  messageColumnContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    rowGap: spacing(Spacing.S2),
    width: '100%',
  }),
  ownMessageColumnContainer: css({
    alignItems: 'flex-end',
  }),
  hiddenAvatar: css({
    visibility: 'hidden',
  }),
  messageContainer: css({
    display: 'flex',
    padding: spacing(Spacing.S4),
    maxWidth: '75%',
  }),
  ownMessageContainer: css({
    borderRadius: '20px 20px 0px 20px',
  }),
  otherMessageContainer: css({
    borderRadius: '20px 20px 20px 0px',
  }),
  ownText: css({
    color: ColorVariables.TEXT_INVERSE,
    a: {
      color: ColorVariables.TEXT_INVERSE,
    },
  }),
  timestamp: css({
    alignSelf: 'center',
    margin: spacing(Spacing.S2, 0),
  }),
  firstMessageTimestamp: css({
    marginTop: 0,
  }),
  htmlDiv: css({
    overflow: 'hidden',
  }),
  image: css({
    maxWidth: '50%',
    maxHeight: 'auto',
    border: '3px solid',
  }),
  ownImage: css({
    borderRadius: '20px 20px 0px 20px',
  }),
  otherImage: css({
    borderRadius: '20px 20px 20px 0px',
  }),
  documentHeader: css({
    display: 'flex',
    alignItems: 'center',
  }),
  documentIcon: css({
    width: 18,
    height: 18,
    marginRight: spacing(Spacing.S1),
  }),
  ownIcon: css({
    color: ColorVariables.TEXT_INVERSE,
  }),
  avatarImage: css({
    objectFit: 'contain',
  }),
}

const isSameTime = (currentMessage?: Message, message?: Message) => {
  if (!message || !currentMessage) {
    return false
  }

  const previousMessageTime = moment(message?.createdAt)
  const currentMessageTime = moment(currentMessage?.createdAt)
  return currentMessageTime.diff(previousMessageTime, 'minutes') < 1
}

const MessageView = ({
  client,
  business,
  message,
  previousMessage,
  nextMessage,
  index,
  onShowMediaPreview,
  ...rest
}: MessageViewProps) => {
  const { t } = useTranslation('Common')

  const isOwnMessage = client?.id === message.user?.id
  const isOtherNextMessage =
    !isOwnMessage
    && (message?.user?.id !== nextMessage?.user?.id
      || !isSameTime(nextMessage, message))
  const isTimeDiffToRenderTime =
    message?.user?.id !== previousMessage?.user?.id
    || !isSameTime(message, previousMessage)
  const isText = message?.type === MessageContentType.TEXT
  const isImage = message?.type === MessageContentType.IMAGE
  const isDocument = message?.type === MessageContentType.DOCUMENT

  const now = moment()
  const messageDate = moment(message.createdAt)
  const timestamp = messageDate.calendar({
    sameDay: `[${t('Common:TODAY')}], ${DateFormat.FULL_TIME_MERIDIAN_UPPER}`,
    lastDay: `[${t('Common:YESTERDAY')}], ${
      DateFormat.FULL_TIME_MERIDIAN_UPPER
    }`,
    lastWeek: `[${t('Common:LAST')}] ${
      DateFormat.DAY_OF_WEEK_COMMA_FULL_TIME_MERIDIAN_UPPER
    }`,
    sameElse: () =>
      messageDate.year() !== now.year()
        ? DateFormat.DAY_OF_WEEK_SHORT_COMMA_MONTH_DAY_COMMA_FULL_TIME_MERIDIAN_UPPER_COMMA_YEAR
        : DateFormat.DAY_OF_WEEK_SHORT_COMMA_MONTH_DAY_COMMA_FULL_TIME_MERIDIAN_UPPER,
  })

  return (
    <div css={styles.outerContainer} {...rest}>
      {isTimeDiffToRenderTime && (
        <Text
          css={[styles.timestamp, index === 0 && styles.firstMessageTimestamp]}
          variant={TextVariant.CAPTION}
        >
          {timestamp}
        </Text>
      )}

      <div css={[styles.container, !isOwnMessage && styles.otherContainer]}>
        {!isOwnMessage && (
          <Avatar
            avatarBackgroundColor={ColorVariables.UI_BG_PRIMARY}
            avatarSize={40}
            css={!isOtherNextMessage && styles.hiddenAvatar}
            imageStyle={styles.avatarImage}
            photo={business?.logo}
          />
        )}

        <div
          css={[
            styles.messageColumnContainer,
            isOwnMessage && styles.ownMessageColumnContainer,
          ]}
        >
          {isImage && (
            <img
              alt={message?.fileName || ''}
              css={[
                styles.image,
                isOwnMessage && styles.ownImage,
                !isOwnMessage && styles.otherImage,
                isOwnMessage
                  ? css({
                    borderColor: ColorVariables.UI_BG_BRAND_ALTERNATE,
                  })
                  : mediaQueryMatches(MediaQuery.MIN_MD)
                    ? css({ borderColor: ColorVariables.UI_BG_02 })
                    : css({ borderColor: ColorVariables.UI_BG_PRIMARY }),
              ]}
              src={
                message?.image?.url !== null ? message?.image?.url : undefined
              }
              {...clickable(() => onShowMediaPreview?.(message))}
            />
          )}

          {(isText || isDocument) && (
            <div
              css={[
                styles.messageContainer,
                isOwnMessage && styles.ownMessageContainer,
                !isOwnMessage && styles.otherMessageContainer,
                isOwnMessage
                  ? css({
                    backgroundColor: ColorVariables.UI_BG_BRAND_ALTERNATE,
                  })
                  : mediaQueryMatches(MediaQuery.MIN_MD)
                    ? css({ backgroundColor: ColorVariables.UI_BG_02 })
                    : css({ backgroundColor: ColorVariables.UI_BG_PRIMARY }),
              ]}
              {...(isDocument
                ? clickable(() => onShowMediaPreview?.(message))
                : {})}
            >
              {isText && (
                <div
                  css={[styles.htmlDiv, isOwnMessage && styles.ownText]}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: linkify(message.text, mapLinkToAlias) || '',
                  }}
                />
              )}

              {isDocument && (
                <div>
                  <div css={styles.documentHeader}>
                    <KibAttachIcon
                      css={[
                        styles.documentIcon,
                        isOwnMessage && styles.ownIcon,
                      ]}
                    />

                    <Text
                      css={isOwnMessage && styles.ownText}
                      variant={TextVariant.CAPTION}
                    >
                      {t('Common:DOCUMENT')}
                    </Text>
                  </div>

                  <Text
                    css={isOwnMessage && styles.ownText}
                    variant={TextVariant.PARAGRAPH}
                  >
                    {message?.fileName || t('Common:FILE')}
                  </Text>
                </div>
              )}
            </div>
          )}

          {isOtherNextMessage && (
            <Text
              css={[isOwnMessage && styles.ownText]}
              variant={TextVariant.CAPTION}
            >
              {business?.name || ''}
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default MessageView
