/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { Spacing } from '../../../constants/spacing'
import { FileTemplate } from '../../../types/entities'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis, ButtonTheme } from '../button/Button'
import FileInput, { FileInputHandle } from '../input/file/FileInput'
import Modal, { ModalProps } from './Modal'

export const FileInputUsage = {
  AVATAR: 'avatar',
  ATTACHMENT: 'attachment',
}

type FileInputModalProps = ModalProps & {
  fileInputUsage: (typeof FileInputUsage)[keyof typeof FileInputUsage]
  onFileReady?: (file: FileTemplate) => void
}

const styles = {
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: spacing(Spacing.S2),
  }),
}

const FileInputModal = ({
  fileInputUsage,
  onFileReady,
  ...rest
}: FileInputModalProps) => {
  const { t } = useTranslation('Common')

  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<FileTemplate | undefined>()

  const isAvatar = fileInputUsage === FileInputUsage.AVATAR

  const inputRef = useRef<FileInputHandle>(null)

  return (
    <Modal
      {...rest}
      // eslint-disable-next-line
      controls={({ close }) =>
        isAvatar && (
          <div css={styles.buttonsContainer}>
            <Button
              emphasis={ButtonEmphasis.TERTIARY}
              id="fim-can"
              theme={ButtonTheme.UTILITY}
              onClick={() => {
                close()
                inputRef?.current?.clear()
              }}
            >
              {t('Common:CANCEL')}
            </Button>

            <Button
              disabled={loading || !file}
              id={isAvatar ? 'fim-save' : 'fim-att'}
              loading={loading}
              onClick={() => {
                inputRef?.current?.crop()
              }}
            >
              {t('Common:SAVE')}
            </Button>
          </div>
        )
      }
      size="default"
    >
      <FileInput
        aspectRatio="1:1"
        enableCropper={isAvatar}
        onlyImages={isAvatar}
        ref={inputRef}
        onFileReady={data => {
          if (onFileReady) {
            onFileReady(data)
          }
          setLoading(false)
        }}
        onFileSelected={data =>
          isAvatar ? setFile(data) : onFileReady?.(data)
        }
        onProcessing={setLoading}
      />
    </Modal>
  )
}

export default FileInputModal
