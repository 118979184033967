/** @jsxImportSource @emotion/react */
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import AccountDetailsFragment from './AccountDetailsFragment'
import AccountFragment from './AccountFragment'

const AccountPage = () => (
  <KibGrid align="center">
    <KibGridItem offset="2@min-lg" span="4@max-sm 3@min-md 3@min-lg">
      <AccountFragment />

      {mediaQueryMatches(MediaQuery.MAX_SM) && <AccountDetailsFragment />}
    </KibGridItem>

    {mediaQueryMatches(MediaQuery.MIN_MD) && (
      <KibGridItem push="2@min-lg" span="5@min-md 5@min-lg">
        <AccountDetailsFragment />
      </KibGridItem>
    )}
  </KibGrid>
)

export default AccountPage
