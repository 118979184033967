/** @jsxImportSource @emotion/react */
import { KibField, KibFieldProps } from '@chewy/kib-fields-new-react'

export type InputTextProps = KibFieldProps & {
  onChangeText?: (value?: string) => void
}

const InputText = ({ onChangeText, ...rest }: InputTextProps) => (
  <KibField
    crossOrigin
    onChange={event => {
      if (onChangeText) {
        onChangeText(event.target.value)
      }
    }}
    {...rest}
  />
)

export default InputText
