import gql from 'graphql-tag'

export const GET_UPCOMING_TASKS = gql`
  query GetUpcomingTasks($offset: Int!, $limit: Int!) {
    upcomingTasks(offset: $offset, limit: $limit) {
      data {
        id
        name
        instructions
        notes
        dueDate
        completionDate
        aggregatedCompletionDate
        recurrenceType
        readiness
        state {
          id
          name
        }
        aggregatedState {
          id
          name
        }
        timesOfDay {
          times
        }
        recurrencePeriod {
          amount
          unit
        }
        patient {
          id
          name
          photo
          photoThumbnail
        }
        recurrenceStartDatetime
        recurrenceEndDatetime
        business {
          id
          name
        }
      }
      totalCount
    }
  }
`

export const GET_COMPLETED_TASKS = gql`
  query GetAllTasks($offset: Int!, $limit: Int!) {
    allTasksInDoneState(offset: $offset, limit: $limit, inDoneState: true) {
      data {
        id
        name
        instructions
        notes
        dueDate
        completionDate
        aggregatedCompletionDate
        recurrenceType
        readiness
        state {
          id
          name
        }
        aggregatedState {
          id
          name
        }
        timesOfDay {
          times
        }
        recurrencePeriod {
          amount
          unit
        }
        patient {
          id
          name
          photo
          photoThumbnail
        }
        recurrenceStartDatetime
        recurrenceEndDatetime
        business {
          id
          name
        }
      }
      totalCount
    }
  }
`

export const GET_TASK_UPCOMING = gql`
  query GetTaskUpcoming($id: ID!, $offset: Int!, $limit: Int!) {
    task(id: $id) {
      id
      readiness
      upcoming(offset: $offset, limit: $limit) {
        data {
          id
          name
          dueDate
          completionDate
          state {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`

export const GET_TASK_OVERDUE = gql`
  query GetTaskOverdue($id: ID!, $offset: Int!, $limit: Int!) {
    task(id: $id) {
      id
      readiness
      overdue(offset: $offset, limit: $limit) {
        data {
          id
          name
          dueDate
          completionDate
          state {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`

export const GET_TASK_COMPLETED = gql`
  query GetTaskCompleted($id: ID!, $offset: Int!, $limit: Int!) {
    task(id: $id) {
      id
      readiness
      history(offset: $offset, limit: $limit) {
        data {
          id
          name
          dueDate
          completionDate
          state {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`

export const GET_TASK_READINESS = gql`
  query GetTaskReadiness($id: ID!) {
    task(id: $id) {
      id
      readiness
      aggregatedState {
        id
        name
      }
    }
  }
`
