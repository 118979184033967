/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { KibAccountIcon } from '@chewy/kib-icons-react'
import KibTransition from '@chewy/kib-transition-react'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { SCHEDULE_APPOINTMENT } from '../../../../api/mutations/appointment'
import { GET_PATIENT_CARD_DATA } from '../../../../api/queries/patient'
import { ColorVariables } from '../../../../constants/colors'
import { DateFormat } from '../../../../constants/dateFormat'
import { Spacing } from '../../../../constants/spacing'
import { SuggestedAppointment } from '../../../../types/dto/Appointment'
import { Business } from '../../../../types/dto/Business'
import { Patient } from '../../../../types/dto/Patient'
import { getPersonName, getTimezone } from '../../../../utils'
import { addYearToFormatIfNotCurrent } from '../../../../utils/date'
import { getPatientCardQueryVariables } from '../../../../utils/patient'
import { spacing } from '../../../../utils/spacing'
import Button, {
  ButtonEmphasis,
  ButtonSize,
  ButtonTheme,
} from '../../button/Button'
import Card from '../../card/Card/Card'
import Divider from '../../divider/Divider'
import Avatar from '../../icon/Avatar'
import Tag, { TagTheme } from '../../tag/Tag'
import Text, { TextVariant } from '../../typography/Text/Text'

export const SUGGESTED_WIDGET_HEIGHT = 200

type SuggestedAppointmentWidgetProps = {
  business?: Business
  onBooked: (suggestedAppointment?: SuggestedAppointment) => void
  onChange: (SuggestedAppointment?: SuggestedAppointment) => void
  onDecline: (suggestedAppointment?: SuggestedAppointment) => void
  onError: (message: string) => void
  patient?: Patient
  suggestedAppointment?: SuggestedAppointment
  supportedReminderStateIds: string[]
}

const styles = {
  container: css({
    position: 'relative',
    minHeight: SUGGESTED_WIDGET_HEIGHT,
  }),
  contentContainer: css({
    position: 'relative',
    minHeight: SUGGESTED_WIDGET_HEIGHT,
  }),
  label: css({
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 0,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(Spacing.S6, Spacing.S4, Spacing.S3, Spacing.S4),
  }),
  mainContentWithAvatar: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
  mainContent: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  placeholder: css({
    width: '80%',
    height: '80%',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  dateText: css({
    marginTop: spacing(Spacing.S1),
  }),
  withVeterinatianText: css({
    marginTop: spacing(Spacing.S1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  divider: css({
    marginTop: spacing(Spacing.S2),
    marginBottom: spacing(Spacing.S3),
  }),
  buttonsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    rowGap: spacing(Spacing.S3),
  }),
  declineButton: css({
    marginRight: 'auto',
  }),
}

const SuggestedAppointmentWidget = ({
  suggestedAppointment,
  patient,
  business,
  supportedReminderStateIds,
  onBooked,
  onChange,
  onDecline,
  onError,
  ...rest
}: SuggestedAppointmentWidgetProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const [isShown, setIsShown] = useState(true)

  const [scheduleAppointment, { loading: bookingLoading }] = useMutation(
    SCHEDULE_APPOINTMENT,
    {
      refetchQueries: [
        {
          query: GET_PATIENT_CARD_DATA,
          variables: getPatientCardQueryVariables(
            patient?.id,
            supportedReminderStateIds,
          ),
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        onBooked(suggestedAppointment)
      },
      onError: () => {
        onError(t('Home:ERROR_WHILE_BOOKING_APPOINTMENT'))
      },
    },
  )

  const appointmentDate = addYearToFormatIfNotCurrent(
    DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME_SHORT,
    moment.tz(
      suggestedAppointment?.scheduledStartDatetime,
      business?.timezone || getTimezone(),
    ),
  )
  const withVeterinatianText = `${t('Common:WITH')} ${getPersonName(
    suggestedAppointment?.veterinarian,
  )}`

  const handleBook = () => {
    scheduleAppointment({
      variables: {
        input: {
          businessId: suggestedAppointment?.businessId,
          businessAppointmentTypeId:
            suggestedAppointment?.businessAppointmentReason
              ?.businessAppointmentTypeId,
          patientId: patient?.id,
          veterinarianId: suggestedAppointment?.veterinarian?.id,
          scheduledStartDatetime: suggestedAppointment?.scheduledStartDatetime,
          scheduledEndDatetime: suggestedAppointment?.scheduledEndDatetime,
          notes: suggestedAppointment?.notes,
        },
      },
    })
  }

  return (
    <KibTransition
      afterLeave={() => onDecline(suggestedAppointment)}
      name="slide-right"
      speed="slow"
      switchKey={isShown}
    >
      <Card css={styles.container} {...rest}>
        <Tag css={styles.label} theme={TagTheme.CAUTION}>
          {t('Home:SUGGESTED').toUpperCase()}
        </Tag>

        <div css={styles.content}>
          <div css={styles.mainContentWithAvatar}>
            <div css={styles.mainContent}>
              <Text variant={TextVariant.PARAGRAPH}>
                {suggestedAppointment?.title || ''}
              </Text>

              <Text css={styles.dateText} variant={TextVariant.SECTION_2}>
                {appointmentDate || ''}
              </Text>

              <Text
                css={styles.withVeterinatianText}
                variant={TextVariant.PARAGRAPH}
              >
                {withVeterinatianText || ''}
              </Text>
            </div>

            <Avatar
              Placeholder={<KibAccountIcon css={styles.placeholder} />}
              avatarSize={44}
              photo={
                suggestedAppointment?.veterinarian?.photoThumbnail
                || suggestedAppointment?.veterinarian?.photo
              }
            />
          </div>

          <Text
            css={styles.withVeterinatianText}
            variant={TextVariant.PARAGRAPH}
          >
            {suggestedAppointment?.description || ''}
          </Text>

          <Divider css={styles.divider} />

          <div css={styles.buttonsContainer}>
            <Button
              css={styles.declineButton}
              disabled={bookingLoading}
              emphasis={ButtonEmphasis.TERTIARY}
              size={ButtonSize.MEDIUM}
              theme={ButtonTheme.UTILITY}
              onClick={() => setIsShown(false)}
            >
              {t('Common:DECLINE')}
            </Button>

            <Button
              disabled={bookingLoading}
              emphasis={ButtonEmphasis.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={() => onChange(suggestedAppointment)}
            >
              {t('Common:CHANGE')}
            </Button>

            <Button
              disabled={bookingLoading}
              loading={bookingLoading}
              size={ButtonSize.MEDIUM}
              onClick={handleBook}
            >
              {t('Common:BOOK')}
            </Button>
          </div>
        </div>
      </Card>
    </KibTransition>
  )
}

export default SuggestedAppointmentWidget
