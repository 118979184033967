/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { KibAccountIcon } from '@chewy/kib-icons-react'
import { KibSkeletonText } from '@chewy/kib-skeleton-react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import useAvatarUpload from '../../../api/imageUpload'
import { UPDATE_ME } from '../../../api/mutations/client'
import { GET_ACCOUNT_CLIENT_DATA } from '../../../api/queries/client'
import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { FileTemplate } from '../../../types/entities'
import { distinct, getPersonName } from '../../../utils'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis, ButtonTheme } from '../button/Button'
import Card from '../card/Card/Card'
import Avatar from '../icon/Avatar'
import EditIcon from '../icon/EditIcon'
import InputText from '../input/InputText'
import FileInputModal, { FileInputUsage } from '../modal/FileInputModal'
import Text, { TextVariant } from '../typography/Text/Text'

type Validation = {
  firstName: boolean
  lastName: boolean
}

const styles = {
  container: css({
    display: 'flex',
    padding: spacing(Spacing.S6, Spacing.S4),
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  content: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  avatarContainer: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  }),
  placeholder: css({
    width: '80%',
    height: '80%',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  linkPlaceholder: css({
    visibility: 'hidden',
  }),
  editModeContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: spacing(Spacing.S4),
  }),
  editableInput: css({
    width: '100%',
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S4),
  }),
  saveButton: css({
    marginRight: spacing(Spacing.S2),
  }),
  nameContainer: css({
    marginTop: spacing(Spacing.S4),
  }),
  linkContainer: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }),
}

const validate = (
  firstName: string | undefined,
  lastName: string | undefined,
): Validation => ({
  firstName: Boolean(firstName),
  lastName: Boolean(lastName),
})

const ClientCard = () => {
  const { t } = useTranslation(['Account', 'Common'])
  const apolloClient = useApolloClient()

  const [firstName, setFirstName] = useState<string | undefined>('')
  const [lastName, setLastName] = useState<string | undefined>('')
  const [newPhoto, setNewPhoto] = useState<FileTemplate | undefined>()

  const [validation, setValidation] = useState<Validation>()
  const [triedToSave, setTriedToSave] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [changed, setChanged] = useState(false)
  const [fileInputOpen, setFileInputOpen] = useState(false)
  const [photoLoading, setPhotoLoading] = useState<boolean>(false)

  const { data: { me = {} } = {}, loading } = useQuery(GET_ACCOUNT_CLIENT_DATA)

  const [updateContactInfo, { loading: updateLoading }] = useMutation(
    UPDATE_ME,
    {
      refetchQueries: [{ query: GET_ACCOUNT_CLIENT_DATA }],
      onCompleted: () => {
        setEditMode(false)
      },
    },
  )

  const { uploadImage } = useAvatarUpload()

  const setInitialClientData = () => {
    setFirstName(me?.firstName)
    setLastName(me?.lastName)
    setNewPhoto(undefined)
    setChanged(false)
    setValidation(undefined)
    setTriedToSave(false)
  }

  useEffect(() => {
    setInitialClientData()
  }, [me])

  useEffect(() => {
    setChanged(
      me?.firstName !== firstName
        || me?.lastName !== lastName
        || Boolean(newPhoto),
    )
  }, [firstName, lastName, newPhoto])

  const handleSave = async () => {
    setTriedToSave(true)
    const validationData = validate(firstName, lastName)

    if (R.all(Boolean, Object.values(validationData))) {
      if (newPhoto) {
        setPhotoLoading(true)
        const { url, thumbnailUrl } = await uploadImage(newPhoto, {
          client: me.id,
        })

        apolloClient.writeQuery({
          query: GET_ACCOUNT_CLIENT_DATA,
          data: { me: { ...me, photo: url, photoThumbnail: thumbnailUrl } },
        })

        setNewPhoto(undefined)
      }

      const updatedContact = {
        firstName,
        lastName,
      }

      const clientDistinction = distinct(me, updatedContact)

      if (!R.isEmpty(clientDistinction)) {
        updateContactInfo({
          variables: { input: updatedContact },
        })
      } else {
        setInitialClientData()
        setEditMode(false)
      }
    } else {
      setValidation(validationData)
    }

    setPhotoLoading(false)
  }

  return (
    <Card css={styles.container}>
      <div css={styles.content}>
        <div css={styles.avatarContainer}>
          <EditIcon
            css={styles.linkPlaceholder}
            onEdit={() => setEditMode(true)}
          />

          <Avatar
            Placeholder={<KibAccountIcon css={styles.placeholder} />}
            avatarSize={72}
            editable={editMode}
            id="cc-avatar"
            loading={loading}
            photo={newPhoto?.data || me?.photoThumbnail || me?.photo}
            onClick={() => setFileInputOpen(true)}
          />

          <EditIcon
            css={editMode && styles.linkPlaceholder}
            id="cc-edit"
            onEdit={() => setEditMode(true)}
          />
        </div>

        {!editMode && (
          <div css={styles.nameContainer}>
            {loading ? (
              <KibSkeletonText style={{ width: 120 }} />
            ) : (
              <Text variant={TextVariant.SECTION_1}>{getPersonName(me)}</Text>
            )}
          </div>
        )}

        {editMode && (
          <div css={styles.editModeContainer}>
            <InputText
              css={styles.editableInput}
              id="cc-fn"
              invalid={validation && !validation.firstName}
              label={t('Common:FIRST_NAME')}
              messagesContent={t('Account:PLEASE_ENTER_FIRST_NAME')}
              value={firstName}
              onChangeText={value => {
                setFirstName(value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, firstName: true })
                }
              }}
            />

            <InputText
              css={styles.editableInput}
              id="cc-ln"
              invalid={validation && !validation.lastName}
              label={t('Common:LAST_NAME')}
              messagesContent={t('Account:PLEASE_ENTER_LAST_NAME')}
              value={lastName}
              onChangeText={value => {
                setLastName(value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, lastName: true })
                }
              }}
            />

            <div css={styles.buttonContainer}>
              <Button
                css={styles.saveButton}
                disabled={!changed || updateLoading || photoLoading}
                id="cc-save"
                loading={updateLoading || photoLoading}
                onClick={handleSave}
              >
                {t('Common:SAVE')}
              </Button>
              <Button
                emphasis={ButtonEmphasis.TERTIARY}
                id="cc-can"
                theme={ButtonTheme.UTILITY}
                onClick={() => {
                  if (!updateLoading) {
                    setEditMode(false)
                    setInitialClientData()
                  }
                }}
              >
                {t('Common:CANCEL')}
              </Button>
            </div>
          </div>
        )}
      </div>

      <FileInputModal
        fileInputUsage={FileInputUsage.AVATAR}
        open={fileInputOpen}
        title={t('Account:CHOOSE_YOUR_PROFILE_PICTURE')}
        onClose={() => setFileInputOpen(false)}
        onFileReady={data => {
          setNewPhoto(data)
          setFileInputOpen(false)
        }}
      />
    </Card>
  )
}

export default ClientCard
