/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  KibCallIcon,
  KibChevronDownIcon,
  KibChevronUpIcon,
  KibLinkIcon,
  KibPlaceIcon,
} from '@chewy/kib-icons-react'
import KibTransition from '@chewy/kib-transition-react'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { ColorVariables } from '../../../constants/colors'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import i18n from '../../../locales/i18n'
import { Business, WorkingHours } from '../../../types/dto/Business'
import { getAddress } from '../../../utils'
import {
  getPracticeWorkingHoursMap,
  showBusinessOnMaps,
} from '../../../utils/business'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import useResize from '../../../utils/useResize'
import Button, { ButtonEmphasis, ButtonSize } from '../button/Button'
import Card from '../card/Card/Card'
import Divider from '../divider/Divider'
import Link from '../link/Link'
import Text, { TextVariant } from '../typography/Text/Text'
import BusinessLogo from './BusinessLogo'
import BusinessStatusLabel from './BusinessStatusLabel'

interface BusinessCardProps {
  business: Business
  index?: number
  onBook: (businessId: string) => void
}

const styles = {
  container: css({
    backgroundColor: 'var(--chirp-ui-bg-brand-accent-07, #ffffff)',
    width: '100%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  logoContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minHeight: 90,
  }),
  label: css({
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 0,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
  }),
  buttonsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacing(Spacing.S4),
    rowGap: spacing(Spacing.S4),
    padding: spacing(Spacing.S4),
  }),
  statusContainer: css({
    display: 'flex',
    padding: spacing(0, 0, Spacing.S4, 0),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  link: css({
    marginLeft: spacing(Spacing.S4),
  }),
  detailsContainer: css({
    padding: spacing(Spacing.S4, 0),
  }),
  secondaryButtonContainer: css({
    display: 'flex',
    columnGap: spacing(Spacing.S2),
    rowGap: spacing(Spacing.S2),
  }),
  addressContainer: css({
    padding: spacing(0, Spacing.S4),
  }),
  address: css({
    marginTop: spacing(Spacing.S2),
  }),
  hoursContainer: css({
    marginTop: spacing(Spacing.S7),
  }),
  hoursContainerTitle: css({
    padding: spacing(0, Spacing.S4),
  }),
  dayContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(Spacing.S1),
    padding: spacing(Spacing.S1, Spacing.S4),
    ...mediaQuery(MediaQuery.MD, {
      flexDirection: 'column',
    }),
  }),
  dayContainerCurrent: css({
    backgroundColor: ColorVariables.UI_BG_04,
  }),
  workingHourContainer: css({
    display: 'flex',
    alignItems: 'flex-start',
  }),
  workingHourContainerLeft: css({
    width: '50%',
    ...mediaQuery(MediaQuery.MD, {
      width: '100%',
    }),
  }),
  workingHourContainerRight: css({
    width: '50%',
    ...mediaQuery(MediaQuery.MD, {
      width: '100%',
    }),
  }),
  linkIcon: {
    width: 16,
    height: 16,
    color: ColorVariables.LINK_ACTIVE_PRIMARY,
  },
}

const getTimeRange = (workingHours: WorkingHours, timezone?: string) => {
  if (!workingHours) {
    return i18n.t('Common:CLOSED').toUpperCase()
  }

  const { from, to } = workingHours
  const fromStr = timezone
    ? moment.tz(from, timezone).format(DateFormat.FULL_TIME_SHORT_MERIDIAN)
    : ''
  const toStr = timezone
    ? moment.tz(to, timezone).format(DateFormat.FULL_TIME_SHORT_MERIDIAN)
    : ''

  return `${fromStr} - ${toStr}`
}

const BusinessCard = ({
  business,
  index = 1,
  onBook,
  ...rest
}: BusinessCardProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const [opened, setOpened] = useState(mediaQueryMatches(MediaQuery.MIN_MD))

  const { isMobile } = useResize()

  useEffect(() => {
    setOpened(!isMobile)
  }, [isMobile])

  const businessAddress = getAddress(business)
  const currentWeekDay = business?.timezone
    ? moment.tz(business.timezone).format(DateFormat.DAY_OF_WEEK)
    : ''
  const workingHoursMap = getPracticeWorkingHoursMap(
    business.openingHours,
    business.timezone,
  )

  return (
    <Card css={styles.container} {...rest}>
      <div css={styles.logoContainer}>
        <BusinessLogo logo={business?.logo} name={business?.name} />

        <BusinessStatusLabel
          css={styles.label}
          openingHours={business?.openingHours}
          timezone={business?.timezone}
        />
      </div>

      <Divider />

      <div css={styles.buttonsContainer}>
        <Button id={`bc${index}-book`} onClick={() => onBook(business.id)}>
          {t('Common:BOOK')}
        </Button>

        <div css={styles.secondaryButtonContainer}>
          {business?.website && (
            <Button
              iconOnly
              emphasis={ButtonEmphasis.TERTIARY}
              icon={<KibLinkIcon />}
              id={`bc${index}-web`}
              size={ButtonSize.MEDIUM}
              onClick={() => window.open(business.website, '_blank')}
            >
              {t('Common:WEBSITE')}
            </Button>
          )}

          <Button
            iconOnly
            emphasis={ButtonEmphasis.TERTIARY}
            icon={<KibPlaceIcon />}
            id={`bc${index}-map`}
            size={ButtonSize.MEDIUM}
            onClick={() => showBusinessOnMaps(business)}
          >
            {t('Common:MAP')}
          </Button>

          {business?.primaryPhone && (
            <Button
              iconOnly
              emphasis={ButtonEmphasis.TERTIARY}
              icon={<KibCallIcon />}
              id={`bc${index}-call`}
              size={ButtonSize.MEDIUM}
              onClick={() => {
                window.open(`tel:${business.primaryPhone}`, '_self')
              }}
            >
              {t('Common:CALL')}
            </Button>
          )}
        </div>
      </div>

      {mediaQueryMatches(MediaQuery.MAX_SM) && (
        <div css={styles.statusContainer}>
          <Link
            Icon={opened ? KibChevronUpIcon : KibChevronDownIcon}
            css={styles.link}
            iconStyle={styles.linkIcon}
            id={`bc${index}-info`}
            onClick={() => setOpened(!opened)}
          >
            {t('Home:PRACTICE_INFO')}
          </Link>
        </div>
      )}

      <Divider />

      <KibTransition name="collapse" speed="fast" switchKey={opened}>
        <div css={styles.detailsContainer}>
          <div css={styles.addressContainer}>
            <Text variant={TextVariant.SECTION_1}>{t('Common:ADDRESS')}</Text>

            <Text css={styles.address} variant={TextVariant.PARAGRAPH_1}>
              {businessAddress}
            </Text>
          </div>

          <div css={styles.hoursContainer}>
            <Text
              css={styles.hoursContainerTitle}
              variant={TextVariant.SECTION_1}
            >
              {t('Common:HOURS')}
            </Text>
            {moment.weekdays().map(weekDay => {
              const workingHours = workingHoursMap[weekDay.toLowerCase()]
              const isSameWeekDay = weekDay === currentWeekDay
              const variant = isSameWeekDay
                ? TextVariant.SECTION_2
                : TextVariant.PARAGRAPH_1
              return (
                <div
                  css={[
                    styles.dayContainer,
                    isSameWeekDay && styles.dayContainerCurrent,
                  ]}
                  key={weekDay}
                >
                  <div
                    css={[
                      styles.workingHourContainer,
                      styles.workingHourContainerLeft,
                    ]}
                  >
                    <Text variant={variant}>{weekDay}</Text>
                  </div>
                  <div
                    css={[
                      styles.workingHourContainer,
                      styles.workingHourContainerRight,
                    ]}
                  >
                    <Text variant={variant}>
                      {getTimeRange(workingHours, business.timezone)}
                    </Text>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </KibTransition>
    </Card>
  )
}

export default BusinessCard
