import { Media } from '@twilio/conversations'

export type BlobWithName = Blob & {
  name: string
}

export type FileTemplate = {
  body?: string
  data?: string
  description?: string
  extension?: string | undefined
  fileName?: string | undefined
  fileUrl?: string | undefined
  id?: string | undefined
  name?: string | undefined
  raw?: BlobWithName
  required?: boolean
  typeId?: string
}

export const MessageContentType = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
}

export type MessageType = (typeof MessageContentType)[keyof typeof MessageContentType]

export type Message = {
  contentType?: string
  createdAt?: Date | null
  fileName?: string | null
  id?: string
  image?: { height: number; url?: string | null; width: number }
  mediaSid?: string
  originalMedia?: Media
  text?: string | null
  type?: MessageType
  user?: { id: string | null; name?: string }
}

export type FilePreviewTemplate = {
  contentType?: string
  id: string
  name?: string
  url?: string
}

export type SnackbarState = {
  message?: string
  show: boolean
}
