/** @jsxImportSource @emotion/react */
import {
  KibSnackbar,
  KibSnackbarManager,
  KibSnackbarManagerProps,
} from '@chewy/kib-messaging-react'

type SnackbarProps = KibSnackbarManagerProps & {
  message: string
}

const Snackbar = ({ message, ...rest }: SnackbarProps) => (
  <KibSnackbarManager placeholder="" {...rest}>
    <KibSnackbar>{message}</KibSnackbar>
  </KibSnackbarManager>
)

export default Snackbar
