/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { ColorVariables } from '../../../../constants/colors'
import { Spacing } from '../../../../constants/spacing'
import { Patient } from '../../../../types/dto/Patient'
import { clickable } from '../../../../utils/component'
import { spacing } from '../../../../utils/spacing'
import Avatar from '../../icon/Avatar'
import Text, { TextVariant } from '../../typography/Text/Text'

type PatientMenuItemProps = {
  onClick: () => void
  patient: Patient
}

const styles = {
  container: css({
    display: 'flex',
    padding: spacing(Spacing.S2, Spacing.S2),
    alignItems: 'center',
    '&:hover': css({
      backgroundColor: ColorVariables.MENU_PRIMARY_HOVER_PRIMARY,
      cursor: 'pointer',
    }),
  }),
  label: css({
    textOverflow: 'ellipsis',
    marginLeft: spacing(Spacing.S2),
  }),
}

const PatientMenuItem = ({
  patient,
  onClick,
  ...rest
}: PatientMenuItemProps) => (
  <div css={styles.container} {...clickable(onClick)} {...rest}>
    <Avatar avatarSize={56} photo={patient?.photoThumbnail || patient?.photo} />

    <Text css={styles.label} variant={TextVariant.SECTION_3}>
      {patient?.name}
    </Text>
  </div>
)

export default PatientMenuItem
