import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './components/App'
import { Auth0ProviderWithNavigate } from './components/auth/auth0/Auth0ProviderWithNavigate'
import ScrollToTop from './components/common/navigation/ScrollToTop'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <ScrollToTop />

      <App />
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
)
