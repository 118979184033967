/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { UPDATE_ME } from '../../../api/mutations/client'
import { GET_ACCOUNT_CLIENT_DETAILS_DATA } from '../../../api/queries/client'
import { ColorVariables } from '../../../constants/colors'
import { COUNTRIES_MAP } from '../../../constants/country'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import i18n from '../../../locales/i18n'
import { Client } from '../../../types/dto/Client'
import { SaveFormHandle, SaveFormProps } from '../../../types/forms'
import { distinct, getAddress } from '../../../utils'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import { isValidZipCode } from '../../../utils/validation'
import Button, { ButtonEmphasis, ButtonTheme } from '../button/Button'
import Divider from '../divider/Divider'
import EditIcon from '../icon/EditIcon'
import InputText from '../input/InputText'
import SelectInput from '../input/SelectInput'
import Text, { TextVariant } from '../typography/Text/Text'
import InfoFormItem from './item/InfoFormItem'

const ID = 'clientAddress'

type Validation = {
  addressLine1: boolean
  city: boolean
  state: boolean
  zip: boolean
}

type ClientAddressFormProps = SaveFormProps & {
  alwaysEditMode?: boolean
  client?: Client
  hideBottomDivider?: boolean
  hideEditButtons?: boolean
  loading?: boolean
}

const styles = {
  container: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderBottom: `1px solid ${ColorVariables.UI_BG_04}`,
      padding: spacing(Spacing.S2, 0),
    }),
  }),
  hiddenBorderBottom: css({
    ...mediaQuery(MediaQuery.MIN_XS, {
      borderBottom: 'none',
    }),
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  editModeContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: spacing(Spacing.S2),
    padding: spacing(Spacing.S2, 0),
    ...mediaQuery(MediaQuery.MIN_MD, {
      borderBottom: `1px solid ${ColorVariables.UI_BG_04}`,
    }),
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S2),
  }),
  editableInput: css({
    width: '100%',
  }),
  editableInputHalf: css({
    width: '100%',
    ...mediaQuery(MediaQuery.MIN_MD, {
      width: '49%',
    }),
  }),
  editableInputThird: css({
    width: '100%',
    ...mediaQuery(MediaQuery.MIN_MD, {
      width: '32%',
    }),
  }),
  saveButton: css({
    marginRight: spacing(Spacing.S2),
  }),
  divider: css({
    marginTop: spacing(Spacing.S3),
  }),
}

const validate = (
  addressLine1?: string,
  city?: string,
  stateId?: string,
  zip?: string,
  countryId?: string,
): Validation => ({
  addressLine1: Boolean(addressLine1),
  city: Boolean(city),
  state: Boolean(stateId),
  zip: Boolean(isValidZipCode(zip, countryId)),
})

const getStateLabel = (countryId?: string) => {
  switch (countryId) {
    case 'CA':
      return i18n.t('Account:PROVINCE')
    default:
      return i18n.t('Account:STATE')
  }
}

const getStateErrorLabel = (countryId?: string) => {
  switch (countryId) {
    case 'CA':
      return i18n.t('Account:PROVINCE_IS_REQUIRED')
    default:
      return i18n.t('Account:STATE_IS_REQUIRED')
  }
}

const ClientAddressForm = forwardRef<SaveFormHandle, ClientAddressFormProps>(
  function ClientAddressForm(
    {
      client,
      loading,
      alwaysEditMode,
      hideEditButtons,
      hideBottomDivider,
      onSaveCompleted,
      onSaveLoadingStateChange,
      ...rest
    },
    ref,
  ) {
    const { t } = useTranslation(['Account', 'Common'])

    const [addressLine1, setAddressLine1] = useState<string | undefined>()
    const [addressLine2, setAddressLine2] = useState<string | undefined>()
    const [city, setCity] = useState<string | undefined>()
    const [stateId, setStateId] = useState<string | undefined>()
    const [zip, setZip] = useState<string | undefined>()
    const [county, setCounty] = useState<string | undefined>()
    const [countryId, setCountryId] = useState<string | undefined>()

    const [validation, setValidation] = useState<Validation>()
    const [triedToSave, setTriedToSave] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [changed, setChanged] = useState(false)

    const countiesList = Object.values(COUNTRIES_MAP).map(country => ({
      label: country.name,
      value: country.code,
    }))
    const selectedCountry = countryId
      ? countiesList?.find(({ value }) => value === countryId)
      : undefined

    const statesList = countryId
      ? Object.keys(COUNTRIES_MAP[countryId]?.states).map(id => ({
        label: id,
        value: id,
      }))
      : []
    const selectedState = stateId
      ? { label: stateId, value: stateId }
      : undefined

    const clientAddress = getAddress(client)

    const isInEditMode = alwaysEditMode || editMode

    const [updateContactInfo, { loading: updateLoading }] = useMutation(
      UPDATE_ME,
      {
        refetchQueries: [{ query: GET_ACCOUNT_CLIENT_DETAILS_DATA }],
        onCompleted: () => {
          setEditMode(false)

          if (onSaveCompleted) {
            onSaveCompleted(ID)
          }
        },
      },
    )

    const setInitialClientData = () => {
      setAddressLine1(client?.address)
      setAddressLine2(client?.suite)
      setCity(client?.city)
      setStateId(client?.state)
      setZip(client?.zip)
      setCounty(client?.county)
      setCountryId(client?.country)
      setValidation(undefined)
      setTriedToSave(false)
    }

    useEffect(() => {
      setInitialClientData()
    }, [client])

    useEffect(() => {
      if (onSaveLoadingStateChange) {
        onSaveLoadingStateChange({ id: ID, loading: updateLoading })
      }
    }, [updateLoading])

    useEffect(() => {
      setChanged(
        client?.address !== addressLine1
          || client?.suite !== addressLine2
          || client?.city !== city
          || client?.state !== stateId
          || client?.zip !== zip
          || client?.county !== county
          || client?.country !== countryId,
      )
    }, [addressLine1, addressLine2, city, stateId, zip, county, countryId])

    const handleSave = async () => {
      setTriedToSave(true)
      const validationData = validate(
        addressLine1,
        city,
        stateId,
        zip,
        countryId,
      )

      if (R.all(Boolean, Object.values(validationData))) {
        const updatedClient = {
          address: addressLine1 || '',
          suite: addressLine2 || '',
          city: city || '',
          state: stateId,
          zip: zip || '',
          county: county || '',
          country: countryId || '',
        }

        const clientDistinction = distinct(client, updatedClient)

        if (!R.isEmpty(clientDistinction)) {
          updateContactInfo({ variables: { input: updatedClient } })
        } else {
          setInitialClientData()
          setEditMode(false)

          if (onSaveCompleted) {
            onSaveCompleted(ID)
          }
        }
      } else {
        setValidation(validationData)
      }
    }

    useImperativeHandle(ref, () => ({
      save: () => handleSave(),
    }))

    return (
      <div
        css={[styles.container, hideBottomDivider && styles.hiddenBorderBottom]}
        {...rest}
      >
        <div css={styles.header}>
          <Text variant={TextVariant.SECTION_1}>{t('Account:ADDRESS')}</Text>

          {!isInEditMode && !loading && (
            <EditIcon id="cdc-ca-edit" onEdit={() => setEditMode(true)} />
          )}
        </div>

        {!isInEditMode && (
          <div>
            {(loading || clientAddress) && (
              <InfoFormItem
                hideDivider={mediaQueryMatches(MediaQuery.MAX_SM)}
                loading={loading}
                value={clientAddress}
              />
            )}
            {(loading || client?.county) && (
              <InfoFormItem
                hideDivider={mediaQueryMatches(MediaQuery.MAX_SM)}
                loading={loading}
                title={t('Account:COUNTY')}
                value={county}
              />
            )}
            {(loading || client?.country) && (
              <InfoFormItem
                hideDivider={mediaQueryMatches(MediaQuery.MAX_SM)}
                loading={loading}
                title={t('Account:COUNTRY')}
                value={selectedCountry?.label}
              />
            )}
            {mediaQueryMatches(MediaQuery.MIN_MD)
              && !clientAddress
              && !client?.country
              && !client?.county && <Divider css={styles.divider} />}
          </div>
        )}

        {isInEditMode && (
          <div
            css={[
              styles.editModeContainer,
              hideBottomDivider && styles.hiddenBorderBottom,
            ]}
          >
            <InputText
              css={styles.editableInput}
              id="cdc-ca-al1"
              invalid={validation && !validation.addressLine1}
              label={t('Account:ADDRESS_LINE_1')}
              messagesContent={t('Account:CITY_STREET_LINE_ONE_REQUIRED')}
              value={addressLine1}
              onChangeText={value => {
                setAddressLine1(value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, addressLine1: true })
                }
              }}
            />

            <InputText
              css={styles.editableInput}
              id="cdc-ca-al2"
              label={t('Account:ADDRESS_LINE_2')}
              value={addressLine2}
              onChangeText={setAddressLine2}
            />

            <InputText
              css={styles.editableInputThird}
              id="cdc-ca-city"
              invalid={validation && !validation.city}
              label={t('Account:CITY')}
              messagesContent={t('Account:CITY_IS_REQUIRED')}
              value={city}
              onChangeText={value => {
                setCity(value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, city: true })
                }
              }}
            />

            <SelectInput
              emptyOption
              css={styles.editableInputThird}
              disabled={!countryId}
              id="cdc-ca-state"
              invalid={validation && !validation.state}
              label={getStateLabel(countryId)}
              messagesContent={getStateErrorLabel(countryId)}
              options={statesList}
              value={selectedState?.value}
              onChange={event => {
                setStateId(event.target.value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, state: true })
                }
              }}
            />

            <InputText
              css={styles.editableInputThird}
              id="cdc-ca-zip"
              invalid={validation && !validation.zip}
              label={t('Account:ZIP_CODE')}
              messagesContent={t('Account:PLEASE_ENTER_VALID_ZIP')}
              value={zip}
              onChangeText={value => {
                setZip(value)
                if (triedToSave && validation) {
                  setValidation({ ...validation, zip: true })
                }
              }}
            />

            <SelectInput
              emptyOption
              css={styles.editableInputHalf}
              id="cdc-ca-con"
              label={t('Account:COUNTRY')}
              options={countiesList}
              value={selectedCountry?.value}
              onChange={event => {
                const newCountryId = event.target.value
                if (newCountryId !== countryId) {
                  setStateId(undefined)
                }
                setCountryId(newCountryId)
              }}
            />

            <InputText
              css={styles.editableInputHalf}
              id="cdc-ca-cou"
              label={t('Account:COUNTY')}
              value={county}
              onChangeText={setCounty}
            />

            {!hideEditButtons && (
              <div css={styles.buttonContainer}>
                <Button
                  css={styles.saveButton}
                  disabled={!changed || updateLoading}
                  id="cdc-ca-save"
                  loading={updateLoading}
                  onClick={handleSave}
                >
                  {t('Common:SAVE')}
                </Button>
                <Button
                  emphasis={ButtonEmphasis.TERTIARY}
                  id="cdc-ca-can"
                  theme={ButtonTheme.UTILITY}
                  onClick={() => {
                    if (!updateLoading) {
                      setEditMode(false)
                      setInitialClientData()
                    }
                  }}
                >
                  {t('Common:CANCEL')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  },
)

export default ClientAddressForm
