import {
  Client as TwilioChatClient,
  Conversation,
  JSONValue,
  SendMediaOptions,
} from '@twilio/conversations'

const PAGE_SIZE = 50

export const getConversationBySid = (
  channelSID: string,
  client?: TwilioChatClient,
) => client?.getConversationBySid(channelSID)

export const getMessages = (conversation: Conversation) =>
  conversation.getMessages(PAGE_SIZE)

export const sendMessage = (
  conversation: Conversation,
  message: string | FormData | SendMediaOptions | null,
  messageAttributes: JSONValue | undefined,
) => conversation.sendMessage(message, messageAttributes)

export const setAllMessagesRead = async (
  sid: string,
  client?: TwilioChatClient,
) => {
  const conversation = await getConversationBySid(sid, client)
  conversation?.setAllMessagesRead()
}

export const getUnreadMessagesCount = async (
  sid: string,
  overallMessagesCount: number,
  conversation?: Conversation,
) => {
  // const conversation = await getConversationBySid(sid, client)
  const unreadMessageCount = await conversation?.getUnreadMessagesCount()

  // Twilio returns null as unreadMessageCount for new chats that were never fetched before
  // even if they have sent messages. So, in that case all chat messages are unread
  if (unreadMessageCount == null) {
    return Promise.resolve(overallMessagesCount)
  }

  return Promise.resolve(unreadMessageCount)
}
