/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button from '../../common/button/Button'
import SignInImage from '../../common/images/SignInImage'
import Text, { TextVariant } from '../../common/typography/Text/Text'

const styles = {
  grid: css({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  leftContainer: css({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  }),
  gridItem: css({
    display: 'flex',
    height: '100%',
    ...mediaQuery(MediaQuery.MIN_MD, {
      alignItems: 'center',
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S8, 0),
    }),
  }),
  gridItemLeft: css({
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, 0, 0, Spacing.S8),
    }),
  }),
  gridItemRight: css({
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S8, 0, 0),
    }),
  }),
  title: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      alignSelf: 'center',
    }),
  }),
  subtitle: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    ...mediaQuery(MediaQuery.MAX_SM, {
      alignSelf: 'center',
    }),
  }),
  formLabel: css({
    marginTop: spacing(Spacing.S8),
    ...mediaQuery(MediaQuery.MAX_SM, {
      alignSelf: 'center',
    }),
  }),
  form: css({
    padding: spacing(Spacing.S4, 0, 0, Spacing.S6),
    ...mediaQuery(MediaQuery.MAX_SM, {
      alignSelf: 'center',
    }),
  }),
  button: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      alignSelf: 'center',
    }),
  }),
  image: css({
    '.b': {
      fill: ColorVariables.UI_BG_BRAND_PRIMARY,
    },
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '90%',
      height: '90%',
      alignSelf: 'center',
      marginTop: spacing(Spacing.S6),
    }),
  }),
}

const SignInPage = () => {
  const { t } = useTranslation('Auth')

  const { loginWithRedirect } = useAuth0()

  const handleSignIn = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    })
  }

  return (
    <KibGrid align="center" css={styles.grid}>
      <KibGridItem
        css={[styles.gridItem, styles.gridItemLeft]}
        offset="2@min-lg"
        span="4@min-xs 4@min-md 4@min-lg"
      >
        <div css={styles.leftContainer}>
          <Text css={styles.title} variant={TextVariant.DISPLAY_3}>
            {t('Auth:WELCOME_TO_BOOP')}
          </Text>

          <Text css={styles.subtitle} variant={TextVariant.DISPLAY_6}>
            {t('Auth:YOUR_PET_PARENT_PORTAL')}
          </Text>

          <Text css={styles.formLabel} variant={TextVariant.FORM_LABEL}>
            {`${t('Auth:PLEASE_SIGN_IN_TO')}:`}
          </Text>

          <ul css={styles.form}>
            <li>
              <Text variant={TextVariant.FORM_TEXT}>
                {t('Auth:ACCESS_YOUR_VACCINATION_CERTIFICATES')}
              </Text>
            </li>
            <li>
              <Text variant={TextVariant.FORM_TEXT}>
                {t('Auth:VIEW_TRAVEL_DOCUMENTS_FOR_YOUR_PET')}
              </Text>
            </li>
            <li>
              <Text variant={TextVariant.FORM_TEXT}>
                {t('Auth:MAKE_AN_APPOINTMENT_WITH_YOUR_VET')}
              </Text>
            </li>
            <li>
              <Text variant={TextVariant.FORM_TEXT}>{t('Auth:AND_MORE')}</Text>
            </li>
          </ul>

          <Button css={styles.button} id="si-sign-in" onClick={handleSignIn}>
            {t('Auth:SIGN_IN')}
          </Button>

          {mediaQueryMatches(MediaQuery.MAX_SM) && (
            <SignInImage css={styles.image} />
          )}
        </div>
      </KibGridItem>

      {mediaQueryMatches(MediaQuery.MIN_MD) && (
        <KibGridItem
          css={[styles.gridItem, styles.gridItemRight]}
          push="2@min-lg"
          span="4@min-md 4@min-lg"
        >
          <SignInImage css={styles.image} />
        </KibGridItem>
      )}
    </KibGrid>
  )
}

export default SignInPage
