import gql from 'graphql-tag'

export const UPDATE_TASK_STATE = gql`
  mutation UpdateTaskState($taskId: ID!, $stateId: ID!) {
    updateTaskState(taskId: $taskId, stateId: $stateId) {
      id
      completionDate
      state {
        id
        name
      }
      aggregatedState {
        id
        name
      }
      parent {
        id
        recurrenceType
        aggregatedState {
          id
          name
        }
        aggregatedCompletionDate
        state {
          id
          name
        }
        completionDate
        readiness
      }
    }
  }
`

export const UPDATE_TASK_NOTES = gql`
  mutation UpdateTaskNotes($taskId: ID!, $notes: String!) {
    updateTaskNotes(taskId: $taskId, notes: $notes) {
      id
      notes
    }
  }
`

export const CREATE_TASK = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
      name
      instructions
      notes
      dueDate
      completionDate
      aggregatedCompletionDate
      recurrenceType
      readiness
      state {
        id
        name
      }
      aggregatedState {
        id
        name
      }
      timesOfDay {
        times
      }
      recurrencePeriod {
        amount
        unit
      }
      patient {
        id
        name
        photo
        photoThumbnail
      }
      recurrenceStartDatetime
      recurrenceEndDatetime
      business {
        id
        name
      }
    }
  }
`
