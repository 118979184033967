/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { CHECK_IN } from '../../../api/mutations/appointment'
import { GET_REMINDER_STATES } from '../../../api/queries/constants'
import { GET_PATIENT_CARD_DATA } from '../../../api/queries/patient'
import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { SaveFormHandle, SaveFormProps } from '../../../types/forms'
import {
  getPatientCardQueryVariables,
  getReminderStatesForWidgets,
} from '../../../utils/patient'
import { spacing } from '../../../utils/spacing'
import Spinner from '../spinner/Spinner'
import Text, { TextVariant } from '../typography/Text/Text'

const ID = 'arrivalForm'

type CheckInArrivalFormProps = SaveFormProps & {
  appointmentId?: string
  businessId?: string
  patientId?: string
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  spinner: css({
    marginTop: spacing(Spacing.S4),
    alignSelf: 'center',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  questionsContainer: css({
    padding: spacing(Spacing.S4, 0),
  }),
}

const CheckInArrivalForm = forwardRef<SaveFormHandle, CheckInArrivalFormProps>(
  function CheckInArrivalForm(
    {
      appointmentId,
      patientId,
      businessId,
      onReady,
      onSaveCompleted,
      onSaveLoadingStateChange,
      ...rest
    },
    ref,
  ) {
    const { t } = useTranslation('Home')

    const { data: { constants: { reminderStates = [] } = {} } = {}, loading } =
      useQuery(GET_REMINDER_STATES)

    const supportedReminderStateIds =
      getReminderStatesForWidgets(reminderStates)

    const [checkIn, { loading: checkInLoading }] = useMutation(CHECK_IN, {
      refetchQueries: [
        {
          query: GET_PATIENT_CARD_DATA,
          variables: getPatientCardQueryVariables(
            patientId,
            supportedReminderStateIds,
          ),
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => onSaveCompleted?.(ID),
    })

    useEffect(() => {
      if (!loading) {
        onReady?.(ID)
      }
    }, [loading])

    useEffect(() => {
      onSaveLoadingStateChange?.({ id: ID, loading: checkInLoading })
    }, [checkInLoading])

    const handleSave = () =>
      checkIn({
        variables: {
          businessId,
          appointmentId,
          arrived: true,
        },
      })

    useImperativeHandle(ref, () => ({
      save: handleSave,
    }))

    return (
      <div css={styles.container} {...rest}>
        {loading ? (
          <Spinner css={styles.spinner} />
        ) : (
          <>
            <Text variant={TextVariant.SECTION_1}>
              {t('Home:HAVE_YOU_ARRIVED_FOR_YOUR_APPOINTMENT')}
            </Text>
          </>
        )}
      </div>
    )
  },
)

export default CheckInArrivalForm
