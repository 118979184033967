/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import moment from 'moment-timezone'

import { DateFormat } from '../../../constants/dateFormat'
import { OpeningHours } from '../../../types/dto/Business'
import {
  getPracticeOpenStatus,
  getPracticeWorkingHoursMap,
} from '../../../utils/business'
import Tag, { TagTheme } from '../tag/Tag'

type BusinessStatusLabelProps = {
  openingHours?: OpeningHours[]
  timezone?: string
}

const styles = {
  tag: css({
    textTransform: 'uppercase',
    marginBottom: 7,
  }),
}

const BusinessStatusLabel = ({
  openingHours,
  timezone,
  ...rest
}: BusinessStatusLabelProps) => {
  const { t } = useTranslation('Common')

  if (!timezone) {
    return (
      <Tag css={styles.tag} theme={TagTheme.ERROR} {...rest}>
        {t('Common:CLOSED')}
      </Tag>
    )
  }

  const currentWeekDay = moment.tz(timezone).format(DateFormat.DAY_OF_WEEK)
  const workingHoursMap = getPracticeWorkingHoursMap(openingHours, timezone)
  const openStatus = getPracticeOpenStatus(
    workingHoursMap[currentWeekDay.toLowerCase()],
    timezone,
  )

  return (
    <Tag css={styles.tag} theme={openStatus.labelVariant} {...rest}>
      {openStatus.text}
    </Tag>
  )
}

export default BusinessStatusLabel
