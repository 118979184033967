/** @jsxImportSource @emotion/react */
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { KibImageIcon, KibSendIcon } from '@chewy/kib-icons-react'
import { css } from '@emotion/react'
import {
  Conversation,
  Message as TwilioMessage,
  Paginator,
} from '@twilio/conversations'
import * as R from 'ramda'

import { UPDATE_CHAT } from '../../../api/mutations/chat'
import {
  getConversationBySid,
  getMessages,
  sendMessage,
  setAllMessagesRead,
} from '../../../api/twilio'
import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { MESSAGE_ADDED } from '../../../constants/twilio'
import { Chat } from '../../../types/dto/Chat'
import {
  FileTemplate,
  Message,
  MessageContentType,
} from '../../../types/entities'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis } from '../button/Button'
import Card from '../card/Card/Card'
import InputTextArea from '../input/InputTextArea'
import Text, { TextVariant } from '../typography/Text/Text'
import ChatClientContext from './ChatClientContext'
import ConversationView, { ConversationViewHandle } from './ConversationView'

const IMAGE_SIZE = 1024

type ChatViewProps = {
  chat?: Chat
  onCreateNewChat: () => void
  onShowFileInput: () => void
  onShowMediaPreview?: (message: Message) => void
}

type MessageImageAttributes = {
  image: { height?: number; width?: number }
}

export type ChatViewHandle = {
  sendMediaMessage: (file: FileTemplate) => void
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    rowGap: spacing(Spacing.S4),
  }),
  header: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'sticky',
    top: 72,
    left: 0,
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
    borderBottom: `1px solid ${ColorVariables.UI_BG_02}`,
    padding: spacing(Spacing.S2),
    zIndex: 1,
  }),
  conversationView: css({
    flex: 1,
    overflow: 'auto',
    padding: spacing(0, Spacing.S4, Spacing.S2, Spacing.S4),
    ...mediaQuery(MediaQuery.MAX_SM, {
      marginBottom: 40,
      padding: spacing(0, Spacing.S2, Spacing.S2, Spacing.S2),
    }),
  }),
  inputContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacing(Spacing.S4),
    padding: spacing(0, Spacing.S4, Spacing.S2, Spacing.S4),
  }),
  inputContainerMobile: css({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
    padding: spacing(Spacing.S2, Spacing.S2, 0, Spacing.S2),
    zIndex: 2,
  }),
  closedContainer: css({
    flexDirection: 'column',
    rowGap: spacing(Spacing.S4),
    padding: spacing(0, Spacing.S4, Spacing.S4, Spacing.S4),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4),
    }),
  }),
  input: css({
    flex: 1,
    '.kib-field__error': {
      display: 'none',
    },
  }),
  closedTitle: css({
    fontWeight: 'bold',
  }),
  closedButtonsContainer: css({
    display: 'flex',
    columnGap: spacing(Spacing.S2),
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
      rowGap: spacing(Spacing.S2),
    }),
  }),
}

const twilioMessageToMessage = async (message: TwilioMessage) => {
  const chatMessage: Message = {
    id: message.sid,
    createdAt: message.dateCreated,
    user: {
      id: message.author,
    },
  }

  if (message.type === 'media') {
    const media = message?.attachedMedia?.[0]
    const contentType = media?.contentType

    if (contentType?.includes('image')) {
      chatMessage.type = MessageContentType.IMAGE
      const mediaUrl = await media?.getContentTemporaryUrl()
      chatMessage.image = {
        url: mediaUrl,
        width:
          (message?.attributes as MessageImageAttributes)?.image?.width
          || IMAGE_SIZE,
        height:
          (message?.attributes as MessageImageAttributes)?.image?.height
          || IMAGE_SIZE,
      }
    } else if (contentType?.includes('video')) {
      chatMessage.type = MessageContentType.VIDEO
    } else {
      chatMessage.type = MessageContentType.DOCUMENT
    }
    chatMessage.fileName = media?.filename
    chatMessage.mediaSid = media?.sid
    chatMessage.originalMedia = media
  } else {
    chatMessage.text = message.body
    chatMessage.type = MessageContentType.TEXT
  }

  return chatMessage
}

const ChatView = forwardRef<ChatViewHandle, ChatViewProps>(function ChatView(
  { chat, onCreateNewChat, onShowFileInput, onShowMediaPreview, ...rest },
  ref,
) {
  const client = useContext(ChatClientContext)

  const { t } = useTranslation('Chat')

  const [conversation, setConversation] = useState<Conversation | undefined>()
  const [lastPage, setLastPage] = useState<Paginator<TwilioMessage>>()
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)

  const messagesStateRef = useRef<Message[] | undefined>()
  const coversationRef = useRef<ConversationViewHandle>(null)

  const [
    updateChat,
    {
      data: {
        updateChat: { isClosed: isClosedFromMutation = undefined } = {},
      } = {},
      loading: updateChatLoading,
    },
  ] = useMutation(UPDATE_CHAT)

  const isClosedRecievedAfterReopen = !R.isNil(isClosedFromMutation)
  const isChatClosed = isClosedRecievedAfterReopen
    ? isClosedFromMutation
    : chat?.isClosed

  messagesStateRef.current = messages

  useEffect(() => {
    if (chat) {
      setLoading(true)
      getConversationBySid(chat.channelSID, client)
        ?.then(conv => {
          setConversation(conv)
          return getMessages(conv)
        })
        ?.then(page => {
          setLastPage(page)
          const newMessages = R.map(twilioMessageToMessage, page.items)
          return Promise.all(newMessages)
        })
        ?.then(loadedMessages => {
          setMessages(loadedMessages)
        })
        ?.then(() => {
          setAllMessagesRead(chat.channelSID, client)
        })
        .finally(() => {
          setLoading(false)
          coversationRef?.current?.scrollToBottom()
        })
    }

    setMessage('')
  }, [chat])

  const handleNewMessageReceived = async (twilioMessage: TwilioMessage) => {
    const newMessage = await twilioMessageToMessage(twilioMessage)
    setMessages([...(messagesStateRef?.current || []), newMessage])
    if (chat?.channelSID) {
      setAllMessagesRead(chat?.channelSID, client)
    }

    setTimeout(() => coversationRef?.current?.scrollToBottom(), 1)
  }

  useEffect(() => {
    if (conversation) {
      conversation.on(MESSAGE_ADDED, handleNewMessageReceived)
    }

    return () => {
      if (conversation) {
        conversation.off(MESSAGE_ADDED, handleNewMessageReceived)
      }
    }
  }, [conversation])

  const loadMore = () => {
    lastPage
      ?.nextPage()
      ?.then(page => {
        setLastPage(page)
        const newMessages = R.map(twilioMessageToMessage, page.items)
        return Promise.all(newMessages)
      })
      ?.then(loadedMessages => {
        setMessages([...loadedMessages, ...messages])
      })
  }

  const handleSendTextMessage = (msg: string) => {
    if (conversation) {
      sendMessage(conversation, msg, {})
      setMessage('')
      coversationRef?.current?.scrollToBottom()
    }
  }

  const handleSendMediaMessage = (file: FileTemplate) => {
    const formData = new FormData()

    if (file.raw) {
      formData.append(file?.name || 'file', file.raw, file.fileName)
    }

    if (conversation) {
      sendMessage(conversation, formData, {})
    }
  }

  const handleReopen = () => {
    const chatUpdateInfo = { isClosed: false }
    updateChat({ variables: { id: chat?.id, input: chatUpdateInfo } })
  }

  const Container = mediaQueryMatches(MediaQuery.MIN_MD) ? Card : 'div'

  useImperativeHandle(ref, () => ({
    sendMediaMessage: handleSendMediaMessage,
  }))

  return (
    <Container css={styles.container} {...rest}>
      {mediaQueryMatches(MediaQuery.MAX_SM) && (
        <div css={styles.header}>
          <Text variant={TextVariant.SECTION_3}>{chat?.title}</Text>
          <Text variant={TextVariant.PARAGRAPH_2}>
            {`${t('Chat:CHAT_WITH')} ${chat?.business?.name}`}
          </Text>
        </div>
      )}

      <ConversationView
        chat={chat}
        css={styles.conversationView}
        hasMore={lastPage?.hasNextPage === true}
        loadMore={loadMore}
        loading={loading}
        messages={messages}
        ref={coversationRef}
        onShowMediaPreview={onShowMediaPreview}
      />

      <div
        css={[
          styles.inputContainer,
          mediaQueryMatches(MediaQuery.MAX_SM) && styles.inputContainerMobile,
          isChatClosed && styles.closedContainer,
        ]}
      >
        {isChatClosed ? (
          <>
            <Text css={styles.closedTitle} variant={TextVariant.CAPTION}>
              {t('Chat:MESSAGE_CLOSED')}
            </Text>

            <div css={styles.closedButtonsContainer}>
              <Button
                disabled={updateChatLoading}
                id="cv-reopen"
                loading={updateChatLoading}
                onClick={handleReopen}
              >
                {t('Chat:REOPEN_MESSAGE')}
              </Button>

              <Button
                disabled={updateChatLoading}
                emphasis={ButtonEmphasis.SECONDARY}
                id="cv-nm"
                onClick={onCreateNewChat}
              >
                {t('Chat:START_NEW_MESSAGE')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Button
              iconOnly
              emphasis={ButtonEmphasis.TERTIARY}
              icon={<KibImageIcon />}
              id="cv-file"
              onClick={onShowFileInput}
            />

            <InputTextArea
              hiddenLabel
              css={styles.input}
              id="cv-message"
              label=""
              resize="none"
              value={message}
              onChangeText={text => {
                setMessage(text)
              }}
            />

            {message && message?.length > 0 && (
              <Button
                iconOnly
                icon={<KibSendIcon />}
                id="cv-send"
                onClick={() => handleSendTextMessage(message)}
              />
            )}
          </>
        )}
      </div>
    </Container>
  )
})

export default ChatView
