/** @jsxImportSource @emotion/react */
import { KibChipInput, KibChipInputProps } from '@chewy/kib-chips-react'

type InputChipProps = Omit<KibChipInputProps, 'remove'> & {
  onRemove?: () => void
}

const InputChip = ({ onRemove, ...rest }: InputChipProps) => (
  <KibChipInput remove={onRemove} {...rest}>
    {rest.children}
  </KibChipInput>
)

export default InputChip
