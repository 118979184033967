/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KibSheet, KibSheetProps } from '@chewy/kib-overlays-react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { MediaQuery } from '../../../../constants/mediaQuery'
import { Spacing } from '../../../../constants/spacing'
import { Constant } from '../../../../types/dto/Constants'
import { mediaQuery } from '../../../../utils/mediaQuery'
import { spacing } from '../../../../utils/spacing'
import Button from '../../button/Button'
import Checkbox from '../../choice/Checkbox'
import Divider from '../../divider/Divider'

type TimelineFilterSheetProps = Omit<KibSheetProps, 'children' | 'title'> & {
  onFilter: (types: Constant[]) => void
  supportedTimelineTypes: Constant[]
}

const styles = {
  content: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: spacing(Spacing.S4),
    rowGap: spacing(Spacing.S4),
    ...mediaQuery(MediaQuery.MIN_MD, {
      alignItems: 'flex-start',
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0, 0, Spacing.S6, 0),
    }),
  }),
  checkboxContainer: css({
    width: '100%',
  }),
  divider: css({
    width: '100%',
    marginLeft: 28,
  }),
}

const TimelineFilterSheet = ({
  supportedTimelineTypes,
  onFilter,
  ...rest
}: TimelineFilterSheetProps) => {
  const { t } = useTranslation('Pet')

  const [filters, setFilters] = useState<Constant[]>([])

  const handleFilterCheck = (checked: boolean, type: Constant) => {
    if (checked) {
      setFilters([...filters, type])
    } else {
      setFilters(R.without([type], filters))
    }
  }

  return (
    <KibSheet {...rest} bottom title={t('Pet:FILTER_TIMELINE')}>
      <div css={styles.content}>
        {supportedTimelineTypes.map((type: Constant, index: number) => (
          <div css={styles.checkboxContainer} key={type.id}>
            <Checkbox
              checked={R.includes(type, filters)}
              id={`tfs-${type.name.substring(0, 3)}`}
              label={type.name}
              onChange={value => handleFilterCheck(Boolean(value), type)}
            />

            {index < supportedTimelineTypes.length - 1 && (
              <Divider css={styles.divider} />
            )}
          </div>
        ))}

        <Button id="tfs-filter" onClick={() => onFilter(filters)}>
          {t('Pet:FILTER_TIMELINE')}
        </Button>
      </div>
    </KibSheet>
  )
}

export default TimelineFilterSheet
