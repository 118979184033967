/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { AnswerInput } from '../../../types/dto/Appointment'
import { Constant } from '../../../types/dto/Constants'
import { findConstantByName } from '../../../utils'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Checkbox from '../choice/Checkbox'
import InputDatepicker from '../input/InputDatepicker'
import InputText from '../input/InputText'

type AnswerViewProps = {
  answerInputTypes?: Constant[]
  inputs: AnswerInput[]
  onChange?: (inputId?: string, value?: string) => void
  selected?: boolean
  selectedInputsMap?: Record<string, string>
  text?: string
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  checkbox: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  }),
  input: css({
    ...mediaQuery(MediaQuery.MIN_MD, {
      width: '50%',
    }),
  }),
  innerInput: css({
    margin: spacing(0, Spacing.S6, Spacing.S4, Spacing.S6),
    ...mediaQuery(MediaQuery.MAX_SM, {
      margin: spacing(0, 0, Spacing.S4, 0),
    }),
  }),
}

const InputTypes = {
  TEXT: 'Text box',
  DATE: 'Date selector',
}

const AnswerView = ({
  text,
  selected,
  inputs,
  selectedInputsMap = {},
  answerInputTypes,
  onChange = R.F,
}: AnswerViewProps) => {
  const { t } = useTranslation(['Common'])

  const showInputs = Boolean(!text || selected)

  const textInputType = findConstantByName(
    InputTypes.TEXT,
    answerInputTypes,
  )?.id
  const dateInputType = findConstantByName(
    InputTypes.DATE,
    answerInputTypes,
  )?.id

  return (
    <div css={styles.container}>
      {Boolean(text) && (
        <Checkbox
          label={text}
          selectedValue={selected}
          onChange={() => onChange()}
        />
      )}

      {showInputs
        && inputs.map(({ id, typeId }) => (
          <div key={id}>
            {typeId === dateInputType && (
              <InputDatepicker
                inputStyle={[styles.input, Boolean(text) && styles.innerInput]}
                value={
                  selectedInputsMap[id]
                    ? moment(selectedInputsMap[id]).toDate()
                    : null
                }
                onChange={value => {
                  if (value === null) {
                    onChange(id, undefined)
                  } else {
                    onChange(
                      id,
                      moment(value).format(DateFormat.BACKEND_FULL_DATE),
                    )
                  }
                }}
              />
            )}
            {typeId === textInputType && (
              <InputText
                hiddenLabel
                css={[styles.input, Boolean(text) && styles.innerInput]}
                label=""
                placeholder={t('Common:ENTER_DETAILS')}
                value={id ? selectedInputsMap[id] : ''}
                onChangeText={value => onChange(id, value)}
              />
            )}
          </div>
        ))}
    </div>
  )
}

export default AnswerView
