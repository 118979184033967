/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { KibBreadcrumbs, KibBreadcrumbsItem } from '@chewy/kib-menus-react'
import { KibSkeletonText } from '@chewy/kib-skeleton-react'
import { css } from '@emotion/react'
import moment from 'moment-timezone'
import * as R from 'ramda'

import useAvatarUpload, { UploadedImageType } from '../../../api/imageUpload'
import {
  CREATE_PATIENT,
  UPDATE_PATIENT_DETAILS,
} from '../../../api/mutations/patient'
import {
  GET_CLIENT_ID,
  GET_DASHBOARD_PATIENTS,
} from '../../../api/queries/client'
import { GET_PATIENT_DETAILS } from '../../../api/queries/patient'
import { DateFormat } from '../../../constants/dateFormat'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Patient } from '../../../types/dto/Patient'
import { FileTemplate } from '../../../types/entities'
import { distinct } from '../../../utils'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis, ButtonTheme } from '../button/Button'
import Card from '../card/Card/Card'
import PatientAdditionalInfoForm, {
  PatientAdditionalInfoFormHandle,
} from '../form/PatientAdditionalInfoForm'
import PatientBasicInfoForm, {
  PatientBasicInfoFormHandle,
} from '../form/PatientBasicInfoForm'
import PatientInsuranceForm, {
  PatientInsuranceFormHandle,
} from '../form/PatientInsuranceForm'
import PatientLicenseForm, {
  PatientLicenseFormHandle,
} from '../form/PatientLicenseForm'
import Avatar from '../icon/Avatar'
import FileInputModal, { FileInputUsage } from '../modal/FileInputModal'

const AVATAR_SIZE = 72

export const EditPatientType = {
  ADD: 'add',
  EDIT: 'edit',
}

type EditPatientTypeProp =
  (typeof EditPatientType)[keyof typeof EditPatientType]

type EditPatientCardProps = {
  patientId?: string | undefined
  type: EditPatientTypeProp
}

type Change = {
  additionalInfo?: boolean
  basicInfo?: boolean
  insurance?: boolean
  license?: boolean
  photo?: boolean
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(Spacing.S5, Spacing.S4),
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  breadcrumbs: css({
    margin: 0,
  }),
  avatarContainer: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }),
  content: css({
    marginTop: spacing(Spacing.S4),
  }),
  fieldsContainer: css({
    ...mediaQuery(MediaQuery.MIN_MD, {
      padding: spacing(Spacing.S2, Spacing.S4, 0, Spacing.S4),
    }),
  }),
  title: css({
    marginTop: spacing(Spacing.S4),
  }),
  fieldRow: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  halfRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '48%',
  }),
  approximateRow: css({
    display: 'flex',
    alignItems: 'center',
    columnGap: spacing(Spacing.S3),
    width: '48%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '100%',
    }),
    ...mediaQuery(MediaQuery.MIN_MD, {
      columnGap: spacing(Spacing.S4),
    }),
  }),
  firstField: css({
    marginTop: spacing(Spacing.S4),
  }),
  field: css({
    width: '100%',
  }),
  halfField: css({
    width: '48%',
  }),
  halfFieldRight: css({
    width: '50%',
  }),
  oneFourthField: css({
    width: '24%',
  }),
  checkboxContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S4),
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(Spacing.S4),
  }),
  saveButton: css({
    marginRight: spacing(Spacing.S2),
  }),
}

const mapPetToInput = (pet: Patient) => ({
  ...pet,
  speciesId: pet.species?.id,
  breedIds: pet?.breeds?.[0]?.id ? [pet.breeds[0]?.id] : [],
  genderId: pet.gender?.id,
  spayedNeuteredStatusId: pet.spayedNeuteredStatus?.id,
  rabiesTagExpiration:
    pet.rabiesTagExpiration
    && moment(pet.rabiesTagExpiration)
      .utc(true)
      .format(DateFormat.BACKEND_FULL_DATE),
  environmentId: pet.environment?.id,
  serviceDesignationId: pet.serviceDesignation?.id,
  fitnessLevelId: pet.fitnessLevel?.id,
  purchasedFromId: pet.purchasedFrom?.id,
})

const EditPatientCard = ({
  patientId,
  type,
  ...rest
}: EditPatientCardProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'Home', 'Pet'])
  const apolloClient = useApolloClient()

  const [newPhoto, setNewPhoto] = useState<FileTemplate | undefined>()

  const [fileInputOpen, setFileInputOpen] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [changed, setChanged] = useState<Change>({})

  const basicInfoDataRef = useRef<PatientBasicInfoFormHandle>(null)
  const licenseDataRef = useRef<PatientLicenseFormHandle>(null)
  const insuranceDataRef = useRef<PatientInsuranceFormHandle>(null)
  const additionalInfoDataRef = useRef<PatientAdditionalInfoFormHandle>(null)

  const { data: { me = {} } = {} } = useQuery(GET_CLIENT_ID, {
    fetchPolicy: 'cache-first',
  })

  const { data: { pet: patient } = {}, loading: patientDetailsLoading } =
    useQuery(GET_PATIENT_DETAILS, {
      variables: { id: patientId },
      skip: type === EditPatientType.ADD,
    })

  const [updatePatient] = useMutation(UPDATE_PATIENT_DETAILS, {
    refetchQueries: [
      { query: GET_PATIENT_DETAILS, variables: { id: patientId } },
    ],
  })

  const [createPatient] = useMutation(CREATE_PATIENT, {
    onCompleted: () => {},
  })

  const { uploadImage } = useAvatarUpload()

  useEffect(() => {
    setChanged({ ...changed, photo: Boolean(newPhoto) })
  }, [newPhoto])

  const loading = patientDetailsLoading

  const uploadAvatar = async (
    avatarPatientId: string | undefined,
  ): Promise<UploadedImageType | undefined> => {
    let uploadImageObject: UploadedImageType | undefined
    if (newPhoto) {
      uploadImageObject = await uploadImage(newPhoto, {
        clientId: me?.id,
        patientId: avatarPatientId,
      })

      setNewPhoto(undefined)
    }

    return uploadImageObject
  }

  const createNewPatient = async (patientToCreate: Patient) => {
    const {
      data: { createPet: createdPatient },
    } = await createPatient({ variables: { input: patientToCreate } })

    await uploadAvatar(createdPatient?.id)

    await apolloClient.query({
      query: GET_DASHBOARD_PATIENTS,
      fetchPolicy: 'network-only',
    })
  }

  const updatePatientDetails = async (updatedPatient: Patient) => {
    const uploadImageObject = await uploadAvatar(patientId)

    if (uploadImageObject?.url) {
      apolloClient.writeQuery({
        query: GET_PATIENT_DETAILS,
        data: {
          pet: {
            ...patient,
            photo: uploadImageObject?.url,
            photoThumbnail: uploadImageObject?.thumbnailUrl,
          },
        },
        variables: { id: patientId },
      })
    }

    const patientDistinction = distinct(mapPetToInput(patient), updatedPatient)

    if (!R.isEmpty(patientDistinction)) {
      const distinctInput: Patient = {
        ...patientDistinction,
        name: updatedPatient?.name || undefined,
      }

      if (uploadImageObject?.url) {
        distinctInput.photo = uploadImageObject?.url
      }

      if (uploadImageObject?.thumbnailUrl) {
        distinctInput.photoThumbnail = uploadImageObject?.thumbnailUrl
      }

      await updatePatient({
        variables: { id: patient.id, input: distinctInput },
      })
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  const handleSave = async () => {
    if (
      R.all(Boolean, [
        basicInfoDataRef?.current?.validate(),
        licenseDataRef?.current?.validate(),
        insuranceDataRef?.current?.validate(),
        additionalInfoDataRef?.current?.validate(),
      ])
    ) {
      try {
        setSaveLoading(true)
        const basicInfoData = basicInfoDataRef?.current?.getData() || {}
        const licenseData = licenseDataRef?.current?.getData() || {}
        const insuranceData = insuranceDataRef?.current?.getData() || {}
        const additionalData = additionalInfoDataRef?.current?.getData() || {}

        const updatedPatient = {
          ...basicInfoData,
          ...licenseData,
          ...insuranceData,
          ...additionalData,
        } as const

        if (type === EditPatientType.ADD) {
          await createNewPatient(updatedPatient)
        } else {
          await updatePatientDetails(updatedPatient)
        }

        setSaveLoading(false)

        goBack()
      } catch (error) {
        setSaveLoading(false)
        // TODO handle error here
      }
    }
  }

  const onBreadcrumbClick = () => {
    if (type === EditPatientType.ADD) {
      goBack()
    } else {
      navigate(`/patient/${patientId}`)
    }
  }

  return (
    <Card css={styles.container} {...rest}>
      {loading ? (
        <KibSkeletonText style={{ width: 160 }} />
      ) : (
        <KibBreadcrumbs css={styles.breadcrumbs}>
          <KibBreadcrumbsItem
            id="epc-bread"
            // eslint-disable-next-line
            href="javascript:void(0);"
            onClick={onBreadcrumbClick}
          >
            {type === EditPatientType.ADD ? t('Home:HOME') : patient?.name}
          </KibBreadcrumbsItem>
          <KibBreadcrumbsItem current>
            {type === EditPatientType.ADD
              ? t('Pet:ADD_NEW_PET')
              : t('Pet:EDIT_PET_PROFILE')}
          </KibBreadcrumbsItem>
        </KibBreadcrumbs>
      )}

      <KibGrid css={styles.content}>
        <KibGridItem
          css={styles.avatarContainer}
          span="4@max-sm 1@min-md 1@min-lg"
        >
          <Avatar
            editable
            avatarSize={AVATAR_SIZE}
            id="epc-avatar"
            loading={loading}
            photo={newPhoto?.data || patient?.photoThumbnail || patient?.photo}
            onClick={() => setFileInputOpen(true)}
          />
        </KibGridItem>

        <KibGridItem
          css={styles.fieldsContainer}
          push="1@min-lg"
          span="7@min-md 10@min-lg"
        >
          <PatientBasicInfoForm
            loading={patientDetailsLoading}
            patient={patient}
            ref={basicInfoDataRef}
            onChange={isChanged => {
              setChanged({ ...changed, basicInfo: isChanged })
            }}
          />

          <PatientLicenseForm
            loading={loading}
            patient={patient}
            ref={licenseDataRef}
            onChange={isChanged => {
              setChanged({ ...changed, license: isChanged })
            }}
          />

          <PatientInsuranceForm
            loading={loading}
            patient={patient}
            ref={insuranceDataRef}
            onChange={isChanged => {
              setChanged({ ...changed, insurance: isChanged })
            }}
          />

          <PatientAdditionalInfoForm
            patient={patient}
            ref={additionalInfoDataRef}
            onChange={isChanged => {
              setChanged({ ...changed, additionalInfo: isChanged })
            }}
          />

          <div css={styles.buttonContainer}>
            <Button
              css={styles.saveButton}
              disabled={
                loading
                || !R.any(Boolean, Object.values(changed))
                || saveLoading
              }
              id="epc-save"
              loading={saveLoading}
              onClick={handleSave}
            >
              {t('Common:SAVE_CHANGES')}
            </Button>
            <Button
              disabled={loading || saveLoading}
              emphasis={ButtonEmphasis.TERTIARY}
              id="epc-can"
              theme={ButtonTheme.UTILITY}
              onClick={goBack}
            >
              {t('Common:CANCEL')}
            </Button>
          </div>
        </KibGridItem>
      </KibGrid>

      <FileInputModal
        fileInputUsage={FileInputUsage.AVATAR}
        open={fileInputOpen}
        title={t('Pet:CHOOSE_YOUR_PATIENT_PICTURE')}
        onClose={() => setFileInputOpen(false)}
        onFileReady={data => {
          setNewPhoto(data)
          setFileInputOpen(false)
        }}
      />
    </Card>
  )
}

export default EditPatientCard
