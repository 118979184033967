/* eslint-disable */
import Modal, { ModalProps } from './Modal'

const PrivacyModal = ({ ...rest }: ModalProps) => (
  <Modal {...rest} title="BOOP PRIVACY NOTICE" size="large">
    <div
      dangerouslySetInnerHTML={{
        __html: `<p>
  Boop is operated by Petabyte Technology, Inc., which has offices at 11235
  SE 6th Street, Bellevue, Washington 98004 (“<u>Company</u>”, “<u>we</u>”, “<u>our</u>” or “<u>us</u>”). We
  believe that privacy is a fundamental right, and we are committed to protecting your privacy in accordance
  with this privacy notice (“<b>Notice</b>”), which describes:
</p>
<p>
  (1) the <b><u>type</u></b> of information and data we collect when you download and use the Boop or any white
  label version of Boop app; and
</p>
<p>
  (2) <b><u>how</u></b> we use, maintain, and protect such data.
</p>
<p>
  Our websites and apps, and other third parties have their own privacy notices, which we encourage you to read
  before providing information on or through them.
</p>
<p>
  Please read this Notice carefully to understand our policies and practices regarding your information and how
  we will treat it. If you do not agree with our policies and practices, do not download, register with, or use
  this App. By downloading, registering with, or using this App, you agree to this privacy Notice. This Notice
  may change from time to time (see Changes to Our Privacy Notice). Your continued use of this App after we
  revise this Notice means you accept those changes, so please check the Notice periodically for updates.
</p>
<br>
<p role="heading"><b>Children Under the Age of 13</b></p>
<p>
  The App is not intended for children under 13 years of age, and we do not knowingly collect personal
  information from children under 13. If we learn we have collected or received personal information from a
  child under 13 without verification of parental consent, we will immediately delete that information. If you
  believe we might have any information from or about a child under 13, please contact us at
  <a href="mailto:support@boop.vet">support@boop.vet</a>
</p>
<br>
<p role="heading"><b>Information We Collect and How We Collect It</b></p>
<p>We collect information from and about users of our App:</p>
<p>• Directly from you when you provide it to us.</p>
<p>• Automatically when you use the App.</p>
<br>
<p role="heading"><b>Information You Provide to Us</b></p>
<p>When you download, register with, or use this App, we may ask you provide information:</p>
<p>
  • By which you may be personally identified, such as name, postal address, email address, telephone number,
  pet name, breed, and age (”<b>personal information</b>”).
</p>
<p>• That is about you, but does not individually identify you, such as:</p>
<p>
  • Information that you provide by filling in forms in the App. This includes information provided at the time
  of registering to use the App, subscribing to our service, posting material, and requesting further services.
  We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report
  a problem with the App.
</p>
<p>
  • Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.
</p>
<p>• Your responses to surveys that we might ask you to complete for research purposes.</p>
<p>
  • Details of transactions you carry out through the App and of the fulfillment of your orders. You may be
  required to provide financial information before placing an order through the App.
</p>
<p>• Your search queries on the App.</p>
<p>
  You may also provide information for publication or display (”<b>Posted”</b>) on public areas of the App
  (collectively, “<b>User Contributions</b>”). Your User Contributions are Posted and transmitted to others at
  your own risk. Although you may set certain privacy settings for such information by logging into your account
  profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot
  control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we
  cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
</p>
<br>
<p role="heading"><b>Automatic Information Collection Tracking</b></p>
<p>When you download, access, and use the App, it may use technology to automatically collect:</p>
<p>
  <b>• Usage Details.</b> When you access and use the App, we may automatically collect certain details of your
  access to and use of the App, including traffic data, location data, logs, and other communication data and
  the resources that you access and use on or through the App.
</p>
<p>
  <b>• Device Information.</b> We may collect information about your mobile device and internet connection,
  including the device’s unique device identifier, IP address, operating system, browser type, mobile network
  information, and the device’s telephone number.
</p>
<p>
  If you do not want us to collect this information you may opt out at any time by opting out via the in-app
  opt-out option or by e-mailing <a href="mailto:support@boop.vet">support@boop.vet</a>. Further, you may
  utilize the privacy options on your device for further opt-out options.
</p>
<p role="heading"><b>Information Collection and Tracking Technologies</b></p>
<p>The technologies we use for automatic information collection may include:</p>
<p>
  • Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to
  refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you
  select this setting you may be unable to access certain parts of our App.
</p>
<p>
  • Pixel Tags. Pages of the App and our emails may contain small electronic files known as pixel tags (also
  referred to as clear gifs, web beacons, and single-pixel gifs) that permit the Company, for example, to count
  users who have visited those pages or opened an email and for other related app statistics (for example,
  recording the popularity of certain app content and verifying system and server integrity).
</p>
<p role="heading"><b>Third-Party Information Collection</b></p>
<p>
  When you use the App or its content, certain third parties may use automatic information collection
  technologies to collect information about you or your device. These third parties may include:
</p>
<p>• Advertisers, ad networks, and ad servers.</p>
<p>• Analytics companies.</p>
<p>• Your mobile device manufacturer.</p>
<p>• Your mobile service provider.</p>
<p>
  These third parties may use tracking technologies to collect information about you when you use this app. The
  information they collect may be associated with your personal information or they may collect information,
  including personal information, about your online activities over time and across different websites, apps,
  and other online services websites. They may use this information to provide you with interest-based
  (behavioral) advertising or other targeted content.
</p>
<p>
  We do not control these third parties’ tracking technologies or how they may be used. If you have any
  questions about an advertisement or other targeted content, you should contact the responsible provider
  directly. For information about how you can opt out of receiving targeted advertising from many providers, see
  Your Choices About Our Collection, Use, and Disclosure of Your Information (below).
</p>
<p role="heading"><b>How We Use Your Information</b></p>
<p>
  We use information that we collect about you or that you provide to us, including any personal information,
  to:
</p>
<p>
  • Provide you with the App and its contents, and any other information, products or services that you request
  from us.
</p>
<p>• Fulfill any other purpose for which you provide it.</p>
<p>• Give you notices about your account/subscription, including expiration and renewal notices.</p>
<p>
  • Carry out our obligations and enforce our rights arising from any contracts entered into between you and us,
  including for billing and collection.
</p>
<p>
  • Provide you with relevant products or services from our sponsors or partners based upon your interaction and
  information provided through the App.
</p>
<p>
  • Notify you when App updates are available, and of changes to any products or services we offer or provide
  though it.
</p>
<p>
  • Cross reference the information with information we hold under our Rhapsody veterinary practice management
  software and database in order to optimize the App services for you.
</p>
<p>
  The usage information we collect helps us to improve our App and to deliver a better and more personalized
  experience by enabling us to:
</p>
<p>• Estimate our user size and usage patterns.</p>
<p>
  • Store information about your preferences, allowing us to customize our App according to your individual
  interests.
</p>
<p>• Speed up your searches.</p>
<p>• Recognize you when you use the App.</p>
<p>
  We may also use your information to contact you about our own and third parties’ goods and services that may
  be of interest to you. If you do not want us to use your information in this way, please check the relevant
  box located on the form on which we collect your data (the order form/registration form)/adjust your user
  preferences in your account profile. For more information, see Your Choices About Our Collection, Use, and
  Disclosure of Your Information (below).
</p>
<p role="heading"><b>Disclosure of Your Information</b></p>
<p>
  We may disclose aggregated information about our users or personal information that we collect or you provide:
</p>
<p>• To our subsidiaries and affiliates.</p>
<p>
  • To contractors, service providers, and other third parties we use to support our business and who are bound
  by contractual obligations to keep personal information confidential and use it only for the purposes for
  which we disclose it to them.
</p>
<p>
  • To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
  dissolution, or other sale or transfer of some or all of Petabyte Technology Inc.’s assets, whether as a going
  concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by
  Petabyte Technology Inc. about our App users is among the assets transferred.
</p>
<p>
  • To fulfill the purpose for which you provide it. For example, if you give us an email address to use the
  “email a friend” feature of our App, we will transmit the contents of that email and your email address to the
  recipients.
</p>
<p>• For any other purpose disclosed by us when you provide the information.</p>
<p>• With your consent.</p>
<p>
  • To comply with any court order, law, or legal process, including to respond to any government or regulatory
  request.
</p>
<p>
  • To enforce our rights arising from any contracts entered into between you and us, including the App EULA,
  and for billing and collection.
</p>
<p>
  • If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Petabyte
  Technology Inc., our customers or others. This includes exchanging information with other companies and
  organizations for the purposes of fraud protection and credit risk reduction.
</p>
<p role="heading"><b>Your Choices About Our Collection, Use, and Disclosure of Your Information</b></p>
<p>
  You have choices regarding the personal information you provide to us. This section describes mechanisms we
  provide for you to control certain uses and disclosures of over your information.
</p>
<p>
  <b>• Tracking Technologies.</b> You can set your browser to refuse all or some browser cookies, or to alert
  you when cookies are being sent. You can choose whether or not to allow the App to collect information through
  other tracking technologies by adjusting the appropriate settings in the App or on your smartphone device. If
  you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then
  be inaccessible or not function properly.
</p>
<p>
  <b>• Promotion by the Company.</b> If you do not want us to use your email address or other account
  information to promote our own or third parties’ products or services, you can opt-out by emailing us by
  sending us an email stating your request to <a href="mailto:support@boop.vet">support@boop.vet</a> You can
  also always opt-out by logging into the App and adjusting your user preferences in your account profile by
  checking or unchecking the relevant boxes.
</p>
<p>
  <b>• Targeted Advertising by the Company.</b> If you do not want us to use information that we collect or that
  you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can
  opt-out by adjusting the appropriate settings in the App. You can also always adjust your user advertising
  preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email
  stating your request to <a href="mailto:support@boop.vet">support@boop.vet</a>
</p>
<p>
  <b>• Disclosure of Your Information for Third-Party Advertising and Marketing.</b> We do NOT share your
  personal information with unaffiliated or non-agent third parties for advertising and marketing purposes.
</p>
<p role="heading"><b>Right to Accessing or Correct Your Personal Information</b></p>
<p>
  You can review and change your personal information by logging into the App and visiting your account profile
  page.
</p>
<p>
  You may also send us an email at <a href="mailto:support@boop.vet">support@boop.vet</a> to request access to,
  correct, or delete any personal information that you have provided to us. We cannot delete your personal
  information except by also deleting your user account. We may not accommodate a request to change information
  if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
</p>
<p>
  If you delete your User Contributions from the App, copies of your User Contributions may remain viewable in
  cached and archived pages or might have been copied or stored by other App users. Proper access and use of
  information provided on the App, including User Contributions, is governed by our terms of use.
</p>
<p role="heading"><b>Data Security</b></p>
<p>
  We utilize measures designed to secure your personal information from accidental loss and from unauthorized
  access, use, alteration, and disclosure. All information you provide to us is stored on secure servers. Any
  payment transactions will be encrypted using SSL technology. The safety and security of your information also
  depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of
  our App, you are responsible for keeping this password confidential. We ask you not to share your password
  with anyone. We urge you to be careful about giving out information in public areas of the App like message
  boards. The information you share in public areas may be viewed by any user of the App.
</p>
<p>
  Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure.
  Although we do our best to protect your personal information, we cannot guarantee the security of your
  personal information transmitted through our App. Any transmission of personal information is at your own
  risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
</p>
<p role="heading"><b>Changes to Our Privacy Notice</b></p>
<p>
  We may update our privacy notice from time to time. If we make material changes to how we treat our users’
  personal information, we will post the new privacy notice with a notice that the privacy notice has been
  updated and notify you by email to the email address specified in your account and an in-App alert the first
  time you use the App after we make the change.
</p>
<p>
  The date the privacy notice was last revised is identified at the top of the page. You are responsible for
  ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this
  privacy Notice to check for any changes.
</p>
<p role="heading"><b>Contact Information</b></p>
<p>To ask questions or comment about this privacy notice and our privacy practices, contact us at:</p>
<p><a href="mailto:support@boop.vet">support@boop.vet</a></p>
<p>To register a complaint or concern, please e-mail <a href="mailto:support@boop.vet">support@boop.vet</a></p>`,
      }}
    />
  </Modal>
)

export default PrivacyModal
