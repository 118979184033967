/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { Task } from '../../types/dto/Task'
import Button from '../common/button/Button'
import CreateTaskForm, { CreateTaskFormHandle } from '../common/form/CreateTaskForm'
import Modal, { ModalProps } from '../common/modal/Modal'

type CreateTaskModalProps = ModalProps & {
  onTaskCreated: (task: Task) => void
}

const styles = {
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
}

const CreateTaskModal = ({ onTaskCreated, ...rest }: CreateTaskModalProps) => {
  const { t } = useTranslation(['Common', 'Tasks'])

  const [canAddTask, setCanAddTask] = useState(false)
  const [loading, setLoading] = useState(false)

  const formRef = useRef<CreateTaskFormHandle>(null)

  return (
    <Modal
      size="large"
      title={t('Tasks:NEW_TASK')}
      {...rest}
      // eslint-disable-next-line
      controls={({ close }) => (
        <div css={styles.buttonsContainer}>
          <Button
            disabled={!canAddTask || loading}
            id="ctf-add"
            loading={loading}
            onClick={() => formRef.current?.add()}
          >
            {t('Common:ADD')}
          </Button>
        </div>
      )}
    >
      <CreateTaskForm
        ref={formRef}
        onDataChanged={setCanAddTask}
        onLoading={setLoading}
        onTaskCreated={onTaskCreated}
      />
    </Modal>
  )
}

export default CreateTaskModal
