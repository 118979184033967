export const ColorVariables = {
  ACTION_UTILITY_ALTERNATE_PRIMARY: 'var(--chirp-action-utility-alternate-primary, #ffffff)',
  ACTION_UTILITY_ALTERNATE_03: 'var(--chirp-action-utility-alternate-03, #eeeeee)',
  ACTION_UTILITY_ALTERNATE_TEXT: 'var(--chirp-action-utility-alternate-text, #d4d4d4)',
  UI_BG_PRIMARY: 'var(--chirp-ui-bg-primary, #ffffff)',
  UI_BG_BRAND_PRIMARY: 'var(--chirp-ui-bg-brand-primary, #ffffff)',
  UI_BG_BRAND_ALTERNATE: 'var(--chirp-ui-bg-brand-alternate, #ffffff)',
  UI_BG_OVERLAY_PRIMARY: 'var(--chirp-ui-bg-overlay-primary, #ffffff)',
  UI_BG_OVERLAY_02: 'var(--chirp-ui-bg-overlay-02, #ffffff)',
  UI_BG_02: 'var(--chirp-ui-bg-02, #f5f5f5)',
  UI_BG_04: 'var(--chirp-ui-bg-04, #eeeeee)',
  UI_BG_05: 'var(--chirp-ui-bg-05, #ff0000)',
  UI_BG_07: 'var(--chirp-ui-bg-07, #ffffff)',
  UI_BG_09: 'var(--chirp-ui-bg-09, #ffffff)',
  UI_BRAND_ACCENT_01: 'var(--chirp-ui-bg-brand-accent-01, #ffffff)',
  UI_ELEMENTS_FLAG_NEW_PRIMARY: 'var(--chirp-ui-elements-flag-new-primary, #ff0000)',
  LINK_ACTIVE_PRIMARY: 'var(--chirp-link-active-primary, #ffffff)',
  LINK_ACTIVE_SECONDARY: 'var(--chirp-link-active-secondary, #ffffff)',
  STATIC_GRAY1_50: 'var(--chirp-static-gray1-50, #ffffff)',
  STATIC_GRAY1_900: 'var(--chirp-static-gray1-900, #ffffff)',
  TEXT_PRIMARY: 'var(--chirp-text-primary, #ffffff)',
  TEXT_SECONDARY: 'var(--chirp-text-secondary, #ffffff)',
  TEXT_INVERSE: 'var(--chirp-text-inverse, #ffffff)',
  TEXT_ERROR: 'var(--chirp-text-error, #ff0000)',
  MENU_PRIMARY_HOVER_PRIMARY: 'var(--chirp-menu-primary-hover-primary, #ffffff)',
  ICON_FRESH: 'var(--chirp-icon-fresh, #00ff00)',
  FIELD_ERROR: 'var(--chirp-field-06, #ff0000)',
  MESSAGE_SUCCESS_PRIMARY: 'var(--chirp-message-success-primary, #00ff00)',
}
