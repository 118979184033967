/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { GET_PATIENT_ADDITIONAL_INFO_CONSTANTS } from '../../../api/queries/constants'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Constant } from '../../../types/dto/Constants'
import { Patient } from '../../../types/dto/Patient'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import SelectInput from '../input/SelectInput'
import Text, { TextVariant } from '../typography/Text/Text'

type PatientAdditionalInfoFormProps = {
  onChange: (changed: boolean) => void
  patient?: Patient
}

export type PatientAdditionalInfoFormHandle = {
  getData: () => {
    environmentId: string | undefined
    fitnessLevelId: string | undefined
    purchasedFromId: string | undefined
    serviceDesignationId: string | undefined
  }
  validate: () => boolean
}

const styles = {
  title: css({
    marginTop: spacing(Spacing.S4),
  }),
  fieldRow: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  firstField: css({
    marginTop: spacing(Spacing.S4),
  }),
  field: css({
    width: '100%',
  }),
  halfField: css({
    width: '48%',
  }),
  halfFieldRight: css({
    width: '50%',
  }),
}

const PatientAdditionalInfoForm = forwardRef<
PatientAdditionalInfoFormHandle,
PatientAdditionalInfoFormProps
>(function PatientBasicInfoForm({ patient, onChange, ...rest }, ref) {
  const { t } = useTranslation('Pet')

  const [environment, setEnvironment] = useState<Constant | undefined>()
  const [serviceDesignation, setServiceDesignation] = useState<
  Constant | undefined
  >()
  const [fitnessLevel, setFitnessLevel] = useState<Constant | undefined>()
  const [purchasedFromType, setPurchasedFromType] = useState<
  Constant | undefined
  >()

  const {
    data: {
      constants: {
        environments = [],
        fitnessLevels = [],
        serviceDesignations = [],
        purchasedFromTypes = [],
      } = {},
    } = {},
    loading,
  } = useQuery(GET_PATIENT_ADDITIONAL_INFO_CONSTANTS)

  useEffect(() => {
    if (patient) {
      setEnvironment(patient.environment)
      setServiceDesignation(patient.serviceDesignation)
      setFitnessLevel(patient.fitnessLevel)
      setPurchasedFromType(patient.purchasedFrom)
    }
  }, [patient, loading])

  useEffect(() => {
    onChange(
      patient?.environment?.id !== environment?.id
        || patient?.serviceDesignation?.id !== serviceDesignation?.id
        || patient?.fitnessLevel?.id !== fitnessLevel?.id
        || patient?.purchasedFrom?.id !== purchasedFromType?.id,
    )
  }, [environment, serviceDesignation, fitnessLevel, purchasedFromType])

  const environmentOptions = environments.map((item: Constant) => ({
    label: item.name,
    value: item.id,
  }))
  const environmentSelectedOption = environment
    ? { label: environment.name, value: environment.id }
    : undefined

  const serviceDesignationOptions = serviceDesignations.map(
    (item: Constant) => ({
      label: item.name,
      value: item.id,
    }),
  )
  const serviceDesignationSelectedOption = serviceDesignation
    ? { label: serviceDesignation.name, value: serviceDesignation.id }
    : undefined

  const fitnessLevelOptions = fitnessLevels.map((item: Constant) => ({
    label: item.name,
    value: item.id,
  }))
  const fitnessLevelSelectedOption = fitnessLevel
    ? { label: fitnessLevel.name, value: fitnessLevel.id }
    : undefined

  const purchasedFromTypeOptions = purchasedFromTypes.map((item: Constant) => ({
    label: item.name,
    value: item.id,
  }))
  const purchasedFromTypeSelectedOption = purchasedFromType
    ? { label: purchasedFromType.name, value: purchasedFromType.id }
    : undefined

  const handleEnvironmentSelected = (id: string) => {
    const selectedEnvironment = environments.find(
      (item: Constant) => item.id === id,
    )
    setEnvironment(selectedEnvironment)
  }

  const handleServiceDesignationSelected = (id: string) => {
    const selectedDesignation = serviceDesignations.find(
      (item: Constant) => item.id === id,
    )

    setServiceDesignation(selectedDesignation)
  }

  const handleFitnessLevelSelected = (id: string) => {
    const selectedLevel = fitnessLevels.find((item: Constant) => item.id === id)
    setFitnessLevel(selectedLevel)
  }

  const handlePurchasedFromTypeSelected = (id: string) => {
    const selectedType = purchasedFromTypes.find(
      (item: Constant) => item.id === id,
    )
    setPurchasedFromType(selectedType)
  }

  useImperativeHandle(ref, () => ({
    getData: () => ({
      environmentId: environment?.id,
      serviceDesignationId: serviceDesignation?.id,
      fitnessLevelId: fitnessLevel?.id,
      purchasedFromId: purchasedFromType?.id,
    }),
    validate: R.T,
  }))

  return (
    <div {...rest}>
      <Text
        css={styles.title}
        skeletonWidth={160}
        variant={TextVariant.SECTION_1}
      >
        {t('Pet:ADDITIONAL_INFORMATION')}
      </Text>

      <div css={[styles.fieldRow, styles.firstField]}>
        <SelectInput
          emptyOption
          css={[
            mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
            mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
          ]}
          id="epc-env"
          label={t('Pet:ENVIRONMENT')}
          options={environmentOptions}
          value={environmentSelectedOption?.value}
          onChange={event => {
            handleEnvironmentSelected(event.target.value)
          }}
        />

        <SelectInput
          emptyOption
          css={[
            mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfFieldRight,
            mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
          ]}
          id="epc-ser-des"
          label={t('Pet:SERVICE_DESIGNATION')}
          options={serviceDesignationOptions}
          value={serviceDesignationSelectedOption?.value}
          onChange={event => {
            handleServiceDesignationSelected(event.target.value)
          }}
        />
      </div>

      <div css={styles.fieldRow}>
        <SelectInput
          emptyOption
          css={[
            mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfField,
            mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
          ]}
          id="epc-fit-lvl"
          label={t('Pet:FITNESS_LEVEL')}
          options={fitnessLevelOptions}
          value={fitnessLevelSelectedOption?.value}
          onChange={event => {
            handleFitnessLevelSelected(event.target.value)
          }}
        />

        <SelectInput
          emptyOption
          css={[
            mediaQueryMatches(MediaQuery.MIN_MD) && styles.halfFieldRight,
            mediaQueryMatches(MediaQuery.MAX_SM) && styles.field,
          ]}
          id="epc-pur-from"
          label={t('Pet:RECEIVED_FROM')}
          options={purchasedFromTypeOptions}
          value={purchasedFromTypeSelectedOption?.value}
          onChange={event => {
            handlePurchasedFromTypeSelected(event.target.value)
          }}
        />
      </div>
    </div>
  )
})

export default PatientAdditionalInfoForm
