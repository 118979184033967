import * as R from 'ramda'

import i18n from '../locales/i18n'
import { FilePreviewTemplate, Message } from '../types/entities'

const ALIASES = [
  {
    alias: i18n.t('Chat:CONNECT_WITH_VET'),
    regex: /^((https?):\/\/)?(www.)?(chewy.com)(\/b\/connect-vet-)([0-9]*)/,
  },
]

export const mapLinkToAlias = (url: string) => R.find(obj => obj?.regex?.test(url), ALIASES)?.alias || url

export const messageToFilePreview = (
  message: Message,
  url?: string,
): FilePreviewTemplate => ({
  id: message.mediaSid || '',
  name: message.fileName || 'File',
  url,
  contentType: message?.originalMedia?.contentType,
})
