/** @jsxImportSource @emotion/react */
import {
  KibProgressPath,
  KibProgressPathProps,
  KibProgressPathStep,
} from '@chewy/kib-progress-new-react'

type ProgressPathProps = KibProgressPathProps & {
  steps: string[]
}

const ProgressPath = ({ steps, ...rest }: ProgressPathProps) => (
  <KibProgressPath {...rest}>
    {steps.map(step => (
      <KibProgressPathStep key={step}>{step}</KibProgressPathStep>
    ))}
  </KibProgressPath>
)

export default ProgressPath
