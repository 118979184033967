/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'

import { CHECK_IN } from '../../../api/mutations/appointment'
import { GET_PATIENT_CARD_DATA } from '../../../api/queries/patient'
import { Spacing } from '../../../constants/spacing'
import { Appointment } from '../../../types/dto/Appointment'
import { getPatientCardQueryVariables } from '../../../utils/patient'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis } from '../button/Button'
import VetImage from '../images/vet.svg'
import Text, { TextVariant } from '../typography/Text/Text'
import Modal, { ModalProps } from './Modal'

type CheckInModalProps = ModalProps & {
  appointment: Appointment
  onCancel: (appointment: Appointment) => void
  onCheckedIn: (appointment: Appointment) => void
  supportedReminderStateIds: string[]
}

const styles = {
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  image: css({
    width: 200,
    height: 200,
  }),
  text: css({
    marginTop: spacing(Spacing.S6),
  }),
}

const CheckInModal = ({
  appointment,
  supportedReminderStateIds,
  onCancel,
  onCheckedIn,
  ...rest
}: CheckInModalProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const [checkIn, { loading }] = useMutation(CHECK_IN, {
    refetchQueries: [
      {
        query: GET_PATIENT_CARD_DATA,
        variables: getPatientCardQueryVariables(
          appointment?.patient?.id,
          supportedReminderStateIds,
        ),
      },
    ],
    awaitRefetchQueries: true,
  })

  const handleCheckIn = async () => {
    const {
      data: { checkIn: checkInData },
    } = await checkIn({
      variables: {
        businessId: appointment?.business?.id,
        appointmentId: appointment?.id,
        arrived: false,
      },
    })

    onCheckedIn({ ...appointment, ...(checkInData || {}) })
  }

  return (
    <Modal
      {...rest}
      // eslint-disable-next-line
      controls={({ close }) => (
        <div css={styles.buttonsContainer}>
          <Button
            disabled={loading}
            emphasis={ButtonEmphasis.SECONDARY}
            id="cim-later"
            onClick={close}
          >
            {t('Common:LATER')}
          </Button>

          <Button
            disabled={loading}
            id="cim-cin"
            loading={loading}
            onClick={handleCheckIn}
          >
            {t('Home:CHECK_IN')}
          </Button>
        </div>
      )}
      title={t('Home:READY_TO_CHECK_IN')}
      onClose={() => onCancel(appointment)}
    >
      <div css={styles.content}>
        <img alt="Vet" css={styles.image} src={VetImage} />

        <Text css={styles.text} variant={TextVariant.PARAGRAPH}>
          {t('Home:IF_YOU_HERE_CHECK_IN')}
        </Text>
      </div>
    </Modal>
  )
}

export default CheckInModal
