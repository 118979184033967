import { gql } from '@apollo/client/core'

export const SIGN_UP = gql`
  mutation SignUp($clientId: ID!) {
    signUp(clientId: $clientId) {
      id
    }
  }
`

export const UPDATE_ME = gql`
  mutation UpdateMe($input: CreateClientInput!) {
    me(input: $input) {
      id
    }
  }
`

export const UPDATE_CONTACT_INFO = gql`
  mutation UpdateContactInfo($input: ContactInfoInput!) {
    updateContactInfo(input: $input) {
      id
    }
  }
`
