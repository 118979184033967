/** @jsxImportSource @emotion/react */
import { KibSkeletonShape, KibSkeletonText } from '@chewy/kib-skeleton-react'
import { css } from '@emotion/react'

import { Spacing } from '../../../../constants/spacing'
import { spacing } from '../../../../utils/spacing'

const styles = {
  container: css({
    display: 'flex',
    padding: spacing(Spacing.S2, Spacing.S2),
    alignItems: 'center',
  }),
  label: css({
    marginLeft: spacing(Spacing.S2),
  }),
}

const PatientMenuItemSkeleton = ({ ...rest }) => (
  <div css={styles.container} {...rest}>
    <KibSkeletonShape circle style={{ width: 56 }} />

    <KibSkeletonText css={styles.label} />
  </div>
)

export default PatientMenuItemSkeleton
