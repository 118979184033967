const PhoneCountry = {
  US: 'US',
  CA: 'CA',
}

export const formatPhoneNumber = (phoneNumber?: string) => {
  const cleaned = `${phoneNumber}`?.replace(/^\+[0-9]/, '')?.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return undefined
}

export const formatPhoneNumberWithCountryCode = (
  phoneNumber?: string,
  phoneCountry = PhoneCountry.US,
) => {
  if (phoneCountry === PhoneCountry.US || phoneCountry === PhoneCountry.CA) {
    return `+1${phoneNumber}`
  }
  return phoneNumber
}

export const formatPhoneNumberWithoutCountryCode = (phoneNumber?: string) =>
  phoneNumber?.replace(/^\+[0-9]/, '')

export const formatZipWithoutDash = (zip?: string) => zip?.replace(/-/g, '')

export const formatZipWithDash = (zip?: string) => {
  const cleaned = `${zip}`?.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1-5})(\d{1-4})$/)

  if (match) {
    return `${match[1]}-${match[2]}`
  }

  return undefined
}
