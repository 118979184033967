/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router-dom'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import EditPatientCard, { EditPatientType } from '../../common/patient/EditPatientCard'

const styles = {
  container: css({
    padding: spacing(Spacing.S4, 0),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S6, Spacing.S4, Spacing.S6),
    }),
  }),
}

const EditPatientDetailsPage = () => {
  const { patientId } = useParams()

  return (
    <KibGrid align="center">
      <KibGridItem
        css={styles.container}
        offset="2@min-lg"
        push="2@min-lg"
        span="4@max-sm 8@min-md 8@min-lg"
      >
        <EditPatientCard patientId={patientId} type={EditPatientType.EDIT} />
      </KibGridItem>
    </KibGrid>
  )
}

export default EditPatientDetailsPage
