const imageExtensions = {
  PNG: 'png',
  JPEG: 'jpeg',
  JPG: 'jpg',
  TIFF: 'tiff',
  JFIF: 'jfif',
  GIF: 'gif',
  SVG: 'svg',
}

export const matchImageExtension = (extension: string | undefined) => {
  if (!extension) {
    return false
  }
  return Object.values(imageExtensions).includes(extension.toLowerCase())
}
