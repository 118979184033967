/** @jsxImportSource @emotion/react */
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'

import {
  GET_VACCINATION_DETAILS,
} from '../../../../api/queries/vaccination'
import { DateFormat } from '../../../../constants/dateFormat'
import i18n from '../../../../locales/i18n'
import { TimelineVaccination } from '../../../../types/dto/Patient'
import {
  Vaccination,
} from '../../../../types/dto/Vaccination'
import { getPersonName, getTimezone } from '../../../../utils'
import TimelineDetailsView, {
  TimelineDetailsContentItem,
} from './TimelineDetailsView'

type VaccinationDetailsResponse = {
  vaccinationDetails?: Vaccination
}

type TimelineVaccinationDetailsViewProps = {
  patientId?: string
  vaccination: TimelineVaccination
}

const getShortDate = (date: moment.MomentInput, timezone?: string) =>
  moment.tz(date, timezone || getTimezone()).format(DateFormat.MONTH_DAY_COMMA_YEAR)

const getContent = (vaccinationDetails?: Vaccination, timezone?: string) => {
  const content: TimelineDetailsContentItem[] = []

  if (vaccinationDetails?.dueDate) {
    content.push({
      title: i18n.t('Pet:IMMUNITY_UNTIL'),
      content: getShortDate(vaccinationDetails.dueDate, timezone),
    })
  }
  if (vaccinationDetails?.manufacturedBy) {
    content.push({
      title: i18n.t('Pet:MANUFACTURER'),
      content: vaccinationDetails.manufacturedBy,
    })
  }
  if (vaccinationDetails?.amount) {
    content.push({
      title: i18n.t('Common:AMOUNT'),
      content: `${vaccinationDetails.amount}`,
    })
  }
  if (vaccinationDetails?.serialNumber) {
    content.push({
      title: i18n.t('Pet:SERIAL_NO'),
      content: vaccinationDetails.serialNumber,
    })
  }
  if (vaccinationDetails?.lotNumber) {
    content.push({
      title: i18n.t('Pet:LOT_NO'),
      content: vaccinationDetails.lotNumber,
    })
  }
  if (vaccinationDetails?.lotExpired) {
    content.push({
      title: i18n.t('Pet:LOT_EXPIRES'),
      content: getShortDate(vaccinationDetails.lotExpired, timezone),
    })
  }

  return content
}

const TimelineVaccinationDetailsView = ({
  vaccination: vaccinationProp,
  patientId,
  ...rest
}: TimelineVaccinationDetailsViewProps) => {
  const { data: { vaccinationDetails } = {}, loading: loadingVaccination } =
    useQuery<VaccinationDetailsResponse>(GET_VACCINATION_DETAILS, {
      variables: { id: vaccinationProp?.id },
    })

  const loading = loadingVaccination
  const vaccinationDate = moment
    .tz(
      vaccinationDetails?.date,
      vaccinationProp?.business?.timezone || getTimezone(),
    )
    .format(
      moment(vaccinationDetails?.date).year() !== moment().year()
        ? DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_COMMA_YEAR_AT_FULL_TIME
        : DateFormat.DAY_OF_WEEK_MONTH_DAY_SHORT_AT_FULL_TIME,
    )
  const subTitle = vaccinationDetails
    ? `${i18n.t('Pet:ADMINISTERED_BY')} ${getPersonName(
      vaccinationDetails.administeredBy,
    )}\n${vaccinationDate}`
    : ''
  const contentItems = getContent(vaccinationDetails) || []

  return (
    <TimelineDetailsView
      contentItems={contentItems}
      headerSubtitle={subTitle}
      headerTitle={vaccinationProp?.title}
      loading={loading}
      timelineType={vaccinationProp?.type?.name}
      {...rest}
    />
  )
}

export default TimelineVaccinationDetailsView
