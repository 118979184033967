/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../../constants/mediaQuery'
import { Spacing } from '../../../../constants/spacing'
import { Patient } from '../../../../types/dto/Patient'
import { mediaQueryMatches } from '../../../../utils/mediaQuery'
import { spacing } from '../../../../utils/spacing'
import Button, { ButtonEmphasis, ButtonSize } from '../../button/Button'
import Card from '../../card/Card/Card'
import Text, { TextVariant } from '../../typography/Text/Text'

type CertificatesCardProps = {
  onRabies?: () => void
  onVaccination?: () => void
  patient?: Patient
}

const styles = {
  container: css({
    padding: spacing(Spacing.S4),
  }),
  buttonsContainer: css({
    display: 'flex',
    columnGap: spacing(Spacing.S2),
    marginTop: spacing(Spacing.S4),
  }),
}

const CertificatesCard = ({
  patient,
  onVaccination,
  onRabies,
  ...rest
}: CertificatesCardProps) => {
  const { t } = useTranslation(['Home', 'Pet'])

  const Container = mediaQueryMatches(MediaQuery.MAX_SM) ? Card : 'div'

  return (
    <Container css={styles.container} {...rest}>
      <Text variant={TextVariant.PARAGRAPH_1}>
        {t('Pet:SHARE_RECORDS', { name: patient?.name })}
      </Text>

      <div css={styles.buttonsContainer}>
        <Button
          emphasis={ButtonEmphasis.TERTIARY}
          id="certc-vacc"
          size={ButtonSize.MEDIUM}
          onClick={onVaccination}
        >
          {t('Home:VACCINATION')}
        </Button>

        <Button
          emphasis={ButtonEmphasis.TERTIARY}
          id="certc-rab"
          size={ButtonSize.MEDIUM}
          onClick={onRabies}
        >
          {t('Pet:RABIES')}
        </Button>
      </div>
    </Container>
  )
}

export default CertificatesCard
