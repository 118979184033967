/** @jsxImportSource @emotion/react */
import React, { forwardRef } from 'react'

import i18n from '../../../../locales/i18n'
import { FileTemplate } from '../../../../types/entities'
import FileSelector, { FileSelectorProps } from './FileSelector'

const MaxFileSize = 5e7

interface SingleFileSelectorProps
  extends Omit<FileSelectorProps, 'processFiles' | 'renderInput'> {
  onError?: (message?: string) => void
  onSelected?: (fileTemplate: FileTemplate) => void
}

const SingleFileSelector = forwardRef(function SingleFileSelector(
  { onSelected, onError, ...rest }: SingleFileSelectorProps,
  ref,
) {
  const processFiles = (
    event:
    | React.ChangeEvent<HTMLInputElement>
    | React.DragEvent<HTMLInputElement>,
  ) => {
    if (onError) {
      onError(undefined)
    }
    // @ts-ignore
    const { files } = event.dataTransfer || event.target || {}
    if (!files || !files.length) {
      return
    }
    const file = files[0]

    if (file.size > MaxFileSize) {
      if (onError) {
        onError(
          i18n.t('Common:FILE_INPUT_ALERT_MESSAGE', {
            maxFileSize: MaxFileSize / 1e6,
          }),
        )
      }
    } else {
      const reader = new FileReader()
      reader.onload = () => {
        const [, name, extension] = file.name.match(/(.*)\.(.*)/) || []
        if (onSelected) {
          onSelected({
            raw: file,
            data: reader.result as string,
            name,
            extension,
          })
        }
      }
      reader.readAsDataURL(file)
    }
  }

  return <FileSelector processFiles={processFiles} ref={ref} {...rest} />
})

export default SingleFileSelector
