import { useState } from 'react'

import i18n from '../../../../locales/i18n'
import { FileTemplate } from '../../../../types/entities'
import { matchImageExtension } from '../../../../utils/image'

const useFileInputHelper = (
  onFileSelected: ((file: FileTemplate) => void) | undefined,
) => {
  const [imageSource, setImageSource] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>()

  const updateSource = (src?: string) => {
    if (src !== imageSource) {
      setImageSource(src)
    }
  }

  const handleImageSelect = (file: FileTemplate) => {
    setError(undefined)
    const image = new Image()
    image.src = file.data || ''
    image.onload = () => {
      updateSource(file.data)
      if (onFileSelected) {
        onFileSelected(file)
      }
    }
    image.onerror = () => {
      setError(i18n.t('Common:ERROR_WHILE_LOADING_IMAGE'))
    }
  }

  const handleDocumentSelect = (file: FileTemplate) => {
    updateSource(file.data)
    if (onFileSelected) {
      onFileSelected(file)
    }
  }

  const onSelected = (file: FileTemplate) => {
    const isImage = matchImageExtension(file.extension)
    if (isImage) {
      handleImageSelect(file)
    } else {
      handleDocumentSelect(file)
    }
  }

  return {
    onSelected,
    error,
    setError,
    updateSource,
    imageSource,
    setImageSource,
  }
}

export default useFileInputHelper
