/* eslint-disable no-console */
import { useNavigate } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'

import { ENVIRONMENT_VARIABLES } from '../../../constants/environmentVariables'
import { getEnvironment } from '../../../utils/environment'

export interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode
}

export const Auth0ProviderWithNavigate = ({
  children,
}: Auth0ProviderWithNavigateProps) => {
  const navigate = useNavigate()

  const environment = getEnvironment() as keyof typeof ENVIRONMENT_VARIABLES

  const domain = ENVIRONMENT_VARIABLES[environment].AUTH0_DOMAIN
  const clientId = ENVIRONMENT_VARIABLES[environment].AUTH0_CLIENT_ID
  const redirectUri = ENVIRONMENT_VARIABLES[environment].AUTH0_CALLBACK_URL

  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!(domain && clientId && redirectUri)) {
    return (
      <p>
        Missing Auth0 configuration. Please check the environment variables
      </p>
    )
  }

  return (
    <Auth0Provider
      useRefreshTokens
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: 'https://petabyte.vet/boop/api/v1/',
        scope: 'openid email profile offline_access',
      }}
      cacheLocation="localstorage"
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
