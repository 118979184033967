/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'

import { GET_TASK_UPCOMING } from '../../api/queries/tasks'
import { Spacing } from '../../constants/spacing'
import { TaskRecurrenceType } from '../../constants/task'
import { Constant } from '../../types/dto/Constants'
import { Task } from '../../types/dto/Task'
import { spacing } from '../../utils/spacing'
import Divider from '../common/divider/Divider'
import ChildTasksList, { BATCH_SIZE } from './ChildTasksList'

type UpcomingChildTasksListProps = {
  parentTask: Task
  taskStates: Constant[]
}

const styles = {
  divider: css({
    margin: spacing(Spacing.S4, 0),
  }),
}

const UpcomingChildTasksList = ({
  parentTask,
  taskStates,
  ...rest
}: UpcomingChildTasksListProps) => {
  const { t } = useTranslation('Tasks')

  const [currentPage, setCurrentPage] = useState(1)

  const isChronic = parentTask?.recurrenceType === TaskRecurrenceType.CHRONIC

  const getVariables = () => ({
    id: parentTask?.id,
    offset: (currentPage - 1) * BATCH_SIZE,
    limit: BATCH_SIZE,
  })

  const {
    data,
    loading: upcomingLoading,
    refetch,
  } = useQuery(GET_TASK_UPCOMING, {
    variables: getVariables(),
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [parentTask])

  return data?.task?.upcoming?.data?.length > 0 || upcomingLoading ? (
    <>
      <Divider css={styles.divider} />

      <ChildTasksList
        currentPage={currentPage}
        loading={upcomingLoading}
        parentTask={parentTask}
        showTotalCount={!isChronic}
        taskStates={taskStates}
        tasks={data?.task?.upcoming?.data || []}
        title={t('Tasks:TO_DO')}
        totalCount={data?.task?.upcoming?.totalCount}
        onMarkDoneCompleted={() => refetch(getVariables())}
        onPageChanged={setCurrentPage}
        {...rest}
      />
    </>
  ) : undefined
}

export default UpcomingChildTasksList
