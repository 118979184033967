import gql from 'graphql-tag'

export const CREATE_PATIENT = gql`
  mutation CreatePatient($input: PatientInput!) {
    createPet(input: $input) {
      id
    }
  }
`

export const UPDATE_PATIENT_DETAILS = gql`
  mutation UpdatePatientDetails($id: ID! $input: PatientInput!) {
    updatePet(id: $id, input: $input) {
      id
      name
      photo
      photoThumbnail
      species {
        id
        name
      },
      breeds {
        id
        name
      },
      gender {
        id
        name
        friendlyName
      },
      spayedNeuteredStatus {
        id
        name
      },
      dateOfBirth
      microchipNumber
      rabiesTag
      rabiesTagExpiration
      insuranceProvider
      insurancePolicyNumber
    }
  }
`

export const UPDATE_PATIENT_SECONDARY_INFO = gql`
  mutation UpdatePatientSecondaryInfo($id: ID! $input: PatientInput!) {
    updatePet(id: $id, input: $input) {
      id
      diet
      allergies
      schedule
      notes
    }
  }
`
