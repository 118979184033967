/*
  We support:
  US ZIP format: XXXXX
  US ZIP+4 format: XXXXX-XXXX
  CA ZIP format: AXA XAX
  where X=digits 0–9, A=letters A-Z
*/

const CANADIAN_MASK = /^[a-vxyA-VXY][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]/
const USA_MASK_SHORT = /^[0-9]{5}/
const USA_MASK_LONG = /^[0-9]{5}-[0-9]{4}/

const FREE_MASK = /^[A-Za-z0-9-/\s]{5,10}/

const getUSAMask = (rawValue: string) =>
  rawValue.length > 5 ? USA_MASK_LONG : USA_MASK_SHORT

const getMask = (zip: string, country: string) => {
  switch (country) {
    case 'CA':
      return CANADIAN_MASK
    case 'US':
      return getUSAMask(zip)
    default:
      return FREE_MASK
  }
}

export const isValidZipCode = (zipCode = '', country = 'US') => (
  RegExp(getMask(zipCode, country)).test(zipCode)
)

export const isValidPhone = (phone: string | undefined) =>
  phone && /^\+{0,}[0-9]{11}/.test(phone)
