/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import i18n from '../../../locales/i18n'
import { Appointment } from '../../../types/dto/Appointment'
import { SaveFormHandle } from '../../../types/forms'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Button, { ButtonEmphasis } from '../button/Button'
import Card from '../card/Card/Card'
import CheckInArrivalForm from '../form/CheckInArrivalForm'
import CheckInDocumentsForm from '../form/CheckInDocumentsForm'
import CheckInQuestionsForm from '../form/CheckInQuestionsForm'
import CheckInReviewClientForm from '../form/CheckInReviewClientForm'
import CheckInReviewPatientForm from '../form/CheckInReviewPatientForm'
import ProgressPath from '../navigation/ProgressPath'
import Text, { TextVariant } from '../typography/Text/Text'

type Step = {
  index: number
  label: string
}

type ProgressStep = Step & {
  progressIndex: number
}

const Steps = {
  REVIEW_CLIENT_DETAILS: {
    index: 0,
    label: i18n.t('Home:REVIEW_ACCOUNT'),
  },
  REVIEW_PATIENT_DETAILS: {
    index: 2,
    label: i18n.t('Home:REVIEW_PATIENT'),
  },
  QUESTIONS: {
    index: 3,
    label: i18n.t('Home:ANSWER_QUESTIONS'),
  },
  DOCUMENTS: {
    index: 4,
    label: i18n.t('Home:SIGN_FORMS'),
  },
  ARRIVAL: {
    index: 5,
    label: i18n.t('Home:ARRIVING'),
  },
}

type CheckInCardProps = {
  appointment: Appointment
  clientNeedsUpdate: boolean
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    ...mediaQuery(MediaQuery.MAX_SM, {
      borderRadius: 0,
    }),
  }),
  innerContainer: css({
    justifyContent: 'center',
    padding: spacing(Spacing.S4, 0),
  }),
  title: css({
    ...mediaQuery(MediaQuery.MAX_SM, {
      margin: spacing(0, Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MD, {
      margin: spacing(0, Spacing.S8),
    }),
  }),
  subtitle: css({
    margin: spacing(Spacing.S2, Spacing.S4, 0, Spacing.S4),
  }),
  content: css({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: spacing(Spacing.S6),
    padding: spacing(Spacing.S4, 0),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S8),
    }),
  }),
  pathProgress: css({
    marginTop: spacing(Spacing.S8),
  }),
  stepContainerHalf: css({
    width: '50%',
    ...mediaQuery(MediaQuery.MAX_SM, {
      width: '100%',
    }),
  }),
  stepContainer: css({
    width: '100%',
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0, Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S8),
    }),
  }),
  backButton: css({
    marginRight: spacing(Spacing.S2),
  }),
}

const CheckInCard = ({
  appointment,
  clientNeedsUpdate,
  ...rest
}: CheckInCardProps) => {
  const navigate = useNavigate()

  const { t } = useTranslation(['Common', 'Home'])

  const [state, setState] = useState<Step>(Steps.ARRIVAL)
  const [saveLoading, setSaveLoading] = useState(false)
  const [readyToNext, setReadyToNext] = useState(false)

  const [documentsCompletionMap, setDocumentsCompletionMap] = useState<
  Record<string, boolean>
  >({})

  useEffect(() => {
    if (clientNeedsUpdate) {
      setState(Steps.REVIEW_CLIENT_DETAILS)
    } else if (appointment?.patient?.needsUpdate) {
      setState(Steps.REVIEW_PATIENT_DETAILS)
    } else if (appointment?.hasQuestions) {
      setState(Steps.QUESTIONS)
    } else if (appointment?.hasDocuments) {
      setState(Steps.DOCUMENTS)
    } else {
      setState(Steps.ARRIVAL)
    }
  }, [])

  const saveRef = useRef<SaveFormHandle>(null)

  const filteredSteps = Object.values(Steps)
    .filter(stepDetails => {
      if (stepDetails.label === Steps.REVIEW_CLIENT_DETAILS.label) {
        return clientNeedsUpdate
      }

      if (stepDetails.label === Steps.REVIEW_PATIENT_DETAILS.label) {
        return appointment?.patient?.needsUpdate
      }

      if (stepDetails.label === Steps.QUESTIONS.label) {
        return appointment?.hasQuestions
      }

      if (stepDetails.label === Steps.DOCUMENTS.label) {
        return appointment?.hasDocuments
      }

      return true
    })
    .map(
      (stepDetails, index) =>
        ({
          ...stepDetails,
          progressIndex: index + 1,
        } as ProgressStep),
    )

  const currentStep = R.find(it => it.index === state?.index, filteredSteps)
  const previousStep = R.findLast(it => it.index < state.index, filteredSteps)
  const nextStep = R.find(it => it.index > state.index, filteredSteps)

  const navigateHome = () => navigate('/dashboard')

  const next = () => {
    if (nextStep) {
      flushSync(() => {
        setState(nextStep)

        setReadyToNext(false)
      })
    } else {
      navigateHome()
    }
  }

  const onNext = () => {
    if (saveRef?.current) {
      saveRef.current.save()
    } else {
      next()
    }
  }

  const onSaveCompleted = () => {
    if (saveLoading) {
      setSaveLoading(false)
    }

    next()
  }

  const onBack = () => {
    if (state.index === Steps.ARRIVAL.index) {
      navigateHome()
    } else if (previousStep) {
      setState(previousStep)
    }
  }

  const onReady = () => {
    setReadyToNext(true)
  }

  return (
    <Card css={styles.container} {...rest}>
      <KibGrid>
        {mediaQueryMatches(MediaQuery.MIN_LG) && (
          <KibGridItem span="1@min-lg" />
        )}

        <KibGridItem
          css={styles.innerContainer}
          span="4@max-sm 8@min-md 10@min-lg"
        >
          <Text css={styles.title} variant={TextVariant.DISPLAY_5}>
            {t('Home:CHECK_IN')}
          </Text>

          <ProgressPath
            css={styles.pathProgress}
            currentStep={currentStep?.progressIndex || 1}
            steps={filteredSteps.map(step => step.label)}
          />

          <div css={styles.content}>
            {state.index === Steps.REVIEW_CLIENT_DETAILS.index && (
              <CheckInReviewClientForm
                ref={saveRef}
                onReady={onReady}
                onSaveCompleted={onSaveCompleted}
                onSaveLoadingStateChange={loadingState =>
                  setSaveLoading(loadingState.loading)
                }
              />
            )}

            {state.index === Steps.REVIEW_PATIENT_DETAILS.index && (
              <CheckInReviewPatientForm
                patientId={appointment?.patient?.id}
                ref={saveRef}
                onReady={onReady}
                onSaveCompleted={onSaveCompleted}
                onSaveLoadingStateChange={loadingState =>
                  setSaveLoading(loadingState.loading)
                }
              />
            )}

            {state.index === Steps.QUESTIONS.index && (
              <CheckInQuestionsForm
                appointmentId={appointment?.id}
                businessId={appointment?.business?.id}
                ref={saveRef}
                onReady={onReady}
                onSaveCompleted={onSaveCompleted}
                onSaveLoadingStateChange={loadingState =>
                  setSaveLoading(loadingState.loading)
                }
              />
            )}

            {state.index === Steps.DOCUMENTS.index && (
              <CheckInDocumentsForm
                appointmentId={appointment?.id}
                business={appointment?.business}
                initialCompletionMap={documentsCompletionMap}
                ref={saveRef}
                onCompletetionMapChange={setDocumentsCompletionMap}
                onReady={onReady}
                onSaveCompleted={onSaveCompleted}
                onSaveLoadingStateChange={loadingState =>
                  setSaveLoading(loadingState.loading)
                }
              />
            )}

            {state.index === Steps.ARRIVAL.index && (
              <CheckInArrivalForm
                appointmentId={appointment?.id}
                businessId={appointment?.business?.id}
                patientId={appointment?.patient?.id}
                ref={saveRef}
                onReady={onReady}
                onSaveCompleted={onSaveCompleted}
                onSaveLoadingStateChange={loadingState =>
                  setSaveLoading(loadingState.loading)
                }
              />
            )}
          </div>

          <div css={styles.buttonContainer}>
            {previousStep && (
              <Button
                css={styles.backButton}
                disabled={saveLoading}
                emphasis={ButtonEmphasis.SECONDARY}
                id={
                  state.index === Steps.ARRIVAL.index
                    ? 'cic-not-yet'
                    : 'cic-back'
                }
                onClick={onBack}
              >
                {state.index === Steps.ARRIVAL.index
                  ? t('Home:NOT_YET')
                  : t('Common:BACK')}
              </Button>
            )}

            <Button
              disabled={saveLoading || !readyToNext}
              id={
                state.index === Steps.ARRIVAL.index ? 'cic-arriv' : 'cic-next'
              }
              loading={saveLoading}
              onClick={onNext}
            >
              {state.index === Steps.ARRIVAL.index
                ? t('Home:HAVE_ARRIVED')
                : t('Common:NEXT')}
            </Button>
          </div>
        </KibGridItem>

        {mediaQueryMatches(MediaQuery.MIN_LG) && (
          <KibGridItem span="1@min-lg" />
        )}
      </KibGrid>
    </Card>
  )
}

export default CheckInCard
