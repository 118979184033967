import moment, { Moment } from 'moment-timezone'
import * as R from 'ramda'

import { DateFormat } from '../constants/dateFormat'
import { ReminderState } from '../constants/reminders'
import i18n from '../locales/i18n'
import { Appointment } from '../types/dto/Appointment'
import { Constant, ReminderStates } from '../types/dto/Constants'
import { Breed } from '../types/dto/Patient'
import { getAddress, getTimezone } from '.'

const getDueDate = (momentDate: Moment) =>
  moment(momentDate.format(DateFormat.BACKEND_FULL_DATE))
    .utc(true)
    .toISOString()

export const getReminderStatesForWidgets = (
  reminderStates: ReminderStates,
): string[] =>
  reminderStates
    ? R.pipe(
      R.filter(
        (it: Constant) =>
          it.name === ReminderState.OPEN || it.name === ReminderState.OVERDUE,
      ),
      R.map((it: Constant) => it.id),
    )(reminderStates)
    : []

export const getPatientCardQueryVariables = (
  id?: string,
  reminderStateIds?: string[],
) => ({
  id,
  offset: 0,
  limit: 3,
  states: reminderStateIds,
  timezone: getTimezone(),
  dueFrom: getDueDate(moment().subtract(90, 'days')),
  dueTo: getDueDate(moment().add(90, 'days')),
})

export const addAppointmentToCalendar = (appointment: Appointment) => {
  const title = i18n.t('Home:VISIT_TO', {
    businessName: appointment?.business?.name,
    patientName: appointment?.patient?.name,
  })
  const details = i18n.t('Home:CALENDAR_REASON_FOR_VISIT', {
    friendlyName: appointment?.subType?.friendlyName,
  })
  const venue = getAddress(appointment?.business)
  const startDate = moment(appointment.scheduledStartDatetime).format(
    DateFormat.GOOGLE_CALENDAR_DATE,
  )
  const endDate = moment(appointment.scheduledEndDatetime).format(
    DateFormat.GOOGLE_CALENDAR_DATE,
  )

  window.open(
    // eslint-disable-next-line max-len
    `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=${details}&location=${venue}&amp;sprop=&amp;sprop=name:`,
    '_blank',
  )
}

export const joinBreeds = (breeds?: Breed[]) => breeds?.map(({ name }) => name)?.join('/') || ''
