export const Spacing = {
  S1: 's1',
  S2: 's2',
  S3: 's3',
  S4: 's4',
  S5: 's5',
  S6: 's6',
  S7: 's7',
  S8: 's8',
  S9: 's9',
  S10: 's10',
} as const
