/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { FilePreviewTemplate } from '../../../types/entities'
import Button from '../button/Button'
import Modal, { ModalProps } from '../modal/Modal'

type NoPreviewProps = ModalProps & {
  file?: FilePreviewTemplate
  onDownload?: (file?: FilePreviewTemplate) => void
}

const styles = {
  buttonsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
}

const NoPreview = ({ file, onDownload, ...rest }: NoPreviewProps) => {
  const { t } = useTranslation('Common')

  return (
    <Modal
      title={file?.name}
      {...rest}
      // eslint-disable-next-line
      controls={({ close }) => (
        <div css={styles.buttonsContainer}>
          <Button onClick={() => onDownload?.(file)}>
            {t('Common:DOWNLOAD')}
          </Button>
        </div>
      )}
    />
  )
}

export default NoPreview
