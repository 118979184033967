/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import KibTransition from '@chewy/kib-transition-react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { GET_DASHBOARD_PATIENTS } from '../../../api/queries/client'
import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { MAIN_ROUTES, RouteNames } from '../../../constants/routes'
import { Spacing } from '../../../constants/spacing'
import { Patient } from '../../../types/dto/Patient'
import { mediaQuery, mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import useResize from '../../../utils/useResize'
import PatientMenuItem from '../patient/menu/PatientMenuItem'
import PatientMenuItemSkeleton from '../patient/menu/PatientMenuItemSkeleton'
import Popup, { PopupEventType, PopupPosition } from '../popup/Popup'
import NavigationItem from './NavigationItem'

type NavigationBarProps = {
  onItemSelected: (path: string) => void
  onLogout: (() => void) | undefined
  onToggle: (opened: boolean) => void
}

export type NavigationBarHandle = {
  toggle: () => void
}

const styles = {
  container: css({
    backgroundColor: ColorVariables.UI_BG_BRAND_PRIMARY,
    ...mediaQuery(MediaQuery.MAX_SM, {
      position: 'absolute',
      left: 0,
      right: 0,
      display: 'flex',
      zIndex: 1,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
    }),
  }),
  gridItem: css({
    // disable default bottom spacing
    marginBottom: 0,
  }),
  content: css({
    display: 'flex',
    ...mediaQuery(MediaQuery.MIN_LG, {
      padding: spacing(0, Spacing.S1),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(0, Spacing.S4, 0, Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      flexDirection: 'column',
    }),
  }),
  patientMenuContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
}

const generateRoutes = () =>
  Object.entries(MAIN_ROUTES)
    .filter(
      ([key]) =>
        key === RouteNames.HOME
        || key === RouteNames.TASKS
        || key === RouteNames.CHAT_LIST
        || (mediaQueryMatches(MediaQuery.MAX_SM) && key === RouteNames.ACCOUNT),
    )
    .map(([, value]) => value)

const NavigationBar = forwardRef<NavigationBarHandle, NavigationBarProps>(
  function NavigationBar({ onItemSelected, onToggle, onLogout }, ref) {
    const navigate = useNavigate()

    const { t } = useTranslation(['Auth', 'Common'])

    const { isMobile } = useResize()

    const [opened, setOpened] = useState(mediaQueryMatches(MediaQuery.MIN_MD))
    const [routes, setRoutes] = useState(generateRoutes())

    const { data: { me } = {}, loading } = useQuery(GET_DASHBOARD_PATIENTS)

    useEffect(() => {
      setOpened(!isMobile)
      setRoutes(generateRoutes())
    }, [isMobile])

    useImperativeHandle(
      ref,
      () => ({
        toggle: () => {
          const isOpened = opened
          setOpened(!isOpened)
          onToggle(!isOpened)
        },
      }),
      [opened],
    )

    return (
      <KibTransition name="fade" speed="fast" switchKey={opened}>
        <KibGrid css={styles.container}>
          <KibGridItem
            css={styles.gridItem}
            offset="2@min-lg"
            push="2@min-lg"
            span="4@min-xs 8@min-md 8@min-lg"
          >
            <div css={styles.content}>
              {routes.map(route => (
                <NavigationItem
                  current={window.location.pathname === route.path}
                  id={`nb-${route.title}`}
                  key={route.title}
                  path={route?.path}
                  title={route?.title}
                  onItemSelected={path => {
                    onItemSelected(path)
                    if (mediaQueryMatches(MediaQuery.MAX_SM)) {
                      setOpened(false)
                      onToggle(false)
                    }
                  }}
                />
              ))}

              {mediaQueryMatches(MediaQuery.MIN_MD)
                && me?.patients?.length > 0 && (
                  <Popup
                    arrow={false}
                    on={[PopupEventType.HOVER, PopupEventType.FOCUS]}
                    position={PopupPosition.BOTTOM_RIGHT}
                    trigger={
                      <div>
                        <NavigationItem
                          showMenuChevron
                          current={R.includes(
                            '/patient/',
                            window.location.pathname,
                          )}
                          id="nb-patient"
                          path="/"
                          showChevron={false}
                          title={t('Common:PETS')}
                        />
                      </div>
                    }
                  >
                    <div css={styles.patientMenuContainer}>
                      {loading ? (
                        <>
                          <PatientMenuItemSkeleton />
                          <PatientMenuItemSkeleton />
                        </>
                      ) : (
                        me?.patients?.map((patient: Patient) => (
                          <PatientMenuItem
                            key={patient.id}
                            patient={patient}
                            onClick={() => {
                              navigate(`/patient/${patient.id}`, {
                                state: { from: window.location.pathname },
                              })
                            }}
                          />
                        ))
                      )}
                    </div>
                  </Popup>
              )}

              {mediaQueryMatches(MediaQuery.MAX_SM) && (
                <NavigationItem
                  current={false}
                  id="nb-sign-out"
                  path="/"
                  showChevron={false}
                  title={t('Auth:SIGN_OUT')}
                  onItemSelected={() => {
                    if (mediaQueryMatches(MediaQuery.MAX_SM)) {
                      setOpened(false)
                      onToggle(false)
                    }

                    if (onLogout) {
                      onLogout()
                    }
                  }}
                />
              )}
            </div>
          </KibGridItem>
        </KibGrid>
      </KibTransition>
    )
  },
)

export default NavigationBar
