/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { GET_PRESCRIPTION_DETAILS } from '../../../../api/queries/prescription'
import i18n from '../../../../locales/i18n'
import { TimelinePrescription } from '../../../../types/dto/Patient'
import { Prescription } from '../../../../types/dto/Prescription'
import { getTimezone } from '../../../../utils'
import TimelineDetailsView from './TimelineDetailsView'

type PrescriptionDetailsResponse = {
  prescription?: Prescription
}

type TimelinePrescriptionDetailsViewProps = {
  onRequestRefill?: (prescription: TimelinePrescription) => void
  prescription: TimelinePrescription
}

const getContent = (prescription?: Prescription) => [
  {
    title: i18n.t('Pet:INSTRUCTIONS'),
    content: prescription?.timelineDetails?.instructions,
  },
  {
    title: i18n.t('Pet:REFILLS'),
    content: prescription?.timelineDetails?.refill,
  },
]

const TimelinePrescriptionDetailsView = ({
  prescription: prescriptionProp,
  onRequestRefill,
  ...rest
}: TimelinePrescriptionDetailsViewProps) => {
  const { t } = useTranslation(['Home', 'Pet'])

  const { data: { prescription } = {}, loading } =
    useQuery<PrescriptionDetailsResponse>(GET_PRESCRIPTION_DETAILS, {
      variables: {
        id: prescriptionProp.id,
        timezone: getTimezone(),
      },
    })

  const title = prescription?.title || t('Home:PRESCRIPTION')
  const contentItems = getContent(prescription)
  const businessPhone =
    prescription?.business?.primaryPhone
    || prescription?.business?.secondaryPhone
    || ''
  const showActionHeader = Boolean(
    Boolean((prescription?.refillsLeft || 0) > 0) && businessPhone,
  )

  return (
    <TimelineDetailsView
      actionHeaderActionTitle={t('Home:REQUEST_REFILL')}
      contentItems={contentItems}
      headerSubtitle={prescription?.timelineDetails?.subtitle}
      headerTitle={title}
      loading={loading}
      showActionHeader={showActionHeader}
      timelineType={prescriptionProp?.type?.name}
      onHeaderAction={() => onRequestRefill?.(prescriptionProp)}
      {...rest}
    />
  )
}

export default TimelinePrescriptionDetailsView
