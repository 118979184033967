/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'

import { FilePreviewTemplate, Message } from '../../../types/entities'
import { messageToFilePreview } from '../../../utils/chat'
import useResize from '../../../utils/useResize'
import ChatView, { ChatViewHandle } from '../../common/chat/ChatView'
import FileInputModal, {
  FileInputUsage,
} from '../../common/modal/FileInputModal'
import FilePreview from '../../common/preview/FilePreview'

const styles = {
  container: css({
    display: 'flex',
  }),
}

const ChatPage = () => {
  const { t } = useTranslation('Common')
  const location = useLocation()
  const { isMobile } = useResize()
  const navigate = useNavigate()

  const { chat } = location.state

  const [fileInputOpen, setFileInputOpen] = useState(false)
  const [fileModalOpen, setFileModalOpen] = useState(false)
  const [file, setFile] = useState<FilePreviewTemplate | undefined>()

  const chatRef = useRef<ChatViewHandle>(null)

  const handleShowMediaPreview = async (message: Message) => {
    const url = await message?.originalMedia?.getContentTemporaryUrl()

    flushSync(() => {
      setFile(messageToFilePreview(message, url || ''))
      setFileModalOpen(true)
    })
  }

  const handleCreateNewChat = () => {
    navigate('/chat/new', {
      state: { from: '/chat' },
    })
  }

  useEffect(() => {
    if (isMobile === false) {
      navigate(-1)
    }
  }, [isMobile])

  return (
    <div css={styles.container}>
      <ChatView
        chat={chat}
        ref={chatRef}
        onCreateNewChat={handleCreateNewChat}
        onShowFileInput={() => setFileInputOpen(true)}
        onShowMediaPreview={handleShowMediaPreview}
      />

      <FilePreview
        file={file}
        open={fileModalOpen}
        onClose={() => setFileModalOpen(false)}
      />

      <FileInputModal
        fileInputUsage={FileInputUsage.ATTACHMENT}
        open={fileInputOpen}
        title={t('Common:CHOOSE_A_FILE')}
        onClose={() => setFileInputOpen(false)}
        onFileReady={data => {
          setFileInputOpen(false)
          chatRef?.current?.sendMediaMessage(data)
        }}
      />
    </div>
  )
}

export default ChatPage
