/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'

import ApolloProviderWithAuth0 from '../api/ApolloProviderWithAuth0'
import i18n from '../locales/i18n'
import useResize from '../utils/useResize'
import Auth0Callback from './auth/auth0/Auth0Callback'
import AuthPage from './auth/AuthPage'
import ChatClientProvider from './common/chat/ChatClientProvider'
import Footer from './common/footer/Footer'
import PrivacyModal from './common/modal/PrivacyModal'
import TermsAndConditionsModal from './common/modal/TermsAndConditionsModal'
import MainPage from './main/MainPage'

const styles = {
  mainContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  }),
}

const App = () => {
  const navigate = useNavigate()

  useResize()

  const [privacyOpen, setPrivacyOpen] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false)

  const { isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    if (
      !isLoading
      && !isAuthenticated
      && !window.location.pathname.includes('/auth')
    ) {
      navigate('auth/sign-in')
    }
  }, [isLoading, isAuthenticated])

  return (
    <div css={styles.mainContainer}>
      <I18nextProvider i18n={i18n}>
        <ApolloProviderWithAuth0>
          <ChatClientProvider>
            <Routes>
              <Route element={<MainPage />} path="/*" />
              <Route element={<Auth0Callback />} path="/callback" />
              <Route element={<AuthPage />} path="auth/*" />
            </Routes>

            <Footer
              onPrivacy={() => setPrivacyOpen(true)}
              onTerms={() => setTermsOpen(true)}
            />

            <PrivacyModal
              open={privacyOpen}
              onClose={() => setPrivacyOpen(false)}
            />

            <TermsAndConditionsModal
              open={termsOpen}
              onClose={() => setTermsOpen(false)}
            />
          </ChatClientProvider>
        </ApolloProviderWithAuth0>
      </I18nextProvider>
    </div>
  )
}

export default App
