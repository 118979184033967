/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { Spacing } from '../../../../constants/spacing'
import { Prescription } from '../../../../types/dto/Prescription'
import { spacing } from '../../../../utils/spacing'
import Button, { ButtonSize } from '../../button/Button'
import Card from '../../card/Card/Card'
import Divider from '../../divider/Divider'
import Tag, { TagTheme } from '../../tag/Tag'
import Text, { TextVariant } from '../../typography/Text/Text'

type PrescriptionWidgetItemProps = {
  onOrder?: (prescription: Prescription) => void
  prescription: Prescription
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: spacing(Spacing.S3, Spacing.S4),
  }),
  containerWithRefills: css({
    padding: spacing(Spacing.S6, Spacing.S4, Spacing.S3, Spacing.S4),
  }),
  refills: css({
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 0,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
  }),
  divider: css({
    marginTop: spacing(Spacing.S2),
    marginBottom: spacing(Spacing.S3),
  }),
  orderButton: css({
    marginLeft: 'auto',
  }),
}

const PrescriptionWidgetItem = ({
  prescription,
  onOrder,
  ...rest
}: PrescriptionWidgetItemProps) => {
  const { t } = useTranslation(['Home', 'Pet'])

  const refillAmount = prescription?.refillsLeft
    ? prescription?.refillsLeft > 1
      ? `${prescription?.refillsLeft} ${t('Pet:REFILLS_LEFT').toLowerCase()}`
      : `${prescription?.refillsLeft} ${t('Pet:REFILL_LEFT').toLowerCase()}`
    : ''

  return (
    <Card
      css={[styles.container, refillAmount && styles.containerWithRefills]}
      {...rest}
    >
      {refillAmount && (
        <Tag css={styles.refills} theme={TagTheme.DEFAULT}>
          {refillAmount}
        </Tag>
      )}

      <Text variant={TextVariant.SECTION_2}>{prescription?.title}</Text>

      <Text variant={TextVariant.PARAGRAPH}>{prescription.dispense}</Text>

      <Divider css={styles.divider} />

      <Button
        css={styles.orderButton}
        size={ButtonSize.MEDIUM}
        onClick={() => onOrder?.(prescription)}
      >
        {t('Home:REQUEST_REFILL')}
      </Button>
    </Card>
  )
}

export default PrescriptionWidgetItem
