import gql from 'graphql-tag'

export const GET_CHAT_ACCESS_TOKEN = gql`
  query GetAccessToken {
    twilioToken
  }
`

export const GET_CHATS = gql`
  query GetChats($offset: Int!, $limit: Int!) {
    chats(offset: $offset, limit: $limit) {
      data {
        id
        business {
          id
          name
          logo
        }
        client {
          id
          firstName
          lastName
          photo
          photoThumbnail
        }
        patient {
          id
          name
          photo
          photoThumbnail
        }
        channelSID
        title
        isClosed
        messagesCount
        messages(offset: 0, limit: 1) {
          data {
            id
            createdAt
            body
            files {
              id
              name
            }
          }
        }
      }
      totalCount
    }
  }
`
