/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { spacing } from '../../../utils/spacing'
import Spinner from '../../common/spinner/Spinner'

const styles = {
  container: css({
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  spinner: css({
    marginTop: spacing(Spacing.S10),
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
}
const Auth0Callback = () => (
  <div css={styles.container}>
    <Spinner css={styles.spinner} />
  </div>
)

export default Auth0Callback
