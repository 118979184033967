import moment from 'moment-timezone'

import { TagTheme } from '../components/common/tag/Tag'
import { DateFormat } from '../constants/dateFormat'
import { TaskRecurrenceType, TaskState } from '../constants/task'
import i18n from '../locales/i18n'
import { Task } from '../types/dto/Task'

export const getFormattedTaskFutureDate = (date: string | undefined) =>
  moment(date).calendar({
    sameDay: `[${i18n.t('Common:TODAY')}], ${
      DateFormat.FULL_TIME_MERIDIAN_UPPER
    }`,
    nextDay: `[${i18n.t('Common:TOMORROW')}], ${
      DateFormat.FULL_TIME_MERIDIAN_UPPER
    }`,
    nextWeek: `[${i18n.t('Common:NEXT')}] ${
      DateFormat.DAY_OF_WEEK_COMMA_FULL_TIME_MERIDIAN_UPPER
    }`,
    sameElse: () =>
      moment(date).year() !== moment().year()
        ? DateFormat.DAY_OF_WEEK_SHORT_COMMA_MONTH_DAY_COMMA_FULL_TIME_MERIDIAN_UPPER_COMMA_YEAR
        : DateFormat.DAY_OF_WEEK_SHORT_COMMA_MONTH_DAY_COMMA_FULL_TIME_MERIDIAN_UPPER,
  })

export const getFormattedTaskDueDate = (date: string | undefined) =>
  moment(date).calendar({
    lastWeek: `[${i18n.t('Tasks:DUE_LAST')}] ${
      DateFormat.DAY_OF_WEEK_SHORT_AT_FULL_TIME_MERIDIAN_UPPER
    }`,
    lastDay: `[${i18n.t('Tasks:DUE_YESTERDAY_AT')}] ${
      DateFormat.FULL_TIME_MERIDIAN_UPPER
    }`,
    sameDay: `[${i18n.t('Tasks:DUE_TODAY_AT')}] ${
      DateFormat.FULL_TIME_MERIDIAN_UPPER
    }`,
    nextDay: `[${i18n.t('Tasks:DUE_TOMORROW_AT')}] ${
      DateFormat.FULL_TIME_MERIDIAN_UPPER
    }`,
    nextWeek: `[${i18n.t('Tasks:DUE_NEXT')}] ${
      DateFormat.DAY_OF_WEEK_SHORT_AT_FULL_TIME_MERIDIAN_UPPER
    }`,
    sameElse: () =>
      moment(date).year() !== moment().year()
        ? `[${i18n.t('Common:DUE')}] ${
          DateFormat.MONTH_DAY_AT_FULL_TIME_MERIDIAN_UPPER_COMMA_YEAR
        }`
        : `[${i18n.t('Common:DUE')}] ${
          DateFormat.MONTH_DAY_AT_FULL_TIME_MERIDIAN_UPPER
        }`,
  })

export const getFormattedTaskCompletionDate = (date: string | undefined) =>
  date ? moment(date)?.format(DateFormat.DATE_WITHOUT_YEAR_FULL_TIME) : ''

export const getTaskReferencePeriod = (task: Task) => {
  const timesCount = task?.timesOfDay?.times?.length
  const amountTimes = timesCount && `${timesCount}x`
  const period = task?.recurrencePeriod
  if (period?.amount === 1) {
    if (period?.unit === 'Days') {
      return timesCount
        ? `${amountTimes} ${i18n.t('Common:DAILY').toLowerCase()}`
        : i18n.t('Common:DAILY')
    }
    if (period?.unit === 'Weeks') {
      return timesCount
        ? `${amountTimes} ${i18n.t('Common:WEEKLY').toLowerCase()}`
        : i18n.t('Common:WEEKLY')
    }
    if (period?.unit === 'Months') {
      return timesCount
        ? `${amountTimes} ${i18n.t('Common:MONTHLY').toLowerCase()}`
        : i18n.t('Common:MONTHLY')
    }
    if (period?.unit === 'Years') {
      return timesCount
        ? `${amountTimes} ${i18n.t('Common:YEARLY').toLowerCase()}`
        : i18n.t('Common:YEARLY')
    }
  }
  return `${
    timesCount
      ? `${amountTimes} ${i18n.t('Common:EVERY').toLowerCase()}`
      : i18n.t('Common:EVERY')
  } ${period?.amount} ${period?.unit?.toLowerCase()}`
}

export const getTaskRecurrencePeriodLabel = (task: Task) => {
  const startTime = moment(task?.recurrenceStartDatetime).format(
    DateFormat.MONTH_DAY_SHORT_YEAR,
  )
  const endTime = moment(task?.recurrenceEndDatetime).format(
    DateFormat.MONTH_DAY_SHORT_YEAR,
  )
  const period =
    task?.recurrenceType === TaskRecurrenceType.REPEAT
      ? `${getTaskReferencePeriod(task)}: ${startTime} - ${endTime}`
      : task?.recurrenceType === TaskRecurrenceType.CHRONIC
        ? getTaskReferencePeriod(task)
        : getFormattedTaskFutureDate(task?.dueDate)

  return period
}

export const getTaskStateLabel = (task: Task) => {
  if (task?.aggregatedState?.name === TaskState.OVERDUE) {
    return { title: i18n.t('Common:OVERDUE'), theme: TagTheme.ERROR }
  }

  if (task?.aggregatedState?.name === TaskState.DONE) {
    return { title: i18n.t('Tasks:COMPLETED'), theme: TagTheme.SUCCESS }
  }

  return { title: i18n.t('Tasks:TO_DO'), theme: TagTheme.DEFAULT }
}
