/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Timeout } from 'react-number-format/types/types'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { Spacing } from '../../../../constants/spacing'
import { Appointment } from '../../../../types/dto/Appointment'
import { getTimeDiffInSeconds } from '../../../../utils/date'
import { spacing } from '../../../../utils/spacing'
import {
  LOCAL_CHECK_IN_MODAL,
  useLocalStorage,
} from '../../../../utils/useLocalStorage'
import Card from '../../card/Card/Card'
import CheckInModal from '../../modal/CheckInModal'
import Tag, { TagTheme } from '../../tag/Tag'
import UpcomingAppointmentWidgetItem, {
  SHOW_CHECK_IN_TIME,
} from './UpcomingAppointmentWidgetItem'

type UpcomingAppointmentWidgetProps = {
  appointments: Appointment[]
  onArrived: () => void
  onCheckedIn: (appointment: Appointment) => void
  parentIndex?: number
  supportedReminderStateIds: string[]
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    rowGap: spacing(Spacing.S3),
    padding: spacing(Spacing.S7, Spacing.S3, Spacing.S3, Spacing.S3),
  }),
  label: css({
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 0,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
  }),
}

const UpcomingAppointmentWidget = ({
  appointments,
  supportedReminderStateIds,
  parentIndex = 1,
  onArrived,
  onCheckedIn,
  ...rest
}: UpcomingAppointmentWidgetProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const [checkInShownList, setCheckInModalShownList] = useLocalStorage<
  Array<string>
  >(LOCAL_CHECK_IN_MODAL, [])

  const [showCheckInModal, setShowCheckInModal] = useState(false)

  const firstAppointment = appointments?.[0]

  const secondsBeforeAppointmentStart =
    getTimeDiffInSeconds(firstAppointment?.scheduledStartDatetime)
    || SHOW_CHECK_IN_TIME + 1

  const checkInWasShown = R.find(
    R.equals(firstAppointment.id),
    checkInShownList,
  )

  useEffect(() => {
    const showCheckInNow =
      firstAppointment
      && !firstAppointment?.state?.checkedIn
      && secondsBeforeAppointmentStart >= -SHOW_CHECK_IN_TIME
      && secondsBeforeAppointmentStart <= SHOW_CHECK_IN_TIME

    if (!checkInWasShown && showCheckInModal !== showCheckInNow) {
      setShowCheckInModal(showCheckInNow)
    }
  }, [firstAppointment])

  useEffect(() => {
    let checkInTimer: Timeout | undefined
    if (
      firstAppointment
      && secondsBeforeAppointmentStart > SHOW_CHECK_IN_TIME
      && secondsBeforeAppointmentStart < SHOW_CHECK_IN_TIME * 3
      && !checkInTimer
    ) {
      checkInTimer = setTimeout(() => {
        if (!showCheckInModal) {
          setShowCheckInModal(true)
        }
      }, (secondsBeforeAppointmentStart - SHOW_CHECK_IN_TIME) * 1000)
    }

    return () => {
      if (checkInTimer) {
        clearTimeout(checkInTimer)
      }
    }
  }, [firstAppointment, showCheckInModal])

  const handleCheckInModalClose = (appointment: Appointment) => {
    setShowCheckInModal(false)
    if (appointment && appointment.id) {
      setCheckInModalShownList([...checkInShownList, appointment.id])
    }
  }

  const afterCheckIn = (appointment: Appointment) => {
    setShowCheckInModal(false)
    onCheckedIn(appointment)
  }

  return (
    <>
      <Card css={styles.container} {...rest}>
        <Tag css={styles.label} theme={TagTheme.CAUTION}>
          {t('Home:UPCOMING_VISITS').toUpperCase()}
        </Tag>

        {appointments.map((appointment, index) => (
          <UpcomingAppointmentWidgetItem
            appointment={appointment}
            index={index}
            key={appointment.id}
            parentIndex={parentIndex}
            supportedReminderStateIds={supportedReminderStateIds}
            onArrived={onArrived}
            onCheckedIn={onCheckedIn}
          />
        ))}
      </Card>

      <CheckInModal
        appointment={firstAppointment}
        open={showCheckInModal}
        supportedReminderStateIds={supportedReminderStateIds}
        onCancel={handleCheckInModalClose}
        onCheckedIn={afterCheckIn}
      />
    </>
  )
}

export default UpcomingAppointmentWidget
