/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { Chat } from '../../../types/dto/Chat'
import { spacing } from '../../../utils/spacing'
import useResize from '../../../utils/useResize'
import CreateChatForm from '../../common/form/CreateChatForm'

const styles = {
  container: css({
    display: 'flex',
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
    padding: spacing(Spacing.S4),
  }),
}

const CreateChatPage = () => {
  const { isMobile } = useResize()
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (isMobile === false) {
      navigate(-1)
    }
  }, [isMobile])

  const handleChatCreated = (chat: Chat) => {
    navigate(`/chat/${chat.id}`, {
      state: { chat, from: '/chat' },
    })
  }

  return (
    <div css={styles.container}>
      <CreateChatForm
        certificateType={state.certificateType}
        prescriptionTitle={state.prescriptionTitle}
        selectedPatientId={state.patientId}
        onChatCreated={handleChatCreated}
      />
    </div>
  )
}

export default CreateChatPage
