/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { Task } from '../../../types/dto/Task'
import { spacing } from '../../../utils/spacing'
import useResize from '../../../utils/useResize'
import Button from '../../common/button/Button'
import CreateTaskForm, {
  CreateTaskFormHandle,
} from '../../common/form/CreateTaskForm'

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorVariables.UI_BG_PRIMARY,
    padding: spacing(Spacing.S4),
  }),
  button: css({
    marginTop: spacing(Spacing.S4),
  }),
}

const CreateTaskPage = () => {
  const { t } = useTranslation('Common')
  const { isMobile } = useResize()
  const navigate = useNavigate()

  const [canAddTask, setCanAddTask] = useState(false)
  const [loading, setLoading] = useState(false)

  const formRef = useRef<CreateTaskFormHandle>(null)

  useEffect(() => {
    if (isMobile === false) {
      navigate(-1)
    }
  }, [isMobile])

  const handleTaskCreated = (task: Task) => {
    navigate(`/task/${task.id}`, {
      state: { task, from: '/tasks' },
    })
  }

  return (
    <div css={styles.container}>
      <CreateTaskForm
        ref={formRef}
        onDataChanged={setCanAddTask}
        onLoading={setLoading}
        onTaskCreated={handleTaskCreated}
      />

      <Button
        css={styles.button}
        disabled={!canAddTask || loading}
        id="ctf-add"
        loading={loading}
        onClick={() => formRef.current?.add()}
      >
        {t('Common:ADD')}
      </Button>
    </div>
  )
}

export default CreateTaskPage
