/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { ENVIRONMENT_VARIABLES } from '../../../constants/environmentVariables'
import { Spacing } from '../../../constants/spacing'
import { getEnvironment } from '../../../utils/environment'
import { spacing } from '../../../utils/spacing'
import Button from '../../common/button/Button'
import EmptyState from '../../common/empty/EmptyState'
import ErrorImage from '../../common/images/cat-receptionist.svg'

const styles = {
  container: css({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(0, Spacing.S4),
  }),
}

const SignInErrorPage = () => {
  const { t } = useTranslation('Auth')
  const { logout } = useAuth0()
  const location = useLocation()

  const header = location?.state?.header || ''
  const message = location?.state?.message || ''

  const handleLogout = () => {
    logout({
      clientId: ENVIRONMENT_VARIABLES[getEnvironment()].AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: `${window.location.origin}/auth/sign-out`,
      },
    })
  }

  return (
    <KibGrid css={styles.container}>
      <KibGridItem
        offset="2@min-lg"
        push="2@min-lg"
        span="4@min-xs 8@min-md 8@min-lg"
      >
        <EmptyState
          actions={
            <Button id="ep-sign-out" onClick={handleLogout}>
              {t('Auth:SIGN_OUT')}
            </Button>
          }
          header={header}
          media={<img alt="" src={ErrorImage} />}
          // eslint-disable-next-line
          message={<div dangerouslySetInnerHTML={{ __html: message }} />}
        />
      </KibGridItem>
    </KibGrid>
  )
}

export default SignInErrorPage
