export const Environment = {
  PROD: 'prod',
  MASTER: 'master',
  STAGE: 'stage',
  LOCAL: 'local',
}

export const ENVIRONMENT_VARIABLES = {
  [Environment.PROD]: {
    AUTH0_CALLBACK_URL: 'https://boop.rhapsody.vet/callback',
    AUTH0_CLIENT_ID: '',
    AUTH0_DOMAIN: 'boop-prod.auth0.com',
  },
  [Environment.MASTER]: {
    AUTH0_CALLBACK_URL: 'https://boop.master.rhapsody.vet/callback',
    AUTH0_CLIENT_ID: '',
    AUTH0_DOMAIN: 'boop-master.auth0.com',
  },
  [Environment.STAGE]: {
    AUTH0_CALLBACK_URL: 'https://boop.stage.rhapsody.vet/callback',
    AUTH0_CLIENT_ID: 'bQqyKCi8VbKzapUbpdmOfzqmyxwNRoJ7',
    AUTH0_DOMAIN: 'boop-stage.auth0.com',
  },
  [Environment.LOCAL]: {
    AUTH0_CALLBACK_URL: 'http://localhost:3000/callback',
    AUTH0_CLIENT_ID: 'bQqyKCi8VbKzapUbpdmOfzqmyxwNRoJ7',
    AUTH0_DOMAIN: 'boop-stage.auth0.com',
  },
}
