/** @jsxImportSource @emotion/react */
import { KibSkeletonText } from '@chewy/kib-skeleton-react'

import styles from './Text.module.scss'

export const TextVariant = {
  DISPLAY_1: 'display-1',
  DISPLAY_2: 'display-2',
  DISPLAY_3: 'display-3',
  DISPLAY_4: 'display-4',
  DISPLAY_5: 'display-5',
  DISPLAY_6: 'display-6',
  SECTION_1: 'section-1',
  SECTION_2: 'section-2',
  SECTION_3: 'section-3',
  PARAGRAPH: 'paragraph',
  PARAGRAPH_1: 'paragraph-1',
  PARAGRAPH_2: 'paragraph-2',
  CAPTION: 'caption',
  BUTTON: 'button',
  BUTTON_MEDIUM: 'button_medium',
  OVERLINE: 'overline',
  FORM_GROUPING: 'form-grouping',
  FORM_LABEL: 'form-label',
  FORM_TEXT: 'form-text',
  FORM_HELPER: 'form-helper',
  FORM_ERROR: 'form-error',
  UTILITY: 'utility',
  UTILITY_SMALL: 'utility-small',
  PRICE_SMALL: 'price-small',
  PRICE_MEDIUM: 'price-medium',
  PRICE_LARGE: 'price-large',
  PRICE_X_LARGE: 'price-x-large',
  PRICE_X_SMALL_STRIKETHROUGH: 'price-x-small-strikethrough',
  PRICE_SMALL_STRIKETHROUGH: 'price-small-strikethrough',
} as const

type TextVariantType = (typeof TextVariant)[keyof typeof TextVariant]

type TextProps = {
  children?: React.ReactNode
  className?: string
  loading?: boolean
  skeletonLines?: number
  skeletonWidth?: number | string
  variant: TextVariantType
}

const Text = ({
  variant,
  loading,
  skeletonWidth,
  skeletonLines = 1,
  className,
  children,
  ...rest
}: TextProps) => (
  <div
    className={`${styles[variant]}${className ? ` ${className}` : ''}`}
    {...rest}
  >
    {loading ? <KibSkeletonText lines={skeletonLines} style={{ width: skeletonWidth }} /> : children}
  </div>
)

export default Text
