/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KibTabsNew, KibTabsNewItem } from '@chewy/kib-content-groups-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Task } from '../../../types/dto/Task'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import CompletedTasksList from '../../tasks/CompletedTasksList'
import UpcomingTasksList from '../../tasks/UpcomingTasksList'

type TaskTabsFragmentProps = {
  onCreateNewTask: () => void
  onTaskSelected: (task: Task) => void
  onTasksLoaded: (tasks?: Task[]) => void
  selectedTask?: Task
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    ...mediaQuery(MediaQuery.MIN_MD, {
      padding: spacing(Spacing.S4, 0),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, 0, 0, Spacing.S6),
    }),
  }),
  tabsContainer: css({
    backgroundColor: 'transparent',
    '.kib-tabs-new__nav': {
      gridTemplateColumns: 'none',
      ...mediaQuery(MediaQuery.MIN_MD, {
        borderBottom: 0,
      }),
    },
    '.kib-tabs-new__content-item': {
      padding: 0,
    },
    '.kib-tabs-new__trigger': {
      ...mediaQuery(MediaQuery.MIN_MD, {
        border: `1px solid ${ColorVariables.UI_BG_07}`,
        borderRadius: '8px 8px 0 0',
      }),
      ...mediaQuery(MediaQuery.MAX_SM, {
        borderRadius: '0 0 0 0',
      }),
    },
  }),
}

const TasksTabsFragment = ({
  selectedTask,
  onTaskSelected,
  onTasksLoaded,
  onCreateNewTask,
  ...rest
}: TaskTabsFragmentProps) => {
  const [activeTab, setActiveTab] = useState(0)
  const { t } = useTranslation('Tasks')

  return (
    <div css={styles.container} {...rest}>
      <KibTabsNew
        css={styles.tabsContainer}
        onTabChange={(_, index) => setActiveTab(index || 0)}
      >
        <KibTabsNewItem label={t('Tasks:UPCOMING')}>
          <UpcomingTasksList
            selectedTask={selectedTask}
            onCreateNewTask={onCreateNewTask}
            onTaskSelected={onTaskSelected}
            onTasksLoaded={tasks => {
              if (activeTab === 0) {
                onTasksLoaded(tasks)
              }
            }}
          />
        </KibTabsNewItem>

        <KibTabsNewItem label={t('Tasks:COMPLETED')}>
          <CompletedTasksList
            selectedTask={selectedTask}
            onCreateNewTask={onCreateNewTask}
            onTaskSelected={onTaskSelected}
            onTasksLoaded={tasks => {
              if (activeTab === 1) {
                onTasksLoaded(tasks)
              }
            }}
          />
        </KibTabsNewItem>
      </KibTabsNew>
    </div>
  )
}

export default TasksTabsFragment
