import { media } from '@chewy/kib-utilities'
import { CSSInterpolation } from '@emotion/serialize'
import * as R from 'ramda'

import { MediaQueryType } from '../constants/mediaQuery'

const query = (mq: MediaQueryType) => media.query(mq)

export const mediaQuery = (
  mq: MediaQueryType,
  ...args: Array<CSSInterpolation>
) => ({
  [`@media${query(mq)}`]: Object.assign({}, ...args),
})

export const mediaQueryMatches = (...args: Array<MediaQueryType>) =>
  R.any(mq => window.matchMedia(query(mq)).matches, args)
