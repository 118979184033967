/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { KibIngressCard } from '@chewy/kib-cards-react'
import { KibPdfIcon } from '@chewy/kib-icons-react/legacy'
import { KibSheet, KibSheetProps } from '@chewy/kib-overlays-react'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { ColorVariables } from '../../../../constants/colors'
import { Spacing } from '../../../../constants/spacing'
import i18n from '../../../../locales/i18n'
import {
  PatientVaccinationCertificates,
  VaccinationCertificate,
} from '../../../../types/dto/Certificate'
import { Patient } from '../../../../types/dto/Patient'
import { FilePreviewTemplate } from '../../../../types/entities'
import { spacing } from '../../../../utils/spacing'
import Button, { ButtonSize } from '../../button/Button'
import Icon from '../../icon/Icon'
import Spinner from '../../spinner/Spinner'
import Text, { TextVariant } from '../../typography/Text/Text'

export const CertificatesType = {
  VACCINATION: 'VACCINATION',
  RABIES: 'RABIES',
} as const

export type CertificatesTypeProp =
  (typeof CertificatesType)[keyof typeof CertificatesType]

type CertificatesSheetProps = Omit<KibSheetProps, 'children' | 'title'> & {
  certificates?: PatientVaccinationCertificates
  loading?: boolean
  onCertificateSelected?: (file: FilePreviewTemplate) => void
  onStartNewMessage?: () => void
  patient?: Patient
  type: CertificatesTypeProp
}

const styles = {
  sheetContent: css({
    '.kib-ingress-card': {
      borderRadius: 0,
    },
    '.kib-sheet__content': {
      padding: spacing(0, 0, Spacing.S2, 0),
    },
  }),
  certificateCard: css({
    border: 0,
    borderBottom: `1px solid ${ColorVariables.UI_BG_04}`,
  }),
  icon: css({
    width: 32,
    height: 32,
    backgroundColor: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  pdfIcon: css({
    width: 20,
    height: 20,
    color: ColorVariables.UI_BG_PRIMARY,
  }),
  spinner: css({
    marginTop: spacing(Spacing.S4),
    alignSelf: 'center',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
  emptyContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: spacing(Spacing.S4),
    padding: spacing(Spacing.S4),
  }),
}

const getCertificates = (
  certificates?: PatientVaccinationCertificates,
  type?: CertificatesTypeProp,
) =>
  type === CertificatesType.VACCINATION
    ? certificates?.proofOfVaccinationsCertificates
    : certificates?.rabiesCertificates

const getEmptyText = (type: CertificatesTypeProp, patient?: Patient) =>
  type === CertificatesType.VACCINATION
    ? i18n.t('Pet:NO_VACCINATION_DOCUMENTS_FOR_PATIENT', {
      patientName: patient?.name || '',
    })
    : i18n.t('Pet:NO_RABIES_DOCUMENTS_FOR_PATIENT', {
      patientName: patient?.name || '',
    })

const CertificatesSheet = ({
  certificates,
  loading,
  patient,
  type,
  onCertificateSelected,
  onStartNewMessage,
  ...rest
}: CertificatesSheetProps) => {
  const { t } = useTranslation('Pet')

  const certificatesList = getCertificates(certificates, type)

  return (
    <KibSheet
      bottom
      css={styles.sheetContent}
      title={t('Pet:VACCINATION_DOCUMENTS')}
      {...rest}
    >
      {loading && <Spinner css={styles.spinner} />}

      {!R.isEmpty(certificatesList) ? (
        certificatesList?.map((certificate: VaccinationCertificate) => (
          <KibIngressCard
            css={styles.certificateCard}
            id={`cs-item-${certificate?.id?.substring(0, 3).toLowerCase()}`}
            image={
              <Icon
                circle
                Component={<KibPdfIcon css={styles.pdfIcon} />}
                css={styles.icon}
              />
            }
            key={certificate.id}
            title={certificate.name}
            onClick={() =>
              onCertificateSelected?.({
                ...certificate,
                url: certificate?.fileUrl,
                contentType: 'application/pdf',
              })
            }
          />
        ))
      ) : (
        <div css={styles.emptyContainer}>
          <Text variant={TextVariant.PARAGRAPH_1}>
            {getEmptyText(type, patient)}
          </Text>

          <Button
            id="cs-new-mes"
            size={ButtonSize.MEDIUM}
            onClick={onStartNewMessage}
          >
            {t('Pet:START_NEW_MESSAGE_HERE')}
          </Button>
        </div>
      )}
    </KibSheet>
  )
}

export default CertificatesSheet
