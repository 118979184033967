/** @jsxImportSource @emotion/react */
import {
  KibStandaloneLink,
  KibStandaloneLinkProps,
} from '@chewy/kib-menus-react'
import { css, Interpolation, Theme } from '@emotion/react'

export const LinkSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const

export type LinkSizeProp = (typeof LinkSize)[keyof typeof LinkSize]

type LinkProps = Omit<KibStandaloneLinkProps, 'size' | 'href'> & {
  Icon?: React.JSXElementConstructor<any> | string
  children?: React.ReactNode
  href?: React.AnchorHTMLAttributes<HTMLAnchorElement>['href']
  iconStyle?: Interpolation<Theme>
  onClick?: () => void
  showNavigationIcon?: boolean
  size?: LinkSizeProp
}

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
  }),
}

const Link = ({
  size = LinkSize.SMALL,
  showNavigationIcon = false,
  children,
  className,
  Icon,
  iconStyle,
  href,
  onClick,
  ...rest
}: LinkProps) =>
  showNavigationIcon ? (
    <KibStandaloneLink
      className={className}
      // eslint-disable-next-line
      href={href ? href : 'javascript:void(0);'}
      role="button"
      size={size}
      onClick={onClick}
      {...rest}
    >
      {children}
    </KibStandaloneLink>
  ) : (
    <div css={styles.container}>
      <a
        className={`kib-standalone-link ${
          size !== 'small' && `kib-standalone-link--${size}`
        } ${className}`}
        // eslint-disable-next-line
        href={href ? href : 'javascript:void(0);'}
        role="button"
        onClick={onClick}
        {...rest}
      >
        <span
          className="kib-standalone-link__label"
          css={css({ fontWeight: 'normal' })}
        >
          {children}
        </span>
      </a>

      {Icon && <Icon css={iconStyle} />}
    </div>
  )

export default Link
