import * as R from 'ramda'

import { Question } from '../types/dto/Appointment'

export const getQuestionsAnswersTextMaps = (questions: Question[]) =>
  R.reduce(
    ({ questionsMap, answersMap: parentAnswerMap }, { id, text, answers }) => {
      questionsMap[id] = text || ''

      const currentAnswersMap = R.reduce(
        (answersMap, { id: answerId, text: answerText }) => {
          answersMap[answerId] = answerText || ''
          return answersMap
        },
        {} as Record<string, string>,
        answers || [],
      )

      return {
        questionsMap,
        answersMap: { ...parentAnswerMap, ...currentAnswersMap },
      }
    },
    {
      questionsMap: {} as Record<string, string>,
      answersMap: {} as Record<string, string>,
    } as Record<string, Record<string, string>>,
    questions,
  )

export const convertToResults = (
  selectedMap: Record<string, Record<string, Record<string, string>>>,
  questionTextsMap: Record<string, string>,
  answersTextMap: Record<string, string>,
) => {
  const mapInputs = R.pipe(
    R.toPairs,
    R.map(([id, value]) => ({ id, value })),
  )

  const mapQuestionAnswers = R.curry((questionId, [answerId, inputs]) => ({
    answerId,
    answerText: answersTextMap[answerId],
    questionId,
    questionText: questionTextsMap[questionId],
    inputs: Object.keys(inputs)?.length ? mapInputs(inputs) : null,
  }))

  const mapAnswers = ([questionId, answers]: Array<[string, any]>) =>
    R.map(mapQuestionAnswers(questionId), R.toPairs(answers))

  return R.pipe(R.toPairs, R.map(mapAnswers), R.flatten)(selectedMap)
}
