import CanadianProvinces from '../json/CanadianProvinces.json'
import USAStates from '../json/USAStates.json'
import i18n from '../locales/i18n'

export const COUNTRIES_MAP = {
  US: {
    code: 'US',
    name: 'USA',
    states: USAStates,
    stateTitle: () => i18n.t('Account:STATE'),
  },
  CA: {
    code: 'CA',
    name: 'Canada',
    states: CanadianProvinces,
    stateTitle: () => i18n.t('Account:PROVINCE'),
  },
} as Record<string, any>
