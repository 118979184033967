export const clickable = (onClick: () => void) => ({
  role: 'button',
  onClick,
  onKeyUp: (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick()
    }
  },
  tabIndex: 0,
  style: { cursor: 'pointer' },
})
