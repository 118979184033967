/** @jsxImportSource @emotion/react */
import { forwardRef, useContext, useImperativeHandle, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { Chat } from '../../../types/dto/Chat'
import { Message } from '../../../types/entities'
import { mediaQueryMatches } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import Spinner from '../spinner/Spinner'
import ChatClientContext from './ChatClientContext'
import MessageView from './MessageView'

type ConversationViewProps = {
  chat?: Chat
  hasMore: boolean
  loadMore: (page: number) => void
  loading?: boolean
  messages?: Message[]
  onShowMediaPreview?: (message: Message) => void
}

export type ConversationViewHandle = {
  scrollToBottom: () => void
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
  }),
  spinner: css({
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
    alignSelf: 'center',
  }),
  firstMessage: css({
    marginTop: spacing(Spacing.S4),
  }),
  message: css({
    marginBottom: spacing(Spacing.S4),
  }),
}

const ConversationView = forwardRef<
ConversationViewHandle,
ConversationViewProps
>(function ConversationView(
  {
    chat, messages, loading, hasMore, loadMore, onShowMediaPreview, ...rest
  },
  ref,
) {
  const client = useContext(ChatClientContext)

  const scrollRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (scrollRef?.current) {
      if (mediaQueryMatches(MediaQuery.MAX_SM)) {
        setTimeout(() => {
          document.body.scrollTo(0, scrollRef?.current?.scrollHeight || 0)
        }, 1)
      } else {
        scrollRef.current.scrollTop = scrollRef?.current?.scrollHeight
      }
    }
  }

  useImperativeHandle(ref, () => ({
    scrollToBottom,
  }))

  return (
    <div css={styles.container} {...rest} ref={scrollRef}>
      {loading ? (
        <Spinner css={styles.spinner} />
      ) : (
        <InfiniteScroll
          isReverse
          hasMore={hasMore}
          initialLoad={false}
          loadMore={loadMore}
          loader={<Spinner css={styles.spinner} />}
          threshold={100}
          useWindow={false}
        >
          {messages?.map((message: Message, index: number) => (
            <MessageView
              business={chat?.business}
              client={{ id: client?.user.identity || '' }}
              css={[
                styles.message,
                index === 0
                  && mediaQueryMatches(MediaQuery.MIN_MD)
                  && styles.firstMessage,
              ]}
              index={index}
              key={message.id}
              message={message}
              nextMessage={messages?.[index + 1]}
              previousMessage={messages?.[index - 1]}
              onShowMediaPreview={onShowMediaPreview}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
})

export default ConversationView
