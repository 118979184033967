/** @jsxImportSource @emotion/react */
import KibTag, { KibTagProps } from '@chewy/kib-tag-react'

export const TagTheme = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  CAUTION: 'caution',
  ERROR: 'error',
  NEUTRAL: 'neutral',
} as const

export type TagThemeProp = (typeof TagTheme)[keyof typeof TagTheme]

type TagProps = Omit<KibTagProps, 'tag'> & {
  children?: React.ReactNode
  theme?: TagThemeProp
}

const Tag = ({
  children,
  theme = TagTheme.DEFAULT,
  ...rest
}: TagProps) => (
  <KibTag theme={theme} {...rest}>
    {children}
  </KibTag>
)

export default Tag
