/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import { css } from '@emotion/react'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import BookAppointmentCard from '../../common/book/BookAppointmentCard'
import Snackbar from '../../common/snackbar/Snackbar'

const styles = {
  container: css({
    padding: spacing(Spacing.S4, 0, Spacing.S8, 0),
    ...mediaQuery(MediaQuery.MAX_SM, {
      padding: spacing(0),
    }),
    ...mediaQuery(MediaQuery.MD, {
      padding: spacing(Spacing.S4, Spacing.S6, Spacing.S8, Spacing.S6),
    }),
  }),
}

const BookAppointmentPage = () => {
  const { t } = useTranslation('Home')
  const navigate = useNavigate()

  const { state: { businessId, patient, suggestedAppointment } = {} } = useLocation()

  const [showError, setShowError] = useState(false)

  const handleBooked = () => {
    navigate(-1)
  }

  const handleBookError = () => {
    setShowError(true)
  }

  return (
    <KibGrid align="center">
      <KibGridItem
        css={styles.container}
        offset="2@min-lg"
        push="2@min-lg"
        span="4@max-sm 8@min-md 8@min-lg"
      >
        <BookAppointmentCard
          businessId={businessId}
          patient={patient}
          suggestedAppointment={suggestedAppointment}
          onBooked={handleBooked}
          onError={handleBookError}
        />
      </KibGridItem>

      <Snackbar
        align="start"
        message={t('Home:ERROR_WHILE_BOOKING_APPOINTMENT')}
        visible={showError}
        onClose={() => setShowError(false)}
      />
    </KibGrid>
  )
}

export default BookAppointmentPage
