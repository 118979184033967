/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { MediaQuery } from '../../../constants/mediaQuery'
import { Spacing } from '../../../constants/spacing'
import { mediaQuery } from '../../../utils/mediaQuery'
import { spacing } from '../../../utils/spacing'
import InputText from '../input/InputText'
import SelectInput from '../input/SelectInput'
import { Reason } from './BookPatientVisitTypeForm'

type BookVisitDetailsFormProps = {
  notes?: string
  onNotesChanged: (notes: string | undefined) => void
  onReasonSelected: (reason: Reason | undefined) => void
  reasons?: Reason[]
  selectedReason?: Reason
  suggestedAppointmentReasonId?: string
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    ...mediaQuery(MediaQuery.MIN_MD, {
      padding: spacing(Spacing.S4, 0, Spacing.S4, Spacing.S4),
    }),
    ...mediaQuery(MediaQuery.MAX_SM, {
      margin: spacing(Spacing.S4, 0, 0, 0),
    }),
  }),
}

const BookVisitDetailsForm = ({
  reasons,
  selectedReason: selectedReasonProp,
  suggestedAppointmentReasonId,
  notes: notesProp,
  onReasonSelected,
  onNotesChanged,
  ...rest
}: BookVisitDetailsFormProps) => {
  const { t } = useTranslation('Home')

  const [selectedReason, setSelectedReason] = useState<Reason | undefined>(
    selectedReasonProp,
  )
  const [notes, setNotes] = useState<string | undefined>(notesProp)

  const reasonOptions =
    reasons?.map(reason => ({
      label: reason.name,
      value: reason.id,
    })) || []

  const handleReasonSelected = (id: string) => {
    const newReason = reasons?.find((item: Reason) => item.id === id)

    setSelectedReason(newReason)
    onReasonSelected(newReason)
  }

  const handleNotesChanged = (value: string | undefined) => {
    setNotes(value)
    onNotesChanged(value)
  }

  useEffect(() => {
    if (suggestedAppointmentReasonId && !R.isEmpty(reasons)) {
      handleReasonSelected(suggestedAppointmentReasonId)
    }
  }, [reasons, suggestedAppointmentReasonId])

  return (
    <div css={styles.container} {...rest}>
      <SelectInput
        emptyOption
        id="ba-reasons"
        label={t('Home:REASON_FOR_VISIT')}
        options={reasonOptions}
        value={selectedReason?.id}
        onChange={event => {
          handleReasonSelected(event.target.value)
        }}
      />

      <InputText
        id="ba-add-det"
        label={t('Home:SHARE_ADDITIONAL_DETAILS')}
        placeholder={t('Home:SHARE_ADDITIONAL_DETAILS_PLACEHOLDER')}
        value={notes}
        onChangeText={handleNotesChanged}
      />
    </div>
  )
}

export default BookVisitDetailsForm
