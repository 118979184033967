// client events
export const TOKEN_ABOUT_TO_EXPIRE = 'tokenAboutToExpire'
export const TOKEN_EXPIRED = 'tokenExpired'
export const CONVERSATION_ADDED = 'conversationAdded'

// conversation events
export const MESSAGE_ADDED = 'messageAdded'
export const UPDATED = 'updated'

// conversation update reasons
export const LAST_READ_MESSAGE_INDEX = 'lastReadMessageIndex'
export const LAST_MESSAGE = 'lastMessage'
