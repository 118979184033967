export const MediaQuery = {
  MIN_XS: 'min-xs',
  XS: 'xs',
  MAX_XS: 'max-xs',
  MIN_SM: 'min-sm',
  SM: 'sm',
  MAX_SM: 'max-sm',
  MIN_MD: 'min-md',
  MD: 'md',
  MAX_MD: 'max-md',
  MIN_LG: 'min-lg',
  LG: 'lg',
  MAX_LG: 'max-lg',
  MIN_XL: 'min-xl',
} as const

export type MediaQueryType = (typeof MediaQuery)[keyof typeof MediaQuery]
