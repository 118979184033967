import moment from 'moment-timezone'
import * as R from 'ramda'

export const addYearToFormatIfNotCurrent = R.curry((format, momentDate) =>
  momentDate
    ? moment().year() !== momentDate.year()
      ? momentDate.format(`${format}, YYYY`)
      : momentDate.format(format)
    : '',
)

export const getTimeDiffInSeconds = (
  firstDate: Date | string | undefined,
  secondDate = moment.utc(),
) => (firstDate ? moment(firstDate).diff(secondDate, 'seconds') : undefined)
