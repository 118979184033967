/** @jsxImportSource @emotion/react */
import { KibCloseIcon } from '@chewy/kib-icons-react/legacy'
import { css } from '@emotion/react'

import { FilePreviewTemplate } from '../../../types/entities'
import Button, { ButtonEmphasis } from '../button/Button'

type ImagePreviewProps = {
  file?: FilePreviewTemplate
  onClose: () => void
  open?: boolean
}

const styles = {
  container: css({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  }),
  image: css({
    maxWidth: '95%',
    height: 'auto',
  }),
  close: css({
    position: 'absolute',
    top: 12,
    right: 12,
  }),
}

const ImagePreview = ({ file, open, onClose, ...rest }: ImagePreviewProps) => (
  <>
    {open && (
      <div css={styles.container} {...rest}>
        <img
          alt={file?.name}
          css={styles.image}
          src={file?.url}
        />

        <Button
          iconOnly
          css={styles.close}
          emphasis={ButtonEmphasis.TERTIARY}
          icon={<KibCloseIcon />}
          onClick={onClose}
        />
      </div>
    )}
  </>
)

export default ImagePreview
