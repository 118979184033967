import { gql } from '@apollo/client/core'

export const GET_APPOINTMENT_REASONS = gql`
  query GetAppointmentReasons($businessId: ID!) {
    businessAppointmentReasons(businessId: $businessId) {
      businessAppointmentTypeId
      name
      staticAppointmentType {
        id
        name
        category
      }
    }
  }
`

export const GET_AVAILABLE_STAFF = gql`
  query GetAvailableStaff(
    $businessId: ID!
    $day: LocalDate!
    $businessAppointmentTypeId: ID!
  ) {
    availableStaffByBusinessAppointmentType(
      businessId: $businessId
      day: $day
      businessAppointmentTypeId: $businessAppointmentTypeId
    ) {
      personInfo {
        id
        firstName
        lastName
      }
      timeSlots {
        from
        to
      }
    }
  }
`

export const GET_TIME_SLOTS = gql`
  query GetTimeslots(
    $businessId: ID!
    $businessAppointmentTypeId: ID!
    $day: LocalDate!
  ) {
    timeSlotsByBusinessAppointmentType(
      businessId: $businessId
      businessAppointmentTypeId: $businessAppointmentTypeId
      day: $day
    ) {
      from
      to
    }
  }
`

export const GET_QUESTIONS = gql`
  query GetQuestions($businessId: ID!, $appointmentId: ID!) {
    questions(businessId: $businessId, appointmentId: $appointmentId) {
      id
      text
      answers {
        id
        text
        inputs {
          id
          typeId
        }
      }
      rank
    }
  }
`

export const ANSWER_INPUT_TYPES = gql`
  query GetAnswerInputTypes {
    answerInputTypes {
      id
      name
    }
  }
`

export const GET_APPOINTMENT_DOCUMENTS = gql`
  query GetAppointmentDocuments($id: ID!) {
    appointment(id: $id) {
      id
      documents {
        id
        name
        file
        signed
      }
      business {
        id
        timezone
      }
    }
  }
`

export const GET_APPOINTMENT_DETAILS = gql`
  query GetAppointmentDetails($id: ID!) {
    appointment(id: $id) {
      id
      business {
        id
        primaryPhone
        secondaryPhone
        timezone
      }
      veterinarianInfo {
        id
        firstName
        lastName
        photo
        photoThumbnail
      }
      vetTechInfo {
        id
        firstName
        lastName
        photo
        photoThumbnail
      }
      notes
      dischargeNotes
      scheduledStartDatetime
      businessReason {
        businessAppointmentTypeId
        name
      }
      patient {
        id
        name
      }
      timelineDetails {
        isSickVisit
      }
    }
  }
`
