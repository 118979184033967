import moment from 'moment-timezone'
import * as R from 'ramda'

import { Business } from '../types/dto/Business'
import { Client } from '../types/dto/Client'
import { Constant } from '../types/dto/Constants'

export const findConstantByName = (
  constantName: string,
  constants: Constant[] | undefined,
): Constant | undefined =>
  Array.isArray(constants)
    ? constants.find(({ name }) => name === constantName) || undefined
    : undefined

export const getTimezone = () => {
  const offset = -(new Date().getTimezoneOffset() / 60)
  return offset >= 0 ? `+${offset}` : offset.toString()
}

export const getPersonName = (
  person:
  | {
    firstName?: string
    lastName?: string
  }
  | undefined,
) => `${person?.firstName || ''} ${person?.lastName || ''}`.trim()

export const getAddress = (data: Business | Client | undefined) => {
  if (!data) {
    return ''
  }

  const { address, suite, city, state, zip } = data

  const suiteAddress = [address, suite].filter(Boolean).join(' ')

  const cityString = city ? `${city},` : undefined

  const cityStateZip = [cityString, state, zip].filter(Boolean).join(' ')

  return [suiteAddress, cityStateZip].filter(Boolean).join('\n')
}

export const distinct = (oldObj: any, newObj: any): Record<string, string> =>
  R.pickBy((value, key) => !R.equals(oldObj[key], value), newObj)

const dateDiff = (
  date: moment.MomentInput,
  currentDate: moment.MomentInput,
  unit: moment.unitOfTime.Diff,
) => moment(currentDate).diff(moment(date), unit)

export const dateDiffInYears = (
  date: moment.MomentInput,
  currentDate: moment.MomentInput = new Date(),
) => dateDiff(date, currentDate, 'years')

export const dateDiffInMonths = (
  date: moment.MomentInput,
  currentDate: moment.MomentInput = new Date(),
) => dateDiff(date, currentDate, 'months')

export const dateDiffInWeeks = (
  date: moment.MomentInput,
  currentDate: moment.MomentInput = new Date(),
) => dateDiff(date, currentDate, 'weeks')

export const dateDiffInDays = (
  date: moment.MomentInput,
  currentDate: moment.MomentInput = new Date(),
) => dateDiff(date, currentDate, 'days')

export const sortAlphabeticallyByName = (
  a: { name: string },
  b: { name: string },
) => a.name.localeCompare(b.name)
