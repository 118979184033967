export const linkify = (
  text: string | undefined | null,
  linkReplaceAliasFunc?: (url: string) => string,
) => {
  const urlRegex =
    // eslint-disable-next-line max-len
    /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]\b|\b(?:www\d?\.)?[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]\.\w{2,}\b)/gi
  return text?.replace(urlRegex, url => {
    const normalizedUrl = /^(?:https?|ftp|file):/i.test(url)
      ? url
      : `http://${url}`
    const queryStringIndex = url.indexOf('?')
    const shortUrl =
      queryStringIndex !== -1 ? url.substring(0, queryStringIndex) : url

    return `<a href="${normalizedUrl}" target="_blank">${
      linkReplaceAliasFunc ? linkReplaceAliasFunc(shortUrl) : shortUrl
    }</a>`
  })
}
