/** @jsxImportSource @emotion/react */
import { KibEditIcon } from '@chewy/kib-icons-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../../constants/colors'
import { Spacing } from '../../../constants/spacing'
import { spacing } from '../../../utils/spacing'
import Button, {
  ButtonEmphasis,
  ButtonProps,
  ButtonSize,
  ButtonTheme,
} from '../button/Button'

type EditIconProps = ButtonProps & {
  onEdit: () => void
}

const styles = {
  iconContainer: css({
    border: `2px solid ${ColorVariables.ACTION_UTILITY_ALTERNATE_03}`,
    backgroundColor: ColorVariables.ACTION_UTILITY_ALTERNATE_PRIMARY,
    width: 32,
    height: 32,
    padding: spacing(Spacing.S1),
    borderRadius: '50%',
    '&:hover': css({
      cursor: 'pointer',
    }),
  }),
  icon: css({
    color: ColorVariables.ACTION_UTILITY_ALTERNATE_TEXT,
  }),
}

const EditIcon = ({ onEdit, ...rest }: EditIconProps) => (
  <Button
    iconOnly
    emphasis={ButtonEmphasis.TERTIARY}
    icon={<KibEditIcon css={styles.icon} />}
    size={ButtonSize.SMALL}
    theme={ButtonTheme.UTILITY}
    onClick={onEdit}
    {...rest}
  />
)

export default EditIcon
