/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import * as R from 'ramda'

import { ReminderState } from '../../../../constants/reminders'
import { Spacing } from '../../../../constants/spacing'
import { Reminder } from '../../../../types/dto/Reminder'
import { spacing } from '../../../../utils/spacing'
import Button, { ButtonSize } from '../../button/Button'
import Card from '../../card/Card/Card'
import Divider from '../../divider/Divider'
import Tag, { TagTheme } from '../../tag/Tag'
import Text, { TextVariant } from '../../typography/Text/Text'
import ReminderWidgetItem from './ReminderWidgetItem'

type RemindersWidgetProps = {
  hasUpcomingAppointments?: boolean
  onBook?: () => void
  reminders: Reminder[]
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    rowGap: spacing(Spacing.S3),
    padding: spacing(Spacing.S7, Spacing.S3, Spacing.S3, Spacing.S3),
  }),
  label: css({
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 0,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
  }),
  divider: css({
    marginTop: spacing(Spacing.S2),
  }),
  overdueText: css({
    textAlign: 'center',
  }),
  bookButton: css({
    marginLeft: 'auto',
  }),
}

const RemindersWidget = ({
  reminders,
  hasUpcomingAppointments,
  onBook,
  ...rest
}: RemindersWidgetProps) => {
  const { t } = useTranslation(['Common', 'Home'])

  const hasOverdueReminder = R.any(
    reminder => reminder.state.name === ReminderState.OVERDUE,
    reminders || [],
  )

  return (
    <Card css={styles.container} {...rest}>
      <Tag css={styles.label} theme={TagTheme.CAUTION}>
        {t('Home:REMINDERS').toUpperCase()}
      </Tag>

      {reminders?.map(reminder => (
        <ReminderWidgetItem key={reminder.id} reminder={reminder} />
      ))}

      {(!hasUpcomingAppointments || hasOverdueReminder) && (
        <Divider css={styles.divider} />
      )}

      {hasUpcomingAppointments && hasOverdueReminder && (
        <Text css={styles.overdueText} variant={TextVariant.FORM_HELPER}>
          {t('Home:WE_ADRESS_CARE_DURING')}
        </Text>
      )}

      {!hasUpcomingAppointments && (
        <Button
          css={styles.bookButton}
          size={ButtonSize.MEDIUM}
          onClick={() => onBook?.()}
        >
          {t('Common:BOOK')}
        </Button>
      )}
    </Card>
  )
}

export default RemindersWidget
