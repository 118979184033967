import moment from 'moment-timezone'

import { TagTheme } from '../components/common/tag/Tag'
import { DateFormat } from '../constants/dateFormat'
import i18n from '../locales/i18n'
import { Business, OpeningHours, WorkingHours } from '../types/dto/Business'
import { getAddress } from '.'

const SOON_HOURS = 1

export const getPracticeWorkingHoursMap = (
  openingHours?: OpeningHours[],
  timezone?: string,
) =>
  openingHours && timezone
    ? openingHours.reduce((map, openHours) => {
      if (openHours?.workingHours) {
        const dayOfWeekFormatted = moment
          .tz(openHours.workingHours.from, timezone)
          .format(DateFormat.DAY_OF_WEEK)
          .toLowerCase()
        map[dayOfWeekFormatted] = openHours.workingHours
      }
      return map
    }, {} as Record<string, WorkingHours>)
    : {}

export const isPracticeOpenNow = (
  workingHours?: WorkingHours,
  timezone?: string,
) => {
  if (!workingHours || !timezone) {
    return false
  }

  const { from, to } = workingHours
  return moment
    .tz(timezone)
    .isBetween(
      moment.tz(from, timezone),
      moment.tz(to, timezone),
      undefined,
      '[]',
    )
}

const isPracticeOpeningSoon = (
  workingHours?: WorkingHours,
  timezone?: string,
) => {
  if (!workingHours || !timezone) {
    return false
  }

  const { from } = workingHours
  return moment
    .tz(timezone)
    .isBetween(
      moment.tz(moment(from).add(-SOON_HOURS, 'hours'), timezone),
      moment.tz(moment(from), timezone),
      undefined,
      '[)',
    )
}

const isPracticeClosingSoon = (
  workingHours?: WorkingHours,
  timezone?: string,
) => {
  if (!workingHours || !timezone) {
    return false
  }

  const { to } = workingHours
  return moment
    .tz(timezone)
    .isBetween(
      moment.tz(moment(to).add(-SOON_HOURS, 'hours'), timezone),
      moment.tz(moment(to), timezone),
      undefined,
      '[]',
    )
}

export const getPracticeOpenStatus = (
  workingHours?: WorkingHours,
  timezone?: string,
) => {
  const isOpen = isPracticeOpenNow(workingHours, timezone)
  const isOpeningSoon = !isOpen && isPracticeOpeningSoon(workingHours, timezone)
  const isClosingSoon = isOpen && isPracticeClosingSoon(workingHours, timezone)

  return isOpeningSoon
    ? {
      text: i18n.t('Common:OPENING_SOON'),
      labelVariant: TagTheme.DEFAULT,
    }
    : isClosingSoon
      ? {
        text: i18n.t('Common:CLOSING_SOON'),
        labelVariant: TagTheme.DEFAULT,
      }
      : isOpen
        ? { text: i18n.t('Common:OPEN_NOW'), labelVariant: TagTheme.SUCCESS }
        : {
          text: i18n.t('Common:CLOSED'),
          labelVariant: TagTheme.ERROR,
        }
}

export const showBusinessOnMaps = (business: Business) => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${getAddress(business)}`,
    '_blank',
  )
}
