/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'

import { ColorVariables } from '../../constants/colors'
import { MAIN_ROUTES, RouteNames, ROUTES } from '../../constants/routes'
import { checkClientAuth, ClientAuthState } from '../../utils/auth'
import Spinner from '../common/spinner/Spinner'
import Toolbar from '../common/toolbar/Toolbar'

const styles = {
  container: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  }),
  spinnerContainer: css({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  spinner: css({
    alignSelf: 'center',
    color: ColorVariables.UI_BG_BRAND_PRIMARY,
  }),
}

const MainPage = () => {
  const navigate = useNavigate()
  const apolloClient = useApolloClient()
  const { isAuthenticated, logout } = useAuth0()

  const [authenticatedSuccessfully, setAuthenticatedSuccessfully] =
    useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const isInitialPage = window.location.pathname === '/'

  useEffect(() => {
    const checkAuth = async () => {
      setLoading(true)
      const auth = await checkClientAuth(apolloClient)
      setLoading(false)

      setAuthenticatedSuccessfully(
        auth.state === ClientAuthState.CLIENT_FOUND
          || auth.state === ClientAuthState.SINGLE_PERSON_FOUND,
      )

      if (auth.state === ClientAuthState.CLIENT_FOUND) {
        if (isInitialPage) {
          navigate(ROUTES[RouteNames.HOME].path)
        }
      } else if (auth.state === ClientAuthState.CLIENT_WITHOUT_BUSINESS_FOUND) {
        navigate(ROUTES[RouteNames.SIGN_IN_ERROR].path, {
          state: auth,
        })
      } else if (auth.state === ClientAuthState.NO_PERSON_FOUND) {
        navigate(ROUTES[RouteNames.SIGN_IN_ERROR].path, {
          state: auth,
        })
      } else if (auth.state === ClientAuthState.SINGLE_PERSON_FOUND) {
        if (isInitialPage) {
          navigate(ROUTES[RouteNames.HOME].path)
        }
      } else if (auth.state === ClientAuthState.MULTIPLE_PERSONS_FOUND) {
        navigate(ROUTES[RouteNames.SIGN_IN_ERROR].path, {
          state: auth,
        })
      } else {
        navigate(ROUTES[RouteNames.SIGN_IN_ERROR].path, {
          state: auth,
        })
      }
    }

    if (isAuthenticated) {
      checkAuth()
    }
  }, [isAuthenticated])

  const handleMenuSelected = (path: string) => {
    navigate(path)
  }

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/auth/sign-out`,
      },
    })
  }

  return (
    <div css={styles.container}>
      <Toolbar
        isAuthenticated={authenticatedSuccessfully}
        onLogout={handleLogout}
        onRouteChanged={handleMenuSelected}
      />

      {loading && isInitialPage && (
        <div css={styles.spinnerContainer}>
          <Spinner css={styles.spinner} />
        </div>
      )}

      <Routes>
        {Object.values(MAIN_ROUTES).map(route =>
          Array.isArray(route.path) ? (
            route.path.map(pathItem => (
              <Route
                element={<route.Component />}
                key={pathItem}
                path={pathItem}
              />
            ))
          ) : (
            <Route
              element={<route.Component />}
              key={route.path}
              path={route.path}
            />
          ),
        )}
      </Routes>
    </div>
  )
}

export default MainPage
