/** @jsxImportSource @emotion/react */
import { KibEmptyState, KibEmptyStateProps } from '@chewy/kib-content-groups-react'
import { css } from '@emotion/react'

import { Spacing } from '../../../constants/spacing'
import { spacing } from '../../../utils/spacing'

const styles = {
  emptyContainer: css({
    h1: {
      marginTop: spacing(Spacing.S4),
      paddingTop: 0,
    },
  }),
}

const EmptyState = ({ ...rest }: KibEmptyStateProps) => <KibEmptyState css={styles.emptyContainer} {...rest} />

export default EmptyState
